
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { billGroupService } from "./billgroup.service";

@Options({})
export default class MemberForm extends mixins(ModalFormMixin, DataTableMixin) {
  formEvent = FormEvents.MEMBER_FORM;
  modalId = "member-form";
  reloadStore = settingsStore.getAllBillGroups;

  // service = practiceGroupsService;
  primaryKey = "EmployeeId";

  // payload = {
  //   Employees: [],
  //   EmployeeId: "",
  // };

  fields = [
    {
      title: "First Name",
      name: "FirstName",
    },
    {
      title: "Last Name",
      name: "Surname",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any) => {
        try {
          var response = await billGroupService.removeUserFromGroup(prop.EmployeeId);
          await this.fetchBillgroup();
          // this.reloadStore();
          this.toast("success", response.Message);
        } catch (error) {
          this.handleError(error);
        }
      },
    },
  ];

  get users() {
    return settingsStore.users;
  }

  async savedata(payload: any) {
    try {
      const data = {
        EmployeeId: payload.EmployeeId,
        Id: payload.Id,
      };
      console.log("Add member payload", this.payload);
      this.isLoading = true;
      var responses = await billGroupService.addMember(data);
      // this.reloadStore();
      this.isLoading = false;
      this.toast("success", responses.Message);
      await this.fetchBillgroup();
    } catch (error) {
      console.log("Add Member Error", error);
      this.handleError(error);
    }
  }
  async fetchBillgroup() {
    var billGroupData = await billGroupService.findOne(this.payload.Id);
    this.payload = billGroupData.Data;
  }
}
