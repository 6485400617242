import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "breadcrumb breadcrumb-arrow" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { href: "#" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = { href: "#" }
const _hoisted_7 = { class: "breadcrumb-item active" }
const _hoisted_8 = { class: "nk-content-inner mt-1" }
const _hoisted_9 = { class: "nk-content-body" }
const _hoisted_10 = { class: "nk-block-head nk-block-head-sm" }
const _hoisted_11 = { class: "nk-block-between" }
const _hoisted_12 = { class: "nk-block-head-content" }
const _hoisted_13 = { class: "nk-block-title page-title" }
const _hoisted_14 = { class: "nk-block-head-content" }
const _hoisted_15 = { class: "nk-block nk-block-lg card card-bordered card-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progressNotesForm = _resolveComponent("progressNotesForm")!
  const _component_progressNotesViewForm = _resolveComponent("progressNotesViewForm")!
  const _component_taskAddForm = _resolveComponent("taskAddForm")!
  const _component_courtUpdateForm = _resolveComponent("courtUpdateForm")!
  const _component_cmaMatterForm = _resolveComponent("cmaMatterForm")!
  const _component_matterForm = _resolveComponent("matterForm")!
  const _component_time_sheets_form = _resolveComponent("time-sheets-form")!
  const _component_nc_dropdown_menu = _resolveComponent("nc-dropdown-menu")!
  const _component_nc_spinner = _resolveComponent("nc-spinner")!
  const _component_table_tools = _resolveComponent("table-tools")!
  const _component_pfizer_table = _resolveComponent("pfizer-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_progressNotesForm),
    _createVNode(_component_progressNotesViewForm),
    _createVNode(_component_taskAddForm),
    _createVNode(_component_courtUpdateForm),
    _createVNode(_component_cmaMatterForm),
    _createVNode(_component_matterForm),
    _createVNode(_component_time_sheets_form),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", null, [
        _createElementVNode("ul", _hoisted_2, [
          _createElementVNode("li", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('message.matters.modules.progressNotes.progressNotesPage.breadCrumb1')), 1)
          ]),
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('message.matters.modules.progressNotes.progressNotesPage.breadCrumb2')), 1)
          ]),
          _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t('message.matters.modules.progressNotes.progressNotesPage.breadCrumb3')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t('message.matters.modules.progressNotes.progressNotesPage.title')), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_nc_dropdown_menu, {
                  classes: "bg-blue-700",
                  options: _ctx.optionsData
                }, null, 8, ["options"])
              ])
            ])
          ]),
          _withDirectives(_createVNode(_component_nc_spinner, null, null, 512), [
            [_vShow, _ctx.isLoading]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_15, [
            _createVNode(_component_pfizer_table, {
              onTableReady: _ctx.handleTableReady,
              fields: _ctx.fields,
              "table-data": _ctx.ProgressNotes,
              options: _ctx.options,
              "datatable-options": _ctx.datatableOptions,
              primaryKey: "Id",
              serverSide: true,
              serverSideDataName: 'data',
              tableId: "progress-notes-table",
              requestMethod: "POST",
              key: _ctx.customKey,
              url: "/api/Matter/ProgressNote/ProgressNotes"
            }, {
              actions: _withCtx((prop) => [
                _createVNode(_component_table_tools, {
                  data: prop,
                  "hide-tool-tip": false,
                  actions: _ctx.tableActions
                }, null, 8, ["data", "actions"])
              ]),
              _: 1
            }, 8, ["onTableReady", "fields", "table-data", "options", "datatable-options"])
          ], 512), [
            [_vShow, _ctx.isLoading== false]
          ])
        ])
      ])
    ])
  ], 64))
}