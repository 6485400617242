import { AppResponse } from "../../../shared/interfaces/app-response";
import { BaseService } from "../../../shared/services/base-service";

class CrmPipelineService extends BaseService {
  resource = "";

  // findIncomeStatement(): Promise<AppResponse> {
  //   return this.get("GetReportSchedule/BS");
  // }
  // findFinPosition(): Promise<AppResponse> {
  //   return this.get("GetReportSchedule/BSS");
  // }

  // updateReportConfig(reportData: any): Promise<AppResponse> {
  //   return this.post("Report/UpdateScheduleInfo", reportData);
  // }

  // addConfig(payload: any): Promise<AppResponse> {
  //   return this.post("Report/AddScheduleInfo", payload);
  // }

  // removeReport(reportConfigId: number): Promise<AppResponse> {
  //   return this.post(`DeleteReportSchedule/${reportConfigId}`);
  // }
  getPipeline(): Promise<AppResponse> {
    return this.get(`LeadStages/GetLeadStagesForPipeline`);
  }
  getData(configId: any): Promise<AppResponse> {
    const formData = new FormData();
    //   formData.append
    return this.post(`Leads/GetLeads`);
  }

  leadLossReasons() {
    return this.get("PLOV/LeadLossReasons", null, true);
  }

  updateLeadLostReason(payload: Record<string, unknown>) {
    return this.post("Leads/UpdateLeadLostReason", payload);
  }
}

export const crmPipelineService = new CrmPipelineService();
