
import { Options, Vue } from "vue-class-component";
import { UserLoginDto } from "../dto/login.dto";
import { authStore } from "../auth.store";
import testModal from "./test.modal.vue";
import { TenantConfiguration } from "../../../app/app.model";
import { AuthResponse } from "../auth.model";
import { required } from "@vuelidate/validators";
import { ProvideReactive } from "vue-property-decorator";

@Options({
  components: {
    testModal,
  },

  validations() {
    return {
      userLoginDto: {
        Username: { required },
        Password: { required },
      },
    };
  },
})
export default class Login extends Vue {
  userLoginDto: Partial<UserLoginDto> = {};

  // @ProvideReactive("v$") $v = v$;

  // v$ = useVuelidate();
  formEvent = "show-test-modal";
  lang = "en";
  //   t = useI18n();
  //  locale = useI18n();

  get auth(): Partial<AuthResponse> {
    return authStore.auth;
  }

  mounted() {
    this.loadJS();
  }

  get tenantConfiguration(): Partial<TenantConfiguration> {
    return authStore.tenantConfiguration;
  }

  async login(userLoginDto: UserLoginDto): Promise<void> {
    // console.log({ payload: this.userLoginDto });
    try {
      this.isLoading = true;
      // console.log(this.v$);
      await this.validate();
      await authStore.login(userLoginDto);
      this.toast("success", "Login success");
      this.isLoading = false;
      // this.$router.push({ name: "Dashboard" });
      window.location.replace("/");
    } catch (error) {
      this.handleError(error);
    }
  }

  setLang(lang: string): void {
    // console.log(lang, "langb4loc", this.$i18n.locale);

    this.$i18n.locale = lang;
  }

  // async login(userLoginDto: UserLoginDto): Promise<void> {
  //   try {
  //     this.isLoading = true;
  //     const response = await AuthService.login(userLoginDto);
  //     this.toastSuccess(response.Message);
  //     this.isLoading = false;
  //   } catch (error) {
  //     this.handleError(error);
  //   }
  // }
}
