
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import { accountService } from "./account.service";
import { PostingGroup } from "./bank_account_dto";

@Options({})
export default class BankAccountForm extends mixins(ModalFormMixin) {
  modalId = "bank-account-form";
  formEvent = FormEvents.BANK_ACCOUNT_FORM;
  service = accountService;
  primaryKey = "BankAccountId";
  reloadStore = settingsStore.findAllBankAccounts;

  get postingGroups(): PostingGroup[] {
    return settingsStore.postingGroups;
  }

  // async savedata(payload: any) {
  //   await super.save(payload);
  //   this.$emit("refresh")
  // }
}
