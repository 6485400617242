import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-control-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Datepicker, {
      inputClassName: "form-control form-control-lg",
      enableTimePicker: _ctx.enableTimePicker,
      monthPicker: _ctx.monthPicker,
      timePicker: _ctx.timePicker,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _ctx.setDate,
      format: _ctx.nFormat,
      placeholder: "Pick a Date",
      id: _ctx.id
    }, null, 8, ["enableTimePicker", "monthPicker", "timePicker", "modelValue", "onUpdate:modelValue", "format", "id"])
  ]))
}