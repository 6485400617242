
  import {mixins, Options} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {GlobalEvent} from "@/shared/plugins/tiny-emitter";
  import { FormEvents } from '@/constants';
  import { ContactService, ContactService2 } from "./contact.service";
  import {VueEditor} from "vue3-editor";

  @Options({
    components: {
      VueEditor
    },
    emits: ["reloadContactMemo"],
  })
  export default class AddMemoForm extends mixins(ModalFormMixin) {
    modalId = 'show-contacts-memo';
    formEvent = FormEvents.SHOW_CONTACT_MEMO;
    service = ContactService;
    primaryKey = "PeopleId";
    memoTypes = [
      {name : "Note", value : 1}, {name : "Email", value : 2}, {name : "Call", value : 3}
    ];
    formData = {
      PeopleId : 0,
      InteractionType : 0,
      Summary : ""
    };

    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        this.formData.PeopleId = this.payload.id;
        // console.log("The form data is: ", this.formData, this.payload);
        // await this.validate();
        // this.globalLoader(true);
        await ContactService2.addMemo(this.formData);
        this.isLoading = false;
        this.toast("success", "Memo added successfully");
        this.formData = {
          PeopleId : 0,
          InteractionType : 0,
          Summary : ""
        };
        GlobalEvent.emit("reloadContactMemo");
        this.modal(this.modalId, false);
      } catch (error) {
        this.handleError(error);
      }
    }
  }
