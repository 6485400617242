import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_nc_setting = _resolveComponent("nc-setting")!
  const _component_PersonnelSettingsForm = _resolveComponent("PersonnelSettingsForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nc_setting, {
      title: _ctx.$t('message.personnelSettings.title'),
      description: _ctx.$t('message.personnelSettings.desc')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.personnelSettings, (setting, i) => {
          return (_openBlock(), _createBlock(_component_setting_item, {
            key: i,
            title: setting.SettingName.split(/(?=[A-Z])/).join(' '),
            description: setting.SettingValue,
            iconClass: "ni-list-thumb-alt-fill",
            type: 'modal',
            handleClick: _ctx.handlePersonnelSettingsClick
          }, null, 8, ["title", "description", "handleClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["title", "description"]),
    (_ctx.personnelSettings.length > 0)
      ? (_openBlock(), _createBlock(_component_PersonnelSettingsForm, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}