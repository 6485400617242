
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mattersService } from "../../../matters.service";
import $ from "jquery";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterDashboardTab extends Vue {
  @Prop() Matter!: MatterModel;
  MatterDashboard: any = null;
  // MatterDashboard: Partial<Data> = null;

  async created(): Promise<void> {
    try {
      this.isLoading = true;
      var response = await mattersService.matterOverviewDashboard(this.Matter.MatterId);
      this.MatterDashboard = response.Data;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const main = this;
      $(document).ready(function() {
        main.handleChart();
        main.loadJS();
      });
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleChart(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const main = this;

    window.am4core.ready(function() {
      // Themes begin
      // window.am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      var chart = window.am4core.create("chartdiv", window.am4charts.XYChart);

      // Add percent sign to all numbers
      chart.numberFormatter.numberFormat = "#.#";

      const CurrentData = main.MatterDashboard.TimeSheetMonthOnMonth?.TimeSheetEntriesCurrent;
      const PreviousData = main.MatterDashboard.TimeSheetMonthOnMonth?.TimeSheetEntriesPrevious;

      const chartdata = [];

      for (let index = 0; index < CurrentData.length; index++) {
        const currentYear = CurrentData[index];
        const previousYear = PreviousData[index];

        const chartItem: Record<string, unknown> = {
          country: currentYear.MonthLabel
        };

        // chartItem[currentYear.Year] = currentYear.Billable;
        // chartItem[previousYear.Year] = currentYear.NonBillable;

        chartItem["year2004"] = currentYear.Billable;
        chartItem["year2005"] = currentYear.NonBillable;

        chartdata.push(chartItem);
      }

      // Add data
      chart.data = chartdata;

      // Create axes
      var categoryAxis = chart.xAxes.push(new window.am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "country";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 15;

      var valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
      valueAxis.title.text = "Duration";
      valueAxis.title.fontWeight = 800;

      // Create series
      var series = chart.series.push(new window.am4charts.ColumnSeries());
      series.dataFields.valueY = "year2004";
      series.dataFields.categoryX = "country";
      series.name = "Billable";
      series.clustered = false;
      series.tooltipText = "Billable {categoryX}: [bold]{valueY}[/]";

      var series2 = chart.series.push(new window.am4charts.ColumnSeries());
      series2.dataFields.valueY = "year2005";
      series2.dataFields.categoryX = "country";
      series2.name = "Non Billable";
      series2.clustered = false;
      series2.columns.template.width = window.am4core.percent(50);
      series2.tooltipText = "Non Billable {categoryX}: [bold]{valueY}[/]";

      chart.cursor = new window.am4charts.XYCursor();

      // Legend
      chart.legend = new window.am4charts.Legend();
      chart.legend.position = "top";
    }); // end window.am4core.ready()
  }
}

interface Data {
  Rates: Rates;
  TotalHoursSummary: TotalHoursSummary;
  BillStatus: BillStatus;
  UnpaidInvoices: UnpaidInvoices;
  UnbilledTime: UnbilledTime;
  UnbilledExpense: UnbilledExpense;
  TotalInflow: TotalInflow;
  TotalOutflow: TotalOutflow;
  TxnGrossProfit: TxnGrossProfit;
  TimeSheetMonthOnMonth: TimeSheetMonthOnMonth;
}

interface Rates {
  UtilizationRateSummary: UtilizationRateSummary;
  RealizationRateSummary: RealizationRateSummary;
  CollectionRateSummary: CollectionRateSummary;
}

interface UtilizationRateSummary {
  Description: string;
  Count: number;
  PercentageCount: number;
}

interface RealizationRateSummary {
  Description: string;
  Count: number;
  PercentageCount: number;
}

interface CollectionRateSummary {
  Description: string;
  Count: number;
  PercentageCount: number;
}

interface TotalHoursSummary {
  Description: string;
  CurrencyValue: number;
  Currency: string;
  CurrencyName: string;
  OtherValue: number;
}

interface BillStatus {
  BillDistributionUnfinalized: BillDistributionUnfinalized;
  BillDistributionFinalized: BillDistributionFinalized;
  BillDistributionGenerated: BillDistributionGenerated;
}

interface BillDistributionUnfinalized {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface BillDistributionFinalized {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface BillDistributionGenerated {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface UnpaidInvoices {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface UnbilledTime {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface UnbilledExpense {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface TotalInflow {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface TotalOutflow {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface TxnGrossProfit {
  Description: string;
  Count: number;
  Amount: number;
  PercentageCount: number;
  Currency: string;
  CurrencyName: string;
}

interface TimeSheetMonthOnMonth {
  TimeSheetEntries: any[];
  TimeSheetEntriesPrevious: TimeSheetEntriesPreviou[];
  TimeSheetEntriesCurrent: TimeSheetEntriesCurrent[];
}

interface TimeSheetEntriesPreviou {
  MonthId: number;
  MonthLabel: string;
  Billable: number;
  NonBillable: number;
  Year: string;
}

interface TimeSheetEntriesCurrent {
  MonthId: number;
  MonthLabel: string;
  Billable: number;
  NonBillable: number;
  Year: string;
}
