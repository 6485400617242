/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "vue-loading-overlay";
import Notifications from "@kyvg/vue3-notification";
import Swal from "./useSwal";
import "sweetalert2/dist/sweetalert2.min.css";
import DropZone from "dropzone-vue";
import {} from "vee-validate";

import "dropzone-vue/dist/dropzone-vue.common.css";

// import VeeValidate from "vee-validate";

// CSS Files
import "vue-loading-overlay/dist/vue-loading.css";

import $ from "jquery";
window.jQuery = $;
// eslint-disable-next-line no-use-before-define
window.xxx = "xxx"; // eslint-disable-next-line no-use-before-define
global.yyy = "yyy"; // eslint-disable-next-line no-use-before-define

// Global Custom Components Files
import NCButton from "./components/CustomButton.vue";
import NCModal from "./components/NCModal.vue";
import NCModal2 from "./components/NCModal2.vue";
import NCModal3 from "./components/NCModal3.vue";
import Ncsetting from "./components/NCSetting.vue";
import NCSelect2 from "./components/NCSelect2.vue";
import NCSelect3 from "./components/NCSelect3.vue";
import NCDatatable from "./components/NCDatable.vue";
import DataTable from "./components/DataTable.vue";
import PfizerTable from "./components/PfizerTable.vue";
import SimpleTable from "./components/SimpleTable.vue";
import ReportTable from "./components/ReportTable.vue";
import ExportButton from "./components/ExportButton.vue";
import NCSelect from "./components/NCSelect.vue";
import TableTools from "./components/TableTools.vue";
import NCTextField from "./components/form/NCTextField.vue";
import NCDateTime from "./components/form/NCDateTime.vue";
import BreadCrumb from "./components/BreadCrumb.vue";
import DateInput from "./components/form/DateInput.vue";
import NCTextArea from "./components/form/NCTextArea.vue";
import NCSpinner from "./components/NCSpinner.vue";
import SwitchForm from "./components/SwitchForm.vue";
import PieChart from "./components/NCPieChart.vue";
import BarChart from "./components/NCBarchart.vue";
import AreaChart from "./components/NCAreaChart.vue";
import NCDropdownMenu from "./components/NCDropdownMenu.vue";
import VueJsDatatable from "@parthfaladu/vue-js-datatable";
import SearchableSelect from "./components/SearchableSelect.vue";
import ValidateField from "./components/ValidateField.vue";

//Types definition

import "./custom-declarations";
// import "./extensions";

// Global Mixins
import GlobalMixin from "./shared/mixins";

//Translations
import { createI18n } from "vue-i18n";
import fr from "./translations/fr";
import en from "./translations/en";
import { GlobalEvent } from "./shared/plugins/tiny-emitter";
import VueHtmlToPaper from "vue-html-to-paper";
import ScheduleBoard from "vue-schedule-board";

const messages = Object.assign({}, en, fr);

const i18n = createI18n({
  messages,
  locale: "en",
  fallbackLocale: "fr",
});

const app = createApp(App);

const swlOptions: any = {
  confirmButtonColor: "#0B105D",
  cancelButtonColor: "#ff7674",
};

app.mixin(GlobalMixin);

app.component("nc-button", NCButton);
app.component("nc-select", NCSelect);
app.component("nc-setting", Ncsetting);
app.component("loading", Loading);
app.component("nc-modal", NCModal);
app.component("nc-modal2", NCModal2);
app.component("nc-modal3", NCModal3);
app.component("nc-select2", NCSelect2);
app.component("nc-select3", NCSelect3);
app.component("nc-table", NCDatatable);
app.component("simple-table", SimpleTable);
app.component("report-table", ReportTable);
app.component("export-button", ExportButton);
app.component("data-table", DataTable);
app.component("pfizer-table", PfizerTable);
app.component("table-tools", TableTools);
app.component("nc-input", NCTextField);
app.component("nc-text-area", NCTextArea);
app.component("vue-table", VueJsDatatable);
// app.component("data-table2", DataTable);
app.component("breadcrumb", BreadCrumb);
// app.component("date-input", DateInput);

app.component("date-input", NCDateTime);
app.component("nc-spinner", NCSpinner);
app.component("switch-form", SwitchForm);
app.component("nc-piechart", PieChart);
app.component("nc-barchart", BarChart);
app.component("nc-areachart", AreaChart);
app.component("nc-dropdown-menu", NCDropdownMenu);
app.component("searchable-select", SearchableSelect);
// app.component("ValidationProvider", ValidationProvider);
app.component("ValidateField", ValidateField);

app.use(i18n);
app.use(Swal);
app.use(DropZone);
app.use(Notifications);
app.use(store);
app.use(ScheduleBoard);
// app.use(VueHtmlToPaper);
app.use(router);
// app.use(Popover);

GlobalEvent.on("isPageReady", () => {
  app.use({
    install: function (_r, _f) {
      // app.config.globalProperties.$jQuery = $;
      const main: any = window;
      app.config.globalProperties.$jQuery = main.jQuery;
    },
  });
});

app.mount("#app");
