import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: "Employee/User Management",
    description: _ctx.$t('message.globalSettings.desc')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_setting_item, {
        title: "Users Management",
        description: "Manage User Roles and Priviledges",
        iconClass: "ni-users",
        type: 'newPage',
        link: { name: 'User Management' }
      }),
      _createVNode(_component_setting_item, {
        title: "Employee Management",
        description: "Manage Employees",
        iconClass: "ni-users",
        type: 'newPage',
        link: { name: 'Employee Management' }
      }),
      _createVNode(_component_setting_item, {
        title: "Roles and Permissions",
        description: "Manage System Roles and Permissions",
        iconClass: "ni-lock",
        type: 'newPage',
        link: { name: 'Role Management' }
      })
    ]),
    _: 1
  }, 8, ["description"]))
}