
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import { invoiceService } from "../invoice/invoice.service";

@Options({})
export default class InvoiceBankForm extends mixins(ModalFormMixin) {
  modalId = "invoice-bank-form";
  formEvent = FormEvents.INVOICE_BANK_FORM;
  service = invoiceService;
  date = new Date();
  reloadStore = settingsStore.findAllInvoiceBanks;

  async save(payload: any): Promise<void> {
    try {
      this.isLoading = true;
      const response = this.isUpdate
        ? await this.service.UpdateBankAccount(payload)
        : await this.service.AddBankAccount(payload);
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.reloadStore();
    } catch (error) {
      this.handleError(error);
    }
  }
}
