import { AppResponse } from '../../../shared/interfaces/app-response';
import { BaseService } from '../../../shared/services/base-service';

export class timesheetService extends BaseService {
  resource = 'TimeSheetReports'; // insert Api resource here

  findAll(payload: any): Promise<AppResponse> {
    return this.post('SearchTimeSheetSummary', payload);
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  remove(Id: string): Promise<AppResponse> {
    return this.get(`Delete/${Id}`);
  }

}

export const TimesheetService = new timesheetService();

export class timesheetService2 extends BaseService {
  resource = 'Time/Entries';

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

}

export const TimesheetService2 = new timesheetService2();

export class timesheetService3 extends BaseService {
  resource = 'Time/TimeSheet';

  remove(Id: string): Promise<AppResponse> {
    return this.get(`Delete/${Id}`);
  }

}

export const TimesheetService3 = new timesheetService3();



