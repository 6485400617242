
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Options, Vue } from "vue-class-component";
import companiesService, { companiesService2 } from "./companies.service";
import AddMemoForm from "./memoForm.vue";
import CompanyForm from "./companyForm.vue";
import ContactForm from "../contact/contactForm.vue";
import LeadForm from "../leads/new-leads-form.vue";

@Options({
  components : {
    AddMemoForm,
    CompanyForm,
    ContactForm,
    LeadForm,
  }
})
export default class CompanyView extends Vue {
  formEvent = FormEvents.COMPANIES_FORM;
  companyData: Record<string, unknown> = {};
  relatedData: Record<string, unknown> = {};
  clientMemos: unknown[] = [];

  updateState = true;
  updateMemo = true;

  app = NCApp;

  optionsData = [
    {
      name: "New Company",
      id: 'menu-item-0',
      formEventName: this.formEvent,
      hasPermission: this.app.hasPermission('0x24'),
    },
    {
      name: "New Contact",
      id: 'menu-item-1',
      formEventName: FormEvents.CONTACT_FORM,
      hasPermission: this.app.hasPermission('0x28'),
    },
    {
      name: "New Lead",
      id: 'menu-item-2',
      formEventName: FormEvents.ADD_LEADS,
      hasPermission:this.app.hasPermission('0x1C'),
    },
  ]

  async created(): Promise<void> {
    this.loadJS();
    try {
      const companyDataId = this.$route.params.id as string;
      const res = await companiesService2.findOne(companyDataId);
      this.companyData = res.Data.CompanyDetails;
      this.updateState = false;
      this.getRelatedData();
      this.getClientMemos();
    } catch (error) {
      // console.log("Error occured in created lifecycle", error);
    }
  }

  mounted(): void {
    GlobalEvent.on("reloadMemo", (data: any) => {
      this.getClientMemos();
    });

    GlobalEvent.on("company-update", async (data : unknown) => {
      try {
        const companyDataId = this.$route.params.id as string;
        const res = await companiesService2.findOne(companyDataId);
        this.companyData = res.Data.CompanyDetails;
      } catch (error) {
        // console.log("Error occured in created lifecycle", error);
      }
    });

    GlobalEvent.on("switchCompany", async (emitData: string) => {
      const res = await companiesService2.findOne(emitData);
      this.companyData = res.Data.CompanyDetails;
      this.getRelatedData();
      this.getClientMemos();
    });
  }

  openMemoForm(): void {
    GlobalEvent.emit(FormEvents.ADD_MEMO_FORM, { id: this.companyData.Id });
  }

  openCompanyForm(): void {
    GlobalEvent.emit(this.formEvent);
  }

  openContactForm() : void {
    GlobalEvent.emit(FormEvents.CONTACT_FORM);
  }

  openLeadForm(): void {
    GlobalEvent.emit(FormEvents.ADD_LEADS);
  }

  openCompanyUpdateForm() : void {
    GlobalEvent.emit(this.formEvent, this.companyData)
  }

  openSwitchForm(): void {
    GlobalEvent.emit(FormEvents.SWITCH_FORM);
  }

  async getRelatedData(): Promise<void> {
    try {
      const related = await companiesService.getRelated(this.companyData.Id as string);
      if (related.Success && related.Data) {
        this.relatedData = related.Data;
      } else {
        throw related.Message;
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  async getClientMemos(): Promise<void> {
    this.updateMemo = true;
    try {
      const memos = await companiesService.getMemo(this.companyData.Id as string);
      if (memos.Success && memos.Data) {
        this.clientMemos = memos.Data;
        this.updateMemo = false;
      } else {
        throw memos.Message;
      }
    } catch (error) {
      this.handleError(error);
      this.updateMemo = false;
    }
  }

  get companyInitials(): string {
    let initials = "";
    if (this.companyData.ClientName) {
      const companyName = this.companyData.ClientName as string;
      const nameList = companyName?.split(" ");
      nameList.forEach(name => (initials += name.charAt(0)?.toUpperCase()));

      if (initials.length > 2) {
        initials = initials.substring(0, 2);
      }
    }
    return initials;
  }

  deleteMemo(id: string): void {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            this.isLoading = true;
            await companiesService.deleteMemo(id);
            this.isLoading = false;
            this.toast("success", "Memo deleted Successfully");
            this.getClientMemos();
          } catch (error) {
            this.handleError(error);
          }
        }
      });
  }
}
