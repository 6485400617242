
import {mixins,Options} from 'vue-class-component';
import ModalFormMixin from '@/shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import axios, { CancelTokenSource } from "axios";
import { settingsStore } from '@/modules/settings/settings.store';
import { GlobalEvent } from '@/shared/plugins/tiny-emitter';
import SearchableSelect from "../../../components/SearchableSelect.vue";
import CMAService, { cmaCustomField }  from "./cmaMatter.service";
  // interface PayloadData {
  //   AnnualRevenue : "",
  //   ClientName : "",
  //   ContactAddress : "",
  //   ContactEmail : "",
  //   ContactPhoneNumber : "",
  //   Country : "",
  //   CountryName?: "",
  //   Id : "",
  //   Industry : "",
  //   IndustryDetail? : "",
  //   NoOfEmployees : "",
  //   StateOrProvince : "",
  //   TwitterLink : "",
  //   LinkedInLink : "",
  //   FacebookLink : "",
  //   WebsiteLink : "",
  //   ZipOrPostalCode : ""
  // }
  @Options({
  components : {
     
    SearchableSelect
  }
})
  export default class AcceptanceForm extends mixins(ModalFormMixin) {
  modalId = 'acceptance-form';
  service = CMAService;
  formEvent = FormEvents.CMA_MATTER_FORM;
  primaryKey = "ClientId";
  industries = settingsStore.industries;
  resource = "CaseManager/GetSelect2Matters"
  matterList = [];
  emitName = "matterSelected"
  cancelSource: CancelTokenSource | null = null;
  customModel = this.payload.Id ? {MatterName : this.payload.ModuleTitle, MatterId : this.payload.ModuleEntityId} : {};

  mounted() : void {
    GlobalEvent.on(this.emitName, (value : Record<string, unknown>) => {
      this.payload.ClientName = value.ClientName;
      this.payload.MatterTitle = value.MatterName;
      this.customModel = value as any;
      // console.log("The module Id is: ", this.payload.ModuleEntityId, value);
    })
  }
    // created() : void {
    //   this.loadJS()
    // }
  get users() {
    return settingsStore.users;
  }

    get contactData() {
    return settingsStore.leadsContacts;
  }     
    submitForm() : void {
      if(this.payload.Id) {
        // this.updateData();
        console.log('To Update');
        
      } else {
        this.saveData();
      }
    }
    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        this.payload.ClientMatterAcceptanceMember.map(String);
        // console.log("The form data is: ", this.payload, );
          // await this.validate();
        // this.globalLoader(true);
        const response = await this.service.addCMA(this.payload as unknown as Record<string, unknown>);
        this.isLoading = false;
        this.$emit('reloadTable','1')
        this.modal(this.modalId, false);
        this.toast("success", response.Message);
      } catch (error) {
        this.handleError(error);
      }
    }
    async updateData(): Promise<void> {
      try {
        this.isLoading = true;
        console.log("The update form data is: ", this.payload) ;
        // await this.validate();
        // this.globalLoader(true);
        if(this.payload.CountryName) {
          delete this.payload.CountryName;
        } 
        if(this.payload.IndustryDetail) {
          delete this.payload.IndustryDetail;
        }
        const response = await this.service.update(this.payload);
        this.isLoading = false;
        this.modal(this.modalId, false);
        this.toast("success", response.Message);
        GlobalEvent.emit("company-update");
      } catch (error) {
        this.handleError(error);
      }
    }

      cancelFetch() : void {
    if (this.cancelSource) {
      this.cancelSource.cancel("Cancelled this ongoing request");
    }
  }

  async fetchOptions(value: string): Promise<void> {
    try {
      if (value.length > 1) {

        this.cancelFetch();
        this.cancelSource = axios.CancelToken.source();
        console.log("Fetch options is called here");

        const response = await this.service.getSelect2(
          `${this.resource}?term=${value}&_type=query&q=${value}`,
          this.cancelSource.token
        );
        console.log("The search value and response value are: ", value, response);
        this.matterList = (response as unknown) as [];
        this.cancelSource = null;
      }
    } catch (error : any) {
      console.log("An error occured while fetching options", error);
      if (error[0] && error[0].MatterId) {
        this.matterList = error as [];
      }
    }
  }

  }
