
import NCApp from "@/app/index";
import { FormEvents } from "@/constants/index";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { mixins, Options, Vue } from "vue-class-component";
import TaskAddForm from "./task-add-form.vue";
import TaskViewForm from "./task-view-modal.vue"
import StarRating from "vue-star-rating";
import taskService from "./tasks.service";
import CourtUpdateForm from "../courtupdates/new-court-update.vue";
import CmaMatterForm from "../clientMatterAcc/cmaMatterForm.vue";
import MatterForm from "../matter/components/MatterForm.vue"
import CompanyForm from "../../crm/companies/companyForm.vue";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import ProgressNotesForm from "../progressNotes/progressNotesForm.vue";
import TimeSheetsForm from "@/modules/time/timesheet/timesheetForm.vue";

@Options({
  components : {
    TaskAddForm,
    TaskViewForm,
    StarRating,
    CourtUpdateForm,
    CmaMatterForm,
    MatterForm,
    CompanyForm,
    ProgressNotesForm,
    TimeSheetsForm
  }
})
export default class TasksPage extends mixins (DataTableMixin) {
  app = NCApp;
  service = taskService;
  customKey = "task-data-table";
  taskSummaries = [0, 0, 0, 0, 0]
  dueToday = [];
  dueSoon = [];
  startToday = [];
  startSoon = [];
  isLoading = true;

  optionsData = [
    {
      name: 'New Progress Note',
      id: 'menu-item-0',
      formEventName: FormEvents.PROGRESS_NOTES_FORM,
      hasPermission: this.app.hasPermission('0x44'),
    },
    {
      name: "New Acceptance",
      id: 'menu-item-0',
      formEventName: FormEvents.CMA_MATTER_FORM,
      hasPermission: this.app.hasPermission('0x40'),
    },
    {
      name: "New Matter",
      id: 'menu-item-1',
      formEventName: FormEvents.MATTER_FORM,
      hasPermission: this.app.hasPermission('0x5C'),
    },
    {
      name: "New Court Update",
      id: 'menu-item-2',
      formEventName: FormEvents.NEW_COURT_UPDATE,
      hasPermission: this.app.hasPermission('0x3C'),
    },
    {
      name: "New Task",
      id: 'menu-item-3',
      formEventName: FormEvents.MATTER_TASK_ADD_FORM,
      hasPermission: this.app.hasPermission('0x24'),
    },
    {
      name: "New Company",
      id: 'menu-item-4',
      formEventName: FormEvents.COMPANIES_FORM,
      hasPermission: this.app.hasPermission('0x24'),
    },
    {
      name: 'New Time Entry',
      id: 'menu-item-5',
      formEventName: FormEvents.TIME_SHEET_FORM,
      hasPermission: this.app.hasPermission('0x68'),
    },
  ];

  created() : void {
    this.loadJS();
  }

  async mounted() : Promise<void> {
    try {
      const response = await this.service.get('AssignedTasksDueSoon');
      this.taskSummaries = response.Data.TaskSummaries;
      this.dueToday = response.Data.DueToday;
      this.dueSoon = response.Data.DueSoon
      this.startToday = response.Data.StartingToday;
      this.startSoon = response.Data.StartingSoon
      console.log("The due summary values are: ", this.dueToday, this.dueSoon, this.startToday, this.startSoon);

    } catch (error) {
      console.log("Error occured", error);
    }
    
    GlobalEvent.on("tasks-datatable-key", (_ : unknown) => {
      this.customKey = this.customKey == "task-data-table" ? "task-data-table1" : "task-data-table";
    })
  }

  handleTableReady() : void {
    this.isLoading = false;
  }

  get taskData() : [] {
    return [];
  }

  openTaskAddForm(payload? : any) : void {
    console.log("Open Task form is called");
    
    if(payload && payload.Id) {
      
      GlobalEvent.emit(FormEvents.MATTER_TASK_ADD_FORM, payload);
    } else {
      GlobalEvent.emit(FormEvents.MATTER_TASK_ADD_FORM);
    }
  }

  openTaskViewForm(prop : Record<string, unknown>, isUpdate? : boolean) : void {
    isUpdate ? GlobalEvent.emit(FormEvents.TASK_VIEW_FORM, {...prop, isUpdate : true}) 
    : GlobalEvent.emit(FormEvents.TASK_VIEW_FORM, prop)
  }

  openCourtUpdateForm() : void {
    GlobalEvent.emit(FormEvents.NEW_COURT_UPDATE);
  }

  openCmaMatterForm() : void {
    GlobalEvent.emit(FormEvents.CMA_MATTER_FORM);
  }

  openMatterForm() : void {
    GlobalEvent.emit(FormEvents.MATTER_FORM);
  }

  openCompanyForm() : void {
    GlobalEvent.emit(FormEvents.COMPANIES_FORM);
  }

  async setRating(rating : number, prop : Record<string, unknown>) : Promise<void> {
    try {
      await this.service.updateStatus('UpdateTaskScore', {
        Id : prop.Id,
        TaskScore : rating
      });
      
      this.toast("success", "Rating updated successfully")
    } catch (error) {

      this.toast("error", "Task rating update was unsuccessful")
    }
  }

  fields = [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Title",
      name: "Title"
    },
    {
      title: "Matter",
      name: "ModuleTitle"
    },
    {
      title: "Due Date",
      name: "FormattedEndDate"
    },
    {
      title: "Supervisor",
      name: "TaskAppraiserFullName"
    },
    {
      title: "Status",
      name: "StatusDescription"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  fields2 = [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Title",
      name: "Title"
    },
    {
      title: "Matter",
      name: "ModuleTitle"
    },
    {
      title: "Due Date",
      name: "FormattedEndDate"
    },
    {
      title: "Assigned To",
      name: "TaskOwnerFullName"
    },
    {
      title: "Status",
      name: "StatusDescription"
    },
    {
      title: "Rating",
      name: "TaskScore"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  getActions(isUpdate : boolean) : any {
    const tableActions = [
      {
        name: isUpdate ? "View/Update" : "View",
        iconClass: "icon ni ni-eye",
        showIcon: true,
        action: async (prop: any, self: any): Promise<void> => {
          isUpdate ? this.openTaskViewForm(prop, isUpdate) : this.openTaskViewForm(prop);
        }
      },
      {
        name: "Edit",
        iconClass: "icon ni ni-edit",
        showIcon: false,
        action: async (prop: any, self: any)  => {
          const payload = {
            Description : prop.Description,
            DueDate : prop.EndDate,
            EndDate : prop.EndDate,
            Id : prop.Id,
            ModuleEntityId : prop.ModuleId,
            ModuleEntityName : prop.ModuleTitle,
            ModuleType : prop.ModuleType,
            Priority : prop.Priority,
            StartDate : prop.StartDate,
            Status : prop.Status,
            TaskAppraiser : prop.TaskAppraiser,
            TaskOwners : [prop.TaskOwner],
            TaskType : prop.TaskType,
            Title : prop.Title,
          }
          this.openTaskAddForm(payload);
        }
      },
    ];

    return tableActions;
  }
}
