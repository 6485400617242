import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class FileService extends BaseService {
  resource = "CRM";

  add(payload: any): Promise<AppResponse> {
    return this.post("Files/Add", payload);
  }

  findAllFiles(payload: any): Promise<AppResponse> {
    return this.post("Files/GetAll", payload);
  }
}

export const fileService = new FileService();

export class UploadFileService extends BaseService {
  resource = "Tenants";

  upload(payload: any): Promise<AppResponse> {
    return this.post("UploadGeneralFile", payload);
  }
}

export const uploadService = new UploadFileService();
