
import { Options, Vue } from 'vue-class-component';
import { FormEvents } from '../../../../constants';
import  cmaForm from './cmaSettingsForm.vue';
import {Watch} from 'vue-property-decorator'
import { settingsStore } from '../../settings.store';
@Options({
    props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { cmaForm }
})
export default class cmaSetting extends Vue {
 formEvent = FormEvents.CMA_FORM;
cma!:any;
cma2!:any;
 
  get CMA() {
    return settingsStore.cma;
  }
  created() {
    this.setFormData()
  }

  setFormData() {
    this.cma = this.CMA.map((c) => {
     return  {
         [c.SettingName]: c.SettingValue
      }
    })
     this.cma2 = Object.assign.apply(Object,this.cma);
    // console.log(this.cma2 ,'CMA HERE', this.cma, 'ddd' , this.CMA);
  }

  @Watch("CMA")
  public watchCma(newValue: number): void {
    // console.log(newValue, "New CMA config");
    
   this.setFormData()
  }

}
