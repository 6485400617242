
import { Options, Vue } from "vue-class-component";
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { ContactService, ContactService2} from "./contact.service";
import CompanyForm from "../companies/companyForm.vue";
import newLeadsForm from "../leads/new-leads-form.vue";
import contactForm from "./contactForm.vue";
import memoForm from "./memoForm.vue";


@Options({
  name: "View Contact",
  components: {
    contactForm,
    memoForm,
    CompanyForm,
    newLeadsForm
  }
})
export default class ContactView extends Vue {
  formEvent = FormEvents.CONTACT_FORM;
  contactData: Record<string, unknown> = {};
  contactDataId = "";
  relatedData: Record<string, unknown> = {};
  contactMemos: unknown[] = [];
  app = NCApp;
  updateState = true;
  updateMemo = true;

  async created(): Promise<void> {
    try {
      // console.log(this.$route, "pageroute");
      this.contactDataId = this.$route.params.id as string;
      const res = await ContactService.findOne(this.contactDataId)
      this.contactData = res.Data;
      this.updateState = false;
      // console.log('The viewpage contact data is: ', this.contactData);
      this.relatedContactData();
      this.getContactMemos();
    } catch (error) {
      // console.log("Created function error: ", error);
      this.updateState = false;
    }
  }

  mounted(): void {
  console.log('MOUNTED')

  
  GlobalEvent.on("reloadContactMemo", (data : any) => {
    this.getContactMemos();
  });

  GlobalEvent.on("switchContact", async (emitData : string) => {
      this.updateState = true;
      // console.log("Switch emit is found: ", emitData);
      const res = await ContactService.findOne(emitData);
      this.contactData = res.Data;
      this.relatedContactData();
      this.getContactMemos();
      this.updateState = false;
    })
  }
  

  openMemoForm() : void {
    GlobalEvent.emit(FormEvents.SHOW_CONTACT_MEMO, {id : this.contactData.Id});
  }

  openContactForm(): void {
    GlobalEvent.emit(FormEvents.CONTACT_FORM, this.contactData);
  }

  openCompanyForm() : void {
     GlobalEvent.emit(FormEvents.COMPANIES_FORM);
  }

  openLeadsForm() : void {
    GlobalEvent.emit(FormEvents.ADD_LEADS);
  }

   openSwitchForm() : void {
    GlobalEvent.emit(FormEvents.SWITCH_FORM);
  }

  async relatedContactData(): Promise<void> {
    try {
      const relatedInfo = await ContactService.getRelatedData(this.contactData.Id as string);
      if (relatedInfo.Success && relatedInfo.Data) {
        this.relatedData = relatedInfo.Data;
      } else {
        throw relatedInfo.Message;
      }
      // console.log("Fetched related data is: ", this.relatedData);
    } catch (error) {
      this.handleError(error);
    }
  }

  async getContactMemos(): Promise<void> {
    try {
      const contactMemo = await ContactService2.getMemo(this.contactData.Id as string);
      if (contactMemo.Success && contactMemo.Data) {
        this.contactMemos = contactMemo.Data;
        this.updateMemo = false;
      } else {
        throw contactMemo.Message;
      }
      // console.log("Fetched memo data is: ", this.contactMemos);
    } catch (error) {
      this.handleError(error);
    }
  }

  get contactInitials(): string {
    let initials = "";
    const contactName = this.contactData.FullName as string;
    if(this.contactData.FullName){
      // console.log(this.contactData, "Fullname Check");
      
      const nameList = contactName.split(" ");
      nameList.forEach(name => (initials += name.charAt(0)?.toUpperCase()));
      if (initials.length > 2) {
        initials = initials.substring(0, 2);
      }
    }
      return initials;
  }

  deleteContactMemo(id: string): void {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: any) => {
        if (result.isConfirmed=== true) {
          try {
            this.isLoading = true;
            const response = await ContactService2.deleteMemo(id);
            if (response.Success === true) {
              this.isLoading = false;
              this.toast("success", "Memo successfully deleted");
              this.getContactMemos();
            }
          } catch (error) {
            this.handleError(error);
          }
        }else{
          // console.log("delete was cancelled");
        }
      });
  }
}
