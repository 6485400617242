import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { StoreKeys } from "../../constants";
import { PreserveState } from "../../shared/plugins/secure-storage";
import store from "@/store";
import { clientsService } from "./clients.service";
import { ClientModel } from "./models";

@Module({
  store,
  name: StoreKeys.clients,
  dynamic: true,
  namespaced: true,
  preserveState: false,
  // preserveState: PreserveState(StoreKeys.clients)
})
class ClientsModule extends VuexModule {
  public clients: ClientModel[] = [];

  @Mutation
  SET_CLIENTLIST(data: ClientModel[]) {
    // console.log("Response", data);
    this.clients = data;
  }

  @Action({ commit: "SET_CLIENTLIST", rawError: true })
  async getClients(): Promise<void> {
    const response = await clientsService.findAll();
    return response.Data
  }
}

export default getModule(ClientsModule);
