export const DashboardTables = {
  recentOpportunities: [
    {
      title: "Name",
      name: "DealName"
    },
    {
      title: "Contact",
      name: "Contact"
      //   center: false
    },
    {
      title: "Stage",
      name: "Stage"
    },
    {
      title: "Value",
      name: "Value"
    }
  ],
  recentMatter: [
    {
      title: "Matter Title",
      name: "MatterTitle"
    },
    {
      title: "Client Name",
      name: "ClientName"
      //   center: false
    },
    {
      title: "Status",
      name: "Status"
    }
  ],
  recentActivities: [
    {
      title: "Time Activity",
      name: "ActivityName"
    },
    {
      title: "Duration",
      name: "TimeDuration"
      //   center: false
    },
    {
      title: "Type",
      name: "ChargeType"
    },
    {
      title: "Date",
      name: "SheetDate"
    }
  ],
};
