
import { BaseService } from '@/shared/services/base-service';
import Multiselect from "vue-multiselect";
import ModalFormMixin from '@/shared/mixins/modal.mixin';
import axios, { CancelTokenSource } from 'axios';
import { Options, mixins } from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import { GlobalEvent } from '@/shared/plugins/tiny-emitter';
import {Watch} from "vue-property-decorator";
import {VueEditor} from "vue3-editor";
import CMAService, { cmaCustomField }  from "./cmaMatter.service";
// import {cmaPayload} from './cma.model'
@Options({
  components : {
    Multiselect, VueEditor
  }
})
export default class customFieldsComp extends mixins(ModalFormMixin) {
  private cancelSource: CancelTokenSource | null = null;

  @Prop() title!: string;
  @Prop() label!: string;
  @Prop() trackBy!: string;
  @Prop() resource!: string;
  @Prop() placeholder!: string;
  @Prop() customSearch!: unknown;
  @Prop() useSlot!: boolean;
  @Prop() emitName!: boolean;
  @Prop() resetEmit!: boolean;
  @Prop() customVmodel!: any;
  @Prop() fieldData!: any;



  service = new BaseService();
  dataList: Record<string, unknown>[] = [];
  payloads: any = {};
  payloadx: any = [];
  dataStored : any;
  updateState = false;
  customSelectValue!: any;

 
mounted() {
//   console.log(this.payloads, "Payloads", this.payloadx, '----', this.fieldData);

//   // for (const key in this.fieldData) {
    
//   //   console.log(key, "KEY"), 'oh',this.fieldData[key];
//   // }
//   console.log(this.payloadx, "PayloadsAfter");
//   GlobalEvent.on('customFields-cma-submit', async (id: string) => {
//     console.log(this.payloads, 'cusPAY', id);
//      const res = await CMAService.approve(this.$route.params.id as string)
//       this.isLoading = false 
//       this.toast("success", res.Message);
//   })
// //   CustomFieldData: "13-Dec-2021"
// // CustomFieldId: 59
// // EntityId: 142
}

  @Watch("customVmodel")
  public watchVmodel(newValue: any, oldValue : any): void {
    console.log(newValue, oldValue, "New CMA config");
    if(newValue[this.trackBy] != oldValue[this.trackBy]) {
      this.dataStored = newValue;
    }
   
  }

  optionsSelect(item: any) {
  if(item.FieldType == 'Currency' || item.FieldType == 'Drop Down List' ) {
      const options = item.CustomFieldValueOptions.split(',')
      const options2 = options.map((item: any) => {
            return { Id: item, label: item, value: `${item}`}
              
      })
      
      return options2;
  }

  }
 

  async approveMatter(vvv: any, type: string) {
   
      this.fieldData.map((comp: any) => {
    const EntityId = this.$route.params.id as string;
    const CustomFieldId = comp.Id
    let CustomFieldData = vvv[comp.FieldType]
    if (comp.FieldType == 'Drop Down List') {
      
      CustomFieldData = this.customSelectValue
    }
    this.payloadx.push({EntityId: EntityId, CustomFieldId: CustomFieldId, CustomFieldData: CustomFieldData})
     this.updateState = true;
  })
    console.log(this.payloadx, 'xxxx' );
    const res = await cmaCustomField.addCustomValues(this.payloadx)
    this.payloadx = [];
    this.payloads = {};
     console.log(res,  'dhdh');
     this.toast("success", res.Message);
    this.updateState = false;
    const res2 = type == 'Approve' ? await CMAService.approve(this.$route.params.id as string) : await CMAService.disapprove(this.$route.params.id as string)
    console.log(res, res2, 'dhdddddh');
     this.toast("success", res2.Message);
  }

  emitSelected(data : Record<string, unknown>) : void {
    console.log("The passed data to emitSelected is: ", data, this.dataStored, this.customVmodel);
    GlobalEvent.emit(this.emitName, data);
  }

  cancelFetch(): void {
    if (this.cancelSource) {
      this.cancelSource.cancel("Cancelled ongoing request");
    }
  }

  async fetchOptions(value: string): Promise<void> {
    try {
      if (value.length > 1) {

        this.cancelFetch();
        this.cancelSource = axios.CancelToken.source();
        console.log("Fetch options is called here");

        const response = await this.service.getSelect2(
          `${this.resource}?term=${value}&_type=query&q=${value}`,
          this.cancelSource.token
        );
        console.log("The search value and response value are: ", value, response);
        this.dataList = (response as unknown) as [];
        this.cancelSource = null;
      }
    } catch (error : any) {
      console.log("An error occured while fetching options", error);
      if (error[0] && error[0].MatterId) {
        this.dataList = error as [];
      }
    }
  }
}
