
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { settingsStore } from "../../settings.store";
import { BankAccount } from "./bank_account_dto";
import BankAccountForm from "./bank_account_form.vue";

@Options({
  components: { BankAccountForm },
})
export default class BankAccounts extends mixins(DataTableMixin) {
  reloadStore = settingsStore.findAllBankAccounts;
  formEvent = FormEvents.BANK_ACCOUNT_FORM;

  get bankAccounts(): BankAccount[] {
    return settingsStore.bankAccounts;
  }

  created(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      // await settingsStore.findAllBankAccounts();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  fields = [
    {
      title: "Account Number",
      name: "AccountNumber",
    },
    {
      title: "Account Name",
      name: "AccountName",
    },
    {
      title: "Bank",
      name: "BankName",
    },
    {
      title: "Active",
      name: "ActiveFlag",
    },
    { name: "actions", title: "Actions", center: true, showTitle: false },
  ];

  tableActions = [
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.BANK_ACCOUNT_FORM, prop);
      },
    },
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: async (prop: any, self: any): Promise<void> => {
    //     // this.deleteInvoiceBank(prop, self);
    //   },
    // },
  ];
}
