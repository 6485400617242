<template>
  <div class="toggle-wrap nk-block-tools-toggle">
    <ul class="nk-block-tools g-3">
      <li>
        <a href="#" @click.prevent="doNothing" :disabled="loading" class="btn btn-white btn-outline-light position--relative export-btn"
          ><em class="icon ni ni-download-cloud"></em><span>{{ buttonText }}</span>
          <div class="export-icons">
            <div class="btns">
              <button type="button" class="icon" @click="exportTable('csv')" v-if="exportOptions_.includes('csv')">
                <file-csv />
              </button>
              <button type="button" class="icon" @click="exportTableToExcel()" v-if="exportOptions_.includes('excel')">
                <file-excel />
              </button>
              <button type="button" class="icon" @click="exportTable('pdf')" v-if="exportOptions_.includes('pdf')">
                <file-pdf />
              </button>
              <button type="button" class="icon" @click="exportTable('print')" v-if="exportOptions_.includes('print')">
                <printer />
              </button>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import FileCsv from "./icons/File_CSV";
import FileExcel from "./icons/File_Excel";
import FilePdf from "./icons/File_Pdf";
import Printer from "./icons/Printer";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  components: {
    FileCsv,
    FileExcel,
    FilePdf,
    Printer,
  },
  computed: {
    buttonText() {
      return this.loading ? "Exporting..." : "Export";
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadDependencyJS([
        "https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.4.1/jspdf.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.5.6/jspdf.plugin.autotable.min.js",
        "https://rawcdn.githack.com/FuriosoJack/TableHTMLExport/v2.0.0/src/tableHTMLExport.js",
      ]);
    }, 2000);
  },
  data() {
    return {
      exportOptions_: ["csv", "excel", "pdf", "print"],
    };
  },
  methods: {
    doNothing() {
      //
    },
    exportTable(type) {
      this.loading = true;
      // console.log(window.jQuery(`#${this.id}`));
      window.jQuery(`#${this.id}`).tableHTMLExport({
        type: type.toString(),
        filename: `${this.id}.${type}`.replaceAll("-", " "),
        ignoreColumns: ".exclude-export",
        // ignoreRows: ".exclude-export"
      });
      // buttonParent.querySelector(buttonSelector).click();
      setTimeout(() => {
        this.loading = false;
      }, 4500);
    },
    exportTableToExcel() {
      console.log("excel export");
      // var downloadLink;
      // var dataType = "application/vnd.ms-excel";
      // var tableSelect = document.getElementById(this.id);
      // var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // // Specify file name
      // let filename = this.id + ".xls";

      // // Create download link element
      // downloadLink = document.createElement("a");

      // document.body.appendChild(downloadLink);

      // if (navigator.msSaveOrOpenBlob) {
      //   var blob = new Blob(["\ufeff", tableHTML], {
      //     type: dataType,
      //   });
      //   navigator.msSaveOrOpenBlob(blob, filename);
      // } else {
      //   // Create a link to the file
      //   downloadLink.href = "data:" + dataType + ", " + tableHTML;

      //   // Setting the file name
      //   downloadLink.download = filename;

      //   //triggering the function
      //   downloadLink.click();
      // }
      // Acquire Data (reference to the HTML table)
      // var table_elt = window.jQuery(`#${this.id}`).clone();
      var table_elt = document.getElementById(this.id);
      table_elt.classList.remove('.exclude-export');
      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table_elt);

      // Process Data (add a new row)
      var ws = workbook.Sheets["Sheet1"];
      XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, `${this.id}.xlsb`);
    },
  },
  props: ["table", "exportOptions", "id"],
  watch: {
    exportOptions: {
      immediate: true,
      handler(options = []) {
        if (options.length) {
          this.exportOptions_ = options;
        }
      },
    },
  },
};
</script>

<style scoped>
.toggle-wrap {
  position: relative;
}
.export-icons {
  height: 0;
  width: 0;
  border-radius: 0 0px 10px 10px;
  box-shadow: 0px 3px 11px 1px #000 17;
  transition: all 0.25s ease-in-out;
  background: #fff;
  position: absolute;
  right: 0;
  z-index: 20;
  top: 70%;
}
.export-icons .btns {
  padding: 10px;
  display: flex;
}
.export-icons .icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background: transparent;
  border: 0;
  padding: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.export-icons .icon:last-of-type {
  margin-right: 0;
}
.export-icons .icon:hover {
  opacity: 0.65;
}
.export-icons .icon ::v-deep .ni {
  font-size: 35px;
  line-height: 40px;
  color: #526484;
}
.export-btn:active .export-icons,
.export-btn:focus .export-icons {
  height: auto;
  width: auto;
  top: 120%;
}
.export-btn:active .export-icons .icon,
.export-btn:focus .export-icons .icon {
  display: flex;
}
</style>
