import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";

export class LeadService extends BaseService {
  resource = "Leads";

  GetLeadSources(): Promise<AppResponse> {
    return this.get("LeadSources");
  }

  getLeadTags(): Promise<AppResponse> {
    return this.get("LeadTags");
  }

  updateLeadStatus(Id: string, LeadStageId: number): Promise<AppResponse> {
    return this.post("UpdateLeadStatus", { Id, LeadStageId });
  }

  updateLeadStatusToWon(LeadId: string): Promise<AppResponse> {
    return this.get(`UpdateLeadStatusToWon/${LeadId}`);
  }
}

export const leadService = new LeadService();
