/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import config from "../../public/assets/config2.json";
// import config from "../../static/config.json";
import axios from "axios";
import { Helpers } from "../shared/helpers";
import { TenantConfiguration } from "./app.model";
import { authStore } from "../modules/auth/auth.store";
import NCPermissions from "./permissions-handler";
const appWindow: any = window;
const $: any = appWindow.$;

const NCApp = {
  async setup(): Promise<void> {
    const { protocol } = window.location;
    const { host } = window.location;
    const { primaryUrl, secondaryUrl, defaultUrl } = this.processAppConfigurationParams(config);
    let request;

    if (primaryUrl.isNotEmpty) {
      request = this.fetchTenantConfiguration(primaryUrl.value, host);
    } else if (secondaryUrl.isNotEmpty) {
      request = this.fetchTenantConfiguration(secondaryUrl.value, host);
    } else {
      request = this.fetchTenantConfiguration(defaultUrl.value, host);
    }

    const response: TenantConfiguration = await request;

    const TenantAppUrl = `${protocol != "https:" ? "https:" : protocol}//${
      response.AppUrl == "localhost:8080" ? "demo.nextcounsel.com" : response.AppUrl
    }`;

    const tenantConfiguration: TenantConfiguration = {
      ...response,
      AppUrl: `${TenantAppUrl}`,
      TenantLogo: `${TenantAppUrl}${response.TenantLogo}`
    };
    authStore.SET_CONFIGURATION(tenantConfiguration);
  },

  async fetchTenantConfiguration(configUrl: string, baseUrl: string): Promise<TenantConfiguration> {
    const request = await axios.post(`${configUrl}/api/Tenants/GetTenant`, {
      TenantUrl: "demo.nextcounsel.com"
    });
    const response = Helpers.handleResponse(request);
    return response.Data;
  },

  processAppConfigurationParams(config: Record<string, string>): Record<string, any> {
    const result: Record<string, any> = {};
    // console.log(config, "appCOnfig inProcess")
    Object.keys(config).forEach(key => {
      result[key] = {
        isNotEmpty: config[key].trim() == null || config[key].trim() == "" ? false : true,
        value: config[key]
      };
    });

    return result;
  },
  ...NCPermissions
};

export default NCApp;
