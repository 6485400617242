
import { Options, Vue } from "vue-class-component";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import { settingsStore } from "../settings.store";

@Options({
  components: { SettingItem }
})
export default class UserSettings extends Vue {
  mounted() {
    settingsStore.AllEmployees();
    settingsStore.findAllRoles();
    settingsStore.findAllPermissions();
  }
}
