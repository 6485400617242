import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class FormActionsService extends BaseService {
  resource = "Leads";

  addForm(payload: any): Promise<AppResponse> {
    console.log("enters add form ...");
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }
  findOne(id: string): Promise<AppResponse> {
    return this.get(`GetLead?LeadId=${id}`);
  }

  setLeadStatusToWon(id: string): Promise<AppResponse> {
    return this.get(`UpdateLeadStatusToWon/${id}`);
  }

  setLeadStatusToLost(payload: any): Promise<AppResponse> {
    return this.post("UpdateLeadLostReason", payload);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`DeleteLead?LeadId=${id}`);
  }

  updateLeadStatus(payload: any): Promise<AppResponse> {
    return this.post(`UpdateLeadStatus`, payload);
  }
}

export const formActionsService = new FormActionsService();

export class LeadDetailService extends BaseService {
  [x: string]: any;
  resource = "GlobalTasks";
  findOne(id: string): Promise<AppResponse> {
    return this.get(`GetAll/1/${id}`);
  }

  create(payload: any): Promise<AppResponse> {
    return this.post("Add", payload);
  }
}

export const leadDetailService = new LeadDetailService();
