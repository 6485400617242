/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApiConfigService } from "../../config/api.config";
import { AppResponse } from "../interfaces/app-response";

export class BaseService extends ApiConfigService {
  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("", { params: pagination });
  }

  list(): Promise<AppResponse> {
    return this.get(`GetAll`);
  }

  findOne(id: string): Promise<AppResponse> {
    return this.get(`Get/${id}`);
  }

  create(payload: unknown): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }

  // abstract dtoClass: new (entity: AbstractService, options?: any) => T;
}
