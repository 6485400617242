import { AppResponse } from "../../../shared/interfaces/app-response";
import { BaseService } from "../../../shared/services/base-service";

class RoleService extends BaseService {
  resource = "Roles";

  getRoles(): Promise<AppResponse> {
    return this.get("Get");
  }

  getPermissions(): Promise<AppResponse> {
    return this.get("GetPermissions");
  }

  getRolePermissions(id: number): Promise<AppResponse> {
    return this.get(`GetRolePermissions/${id}`);
  }

  addPermissionToRole(payload: any) {
    return this.post("AddPermissionToRole", payload);
  }

  removePermissionToRole(payload: any) {
    return this.post("RemovePermissionFromRole", payload);
  }

  create(payload: any) {
    return this.post("SaveRole", payload);
  }
}

export const roleService = new RoleService();
