
import {Vue, mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
// import AcceptanceForm from './cmaMatterForm.vue'
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import billService   from "./bill.service";

@Options({
  components: {
    VueEditor,
  }
  
})
export default class Bill extends mixins(DataTableMixin) {
 formEvent = FormEvents.CMA_MATTER_FORM;
 componentKey = 0;

 
    renderTable() {
    this.componentKey += 1
    }
    openCMAForm(){
      // console.log('open sesame', this.formEvent);
    GlobalEvent.emit(this.formEvent);
  }
   deleteCMA(id: string, self: any) {
    // console.log("the id is", id);
    self.$swal
      .fire({
        title: "Are you sure you want to delete this Client/Matter Acceptance?",
        text: "This lead will be removed along with all associated data.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async () => {
        billService.remove(id).then(async response => {
          if (response.Success === true) {
            try {
              self.isLoading = false;
              self.toast("success", response.Message);
              await this.renderTable();
            } catch (error) {
              self.isLoading = false;
              self.toast("error", response.Message);
              self.handleError(error);
            }
          } else {
            self.isLoading = false;
            self.toast("error", response.Message);
          }
        });
        return;
      });
  }
 
  fields = [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Matter",
      name: "MatterName"
    },
    {
      title: "Amount",
      name: "BillTotal"
    },
    {
      title: "Currency",
      name: "Currency"
    },
    {
      title: "Date",
      name: "FormattedDateCreated"
    },
    {
      title: "Created By",
      name: "CreatedByName"
    },
    {
      title: "Status",
      name: "BillStatusDescription"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

 
   tableActionsGeneratedByme = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        // self.$router.push({ name: "CMAView", params: { id: prop.Id } });
      }
    }
  ];

  tableActionsPending = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        // self.$router.push({ name: "CMAReview", params: { id: prop.Id } });
      }
    },
     {
      type: "divider"
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        // self.$router.push({ name: "CMAReview", params: { id: prop.Id } });
      }
    },
    {
      name: "Send Approval Reminder",
      iconClass: "icon ni ni-bell",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        // self.$router.push({ name: "CMAReview", params: { id: prop.Id } });
      }
    },
   
    {
      name: "Approve Bill",
      iconClass: "icon ni ni-plus ",
      showIcon: false,
      action: async (prop: any, self: any): Promise<void> => {
        // this.deleteData(prop, self);
      }
    },
    {
      name: "Approve Bill",
      iconClass: "icon ni ni-cross ",
      showIcon: false,
      action: async (prop: any, self: any): Promise<void> => {
        // this.deleteData(prop, self);
      }
    }
  ];
}
