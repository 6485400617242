import { Activity } from "./settings.model";
import { PipelineModel } from "./crm/pipeline/pipeline.model";
import { WorkFlow } from "./matters/workflow/workflow.model";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { StoreKeys } from "../../constants";
import { PreserveState } from "../../shared/plugins/secure-storage";
import { IndustryModel } from "./crm/industry/industry.model";
import { UserModel } from "./employee-users/models/user.model";
import { industryService } from "./crm/industry/industry.service";

import { cmaService } from "./matters/cma/cma.service";
import { tmeService } from "./time/tme/tme.service";
import { TME, TMEUsers } from "./time/tme/tme.model";
import { financeReportService } from "./finance/reportSettings/reportSetting.service";
import { CoaService } from "./finance/chart-accounts/chart-account.service";

import { employeeService, userService } from "./employee-users/users.service";

import { accountService } from "./finance/bank-accounts/account.service";
import { invoiceService } from "./global/invoice/invoice.service";
import { taskWorkFlowService } from "./matters/workflow/workflow.service";
import { PracticeGroup } from "./matters/practice-groups/practice-group.model";
import { practiceGroupsService } from "./matters/practice-groups/practice-group.service";
import { BankAccount, PostingGroup } from "./finance/bank-accounts/bank_account_dto";
import { Employee } from "../auth/auth.model";
import { roleService } from "./employee-users/role.service";
import { Role } from "./employee-users/models/role.model";
import { PermissionModel } from "./employee-users/models/permissions.model";
import { BillGroup } from "./time/billgroup/model/billgroup.model";
import { billGroupService } from "./time/billgroup/billgroup.service";
import { timeFormatService } from "./time/timeFormat/timeFormat.service";
import { activityService } from "./time/activitytype/activity.service";
import { ActivityModel } from "./time/activitytype/activitytype.model";
import { SignatureService } from "./time/signatures/signature.service";
import { InvoiceSignatures } from "./time/signatures/signature.model";
import { CustomFieldsService } from "./matters/custom-fields/custom-fields.service";
import { CustomFields } from "./matters/custom-fields/customFields.model";
import { accountPeriod } from "./finance/accountPeriods/accountPeriod.model";
import { AccountPeriodService } from "./finance/accountPeriods/accountperiod.service";
import { pipelineService } from "./crm/pipeline/pipeline.service";
import { GlobalSettingsModel } from "./global/model/globalsettings.model";
import { globalSettingService } from "./global/globalsettings.service";
import { InvoiceBankModel } from "./global/invoice-bank/invoice_bank.model";
import { settingsService } from "./settings.service";
import { FinancialConfigurationModel } from "./finance/configuration/model/financialConfiguration.model";
import { financialConfigurationService } from "./finance/configuration/financeConfiguration.service";
import { currencyService, currencyService2 } from "./global/currency/currency.service";
import { ClientPortalModel } from "./global/clientportal/model/clientPortal.model";
import { clientPortalService } from "./global/clientportal/clientPortal.service";
import { LeadStageName } from "../crm/leads/model/lead-stages.model";
import { businessUnitsService, leadsService, leadStagesService } from "../crm/leads/leads.service";
import { BusinessUnits } from "../crm/leads/model/business-units.model";
import { ContactsApiResponse } from "../crm/leads/model/contacts.model";
import { CompaniesApiResponse } from "../crm/leads/model/companies.model";
import numberingService from "./global/numbering/numbering.service";
import { ContactCategory } from "./crm/contact-categories/contactCategory.model";
import { ContactCategoryService } from "./crm/contact-categories/contact-category.service";

@Module({
  store,
  name: StoreKeys.settings,
  dynamic: true,
  namespaced: true,
  preserveState: PreserveState(StoreKeys.settings)
})
class SettingsModule extends VuexModule {
  public industries: IndustryModel[] = [];
  public users: UserModel[] = [];
  public employees: Employee[] = [];
  public practiceGroups: PracticeGroup[] = [];
  public cma: any[] = [];
  public inv: any[] = [];
  public tme: TME[] = [];
  public billGroups: BillGroup[] = [];
  public incomeStatement: any[] = [];
  public finPosition: any[] = [];
  public coa: any[] = [];
  public baseAccountTypes: any[] = [];
  public accountTypes: any[] = [];
  public signatures: InvoiceSignatures[] = [];
  public accountPeriods: accountPeriod[] = [];
  public workflow: WorkFlow[] = [];
  public bankAccounts: BankAccount[] = [];
  public roles: Role[] = [];
  public permissions: PermissionModel[] = [];
  public postingGroups: PostingGroup[] = [];
  public timeFormat = "0";
  public activityTypes: ActivityModel[] = [];
  public activityGroups: string[] = [];
  public globalSettings: GlobalSettingsModel | Record<string, unknown> = {};
  public clientPortalSettings: ClientPortalModel | Record<string, unknown> = {};
  public leadStages: LeadStageName | Record<string, unknown> = {};
  public businessUnits: BusinessUnits | Record<string, unknown> = {};
  public pipelines: PipelineModel[] = [];
  public invoiceBanks: InvoiceBankModel[] = [];
  public activities: Activity[] = [];
  public financialConfiguration: FinancialConfigurationModel[] = [];
  public allCurrencies: unknown[] = [];
  public transactionCurrencies: unknown[] = [];
  public numberingFormat: unknown[] = [];
  public contactCategory: ContactCategory[] = [];
  public customFields: CustomFields[] = [];
  public leadsContacts: Record<string, unknown> = {};
  public leadsCompanies: Record<string, unknown> = {};

  @Mutation
  SET_INDUSTRIES(data: IndustryModel[]) {
    this.industries = data;
  }

  @Mutation
  SET_USERLIST(data: UserModel[]) {
    this.users = data;
  }

  @Mutation
  SET_EMPLOYEELIST(data: Employee[]) {
    this.employees = data;
  }

  @Mutation
  SET_ROLES(data: Role[]) {
    this.roles = data;
  }

  @Mutation
  SET_PERMISSIONS(data: PermissionModel[]) {
    this.permissions = data;
  }

  @Mutation
  SET_PRACTICE_GROUPS(data: PracticeGroup[]) {
    this.practiceGroups = data;
  }

  // @Mutation
  // SET_PROJECT_LABEL(data: labelModel[]) {
  //   this.projectLabel = data;
  // }

  @Mutation
  SET_CMA(data: any[]) {
    this.cma = data;
  }
  @Mutation
  SET_TME(data: TME[]) {
    this.tme = data;
  }
  @Mutation
  SET_INV(data: TME[]) {
    this.inv = data;
  }

  @Mutation
  SET_WORKFLOW(data: WorkFlow[]) {
    this.workflow = data;
  }

  @Mutation
  SET_BANK_ACCOUNTS(data: BankAccount[]) {
    this.bankAccounts = data;
  }

  @Mutation
  SET_POSTING_GROUPS(data: PostingGroup[]) {
    this.postingGroups = data;
  }

  @Mutation
  SET_BILL_GROUPS(data: BillGroup[]) {
    this.billGroups = data;
  }
  @Mutation
  SET_INCOME_STATEMENT(data: any[]) {
    this.incomeStatement = data;
  }
  @Mutation
  SET_FIN_POSITION(data: any[]) {
    this.finPosition = data;
  }
  @Mutation
  SET_COA(data: any[]) {
    this.coa = data;
  }

  @Mutation
  SET_BASE_ACCOUNT_TYPES(data: any[]) {
    this.baseAccountTypes = data;
  }
  @Mutation
  SET_ACCOUNT_TYPES(data: any[]) {
    this.accountTypes = data;
  }

  @Mutation
  SET_TIME_FORMAT(data: string) {
    this.timeFormat = data;
  }

  @Mutation
  SET_ACTIVITY_TYPES(data: ActivityModel[]) {
    this.activityTypes = data;
  }

  @Mutation
  SET_ACTIVITY_GROUPS(data: string[]) {
    this.activityGroups = data;
  }

  @Mutation
  SET_GLOBAL_SETTINGS(data: GlobalSettingsModel) {
    this.globalSettings = data;
  }

  @Mutation
  SET_CLIENT_PORTAL(data: ClientPortalModel) {
    this.clientPortalSettings = data;
  }

  @Mutation
  SET_LEADS_STAGES(data: LeadStageName) {
    this.leadStages = data;
  }

  @Mutation
  SET_BUSINESS_UNITS(data: BusinessUnits) {
    this.businessUnits = data;
  }

  @Mutation
  SET_LEADS_CONTACTS(data: any) {
    this.leadsContacts = data.data;
  }

  @Mutation
  SET_LEADS_COMPANIES(data: any) {
    this.leadsCompanies = data.data;
  }

  @Mutation
  SET_SIGNATURES(data: InvoiceSignatures[]) {
    this.signatures = data;
  }

  @Mutation
  SET_CUSTOM_FIELDS(data: CustomFields[]) {
    this.customFields = data;
  }

  @Mutation
  SET_ACCOUNT_PERIOD(data: accountPeriod[]) {
    this.accountPeriods = data;
  }

  @Mutation
  SET_PIPELINES(data: PipelineModel[]) {
    this.pipelines = data;
  }

  @Mutation
  SET_INVOICE_ACCOUNTS(data: InvoiceBankModel[]) {
    this.invoiceBanks = data;
  }

  @Mutation
  SET_ACTIVITIES(data: Activity[]) {
    this.activities = data;
  }
  @Mutation
  SET_FINANCIAL_CONFIGURATION(data: FinancialConfigurationModel[]) {
    this.financialConfiguration = data;
  }

  @Mutation
  SET_TR_CURRENCIES(data: []) {
    this.transactionCurrencies = data;
  }

  @Mutation
  SET_CURRENCIES(data: []) {
    this.allCurrencies = data;
  }

  @Mutation
  SET_NUMBERING(data: []) {
    this.numberingFormat = data;
  }

  @Mutation
  SET_CONTACT_CATEGORIES(data: ContactCategory[]) {
    this.contactCategory = data;
  }

  @Action({ commit: "SET_INDUSTRIES", rawError: true })
  async findAllIndustries(): Promise<void> {
    const response = await industryService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_USERLIST", rawError: true })
  async AllUsers(): Promise<void> {
    const response = await userService.findAllUsers();
    return response.Data;
  }

  @Action({ commit: "SET_EMPLOYEELIST", rawError: true })
  async AllEmployees(): Promise<void> {
    const response = await employeeService.findAllEmployees();
    return response.Data;
  }

  @Action({ commit: "SET_BANK_ACCOUNTS", rawError: true })
  async findAllBankAccounts(): Promise<void> {
    const response = await accountService.GetBankAccounts();
    return response.Data;
  }

  @Action({ commit: "SET_INVOICE_ACCOUNTS", rawError: true })
  async findAllInvoiceBanks(): Promise<void> {
    const response = await invoiceService.GetBankAccounts();
    return response.Data;
  }

  @Action({ commit: "SET_FINANCIAL_CONFIGURATION", rawError: true })
  async findFinancialConfiguration(): Promise<void> {
    const response = await financialConfigurationService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_WORKFLOW", rawError: true })
  async findAllWorkFlow(): Promise<void> {
    const response = await taskWorkFlowService.getTaskWorkflow();
    return response.Data;
  }

  @Action({ commit: "SET_PRACTICE_GROUPS", rawError: true })
  async findAllPracticeGroups(): Promise<void> {
    const response = await practiceGroupsService.findAll();
    return response.Data;
  }
  @Action({ commit: "SET_CMA", rawError: true })
  async getCMA(): Promise<void> {
    const response = await cmaService.getCMAConfig();
    return response.Data;
  }
  @Action({ commit: "SET_TME", rawError: true })
  async getTME(): Promise<void> {
    const response = await tmeService.getAll();
    return response.Data;
  }
  @Action({ commit: "SET_INV", rawError: true })
  async getINV(): Promise<void> {
    const response = await invoiceService.getConfig();
    return response.Data;
  }
  @Action({ commit: "SET_INCOME_STATEMENT", rawError: true })
  async getIncomeStatement(): Promise<void> {
    const response = await financeReportService.findIncomeStatement();
    return response.Data;
  }
 
  @Action({ commit: "SET_FIN_POSITION", rawError: true })
  async getFinPosition(): Promise<void> {
    const response = await financeReportService.findFinPosition();
    return response.Data;
  }
  @Action({ commit: "SET_COA", rawError: true })
  async getChartOfAccounts(): Promise<void> {
    const response = await CoaService.getCoa();
    return response.Data;
  }
  @Action({ commit: "SET_BASE_ACCOUNT_TYPES", rawError: true })
  async getBaseAccountTypes(): Promise<void> {
    const response = await CoaService.getBaseAccountTypes();
    return response.Data;
  }

  @Action({ commit: "SET_LEADS_CONTACTS", rawError: true })
  async getLeadsContacts(): Promise<ContactsApiResponse> {
    const response = await leadsService.getContactData();
    // console.log(response, 'leads contactss');
    
    return response;
  }

  @Action({ commit: "SET_LEADS_COMPANIES", rawError: true })
  async getLeadsCompanies(): Promise<CompaniesApiResponse> {
    const response = await leadsService.getCompanyData();
    return response;
  }
  @Action({ commit: "SET_ACCOUNT_TYPES", rawError: true })
  async getAccountTypes(): Promise<void> {
    const response = await CoaService.getAccountTypes();
    return response.Data;
  }

  @Action({ commit: "SET_ROLES", rawError: true })
  async findAllRoles(): Promise<void> {
    const response = await roleService.getRoles();
    return response.Data;
  }

  @Action({ commit: "SET_PERMISSIONS", rawError: true })
  async findAllPermissions(): Promise<void> {
    const response = await roleService.getPermissions();
    return response.Data;
  }

  @Action({ commit: "SET_POSTING_GROUPS", rawError: true })
  async findAllPostingGroups(): Promise<void> {
    const response = await accountService.getPostingGroups();
    return response.Data;
  }

  @Action({ commit: "SET_BILL_GROUPS", rawError: true })
  async getAllBillGroups(): Promise<void> {
    const response = await billGroupService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_GLOBAL_SETTINGS", rawError: true })
  async getGlobalSettings(): Promise<void> {
    const response = await globalSettingService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_CLIENT_PORTAL", rawError: true })
  async getClientPortalSettings(): Promise<void> {
    const response = await clientPortalService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_LEADS_STAGES", rawError: true })
  async getLeadStages(): Promise<void> {
    const response = await leadStagesService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_BUSINESS_UNITS", rawError: true })
  async getBusinessUnits(): Promise<void> {
    const response = await businessUnitsService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_SIGNATURES", rawError: true })
  async getSignatures(): Promise<void> {
    const response = await SignatureService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_CUSTOM_FIELDS", rawError: true })
  async getCustomFields(): Promise<void> {
    const response = await CustomFieldsService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_ACCOUNT_PERIOD", rawError: true })
  async getAllAccountPeriods(): Promise<void> {
    const response = await AccountPeriodService.getAll();
    return response.Data;
  }

  @Action({ commit: "SET_PIPELINES", rawError: true })
  async getPipelines(): Promise<void> {
    const response = await pipelineService.getPipelines();
    return response.Data;
  }

  @Action({ commit: "SET_ACTIVITIES", rawError: true })
  async getActivities(): Promise<void> {
    const response = await settingsService.getActivities();
    return response.Data;
  }

  @Action({ commit: "SET_TIME_FORMAT", rawError: true })
  async getTimeFormat(): Promise<void> {
    const response = await timeFormatService.findAll();
    return response.Data.TimeEntryTimeFormat;
  }

  @Action({ commit: "SET_ACTIVITY_TYPES", rawError: true })
  async getActivityTypes(): Promise<ActivityModel[]> {
    const response = await activityService.findAll();
    // console.log("users", response);
    return response.Data;
  }

  @Action({ commit: "SET_ACTIVITY_GROUPS", rawError: true })
  async setActivityGroups(newData: string[]) {
    return newData;
  }
  @Action({ commit: "SET_TR_CURRENCIES", rawError: true })
  async getTransactionCurrencies(): Promise<[]> {
    const response = await currencyService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_CURRENCIES", rawError: true })
  async getAllCurrencies(): Promise<[]> {
    const response = await currencyService2.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_NUMBERING", rawError: true })
  async getNumberingFormats(): Promise<[]> {
    const response = await numberingService.findAll();
    // console.log("numberingformats", response);
    return response.Data;
  }

  @Action({ commit: "SET_CONTACT_CATEGORIES", rawError: true })
  async getContactCategories(): Promise<void> {
    const response = await ContactCategoryService.findAll();
    return response.Data;
  }
}

export const settingsStore = getModule(SettingsModule);
