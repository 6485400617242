
 

import { Options, Vue, mixins } from "vue-class-component";
import { Watch} from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { ReportConfig } from "./reportSetting.model";
import ReportSettingsForm from "./reportSettingForm.vue";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { financeReportService } from "./reportSetting.service";
// import { practiceGroupsService } from "./practice-group.service";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: {
    ReportSettingsForm 
    // memberform,
    // PracticeAreaForm
  }, 
    computed: {
    reportConfigData() {
      return this.reportType == 1 ? this.incomeStatement: this.finPosition;
    }
  }
})
export default class ReportSetting extends mixins(DataTableMixin){
  formEvent = FormEvents.REPORT_FORM;
  reportTitle = "Income Statement Config"
  reportType = 1;
  // reportData = this.reportType == 1 ? this.incomeStatement: this.finPosition;
  switchCase = true;

  get incomeStatement(): any[] {
    return settingsStore.incomeStatement;
  }
  get finPosition(): any[] {
    return settingsStore.finPosition;
  }

  created(): void {
    settingsStore.getIncomeStatement();
    settingsStore.getFinPosition();
  }
  @Watch("reportType")
  public watchReportType(newValue: number): void {
    if(newValue == 2) {
      // this.reportData = this.finPosition;
      
      this.reportTitle = "Statement Of Financial Position";
    }
    if(newValue == 1) {
      // this.reportData = this.incomeStatement;
      this.reportTitle = "Income Statement Config";
    }
  
  }

  

  showAddForm() {
    this.switchCase = false;
  }
  closeForm(val: any) {
    this.switchCase = true;
 
    
  }

  saveDataForm(val: any) {
  //  settingsStore.getIncomeStatement(); 
  //  settingsStore.getFinPosition();
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  editReportConfig(prop: ReportConfig, self: any) {
    console.log(prop, "HEN SELF",self)
    GlobalEvent.emit("edit-report-config", prop);
   this.switchCase = false;
  }

  deleteData(prop: ReportConfig, self: any): void {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            const response = await  financeReportService.removeReport(prop.AccountReportScheduleInfoId);
            self.isLoading = false;
            self.toast("success", "Report Configuration Deleted");
            // if (prop.AccountReportScheduleId === 1){
            //   console.log('11RELOAD STORE', prop.AccountReportScheduleId);
              
            settingsStore.getIncomeStatement() 
            // //  this.reportData = this.incomeStatement
            //  }
            // if (prop.AccountReportScheduleId === 2){
            //   console.log('22RELOAD STORE', prop.AccountReportScheduleId);
            settingsStore.getFinPosition() 
            //   // this.reportData = this.finPosition 
            //   }
          } catch (error) {
           self.handleError(error);
          }
        }
      });
  }
  fields = [
    {
      title: "Row Number",
      name: "RowNumber"
    },
    {
      title: "Description",
      name: "Description",
      center: false
    },
    {
      title: "Type",
      name: "TotallingType"
    },
    {
      title: "Account Range",
      name: "IncludeRange"
    },
    {
      title: "Totalling",
      name: "Totalling"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: ReportConfig, self: any): void => {
        this.editReportConfig(prop, self)  
      }
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger hr",
      showIcon: true,
      action: (prop: ReportConfig, self: any) => {
        // this.removeTableData(practiceGroupsService.remove, prop.PracticeGroupId);
        this.deleteData(prop, self);
      }
    }
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any) => {

    //   }
    // }
  ];
}
