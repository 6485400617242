
/* eslint-disable @typescript-eslint/no-this-alias */
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { financeService } from "../../../finances/finances.service";

import AddSubAccount from "./AddSubAccount.vue";
import $ from "jquery";

import dropdownMenu from "@/components/DropdownMenu.vue";
import dayjs from "dayjs";
// import dropdownMenu from "@mmmrks/vue-dropdown-menu";

@Options({
  components: {
    AddSubAccount,
    dropdownMenu,
  },
})
export default class SetupAccountTypes extends Vue {
  BaseAccountTypes: any[] = [];
  AccountTypes: any[] = [];
  formEvent = FormEvents.ADD_SUBACCOUNT_FORM;
  isPageReady = false;

  async created(): Promise<void> {
    // const hasReloaded =

    // localStorage.setItem("last-load", dayjs().toString());

    const lastLoaded = localStorage.getItem("last-load");

    const difference = dayjs(Date.now()).diff(dayjs(lastLoaded), "minute");

    if (difference != 0) {
      localStorage.setItem("last-load", dayjs().toString());
      console.log({ difference });
      window.location.reload();
    }

    // localStorage.setItem("last-load", Date.now().toString());
    await Promise.all([this.getBaseAccountTypes(), this.getAccountTypes()]);

    this.isPageReady = true;
    // this.loadJS();

    // const app: any = this;
    // this.isPageReady = false;
    // $(document).ready(function () {
    //   const main = document.createElement("script");
    //   // const bundle = document.createElement('script');
    //   // bundle.setAttribute('src', '/assets/js/bundle.js?ver=2.7.0');
    //   // main.setAttribute('src', '/assets/js/scripts.js?ver=2.7.0');
    //   main.setAttribute("src", "/assets/js/main.js");
    //   main.setAttribute("id", "main-js");
    //   try {
    //     // $('#bundle-js').remove();
    //     setTimeout(() => {
    //       window.jQuery("script[id=main-js]").remove();
    //       // document.body.appendChild(bundle);
    //       document.body.appendChild(main);
    //       app.isPageReady = true;
    //     }, 1000);
    //   } catch (error) {
    //     // document.body.appendChild(bundle);
    //     document.body.appendChild(main);
    //     // app.isPageReady = true;
    //   }
    // });

    // $(document).ready(function () {
    //   $(".collapse").on("click", function () {
    //     console.log("clicked");
    //   });
    // });
  }

  async getBaseAccountTypes(): Promise<void> {
    try {
      const response = await financeService.getBaseAccountTypes();
      this.BaseAccountTypes = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getAccountTypes(): Promise<void> {
    try {
      const response = await financeService.getAccountTypes();
      this.AccountTypes = response.Data.sort((a: number, b: number) => {
        return a - b;
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  findParentSubAccounts(Id: string): any[] {
    return this.AccountTypes.filter((x) => x.BaseAccountTypeId == Id && x.ParentAccountTypeId == null);
  }

  findSubAccounts(AccountTypeId: string): any[] {
    return this.AccountTypes.filter((x) => x.ParentAccountTypeId == AccountTypeId);
  }

  async moveAccount(AccountTypeId: number, direction: string): Promise<void> {
    try {
      const response = direction == "up" ? await financeService.moveAccountUp(AccountTypeId) : await financeService.moveAccountDown(AccountTypeId);

      // await this.getAccountTypes();
      await Promise.all([this.getBaseAccountTypes(), this.getAccountTypes()]);
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }
}
