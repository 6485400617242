import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

class PracticeGroupsService extends BaseService {
  resource = "General/PracticeGroup";

  findAll(): Promise<AppResponse> {
    return this.get("GetAll");
  }

  removeUserFromGroup(EmployeePracticeGroupId: number): Promise<AppResponse> {
    return this.post("RemoveUserFromPracticeGroup", { EmployeePracticeGroupId });
  }

  addMember(payload: { EmployeeId: number; PracticeGroupId: number }): Promise<AppResponse> {
    return this.post("AddEmployee", payload);
  }

  removePracticeArea(PracticeAreaId: number): Promise<AppResponse> {
    return this.get(`RemovePracticeArea/${PracticeAreaId}`);
  }

  findAllasBusinessUnits(): Promise<AppResponse> {
    return this.get("AsBusinessUnits");
  }
}

export const practiceGroupsService = new PracticeGroupsService();
