
  import {mixins, Options} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {GlobalEvent} from "@/shared/plugins/tiny-emitter";
  import {FormEvents} from '@/constants';
  import NCApp from "@/app/index";
  import {TimesheetService,TimesheetService2} from './timesheet.service'
  import {VueEditor} from "vue3-editor";
  import SearchableSelect from "../../../components/SearchableSelect.vue";
  import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
  import { settingsStore } from '@/modules/settings/settings.store';

  @Options({
    components: {
      VueEditor,
      SearchableSelect,
      Multiselect,
    },
    
  })

  export default class TimeSheetsForm extends mixins(ModalFormMixin) {
    modalId = 'show-time-sheets-form';
    formEvent = FormEvents.TIME_SHEET_FORM;
    service = TimesheetService;
    primaryKey = "TimeSheetId";
    resource = "CaseManager/GetSelect2Matters";
    emitName = "timesheetSelected";
    updateState = true;
    TimeSheets: any= [];
    app = NCApp;
    activities : any= [];
    employees = settingsStore.employees;
    payload ={
        ActivityTypeId: "",
        ChargeType: "",
        TimeSheetId: "",
        EmployeeId: "",
        MatterId: "",
        SheetDate: "",
        Narrative: "",  
        TimeEntry: "",
        MatterTitle: "",
    }
  
    customModel =  {MatterName: this.payload.MatterTitle, MatterId : this.payload.MatterId};
    customKey = "time-sheets-data-table";

    created(): void {
      this.loadJS();
    }

    mounted(): void {
        this.allActivities(settingsStore.activities);
        console.log(this.payload, "I am here o.");
        
        GlobalEvent.on(this.emitName, (value: Record<string, unknown>) => {
        this.payload.MatterTitle = value.MatterName as string;
        this.payload.MatterId = value.MatterId as string;
        this.customModel = value as any;
        console.log("The matter Id is: ", this.payload.MatterId,"and the client Id is: ", value, "custom", this.customModel);
        });
        this.customKey = "time-sheets-data-table";
    }

  
    generateBillType() {
        return [
        "Billable",
        "Non Billable",
    ];
    }

    allActivities (arr: any): void {
    const activities = [];
    activities.push({ActivityName: "All Activities"})
    

    for (let i = 0; i < arr.length; i++) {
      let obj = { ...arr[i], ActivityName: arr[i].ActivityName.slice(0, 40) }
      activities.push(obj)
    }

    this.activities = activities
  }

    async updateData(payload: any): Promise<void> {
      console.log(this.payload, "heeerrrrreeee");
      try {
        this.isLoading = true;
        console.log("The form data for timesheet update is:", this.payload);
        await TimesheetService2.update(payload);
        this.isLoading = false;
        this.toast("success", this.$t('message.time.modules.timesheets.notification.Update'));
        this.$emit('reloadTable','1');
        this.modal(this.modalId, false);
      } catch (error) {
        this.handleError(error);
      }
    }
}
