
import { Options, Vue } from "vue-class-component";
import CustomFieldsSetting from "@/modules/settings/matters/custom-fields/customFieldsSetting.vue";
import cmaSetting from "@/modules/settings/matters/cma/cmaSetting.vue";

import SettingItem from "@/modules/settings/components/settings-item.vue";

import { FormEvents } from "../../../constants";

@Options({
  components: {
    CustomFieldsSetting,
    SettingItem,
    cmaSetting
  }
})
export default class MatterSettings extends Vue {
  pgandareaformEvent = FormEvents.PRACTICE_GROUP_AND_AREA_FORM;

  mounted(): void {
    this.loadJS();
  }
}
