
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { PageTitles } from "../../../constants";
import { settingsStore } from "../../settings/settings.store";
import { leadReportService } from "../report.service";

@Options({})
export default class LeadInfoSummary extends Vue {
  breadcrumbLinks = ["Home", "Lead By Owner"];
  report = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "LeadOwnerFullName", title: "Lead Owner" },
    { name: "DealValue", title: "Value" },
    { name: "LeadStageName", title: "Status" },
    { name: "DateCreatedFormatted", title: "Date Created" }
  ];
  payload = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    Value: "",
    LeadOwner: [] as any
  };
  created() {
    document.title = PageTitles.CRMLeadbyOwner;
    this.init();
  }

  get users(): any[] {
    return settingsStore.users;
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.AllUsers();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.LeadOwner.length == 0) payload.LeadOwner = null;
      if (this.payload.Keyword == null) payload.Keyword = "";
      const response = await leadReportService.LeadOwnerSummaryReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
