
import { Options, Vue } from "vue-class-component";
import { PageTitles } from "../../../constants";
import { PipelineModel } from "../../crm/pipeline/pipeline.model";
import { settingsStore } from "../../settings/settings.store";
import { leadReportService } from "../report.service";

@Options({})
export default class LeadInfoSummary extends Vue {
  breadcrumbLinks = ["Home", "Lead Info Summary"];
  report = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "ActivityType", title: "Activity Type" },
    { name: "Summary", title: "Summary" },
    { name: "DateCreatedFormatted", title: "Date Created" },
  ];
  payload = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    activityType: 0 as any,
  };
  created() {
    document.title = PageTitles.CRMLeadActivity;
    this.init();
  }

  get pipelines(): PipelineModel[] {
    return settingsStore.pipelines;
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.getPipelines();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.activityType == 0) payload.activityType = null;
      if (this.payload.Keyword == null) payload.Keyword = "";
      const response = await leadReportService.LeadsActivitySummaryReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
