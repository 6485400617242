
import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

class FinanceReportService extends BaseService {
  resource = "Account";

  findIncomeStatement(): Promise<AppResponse> {
    return this.get("GetReportSchedule/BS");
  }
  findFinPosition(): Promise<AppResponse> {
    return this.get("GetReportSchedule/BSS");
  }

  updateReportConfig(reportData: any): Promise<AppResponse> {
    return this.post("Report/UpdateScheduleInfo", reportData);
  }

  addConfig(payload: any): Promise<AppResponse> {
    return this.post("Report/AddScheduleInfo", payload);
  }

  removeReport(reportConfigId: number): Promise<AppResponse> {
    return this.post(`DeleteReportSchedule/${reportConfigId}`);
  }
}

export const financeReportService = new FinanceReportService();
