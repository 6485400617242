import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class CMAService extends BaseService {
  resource = 'Tenants';

  getCMAConfig(): Promise<AppResponse> {
    return this.get(`GetConfigurations/CMA`);
  }
  updateConfig(data: any): Promise<AppResponse> {
    return this.post(`UpdateConfigurations`, data);
  }
}

export const cmaService = new CMAService();
