
import { Options, Vue } from "vue-class-component";

import { MMAModel } from "./MMA";
import { mattersService } from "@/modules/matters/matters.service";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { FormEvents } from "../../../../constants";
import MatterAssignments from "./MatterAssignments.vue";
import AddMatterAssignmentForm from "./AddMatterAssignmentForm.vue";
import { clientsService } from "../../../clients/clients.service";

@Options({
  components: { MatterAssignments, AddMatterAssignmentForm }
})
export default class MMA extends Vue {
  matterAssignments: MMAModel[] = [];
  formEvent = FormEvents.MATTER_ASSIGNMENT_FORM;
  clients: any[] = [];
  selectedMatters: any[] = [];
  selectedEmployees: any[] = [];

  created(): void {
    this.getMultipleMatterAssignments();
    this.fetchClients();
  }

  async getMultipleMatterAssignments(): Promise<void> {
    try {
      const response = await mattersService.getMatterAssignments();
      this.matterAssignments = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async fetchClients(): Promise<void> {
    try {
      const response = await clientsService.findAll();
      this.clients = response.Data;
      this.clients = this.clients.filter(x => x.Matters.length > 0);
    } catch (error) {
      this.handleError(error);
    }
  }

  fields = [
    {
      title: "User",
      name: "FullName"
    },
    // {
    //   title: "Count",
    //   name: "AssignedMatters"
    // },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  clientFields = [
    {
      title: "Client Name",
      name: "ClientName"
    },
    {
      title: "Matters",
      name: "Matters"
    }
  ];

  clientTableActions = [];

  tableActions = [
    {
      name: "Assigned Matters",
      iconClass: "icon ni ni-list-index",
      showIcon: true,
      action: (prop: any): void => {
        console.log(prop.AssignedMatterDetails);
        GlobalEvent.emit(FormEvents.MATTER_ASSIGNMENTS, prop);
      }
    }
  ];

  handleSelectedItems(items: any[]): void {
    this.selectedEmployees = items;
  }

  handleClientCheck(ClientId: number): void {
    const matters = this.clients.find(x => x.ClientId == ClientId).Matters;

    const matterIds = matters.map((x: any) => x.MatterId);

    if (this.selectedMatters.filter(x => matterIds.includes(x)).length < matterIds.length) {
      this.selectedMatters = [...this.selectedMatters, ...matterIds];
      const temp = [...new Set(this.selectedMatters)];
      this.selectedMatters = temp;
    } else {
      this.selectedMatters = this.selectedMatters.filter(x => !matterIds.includes(x));
    }
  }

  async assignMatters(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await mattersService.assignMatters(this.selectedEmployees, this.selectedMatters);
      this.isLoading = false;
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }
}
