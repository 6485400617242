
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ClientPortalForm from "@/modules/settings/global/clientportal/clientPortalForm.vue";
import { Prop } from "vue-property-decorator";
import { settingsStore } from "../../settings.store";
import { ClientPortalModel } from "./model/clientPortal.model";
import { clientPortalService } from "./clientPortal.service";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
@Options({
  components: { ClientPortalForm }
})
export default class ClientPortal extends Vue {
  formEvent = FormEvents.CLIENT_PORTAL_FORM;

  @Prop() description!: string;
  @Prop() title!: string;
  @Prop() modalAction!: string;
  @Prop() iconClass!: string;

  get clientPortal(): ClientPortalModel | Record<string, unknown> {
    return settingsStore.clientPortalSettings;
  }

  async showClientPortalForm() {
    // await settingsStore.getClientPortalSettings();
    GlobalEvent.emit(FormEvents.CLIENT_PORTAL_FORM, this.clientPortal);
  }

  // mounted() {
  //   console.log(this.globalSettings.EnableClientPortal, "Global Settings", this.globalSettings);
  // }

  created() {
    console.log("client portal data", this.clientPortal);
  }
}
