
import { Options, mixins } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { accountPeriod } from "./accountPeriod.model";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import accountperiodSettingsForm from "./accountperiodSettingsForm.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { AccountPeriodService, GetAccountPeriodService } from "./accountperiod.service";

@Options({
  components: { accountperiodSettingsForm }
})

export default class accountPeriodDetails extends mixins(DataTableMixin){
  reloadStore = settingsStore.getAllAccountPeriods;
  formEvent = FormEvents.ACCOUNT_PERIOD_FORM;
  currentAccountPeriod!: accountPeriod;
  toggleOpen = false;
  allowClick = true;
  // primaryKey="AccountingPeriodId"

  get accountPeriods(): accountPeriod[] {
    return settingsStore.accountPeriods;
  }

  created(): void {
    this.loadJS();
  }

  async updateType(id: number, prop: any, e: any) {
    if(this.allowClick == true){
      this.allowClick = false;
   await GetAccountPeriodService.toggle(id).then(response => {
        if (response.Success) {
          settingsStore.getAllAccountPeriods().then(response => {
            // running = false;
            // e.target.disabled = false
          });
        }
      });
    console.log(e);
   
    // const vm = this;
    setTimeout(()=>{ 
    this.allowClick = true;
    console.log("allowClick is now reset to true")
    }, 2000);
  // return false
    }
    else{
      console.log("allowClick is false");
      
    }
  }

  logDate(val: any) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // console.log(val, "here is the value,");
    // console.log(monthNames, "Month names here");
    
    const xyz=   `01-${monthNames[((val.AccountMonth)*1)-1]}-${val.AccountYear} to ${new Date(
      val.AccountYear,
      val.AccountMonth--,
      0,
    ).getDate()}-${monthNames[val.AccountMonth]}-${val.AccountYear}` 
    // console.log(xyz, "I am xyz");
    return xyz;
  }


  fields = [
    {
      title: "Period",
      name: "AccountYear"
    },

    {
      title: "Type",
      name: "OpenFlag"
    },

    // { name: "actions", title: "Actions", showTitle: false }
  ];

}
