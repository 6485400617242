
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterProgressNotesTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields: { title: string; name: string }[] = [
    ...this.fields,
    {
      title: "Notes",
      name: "Notes"
    },
    { title: "Created By", name: "CreatedBy" },
    { title: "Date", name: "DateCreatedFormatted" }
  ];

  async created(): Promise<void> {
    try {
      const response = await mattersService.matterOverviewProgressNotes(this.Matter.MatterId);
      this.Data = response;
    } catch (error) {
      this.handleError(error);
    }
  }
}
