
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import numberingService from "./numbering.service";

@Options({
  components: {}
})
export default class NumberingFormatForm extends mixins(ModalFormMixin) {
  reloadStore = settingsStore.getNumberingFormats;
  modalId = "show-numbering-form";
  formEvent = FormEvents.NUM_FORM;
  service = numberingService;
  primaryKey = "SettingName";
  timeData = 1;
  formatList = this.numberingFormat;

  get inputList(): string[] {
    return [
      "ClientNumberingType",
      "ClientNumberFormat",
      "MatterNumberingType",
      "MatterNumberFormat",
      "FeeNoteNumberingType",
      "FeeNoteNumberFormat",
      "InvoicePaymentNumberingType",
      "InvoicePaymentNumberFormat"
    ];
  }



  setData(value: number, index: number): void {
    if (this.formatList[index].SettingValue !== value) {
      this.formatList[index].SettingValue = value;
    }
  }

  get numberingFormat(): Record<string, unknown>[] {
    const allFormats = settingsStore.numberingFormat;
    const newFormatList: Record<string, unknown>[] = [];

    for (let i = 0; i < this.inputList.length; i++) {
      const inputData = this.inputList[i];
      for (let x = 0; x < allFormats.length; x++) {
        const format: any = allFormats[x];
        if (inputData === format.SettingName) {
          newFormatList.push(format);
          break;
        }
      }
    }
    return newFormatList;
  }

  async handleModalOpen() : Promise<void> {
    await this.reloadStore();
    this.formatList = this.numberingFormat;
  }

  async updateData(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.service.update(this.formatList);

      this.isLoading = false;
      console.log(this.$t("message.matterSettings.modules.numberingFormats.notification.Update"), "i18n Message");

      this.toast("success", response.Message);
      this.reloadStore();
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
