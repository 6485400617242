
/* eslint-disable @typescript-eslint/no-this-alias */
import { Options, Vue } from "vue-class-component";
import $ from "jquery";
import "quill/dist/quill";
import "quill/themes/snow";

@Options({
  props: ["id"],
  created(): void {
    const main = this;
    $(document).ready(function() {
      var toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [
          {
            list: "ordered"
          },
          {
            list: "bullet"
          }
        ],
        [
          {
            script: "sub"
          },
          {
            script: "super"
          }
        ], // superscript/subscript
        [
          {
            indent: "-1"
          },
          {
            indent: "+1"
          }
        ], // outdent/indent
        [
          {
            header: [1, 2, 3, 4, 5, 6]
          }
        ],
        [
          {
            color: []
          },
          {
            background: []
          }
        ], // dropdown with defaults from theme
        [
          {
            font: []
          }
        ],
        [
          {
            align: []
          }
        ],
        ["clean"] // remove formatting button
      ];
      new window.Quill(`#${main.id}`, {
        modules: { toolbar: toolbarOptions },
        theme: `snow`
      });
    });
  }
})
export default class NCTextArea extends Vue {}
