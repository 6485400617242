import { AppResponse } from "../../shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";

class ClientsService extends BaseService {
  resource = "Clients";

  findAll() {
    return this.get("GetClients");
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`AddClient`, payload);
  }

  findOne(id: string): Promise<AppResponse> {
    return this.get(`GetClientById/${id}`);
  }

  mergeClients(payload: Record<string, unknown> | unknown[]) {
    return this.post("MergeClient", payload);
  }
}

class ClientReportService extends BaseService {
  resource = "ClientReports";

  getDashboard(id: string): Promise<AppResponse> {
    return this.get(`AllClientsDashboard/0/${id}`);
  }

  getLandingDashboard(): Promise<AppResponse> {
    return this.get(`LandingDashboard`);
  }
}

export const clientsService = new ClientsService();

export const clientReportService = new ClientReportService();
