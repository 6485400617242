
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { mattersService } from "../../../matters/matters.service";

@Options({})
export default class MatterAssignments extends mixins(ModalFormMixin) {
  modalId = "MatterAssignments";
  formEvent = FormEvents.MATTER_ASSIGNMENTS;
  selectedItems: any[] = [];

  payload = {
    Id: 0,
    AssignedMatterDetails: []
  };

  fields = [
    {
      title: "Matter Title",
      name: "MatterTitle"
    },
    // {
    //   title: "Matter Code",
    //   name: "MatterCode"
    // },
    {
      title: "Client Name",
      name: "ClientName"
    }
  ];

  async unAssignMatters(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await mattersService.unAssignMatters([this.payload.Id], this.selectedItems);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
      this.selectedItems = [];
      this.$emit("refresh");
    } catch (error) {
      this.handleError(error);
    }
  }
}
