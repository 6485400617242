
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import billService from "../../../time/bill/bill.service";
import { AddBillItemDto } from "../dto/add-bill-item.dto";

@Options({
  emits: ["update-expenses"],
})
export default class AddExpenseForm extends mixins(ModalFormMixin) {
  modalId = "add-bill-item-form";
  formEvent = FormEvents.ADD_BILL_ITEM_FORM;
  payload: Partial<AddBillItemDto> = {};

  async savedata(payload: AddBillItemDto): Promise<void> {
    try {
      const response = await billService.addItem(payload);
      this.toast("success", response.Message);
      this.payload.Description = "";
      this.payload.Amount = 0;
      this.payload.TransactionDate = "";
      this.$emit("update-expenses", response.Data);
    } catch (error) {
      this.handleError(error);
    }
  }
}
