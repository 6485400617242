
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { mixins, Options } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { billGroupService } from "./billgroup.service";
import { BillGroupRate } from "./model/billgroup.model";

@Options({})
export default class BillGroupForm extends mixins(ModalFormMixin, DataTableMixin) {
  formEvent = FormEvents.BILL_GROUP_RATE;
  modalId = "bill-group-rate-form";
  service = billGroupService;
  reloadStore = settingsStore.getAllBillGroups;

  fields = [
    {
      title: "Currency",
      name: "TransactionCurrency",
    },
    {
      title: "Rate",
      name: "BillingRate",
    },
  ];

  ratesPayload: Record<string, unknown>[] = [];

  async update(Id: any) {
    await this.payload.BillGroupRates.map((e: any) => {
      const object = {
        Id: Id,
        BillGroup: e.BillGroup,
        BillingRate: e.BillingRate,
        TransactionCurrencyId: e.TransactionCurrencyId,
      };
      this.ratesPayload.push(object);
    });
    console.log("Payload is ", this.ratesPayload);
    try {
      this.isLoading = true;
      var responses = await billGroupService.update(this.ratesPayload);
      this.isLoading = false;
      this.toast("success", responses.Message);
      await this.fetchBillgroup();
      this.reloadStore();
    } catch (error) {
      this.handleError(error);
    }
  }

  getIndex(item: any, searchValue: any) {
    return item.findIndex((i: any) => i.TransactionCurrencyId == searchValue);
  }

  getBillGroupRateValue(value: BillGroupRate) {
    var index = this.getIndex(this.payload.BillGroupRates, value.TransactionCurrencyId);
    this.payload.BillGroupRates[index].BillingRate = value.BillingRate;
  }

  async fetchBillgroup() {
    var billGroupData = await billGroupService.findOne(this.payload.Id);
    this.payload = billGroupData.Data;
  }

  setBillGroupData(newValue: BillGroupRate) {
    this.payload.BillGroupRates = newValue;
  }

  @Watch("payload.BillGroupRates")
  public watchRateSettings(newValue: BillGroupRate): void {
    console.log("the watcher payload", this.payload.BillGroupRates);
    this.setBillGroupData(newValue);
  }
}
