
import { mixins, Options, Vue } from "vue-class-component";
import { BankPostingGroupModel } from "./BankPostingGroup.model";
import { bankPostingGroupService } from "./BankPostingGroupService.service";
import BankPostingGroupForm from "./BankPostingGroupForm.vue";
import { FormEvents } from "../../../../constants";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";

@Options({
  components: {
    BankPostingGroupForm,
  },
})
export default class BankPostingGroups extends mixins(DataTableMixin) {
  bankPostingGroups: BankPostingGroupModel[] = [];
  formEvent = FormEvents.BANK_POSTING_GROUP_FORM;

  fields = [
    {
      title: "Group Name",
      name: "GroupName",
    },
    {
      title: "GL Account",
      name: "AccountName",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: BankPostingGroupModel): void => {
        GlobalEvent.emit(FormEvents.BANK_POSTING_GROUP_FORM, prop);
      },
    },
  ];

  async created(): Promise<void> {
    // this.loadJS("root");
    await this.getBankPostingGroups();
  }

  async getBankPostingGroups(): Promise<void> {
    try {
      const response = await bankPostingGroupService.findAll();
      this.bankPostingGroups = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
