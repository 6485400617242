import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

export class customFieldsService extends BaseService {
  resource = "CustomFields"; // insert Api resource here

  findAll(): Promise<AppResponse> {
    return this.get("GetAll");
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  removeField(payload: any): Promise<AppResponse> {
    return this.post(`Remove`, payload);
  }
}

export const CustomFieldsService = new customFieldsService();
