import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class accountperiodService extends BaseService {
  resource = 'Account'; // insert Api resource here

  getAll():Promise<AppResponse> {
    return this.get('GetAccountPeriod');
  }


  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }
}


export const AccountPeriodService = new accountperiodService();


export class getAccountPeriodService extends BaseService {
  resource = 'AccountPeriod'; // insert Api resource here
 
  toggle(id:number):Promise<AppResponse> {
    return this.get(`Toggle/${id}`)
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

}

export const GetAccountPeriodService = new getAccountPeriodService();