
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { clientsService } from "@/modules/clients/clients.service";

@Options({})
export default class AddMatterAssignmentForm extends mixins(ModalFormMixin) {
  modalId = "MatterAssignmentForm";
  formEvent = FormEvents.MATTER_ASSIGNMENT_FORM;
  clients: any[] = [];

  get users() {
    return settingsStore.users;
  }

  handleModalOpen(): void {
    this.fetchClients();
    // settingsStore.AllUsers();
  }

  async fetchClients(): Promise<void> {
    try {
      const response = await clientsService.findAll();
      this.clients = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
