<template>
  <div :key="tableKey" :class="options.css.tableDiv">
    <table :id="tableId" :class="options.css.table" style="width: 100%">
      <thead>
        <tr :class="options.css.theadTr">
          <th :class="options.css.theadTh" v-for="(field, key) in fields" :key="key">
            <span :class="options.css.theadTitle">
              <!-- {{ field.title || field.name }} -->
              {{ field.title }}
            </span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr :class="options.css.tbodyTr" v-for="(row, index) in handleData" :key="index">
          <td :class="options.css.tbodyTd" v-for="(field, key) in fields" :key="index + key">
            <template v-if="field.name == 'SNO'">
              <span :class="options.css.tbodyTdText"> &nbsp;{{ index + 1 }}</span>
            </template>
            <template v-if="isSlot(field)">
              <slot :name="field.name" v-bind="row" />
            </template>
            <template v-else>
              <span :class="options.css.tbodyTdText">
                {{ fetchRowData(row, field.name) }}
              </span>
            </template>
          </td>
        </tr>
        <slot name="custom-row" />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-this-alias */
import $ from "jquery";
import "jquery/dist/jquery";
import "select2/dist/js/select2";
import "datatables.net-bs5";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.js";
export default {
  name: "Datatable",
  props: {
    tableId: {
      type: String,
      required: true,
    },
    tableData: {
      type: Array,
      required: false,
    },
    serverSideDataName: {
      type: String,
      required: false,
      default: function () {
        return "data";
      },
    },

    // serverSideData: {
    //   type: Array,
    //   required: false
    // },
    fields: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    datatableOptions: {
      type: Object,
      required: false,
    },
    serverSide: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    actions: {
      type: Array,
      default() {
        /* This contains an array of
        objects with the signature:
        {
          name: String,
          iconClass: HTML,
          action: Function
        } */
        return [];
      },
    },
    requestMethod: String,
    url: String,
  },
emits: ['tableReady'],
  created() {
    this.dispose();
    const main = this;
    $(document).ready(function () {
      main.tempOptions = main.init();
    });
  },

  data() {
    return {
      element: null,
      dataTable: null,
      serverSideData: [],
      updateState: false,
      tempOptions: null,
      firstLoad: true,
      tableKey: 0,
    };
  },
  

  methods: {
    fetchRowData(object, key) {
      const keys = key.split(".");
      let prop = object;
      keys.forEach((key) => {
        prop = prop[key];
      });
      return prop;
    },

    isSlot(field) {
      return field.name in this.$slots;
    },

    init() {
      const main = this;
      const element = $(`#${this.tableId}`);

      let datatableOptions = this.datatableOptions;
      // let datatableOptions = main.tempOptions ?? this.datatableOptions;

      // console.log(this.options);

      if (this.serverSide) {
        let columnData = this.fields;
        // let columnData = this.fields.filter(x => x.name != "SNO" && x.name != "actions");
        columnData = columnData.map((col) => {
          if (col.name == "SNO") return { data: `SNo` };
          if (col.name == "actions") return { data: this.fields[1].name };
          return {
            data: `${col.name}`,
          };
          // return {
          //   data: `${col.name}`
          // };
          // if (col.name != "SNO")
          //   return {
          //     data: `${col.name}`
          //   };
        });
        // console.log({ columnData });
        datatableOptions.serverSide = this.serverSide;
        datatableOptions.processing = false;
        datatableOptions.columns = columnData;
        datatableOptions.ajax = {
          url: this.options.auth.baseUrl + this.url,
          type: this.requestMethod,
          headers: {
            Authorization: `Bearer ${this.options.auth.token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          complete: function (data) {
            main.serverSideData = [];
            setTimeout(() => {
              main.serverSideData = main.fetchRowData(data.responseJSON, main.serverSideDataName);
              main.$emit("tableReady");
            }, 1000);
            // console.log({ serverData: main.serverSideData });
            $(`#${main.tableId} .odd`).remove();
            $(`#${main.tableId} .even`).remove();
            $(document).ready(function () {
              const actionsTh = $(`[aria-label='Actions: activate to sort column ascending']`);
              actionsTh.css("width", "15%");
              actionsTh[0].removeAttr("style");
            });
            // $(`th`).find(`[aria-label='Actions: activate to sort column ascending']`).removeAttr('style');
            // const actionsTh =  $(`th`).find(`[aria-label='Actions: activate to sort column ascending']`);
          },
        };
      }

      // console.log(datatableOptions);

      this.dataTable = element.DataTable(this.datatableOptions);
      $(`select[name="${this.tableId}_length"]`).removeClass("form-select-sm");
      $(`select[name="${this.tableId}_length"]`).removeClass("form-select");

      if (!this.serverSide) {
        this.$emit("tableReady");
      }

      // main.$emit('tableReady')
      return datatableOptions;
    },

    dispose() {
      /// mic check
      try {
        // console.log({
        //   dataTable: this.dataTable
        // });
        this.dataTable.destroy(false);
        // console.log("destroyed table");
      } catch (error) {
        // console.log("");
      }
    },
    showIcon(entry, data) {
      const { showIcon } = entry;
      if (typeof showIcon === "function") {
        return showIcon(data);
      }

      return showIcon;
    },
    showInMenu(entry, data) {
      const { showInMenu } = entry;
      if (typeof showInMenu === "function") {
        return showInMenu(data);
      }

      return showInMenu;
    },
    handleAction(data, action) {
      return action(data, this);
    },
  },
  watch: {
    tableData: {
      handler: function () {
        this.dispose();
        setTimeout(() => {
          this.init();
        }, 2000);
      },
    },
    // updateState: {
    //   handler: function() {
    //     const main = this;
    //     if (main.updateState && !main.firstLoad) {
    //       main.tempOptions.ajax2 = main.tempOptions.ajax;
    //       delete main.tempOptions.ajax;
    //       main.init(main.tempOptions);
    //       main.updateState = false;
    //     }
    //   }
    // }
  },
  computed: {
    handleData: function () {
      return this.serverSide ? this.serverSideData : this.tableData;
    },
  },
};
</script>

<style scoped></style>
