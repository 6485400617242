import NCApp from "../app/index";
import AuthService from "../modules/auth/auth.service";

const authService = new AuthService();

export async function AppGuard(to: any, from: unknown, next: any): Promise<void> {
  // console.log("TO", to);
  if (await authService.checkLogin()) {
    return next();
  }
  next("/login");
}

export async function LoginGuard(to: any, from: unknown, next: any): Promise<void> {
  if (await authService.checkLogin()) {
    return next("/app");
  }
  next();
}

export async function PermissionGuard(to: any, from: unknown, next: any): Promise<void> {
  const hasPermission: boolean = NCApp.hasPermission(to.meta.permission);
  if (hasPermission) {
    return next();
  }
  next("/error403");
}
