
  import {mixins} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import { FormEvents } from '@/constants';
  import { timeFormatService } from './timeFormat.service';
  import { settingsStore } from '../../settings.store';

  export default class FormatSettingsForm extends mixins(ModalFormMixin) {
    reloadStore = settingsStore.getTimeFormat;
    modalId = 'format-setting-form';
    formEvent = FormEvents.TIME_FORMAT_FORM;
    service : any = timeFormatService;
    primaryKey = "TimeEntryTimeFormat"
    timeData = '0';

    async handleModalOpen() : Promise<void> {
      await this.reloadStore();
      this.timeData = settingsStore.timeFormat.toString();
    }

    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        // await this.validate();
        // this.globalLoader(true);
        const response = await this.service.update({TImeEntryTimeFormat : this.timeData});
        this.payload = response.data ? response.data : '';
        // this.toastSuccess(response.Message);
        // this.globalLoader(false);
        this.isLoading = false;
        this.toast("success", response.Message);
        this.reloadStore();
        this.modal(this.modalId, false);
      } catch (error) {
        this.handleError(error);
      }
    }

    setData(value : string) : void {
      this.timeData = value;
    }
  }
