
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { crmSettingsService } from "./crmSettings.service";
import { settingsStore } from "../../settings.store";

@Options({})
export default class CrmSettingsForm extends mixins(ModalFormMixin) {
  modalId = "crm-settings-form";
  formEvent = FormEvents.CRM_FORM;
  service = crmSettingsService;
  isLoading = false;
  reloadStore = settingsStore.getGlobalSettings;

  async handleModalOpen(): Promise<void> {
    this.isLoading = true;
    try {
      settingsStore.getGlobalSettings().then((x: any) => {
        this.globalSettings;
        this.isLoading = false;
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  get globalSettings() {
    return settingsStore.globalSettings;
  }

  mounted() {
    console.log("payload Settings ", this.globalSettings);
  }

  Type = [
    {
      label: "Firm wide",
      value: 1,
    },
    {
      label: "Private",
      value: 0,
    },
  ];

  async update() {
    try {
      this.isLoading = true;
      var response = await this.service.update(this.payload);
      this.payload = { ...this.payload, ...response.Data };
      this.isLoading = false;
      this.reloadStore();
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
