import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NCApp from "../app/index";
import { authStore } from "../modules/auth/auth.store";

import LoginScreen from "../modules/auth/views/Login.vue";

import MainApp from "../views/nc-main.vue";

// SETTINGS MODULE
import Settings from "../modules/settings/views/settings.vue";
import GlobalSettings from "../modules/settings/global/globalsettings.vue";
import InvoiceBanks from "../modules/settings/global/invoice-bank/invoice_banks.vue";
import MatterSettings from "../modules/settings/matters/mattersettings.vue";
import PersonnelSetting from "../modules/settings/personnel/PersonnelSetting.vue";
import FinanceSettings from "../modules/settings/finance/financeSettings.vue";
import TimeSettings from "../modules/settings/time/timesettings.vue";
import IndustryPage from "../modules/settings/matters/industry/industries.vue";
import CrmSettings from "../modules/settings/crm/crmSettings.vue";
import IndustrySettings from "../modules/settings/crm/industry/industrySettings.vue";
import ActivitySettings from "../modules/settings/time/activitytype/activitySetting.vue";
import Pipelines from "../modules/settings/crm/pipeline/pipelines.vue";
import PracticeGroups from "../modules/settings/matters/practice-groups/practice-groups.vue";
import WorkFlows from "../modules/settings/matters/workflow/workflows.vue";
import UserSettings from "../modules/settings/employee-users/userSettings.vue";
import BankAccounts from "../modules/settings/finance/bank-accounts/bank_accounts.vue";
import BankPostingGroups from "../modules/settings/finance/bank-posting-groups/Index.vue";
import UsersMgmt from "../modules/settings/employee-users/users.vue";
import EmployeesMgmt from "../modules/settings/employee-users/employees.vue";
import RolesMgmt from "../modules/settings/employee-users/role_permission.vue";
import BillGroupsDetails from "../modules/settings/time/billgroup/billgroupdetails.vue";
import Error403 from "../modules/errors/403.vue";
import Error404 from "../modules/errors/404.vue";
import tenant404 from "../modules/errors/tenant404.vue";
import { AppGuard, PermissionGuard } from "./router.guard";
import ContactCategoryDetails from "../modules/settings/crm/contact-categories/contactCategoryDetails.vue";
import SignatureDetails from "../modules/settings/time/signatures/signatureDetails.vue";
import customFieldsDetails from "../modules/settings/matters/custom-fields/customFieldsDetails.vue";
import AccountPeriodDetails from "../modules/settings/finance/accountPeriods/accountPeriodDetails.vue";
import FinancialConfiguration from "../modules/settings/finance/configuration/financialConfiguration.vue";
import ReportSetting from "../modules/settings/finance/reportSettings/reportSettings.vue";
import ChartOfAccounts from "../modules/settings/finance/chart-accounts/chart-accounts.vue";
import MMAPage from "../modules/settings/matters/multiple-matter-assignment/MMA.vue";
import BillCoverLetterTemplates from "../modules/settings/time/bill-cover-templates/index.vue";
import LeadsPage from "../modules/crm/leads/leads.vue";
import LeadsDetailPage from "../modules/crm/leads/leads-detail.vue";
import Financials from "../modules/finances/Financials.vue";
import SetupAccountTypes from "../modules/settings/finance/setup/SetupAccountTypes.vue";
import Clients from "../modules/clients/clients.vue";
import ClientDashboard from "../modules/clients/client-dashboard.vue";
import Dashboard from "../modules/dashboard/dashboard.vue";
import TimeDashboard from "../modules/dashboard/time/timeDashboard.vue";
import GeneralDashboard from "../modules/dashboard/general/generalDashboard.vue";
import Landing from "../modules/dashboard/landing/landing.vue";
import crmDashboard from "../modules/dashboard/crm/crmDashboard.vue";
import FinanceDashboard from "../modules/dashboard/finance/FinanceDashboard.vue";

// END SETTINGS MODULE

//REPORTS
// CRM REPORTS
import LeadInfoSummary from "../modules/reports/crm/lead_info_summary.vue";
import LeadBySource from "../modules/reports/crm/lead_by_source.vue";
import LeadByIndustry from "../modules/reports/crm/lead_by_industry.vue";
import LeadByOwner from "../modules/reports/crm/lead_by_owner.vue";
import LeadByBusinessUnits from "../modules/reports/crm/lead_by_business_unit.vue";
import LostLeads from "../modules/reports/crm/lost_leads.vue";
import LeadByStage from "../modules/reports/crm/leads_by_stage.vue";
import LeadActivity from "../modules/reports/crm/lead_activity.vue";
import LeadByTag from "../modules/reports/crm/lead_by_tag.vue";
// END OF CRM REPORTS

//END OF REPORTS

// CRM  MODULE
import SalesPipelines from "../modules/crm/pipeline/SalesPipelines.vue";
import Leads from "../modules/crm/crmTable/leads.vue";
import Companies from "@/modules/crm/companies/companies.vue";
import CompanyView from "@/modules/crm/companies/company_view.vue";
import Contacts from "@/modules/crm/contact/contacts.vue";
import ContactView from "@/modules/crm/contact/contact_view.vue";
// END CRM MODULE

// MATTERS MODULE

import Matters from "../modules/matters/matter/Matters.vue";
import MatterOverview from "../modules/matters/matter/overview/MatterOverview.vue";
import ProgressNotes from "../modules/matters/progressNotes/progressNotes.vue";
import CourtUpdatesPage from "../modules/matters/courtupdates/courtupdates.vue";
import CourtUpdateDetailPage from "../modules/matters/courtupdates/courtupdates-detail.vue";
import TasksPage from "../modules/matters/tasks/tasksPage.vue";

import CMA from "@/modules/matters/clientMatterAcc/cmaMatter.vue";
import CMAView from "@/modules/matters/clientMatterAcc/cmaView.vue";
import CMAReview from "@/modules/matters/clientMatterAcc/cmaReview.vue";
// end MATTERS MODULE

// TIME MODULE
import TrackedActivities from "@/modules/time/trackedActivities/trackedActivities.vue";
import timesheets from "@/modules/time/timesheet/timesheet.vue";
import Billing from "@/modules/time/bill/bill.vue";
import TimeLineOverView from "../modules/time/timeline/timeline.vue";
import MatterDashboard from "../modules/dashboard/matterDashboard/matterDashboard.vue";
// END TIME MODULE

// CALENDAR MODULE
import MyCalendar from "../modules/calendar/myCalendar/myCalendar.vue";
import TimesheetCalendar from "../modules/calendar/timesheetCalendar/timesheetCalendar.vue"


const routes: Array<RouteRecordRaw> = [
  { path: "/:pathMatch(.*)*", redirect: "/error404" },
  {
    path: "/login",
    name: "LoginScreen",
    component: LoginScreen,
  },
  {
    path: "/tenantnotfound",
    name: "TenantError404",
    component: tenant404,
  },
  {
    path: "/",
    name: "Home",
    beforeEnter: AppGuard,
    component: MainApp,
    children: [
      {
        path: "/error404",
        name: "Error404",
        component: Error404,
      },
      {
        path: "/error403",
        name: "Error403",
        component: Error403,
      },
      {
        path: "/General",
        name: "Dashboard",
        component: GeneralDashboard,
      },
      {
        path: "/crm",
        name: "CRM Dashboard",
        component: crmDashboard,
      },
      {
        path: "/financials",
        name: "Finance Dashboard",
        component: FinanceDashboard,
      },
      {
        path: "/time-dashboard",
        name: "TimeDashboard",
        component: TimeDashboard,
      },
      {
        path: "/matter-dashboard",
        component: MatterDashboard,
        name: "matter-dashboard",
      },
      {
        path: "/",
        name: "Landing",
        component: Landing,
      },
      {
        path: "/settings",
        component: Settings,
        meta: { permission: "0x36" },
        beforeEnter: PermissionGuard,
        children: [
          {
            path: "/settings",
            component: GlobalSettings,
            name: "Global Settings",
          },
          { path: "/settings", component: MatterSettings, name: "Matter Settings" },
          { path: "/settings", component: PersonnelSetting, name: "Personnel Settings" },
          { path: "/settings", component: TimeSettings, name: "Time Settings" },
          { path: "/settings", component: CrmSettings, name: "CRM Settings" },
          { path: "/settings", component: UserSettings, name: "User Settings" },
          { path: "/settings", component: PracticeGroups, name: "Practice Groups" },
          { path: "/settings", component: ContactCategoryDetails, name: "Contact Categories" },
          { path: "/settings", component: SignatureDetails, name: "Signatures" },
          { path: "/settings", component: IndustrySettings, name: "Industry Settings" },
          { path: "/settings", component: FinanceSettings, name: "Finance Settings" },
          { path: "/settings", component: ActivitySettings, name: "Activity Settings" },
          { path: "/settings", component: BillGroupsDetails, name: "Bill Groups" },
          { path: "/settings", component: AccountPeriodDetails, name: "Account Periods" },
          { path: "/settings", component: FinancialConfiguration, name: "Financial Configuration" },
          { path: "/settings", component: ReportSetting, name: "Report Settings" },
          { path: "/settings", component: ChartOfAccounts, name: "Chart Of Accounts" },
          { path: "/settings", component: customFieldsDetails, name: "Custom Fields" },
          { path: "/settings", component: MMAPage, name: "MMAPage" },
          { path: "/settings", component: BillCoverLetterTemplates, name: "Bill Cover Letter Templates" },
          { path: "/settings", component: WorkFlows, name: "Work Flows" },
          { path: "/settings", component: BankAccounts, name: "Bank Accounts" },
          { path: "/settings", component: UsersMgmt, name: "User Management" },
          { path: "/settings", component: EmployeesMgmt, name: "Employee Management" },
          { path: "/settings", component: RolesMgmt, name: "Role Management" },
          { path: "/settings", component: Pipelines, name: "Pipelines" },
          { path: "/settings", component: InvoiceBanks, name: "Invoice Banks" },
          { path: "/settings", component: BankPostingGroups, name: "Bank Posting Groups" },
          { path: "/settings/setup-account-types", component: SetupAccountTypes, name: "Setup Account Types" },
        ],
      },
      {
        path: "/crm/leads",
        component: LeadsPage,
        name: "Leads Page",
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
        // children: [{ path: "/setup/setup-accounts", component: SetupAccountTypes, name: "Setup Account Types" }]
      },
      {
        path: "/crm/leads/detail/:id",
        component: LeadsDetailPage,
        name: "Leads Detail Page",
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      { path: "/crm/salesPipeline", component: SalesPipelines, name: "CRM Pipeline", props: true, meta: { permission: "0x22" } },
      {
        path: "/crm/contacts",
        component: Contacts,
        name: "Contacts",
      },
      {
        path: "/crm/contacts_view/:id",
        component: ContactView,
        props: true,
        name: "ContactView",
        meta: { permission: "0x2A" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/crm/companies",
        name: "CompaniesPage",
        component: Companies,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/crm/company_view/:id",
        name: "CompanyView",
        component: CompanyView,
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/reports/crm/summary",
        component: LeadInfoSummary,
        name: "Lead Info Summary",
      },
      {
        path: "/reports/crm/source",
        component: LeadBySource,
        name: "Lead By Source",
      },
      {
        path: "/reports/crm/industry",
        component: LeadByIndustry,
        name: "Lead By Industry",
      },
      {
        path: "/reports/crm/owner",
        component: LeadByOwner,
        name: "Lead By Owner",
      },
      {
        path: "/reports/crm/units",
        component: LeadByBusinessUnits,
        name: "Lead By Units",
      },
      {
        path: "/reports/crm/lost",
        component: LostLeads,
        name: "Lost Leads",
      },
      {
        path: "/reports/crm/lead-stage",
        component: LeadByStage,
        name: "Lead By Stage",
      },
      {
        path: "/reports/crm/activity",
        component: LeadActivity,
        name: "Lead Activity",
      },
      {
        path: "/reports/crm/tags",
        component: LeadByTag,
        name: "Lead By Tag",
      },
      {
        path: "/crm/table",
        component: Leads,
        name: "Crm Table",
      },
      {
        path: "/matters/cma",
        component: CMA,
        name: "CMA Matters",
      },
      {
        path: "/matters/cma/:id",
        name: "CMAView",
        component: CMAView,
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/matters/cma/pending/:id",
        name: "CMAReview",
        component: CMAReview,
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/matters/progressNotes",
        name: "ProgressNotes",
        component: ProgressNotes,
      },
      {
        path: "/matters/tasks",
        component: TasksPage,
        name: "TasksPage",
      },
      {
        path: "/clients",
        component: Clients,
        name: "Clients",
      },
      {
        path: "/client-dashboard/:id",
        component: ClientDashboard,
        name: "Client Dashboard",
      },
      {
        path: "/matters",
        component: Matters,
        name: "Matters",
      },
      {
        path: "/matters/courtUpdates",
        component: CourtUpdatesPage,
        name: "Court Updates Page",
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/matters/courtUpdates/:id",
        name: "CourtUpdatesDetailPage",
        component: CourtUpdateDetailPage,
        props: true,
        meta: { permission: "0x26" },
        beforeEnter: PermissionGuard,
      },
      {
        path: "/matters/matter-overview/:MatterId",
        component: MatterOverview,
        props: true,
        meta: { permission: "0x5E" },
        name: "MatterOverview",
      },
      {
        path: "/time/activities",
        component: TrackedActivities,
        name: "TrackedActivities",
      },
      {
        path: "/time/timesheet",
        name: "Timesheets",
        component: timesheets,
      },
      {
        path: "/time/bill",
        name: "Billing",
        component: Billing,
      },
      {
        path: "/time/timeline",
        component: TimeLineOverView,
        name: "TimeLineOverview",
      },
      {
        path : "/calendar/my-calendar",
        component : MyCalendar,
        name : "MyCalendar"
      },
      {
        path : "/calendar/timesheetCalendar",
        component : TimesheetCalendar,
        name : "TimesheetCalendar"
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _) => {
  try {
    if (to.path == "/login" || !authStore.tenantConfiguration.AppUrl) await NCApp.setup();
    return true;
  } catch (error) {
    console.log("RunApp error", error);
    //  router.push("/yyyy");
    return false;
  }
});

export default router;
