
import { mixins, Options, Vue } from "vue-class-component";
import {ProgressNotesService} from "./progressNotes.service"
import progressNotesForm from "./progressNotesForm.vue"
import progressNotesViewForm from "./progressNotesViewForm.vue"
import { FormEvents } from "@/constants";
import {GlobalEvent} from "@/shared/plugins/tiny-emitter"
import NCApp from "@/app/index";
import TaskAddForm from "../tasks/task-add-form.vue"
import CourtUpdateForm from "../courtupdates/new-court-update.vue";
import CmaMatterForm from "../clientMatterAcc/cmaMatterForm.vue";
import MatterForm from "../matter/components/MatterForm.vue"
import TimeSheetsForm from "@/modules/time/timesheet/timesheetForm.vue"
// import { leadReportService } from "../report.service";
 import DataTableMixin from "@/shared/mixins/datatable.mixin";
// import TimesheetForm from "@/modules/time/trackedActivities/timesheetForm.vue";

@Options({
    components: {
        progressNotesForm,
        progressNotesViewForm,
        CourtUpdateForm,
        CmaMatterForm,
        MatterForm,
        TaskAddForm,
        TimeSheetsForm
    }
})

export default class progressNotes extends mixins(DataTableMixin) {
  formEvent = FormEvents.PROGRESS_NOTES_FORM;
  ProgressNotes = [];
  app = NCApp;
  customKey = "progress-notes-data-table";
  // isLoading = true;

  optionsData = [
      {
        name: 'New Progress Note',
        id: 'menu-item-0',
        formEventName: FormEvents.PROGRESS_NOTES_FORM,
        hasPermission: this.app.hasPermission('0x44'),
      },
      {
        name: 'Client/Matter Acceptance ',
        id: 'menu-item-1',
        formEventName: FormEvents.CMA_MATTER_FORM,
        hasPermission: this.app.hasPermission('0x40'),
      },
      {
        name: 'New Client',
        id: 'menu-item-2',
        formEventName: FormEvents,
        hasPermission:this.app.hasPermission('0x48'),
      },
      {
        name: 'New Matter',
        id: 'menu-item-2',
        formEventName: FormEvents.MATTER_FORM,
        hasPermission:this.app.hasPermission('0x5C'),
      },
      {
        name: 'New Court Update',
        id: 'menu-item-4',
        formEventName: FormEvents.NEW_COURT_UPDATE,
        hasPermission: this.app.hasPermission('0x3C'),
      },
      {
        name: 'New Parties/External Resource',
        id: 'menu-item-4',
        formEventName: FormEvents,
        hasPermission: true,
      },
      {
        name: 'New Task',
        id: 'menu-item-3',
        formEventName: FormEvents.MATTER_TASK_ADD_FORM,
        hasPermission: true,
      },
      {
        name: 'New Time Entry',
        id: 'menu-item-5',
        formEventName: FormEvents.TIME_SHEET_FORM,
        hasPermission: this.app.hasPermission('0x68'),
      },
      {
        name: 'New Milestone',
        id: 'menu-item-4',
        formEventName: FormEvents,
        hasPermission: true,
      },
    ]
  
  mounted() : void {
    GlobalEvent.on("progress-notes-datatable-key", (_ : unknown) => {
      console.log("custom key before is: ", this.customKey);
      this.customKey = this.customKey == "progress-notes-data-table" ? "progress-notes-data-table1" : "progress-notes-data-table";
      console.log("task-page emit is called here", _, this.customKey);
    })
  }
  
  getprogressNotes(){
      return []
  }

  handleTableReady() {
    this.isLoading = false;
    console.log("handleTableReady --",  this.isLoading, this.pageIsReady);
  }
 
  beforeMount() {
    this.isLoading = true;
  }

 openProgressViewForm(prop : Record<string, unknown>) : void {
    console.log("Show task view is called");
    GlobalEvent.emit(FormEvents.PROGRESS_NOTES_VIEW_FORM, prop)
  }

 openProgressNotesForm(payload?: Record<string, unknown>) : void {
    if(payload && payload.Id) {
      console.log('its edith');
      console.log(payload, "I am payload");
      
      
      GlobalEvent.emit(FormEvents.PROGRESS_NOTES_FORM, payload);
    } else {
      GlobalEvent.emit(FormEvents.PROGRESS_NOTES_FORM);
    }
  }

deleteProgressNote(id: string, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        if (result.isConfirmed === true){
          ProgressNotesService.remove(id).then(response=>{
            if (response.Success === true) {
              try {
                self.isLoading = true;
                GlobalEvent.emit("progress-notes-datatable-key");
                self.toast("success", this.$t('message.matters.modules.progressNotes.notification.Delete'));
                self.isLoading = false;
              }
              catch (error) {
                self.handleError(error);
              }
            }else {
              self.isLoading = false;
              self.toast("error", response.Message);
            }
          })
        }else{
          // console.log("delete was cancelled");
        }
      });

  }

fields = [

    {
      title: "SNO",
      name: "SNO"
    },

    {
        title: "Matter Code",
        name: "MatterCode"
    },

    {
      title: "Matter Name",
      name: "MatterTitle"
    },

     {
      title: "Client Code",
      name: "ClientCode"
    },

    
    {
      title: "Client Name",
      name: "ClientName"
    },

    {
      title: "Created By",
      name: "CreatedByName"
    },

    {
      title: "Date",
      name: "DateCreatedFormatted"
    },

    { name: "actions", title: "Actions", showTitle: false }

  ];

  tableActions = [
    {
      name: "View Details",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action:(prop: any, self: any): void => {
       
          console.log("The view action prop is", prop);
      this.openProgressViewForm(prop)
      }
    },
    
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: async (prop: any, self: any) => {
        console.log("Edit", prop);
        console.log(this.formEvent);
        const payload = {
            MatterId : prop.MatterId,
            MatterTitle : prop.MatterTitle ,
            Notes: prop.Notes,
            Id : prop.Id,
          }
       this.openProgressNotesForm(payload);
      }
    },

    {
       type: 'divider'
    },

    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self:any) => {
        this.deleteProgressNote(prop.Id, self);
      }
    }
  ];
}
