import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  class: "form-label",
  for: "fva-topics"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.dataStored,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.dataStored = $event)),
      options: _ctx.dataList,
      "option-height": 100,
      searchable: true,
      label: _ctx.label,
      "track-by": _ctx.trackBy,
      placeholder: _ctx.placeholder ? _ctx.placeholder : `Enter 2 or more characters to search`,
      onSearchChange: _ctx.fetchOptions,
      onSelect: _ctx.emitSelected
    }, {
      option: _withCtx(({ option }) => [
        _renderSlot(_ctx.$slots, "option", { option: option })
      ]),
      _: 3
    }, 8, ["modelValue", "options", "label", "track-by", "placeholder", "onSearchChange", "onSelect"])
  ]))
}