
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { FormEvents } from "@/constants";
import companiesService from "./companies.service";
import { VueEditor } from "vue3-editor";

@Options({
  components: {
    VueEditor
  },
  emits: ["reloadMemo"]
})
export default class AddMemoForm extends mixins(ModalFormMixin) {
  modalId = "add-memo-form";
  formEvent = FormEvents.ADD_MEMO_FORM;
  service = companiesService;
  primaryKey = "ClientId";
  memoTypes = [
    { name: "Note", value: 1 },
    { name: "Email", value: 2 },
    { name: "Call", value: 3 }
  ];
  formData = {
    ClientId: 0,
    InteractionType: 0,
    Summary: ""
  };

  async saveData(): Promise<void> {
    try {
      this.isLoading = true;
      this.formData.ClientId = this.payload.id;
      // await this.validate();
      // this.globalLoader(true);
      await this.service.addMemo(this.formData);
      this.isLoading = false;
      this.toast("success", "Memo added successfully");
      this.formData = {
        ClientId: 0,
        InteractionType: 0,
        Summary: ""
      };
      GlobalEvent.emit("reloadMemo");
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
