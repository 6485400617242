import { BaseService } from "../../shared/services/base-service";

class TenantsService extends BaseService {
  resource = "Tenants";

  getConfigurations() {
    return this.get("GetConfigurations/HRM");
  }

  updateConfiguration(data: any[]) {
    return this.post("UpdateConfigurations", data);
  }
}

export const tenantsService = new TenantsService();
