
import {Options, mixins } from "vue-class-component";
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import {timesheetCalendarService} from "./timesheetCalendar.service";

@Options({
  components : {
    FullCalendar
  }
})

export default class TimesheetCalendar extends mixins(DataTableMixin) {
  customKey = "ckey-1";
  optionsData = [];
  currentDropdown = 1;
  filterDate = new Date();
  isLoading = false;

  calendarOptions : any = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    timeZone: 'UTC',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,dayGridMonth'
    },
    initialView: 'dayGridMonth',
    initialDate: this.filterDate,
    allDaySlot : false,
    events: [],
  }

  updateCalendarOptions(events : Record<string, unknown>[], date : string | Date) : void {
    if(events && events.length) {
      this.calendarOptions = {...this.calendarOptions, events}

      console.log("This event runs", this.calendarOptions);
    }

    if(date) {
      this.calendarOptions = {...this.calendarOptions, initialDate : date}

      console.log("This date runs", this.calendarOptions);
    }
    this.customKey = this.customKey === 'nck-2' ? 'nck-1' : 'nck-2';
  }

  async fetchData() : Promise<void> {
    this.isLoading = true;
    try {
      const entries = await timesheetCalendarService.fetchCalendarActivities({ModuleType : this.currentDropdown})
      console.log("The fetched entries are: ", entries.Data);

      if(entries.Data.length) {
        const newArray : any[] = [];

        for (let i = 0; i < entries.Data.length; i++) {
          const data = entries.Data[i];

          const title = `${data.RelatedEntityTitle} - ${data.Title}`;
          const date = new Date(data.StartDate).toISOString();
          newArray.push({title, date, data});
        }

        console.log("The user entries are: ", newArray);
        this.updateCalendarOptions(newArray, this.filterDate);
      }
    } catch(err) {
      console.log("Tracked activities error: ", err);
    }
    this.isLoading = false;
  }

  updateFilterDate(newDate : any) : void {
    console.log("The old and new dates are: ", new Date(this.filterDate).toDateString(), new Date(newDate).toDateString());
    
    if(new Date(this.filterDate).toDateString() !== new Date(newDate).toDateString()) {
      this.filterDate = newDate;
      this.calendarOptions = {...this.calendarOptions, initialDate : this.filterDate};
      this.customKey = this.customKey === 'nck-1' ? 'nck-2' : 'nck-1';
      console.log("The values are: ", this.customKey);
    }
  }
}
