
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../constants";
import { settingsStore } from "../settings.store";
import { userService } from "./users.service";
import CreateUser from "./add_user_form.vue";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import ConvertToUser from "./convert_to_user_form.vue";
import { UserModel } from "./models/user.model";
import DataTableMixin from "../../../shared/mixins/datatable.mixin";

@Options({
  components: { CreateUser, ConvertToUser }
})
export default class UsersMgmt extends mixins(DataTableMixin) {
  reloadStore = settingsStore.AllUsers;
  formEvent = FormEvents.CREATE_USER_FORM;

  get users(): any[] {
    return settingsStore.users;
  }

  created(): void {
    // settingsStore.AllUsers();
  }

  deleteUser(user: UserModel, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            const response = await userService.deleteUser(user.AppUserId);
            self.isLoading = false;
            self.toast("success", response.Message);
          } catch (error) {
            self.handleError(error);
          }
        }
      });
  }

  resetPassword(user: UserModel, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            const response = await userService.resetUserPassword(user.Username);
            self.isLoading = false;
            self.toast("success", response.Message);
          } catch (error) {
            self.handleError(error);
          }
        }
      });
  }

  fields = [
    {
      title: "Full Name",
      name: "FirstName"
    },
    {
      title: "Role",
      name: "Rolename",
      center: false
    },
    {
      title: "Email Address",
      name: "EmailAddress"
    },
    {
      title: "Date Created",
      name: "DateCreated"
    },
    {
      title: "Status",
      name: "StatusDescription"
    },
    { name: "actions", title: "Actions", showTitle: true }
  ];

  tableActions = [
    {
      name: "Reset Password",
      iconClass: "icon ni ni-lock",
      showIcon: false,
      action: (prop: any, self: any) => {
        this.resetPassword(prop, self);
      }
    },
    {
      name: "Change User Role",
      iconClass: "icon ni ni-unlock",
      showIcon: false,
      action: async (prop: any): Promise<void> => {
        GlobalEvent.emit(FormEvents.EMPLOYEE_TO_USER_FORM, prop);
      }
    },
    {
      name: "Disable User",
      iconClass: "icon ni ni-users",
      showIcon: false
      //   action: (prop: any): void => {
      //     console.log("Edditignin", prop);
      //     GlobalEvent.emit(FormEvents.PRACTICE_GROUP_AND_AREA_FORM, prop);
      //   }
    },
    {
      name: "Delete User",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self: any) => {
        this.deleteUser(prop, self);
      }
    }
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any) => {

    //   }
    // }
  ];
}
