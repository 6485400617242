import { BaseService } from "@/shared/services/base-service";
import { AppResponse } from "@/shared/interfaces/app-response";

export class CompaniesService extends BaseService {
  resource = 'Companies';

  getCompany(id: string): Promise<AppResponse> {
    return this.get(`GetCompany?CompanyId=${id}`);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`DeleteCompany?CompanyId=${id}`);
  }

  getRelated(id : string) : Promise<AppResponse> {
    return this.get(`RelatedData/${id}`);
  }

  getMemo(id : string) : Promise<AppResponse> {
    return this.get(`ClientMemos/${id}`);
  }

  addMemo(newData : Record<string, unknown>) : Promise<AppResponse> {
    return this.post(`CompanyMemo/Add`, newData);
  }

  deleteMemo(id : string) : Promise<AppResponse> {
    return this.get(`ClientMemos/Delete/${id}`);
  }
}

export class CompaniesService2 extends BaseService {
  resource = 'CRMReports/CRMCompanyDashboard';

  findOne(id: string): Promise<AppResponse> {
    return this.get(`${id}`);
  }

}

export class CompaniesService3 extends BaseService {
  resource = 'PLOV/Countries';

  getCountries() : Promise<AppResponse> {
    return this.get("");
  }
}

export class DataFilter extends BaseService {
  resource = "Companies/GetSelect2Companies";

  filterData(q : string) : Promise<AppResponse> {
    return this.get(`?term=${q}&_type=query&q=${q}`);
  }
}
export const companiesService2 = new CompaniesService2();
export const countryService = new CompaniesService3();
const companiesService = new CompaniesService();
export const filterData = new DataFilter();
export default companiesService;
