import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";
import { TimeKeeperDto } from "../time/time-keeper/dto/time-keeper-dto";

export class UserService extends BaseService {
  resource = "Users";

  findAllUsers(): Promise<AppResponse> {
    return this.get("get");
  }

  deleteUser(id: number): Promise<AppResponse> {
    return this.get(`DeleteUserProfile/${id}`);
  }

  resetUserPassword(Username: string): Promise<AppResponse> {
    return this.post(`UserResetPassword`, { Username });
  }
}

export const userService = new UserService();

export class EmployeeService extends BaseService {
  resource = "Employees";

  findAllEmployees(): Promise<AppResponse> {
    return this.get("GetAllEmployees");
  }

  addEmployee(payload: any): Promise<AppResponse> {
    return this.post("AddEmployee", payload);
  }

  updateEmployee(payload: any): Promise<AppResponse> {
    return this.post("UpdateEmployee", payload);
  }

  SaveTimeKeeperEmployees(payload: TimeKeeperDto): Promise<AppResponse> {
    return this.post("SaveTimeKeeperEmployees", payload);
  }

  GetTimeKeeperEmployees(id: number): Promise<AppResponse> {
    return this.get(`TimeKeeperEmployees/${id}`);
  }

  createUserFromEmployee(payload: any): Promise<AppResponse> {
    return this.post("CreateUserAccountForEmployee", payload);
  }
}

export const employeeService = new EmployeeService();
