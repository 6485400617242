import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_client_portal_setting = _resolveComponent("client-portal-setting")!
  const _component_numbering = _resolveComponent("numbering")!
  const _component_invoice_setting = _resolveComponent("invoice-setting")!
  const _component_currency_setting = _resolveComponent("currency-setting")!
  const _component_brand_logo_setting = _resolveComponent("brand-logo-setting")!
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: _ctx.$t('message.globalSettings.title'),
    description: _ctx.$t('message.globalSettings.desc')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_client_portal_setting, {
        title: "Client Portal",
        description: "Enable Collaboration with Client",
        iconClass: "ni-user-fill-c",
        modalAction: true
      }),
      _createVNode(_component_numbering, {
        title: "Numbering Formats",
        description: "Configure Client, Matter and Fee Note Numbering Format",
        iconClass: "ni-property",
        modalAction: true
      }),
      _createVNode(_component_invoice_setting, {
        title: _ctx.$t('message.globalSettings.modules.invoiceSetting.title'),
        description: _ctx.$t('message.globalSettings.modules.invoiceSetting.desc'),
        iconClass: "ni-report-profit",
        modalAction: true
      }, null, 8, ["title", "description"]),
      _createVNode(_component_currency_setting, {
        title: _ctx.$t('message.globalSettings.modules.currencySetting.title'),
        description: _ctx.$t('message.globalSettings.modules.currencySetting.desc'),
        iconClass: "ni-coin",
        modalAction: true
      }, null, 8, ["title", "description"]),
      _createVNode(_component_brand_logo_setting, {
        title: "Brand Logo",
        description: "Manage Logo.",
        iconClass: "ni-report-profit",
        modalAction: true
      }),
      _createVNode(_component_setting_item, {
        title: "Invoice Banks",
        description: "Manage Invoice Banks",
        iconClass: "ni-money",
        type: 'newPage',
        link: { name: 'Invoice Banks' }
      })
    ]),
    _: 1
  }, 8, ["title", "description"]))
}