
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { settingsStore } from "@/modules/settings/settings.store";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import { leadDetailService } from "./form-actions.service";

@Options({
  components: {
    VueEditor,
  },
  emits: ["reloadTask"],
})
export default class AddTaskForm extends mixins(ModalFormMixin) {
  modalId = "add-task-form";
  formEvent = FormEvents.ADD_TASK;
  service = leadDetailService;

  TaskType = [
    {
      label: "Meeting",
      value: 3,
    },
    {
      label: "Phone Call",
      value: 1,
    },
    {
      label: "Email",
      value: 2,
    },
    {
      label: "Appointment",
      value: 4,
    },
  ];

  Priority = [
    {
      label: "Low",
      value: 1,
    },
    {
      label: "Medium",
      value: 2,
    },
    {
      label: "High",
      value: 3,
    },
  ];

  formData = {
    Description: "",
    DueDate: "",
    EndDate: "",
    ModuleEntityId: "",
    ModuleId: 0,
    ModuleType: 1,
    Priority: 1,
    StartDate: "",
    Status: 0,
    TaskOutcome: null,
    TaskOwners: [""],
    TaskType: "",
    Title: "",
  };

  get transactionCurrencies() {
    return settingsStore.transactionCurrencies;
  }

  async refresh(): Promise<void> {
    this.formData = {
      Description: "",
      DueDate: "",
      EndDate: "",
      ModuleEntityId: "",
      ModuleId: 0,
      ModuleType: 1,
      Priority: 1,
      StartDate: "",
      Status: 0,
      TaskOutcome: null,
      TaskOwners: [""],
      TaskType: "",
      Title: "",
    };
  }

  get users() {
    return settingsStore.users;
  }

  date(date?: any) {
    function prefix(n: any) {
      return n < 10 ? `0${n}` : n;
    }
    const m2 = new Date(date).getMonth();
    const d2 = new Date(date).getDate();
    const y2 = new Date(date).getFullYear();
    const dt = `${y2}-${prefix(m2 + 1)}-${prefix(d2)}`;
    const getMonthsShortName = (ss: any) => {
      const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const mnthNum = ss * 1;
      const mnth = monthShortNames[mnthNum];
      return mnth;
    };
    const dts = `${prefix(d2)} ${getMonthsShortName(prefix(m2))}, ${y2}`;
    return dts;
  }

  async saveData(): Promise<void> {
    try {
      this.isLoading = true;
      this.formData.ModuleId = this.payload.id;
      this.formData.ModuleEntityId = `${this.payload.id}`;
      this.formData.StartDate = this.date(new Date());
      const endDate = this.date(this.formData.EndDate);
      this.formData.EndDate = endDate;
      this.formData.DueDate = endDate;

      console.log("The form data is: ", this.formData);
      const response = await this.service.create(this.formData);
      this.isLoading = false;
      this.toast("success", "Task created successfully");
      this.formData = {
        Description: "",
        DueDate: "",
        EndDate: "",
        ModuleEntityId: "",
        ModuleId: 0,
        ModuleType: 1,
        Priority: 1,
        StartDate: "",
        Status: 0,
        TaskOutcome: null,
        TaskOwners: [""],
        TaskType: "",
        Title: "",
      };
      GlobalEvent.emit("reloadTask");
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
