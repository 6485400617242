
import { authStore } from "@/modules/auth/auth.store";
import { Options, Vue } from "vue-class-component";
import { TenantConfiguration } from "../app/app.model";
import { AuthResponse } from "@/modules/auth/auth.model";
// import { AuthResponse} from '../modules/auth/auth.model';
// import { UserModel } from '../modules/authentication/auth.model';
// import { Logout } from '../modules/authentication/auth.api';

@Options({})
export default class Topbar extends Vue {
  get user(): Partial<AuthResponse> {
    return authStore.auth;
  }

  get tenantConfiguration(): Partial<TenantConfiguration> {
    return authStore.tenantConfiguration;
  }

  logout() {
    this.isLoading = true;
    setTimeout(() => {
      // console.log("goinOUT");

      authStore.LOGOUT();
      // console.log("goinOUTD");
      this.$router.push("/login");
      this.isLoading = false;
    }, 3000);
  }
}
