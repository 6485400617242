<template>
  <div class="tools">
    <ul class="nk-tb-actions gx-1">
      <template v-for="(entry, index) in actions">
        <li class="nk-tb-action-hidden" :key="index" v-if="entry.type !== 'divider' && showIcon(entry, data) !== false">
          <a
            href="#"
            class="btn btn-trigger btn-icon"
            data-toggle="tooltip"
            data-placement="top"
            :title="entry.name"
            :data-original-title="entry.name"
            @click.prevent="handleAction(data, entry.action)"
          >
            <em :class="`icon ni ${entry.iconClass}`"></em>
          </a>
        </li>
      </template>
      <li>
        <div class="drodown pr-1">
          <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown" aria-expanded="true"
            ><em class="icon ni ni-more-h"></em
          ></a>
          <div
            class="dropdown-menu dropdown-menu-right"
            style="
              position: absolute;
              transform: translate3d(-20px, 36px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
            x-placement="bottom-end"
          >
            <ul class="link-list-opt no-bdr" v-if="customMenuParsed.length">
              <template v-for="(entry, index) in customMenuParsed">
                <li :key="index" v-if="entry.type == 'menu'">
                  <a href="#" @click.prevent="handleAction(data, entry.action)"
                    ><em :class="`icon ni ${entry.iconClass}`"></em>
                    <span>{{ entry.name }}  </span>
                  </a>
                </li>
                <li v-else class="divider" :key="index`index`"></li>
              </template>
            </ul>
            <ul class="link-list-opt no-bdr" v-else>
              <template v-for="(entry, index) in actions">
                <li
                  :key="index"
                  v-if="showInMenu(entry, data) !== false"
                  :class="{ divider: entry.type === 'divider' }"
                >
                  <a href="#" @click.prevent="handleAction(data, entry.action)" v-if="entry.type !== 'divider'"
                    ><em :class="`icon ni ${entry.iconClass}`"></em>
                    <span>{{ entry.name }}  </span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import GlobalMixin from "@/shared/mixins";
export default {
  computed: {
    customMenuParsed() {
      const menuLength = this.customMenuList.length;
      const menus = [];
      this.customMenuList.forEach((x, outerIndex) => {
        if (Array.isArray(x)) {
          x.forEach((menu, index) => {
            menus.push(Object.assign({ type: "menu" }, menu));
            if (index === x.length - 1 && outerIndex < menuLength - 1) {
              menus.push({ type: "divider" });
            }
          });
        } else {
          menus.push(Object.assign({ type: "menu" }, x));
        }
      });

      return menus;
    }
  },
  methods: {
    showIcon(entry, data) {
      const { showIcon } = entry;
      if (typeof showIcon === "function") {
        return showIcon(data);
      }

      return showIcon;
    },
    showInMenu(entry, data) {
      const { showInMenu } = entry;
      if (typeof showInMenu === "function") {
        return showInMenu(data);
      }

      return showInMenu;
    },
    handleAction(data, action) {
      return action(data, this);
    }
  },
  mounted() {
    // console.log("parent el", this.$el.parentElement);
    // console.log("parent el class-list", this.$el.parentElement.classList);
    this.$el.parentElement.classList.add("nk-tb-col-tools");
  },
  props: {
    data: {
      type: undefined
    },
    actions: {
      type: Array,
      default() {
        /* This contains an array of
        objects with the signature:
        {
          name: String,
          iconClass: HTML,
          action: Function
        } */
        return [];
      }
    },
    customMenuList: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
