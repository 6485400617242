
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { practiceGroupsService } from "./practice-group.service";

@Options({})
export default class MemberForm extends mixins(ModalFormMixin, DataTableMixin) {
  formEvent = FormEvents.MEMBER_FORM;
  modalId = "member-form";
  reloadStore = settingsStore.findAllPracticeGroups;
  // service = practiceGroupsService;
  primaryKey = "EmployeePracticeGroupId";

  payload = {
    PracticeGroupId: "",
    EmployeePracticeGroup: [],
  };

  fields = [
    {
      title: "Member Name",
      name: "Employee",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any) => {
        try {
          var response = await practiceGroupsService.removeUserFromGroup(prop.EmployeePracticeGroupId);
          await this.fetchPraticeGroup();
          this.reloadStore();
          this.toast("success", response.Message);
        } catch (error) {
          this.handleError(error);
        }
      },
    },
  ];

  get users() {
    return settingsStore.users;
  }

  async savedata(payload: any) {
    try {
      const data = {
        EmployeeId: payload.EmployeeId,
        PracticeGroupId: payload.PracticeGroupId,
      };
      this.isLoading = true;
      var response = await practiceGroupsService.addMember(data);
      await this.fetchPraticeGroup();
      this.reloadStore();
      this.isLoading = false;
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }

  async fetchPraticeGroup() {
    var practicegroupdata = await practiceGroupsService.findOne(this.payload.PracticeGroupId);
    this.payload = practicegroupdata.Data;
  }
}
