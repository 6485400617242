
import { BaseService } from "@/shared/services/base-service";
import Multiselect from "vue-multiselect";
import axios, { CancelTokenSource } from "axios";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Watch } from "vue-property-decorator";

@Options({
  components: {
    Multiselect,
  },
})
export default class SearchableSelect extends Vue {
  private cancelSource: CancelTokenSource | null = null;

  @Prop() title!: string;
  @Prop() label!: string;
  @Prop() trackBy!: string;
  @Prop() resource!: string;
  @Prop() placeholder!: string;
  @Prop() customSearch!: unknown;
  @Prop() useSlot!: boolean;
  @Prop() emitName!: string;
  @Prop() resetEmit!: boolean;
  @Prop() customVmodel!: any;

  service = new BaseService();
  dataList: Record<string, unknown>[] = [];

  dataStored = {};

  @Watch("customVmodel")
  public watchVmodel(newValue: any, oldValue: any): void {
    if (newValue[this.trackBy] != oldValue[this.trackBy]) {
      this.dataStored = newValue;
    }
  }

  emitSelected(data: Record<string, unknown>): void {
    this.$emit(this.emitName, data);
    GlobalEvent.emit(this.emitName, data);
  }

  cancelFetch(): void {
    if (this.cancelSource) {
      this.cancelSource.cancel("Cancelled ongoing request");
    }
  }

  async fetchOptions(value: string): Promise<void> {
    try {
      if (value.length > 1) {
        this.cancelFetch();
        this.cancelSource = axios.CancelToken.source();

        const response = await this.service.getSelect2(`${this.resource}?term=${value}&_type=query&q=${value}`, this.cancelSource.token);
        this.dataList = response as unknown as [];
        this.cancelSource = null;
      }
    } catch (error: any) {
      if (error[0] && error[0].MatterId) {
        this.dataList = error as [];
      }
    }
  }
}
