
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../constants";
import ModalFormMixin from "../../shared/mixins/modal.mixin";
import clientsStore from "./client.store";
import { Employee } from "../auth/auth.model";
import { IndustryModel } from "../settings/crm/industry/industry.model";
import { settingsStore } from "../settings/settings.store";
import { clientsService } from "./clients.service";
import { VueEditor } from "vue3-editor";

@Options({
  components: {
    VueEditor,
  },
})
export default class ClientModal extends mixins(ModalFormMixin) {
  modalId = "contact-form";
  formEvent = FormEvents.SHOW_CLIENT_FORM;
  service = clientsService;
  countries = require("../../assets/countries.json");
  reloadStore = clientsStore.getClients();

  created() {
    // console.log(this.countries);
  }

  fields = [];

  get industries(): IndustryModel[] {
    return settingsStore.industries;
  }

  get employees(): Employee[] {
    return settingsStore.employees.filter((employee) => employee.AppUserId == null);
  }
}
