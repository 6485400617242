
import { Options, Vue } from "vue-class-component";
import Topnav from "@/components/topnav.vue";
import { settingsStore } from "../modules/settings/settings.store";
import { crmStore } from "@/modules/crm/crm.store";
import MatterForm from "../modules/matters/matter/components/MatterForm.vue";
import BillForm from "@/modules/matters/bills/BillForm.vue";


// import Sidenav from '@/components/sidenav.vue';

@Options({
  components: {
    Topnav,
    MatterForm,
    BillForm,
  },

  data() {
    return {};
  },

  created() {
    const hasLoaded = localStorage.getItem("hasLoaded");
    if (hasLoaded) return;

    Promise.all([
      settingsStore.getContactCategories(),
      settingsStore.AllEmployees(),
      settingsStore.findAllRoles(),
      settingsStore.findAllPermissions(),
      settingsStore.AllUsers(),
      settingsStore.getAllAccountPeriods(),
      settingsStore.findAllBankAccounts(),
      settingsStore.findFinancialConfiguration(),
      settingsStore.getGlobalSettings(),
      settingsStore.getAllCurrencies(),
    ]);

    Promise.all([
      settingsStore.getTransactionCurrencies(),
      settingsStore.findAllInvoiceBanks(),
      settingsStore.getCustomFields(),
      settingsStore.findAllIndustries(),
      settingsStore.getNumberingFormats(),
      settingsStore.getSignatures(),
      settingsStore.getAllBillGroups(),
      settingsStore.findAllPostingGroups(),
      settingsStore.getActivities(),
      settingsStore.getCMA(),
    ]);

    Promise.all([
      settingsStore.getClientPortalSettings(),
      settingsStore.getBusinessUnits(),
      settingsStore.getLeadStages(),
      settingsStore.getLeadsContacts(),
      settingsStore.getLeadsCompanies(),
      crmStore.getSalesPipeline(),
      crmStore.getLeadStageArr(),
      crmStore.getLossLeadReasons(),
    ]);

    localStorage.setItem("hasLoaded", "true");
  },
})
export default class MainApp extends Vue {
    setLang(lang: string): void {
    this.$i18n.locale = lang;
  }
}
