
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    id: String,
    title: String,
    customClass: String,
    modalStyle: String,
    iconClass: String,
    setOverflow: Boolean
  }
})
export default class NCModal3 extends Vue {}
