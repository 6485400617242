
import { Options, mixins } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { CustomFields } from "./customFields.model";
import customFieldsSettingsForm from "./customFieldsSettingsForm.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { CustomFieldsService } from "./custom-fields.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: { customFieldsSettingsForm }
})
export default class customFieldsDetails extends mixins(DataTableMixin) {
  reloadStore = settingsStore.getCustomFields;
  formEvent = FormEvents.CUSTOM_FIELDS_FORM;

  get customFields(): CustomFields[] {
    return settingsStore.customFields;
  }

  created(): void {
    this.loadJS();
  }

  handleTableReady() {
  this.isLoading = false;
  console.log("handleTableReady --",  this.isLoading, this.pageIsReady);
}
 
  beforeMount() {
    this.isLoading = true;
  }

  deleteCustomField(id: number, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        if (result.isConfirmed === true){
          CustomFieldsService.removeField({ Id: id }).then(response => {
            if (response.Success === true) {
              try {
                self.isLoading = true;
                this.reloadStore();
                self.isLoading = false;
                self.toast("success", self.$t('message.matterSettings.modules.customFields.notification.Delete'));
              } catch (error) {
                self.handleError(error);
              }
            } else if (response.Success === false){
              self.isLoading = false;
              self.toast("Error", response.Message);
              }
          });
        }else{
          // console.log("delete was cancelled");
        }
      });
  }

  showTarget(val: any) {
    if (val === 1) {
      return "matters";
    } else if (val === 2) {
      return "client";
    } else if (val === 3) {
      return "client Acceptance";
    }
  }

  fields = [
    {
      title: "Field Name",
      name: "FieldName"
    },

    {
      title: "Field Type",
      name: "FieldType"
    },

    {
      title: "Mandatory",
      name: "IsMandatory"
    },

    {
      title: "Target",
      name: "EntityType"
    },

    { name: "actions", title: "", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.CUSTOM_FIELDS_FORM, prop);
      }
    },

    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: true,
      action: (prop: any, self: any) => {
        // console.log(prop.Id, "Delete");
        this.deleteCustomField(prop.Id, self);
      }
    }
  ];
}
