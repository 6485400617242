
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import { pipelineService } from "./pipeline.service";

@Options({})
export default class PipelineModal extends mixins(ModalFormMixin) {
  modalId = "pipeline-form";
  formEvent = FormEvents.PIPELINE_FORM;
  service = pipelineService;
  date = new Date();
  reloadStore = settingsStore.getPipelines;
}
