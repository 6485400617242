
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { authStore } from "../../../auth/auth.store";
import { mattersService } from "../../matters.service";

@Options({})
export default class CloseMatterForm extends mixins(ModalFormMixin) {
  ChecklistItems: { ChecklistItem: string; Id: number }[] = [];
  formEvent = FormEvents.CLOSE_MATTER_FORM;
  modalId = "close-matter-form";

  get Username(): string {
    return authStore.auth.user?.Username as string;
  }

  // payload = {
  //   Items: [],
  //   PracticeGroupId: 0
  // };

  async handleModalOpen(): Promise<void> {
    try {
      if (!this.payload.PracticeGroupId) return;
      const response = await mattersService.matterChecklist(this.payload.PracticeGroupId);
      this.ChecklistItems = response.Data.ChecklistItems;
    } catch (error) {
      this.handleError(error);
    }
  }

  async save(payload: any): Promise<void> {
    try {
      const response = await mattersService.close(this.payload.MatterId, payload);
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }
}
