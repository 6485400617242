const en = {
  en: {
    constants: {
      forms: {
        email : "Email Address",
        phone : "Phone Number",
        city : "State / Region",
        contactAddress : "Contact Address",
        formSubmitText1 : "Save",
        formSubmitText2 : "Update",
        modalCloseButton : "Close",
        lastName : "Last Name",
        firstName : "First Name",
        surname : "Surname",
        otherNames : "Other Names",
        activityType : "Activity Type",
        activityName : "Activity Name",
        activityGroup : "Activity Group",
        activityNarrative : "Activity Narrative",
        account : "Account",
        leadStage : "lead Stage",
        companyName : "Company Name",
        markAsDraft : "Mark as Draft",
        jobTitle : "Job Title",
        contactCategory : "Contact Category",
        zipCode : "Zip Code",
        country : "Country",
        industryType : "Industry Type",
        matter : "Matter",
        contactPerson : "Contact Person",
        noOfEmployees : "Number of Employees",
        address : "Address",
        date : "Date",
        status : "Status",
        type : "Type",
        client_matter : "Client/Matter",
        chargeType : "Charge Type",
        hours : "Hours",
        minutes : "Minutes",
        selectOption : "Select an Option",
        currency : "Currency",
        startDate : "Start Date",
        endDate : "End Date",
      },
      pages : {
        breadcrumb1 : "Home",
        buttonText : "Add",
        tasks : "Tasks",
        bills : "Bills",
        courtUpdate : "Court Update",
        timeSheet : "Timesheet",
        relationshipManager : "Relationship Manager",
        export : "Export",
        reports : "Reports",
        matters : "Matters",
        expenses : "Expenses",
        amount : "Amount",
        increase : "increase"
      }
    },
    message: {
      login: { loginDesc: "Sign in to your account" },
      topNav: { crm: "CRM" },
      dashboard : {
        modules : {
          matterDashboard : {
            title : "Matter Dashboard",
            totalInflow : "Total Inflow",
            totalOutflow : "Total Outflow",
            avgClient : "Average per Client",
            tnxGrossProfit : "Transactional Gross Profit",
            financialOverview : "Financial Overview",
            billingInfo : "Statistical information about billings",
            unpaidMatters : "Unpaid Matters",
            unpaidExpenses : "Unpaid Expenses",
            unpaidInvoices : "Unpaid Invoices",
            invoiceMsg : "Invoices that have been sent to the client and haven't been paid",
            invoiceCount : "Invoice Count",
            matterMsg : "Transactions that have not appeared in the billing cycle",
            matterCount : "Matter Count",
            expenseMsg : "Reimbursable expenses that you're yet to bill for",
            expenseCount : "Expense Count",
            bills : "Bills",
            billsMessage : "In last 30 days top invested schemes.",
            generatedBills : "Generated Bills",
            unfinalisedBills : "Unfinalised Bills",
            finalisedBills : "Finalised Bills",
            utilizeRate : "Utilization Rate",
            utilizeMsg : "The percentage of time spent doing billable work.",
            utilizeFooter : "Billable Hours / Total Submitted Hours",
            realizeRate : "Realization Rate",
            realizeMsg : "The percentage of time worked that was invoiced.",
            realizeFooter : "Billed Hours / Total Billable Hours",
            collectionRate : "Collection Rate",
            collectionMsg : "The percentage of billed work that was paid.",
            collectionFooter : "Collected Revenue / Billed Hours",
            inflowByPractice : "Inflow by top Practice Areas",
            recentMatters : "Recent Matters",
            th1 : "SNo",
            th2 : "Title",
            th3 : "Client",
            th4 : "Status",
          }
        }
      },
      globalSettings: {
        title: "Global Settings",
        desc: "Manage General Application Setting",
        modules: {
          invoiceSetting: {
            title: "Invoice Settings",
            desc: "Manage Invoice Settings",
            formLabels: {
              name: "Numbering Formats",
              vat: " Vat Number",
              coyStreet: "Company Street",
              coyCity: "Company City",
              state: "State / Region",
              phone: "Phone Number",
              email: "Email Address",
              atype: "Approval Type",
              aofficers: "Approving Officer"
            },
            notification: {
              Update: " Invoice Successfully Updated"
            }
          },
          currencySetting: {
            title: "Currency Settings",
            desc: "Manage Currency Settings",
            formLabels: {
              name: "Currency Settings",
              selectName: "Transacting Currencies",
              selectButton: "Add Transacting Currency"
            }
          }
        }
      },

      personnelSettings: {
        title: "Personnel Settings",
        desc: "Manage Personnel Configuration"
      },

      crmSettings: {
        title: "CRM Settings",
        desc: "Manage CRM Configuration",
        modules: {
          industrySetting: {
            title: "Industry Settings",
            desc: "Manage Industry Settings",
            formLabels: { name: "Name" }
          },
          industrySettings: {
            title: "Industry Settings",
            desc: "Manage Industry Settings.",
            pageLabels: { title: "Industry Settings", desc: "Manage Your Industry Settings", buttonText: "Add" },
            formLabels: { name: "Industry Form", formInput: "Industry Name" }
          },
          contactCategory: {
            title: "Contact Categories",
            desc: "Manage Contact Categories",
            pageLabels: { title: "Contact Categories", desc: "Manage Contact Categories", buttonText: "Add" },
            formLabels: {
              name1: "Add Contact Categories",
              name2: "Update Contact Categories",
              formInput: "Category Name"
            },
            notification: {
              Update: "Category saved Successfully",
              Delete: "Category deleted Successfully"
            }
          }
        }
      },

      matterSettings: {
        title: "Matter Settings",
        desc: "Manage Matter Settings.",
        modules: {
          clientMatterAcceptance: {
            title: "Numbering Formats",
            desc: "Configure Client, Matter and Fee Note Numbering Formats",
            formLabels: {
              name: "Numbering Formats",
              numberType1: "Client Numbering",
              numberFormat1: "Client Numbering Format",
              numberType2: "Matter Numbering",
              numberFormat2: "Matter Numbering Format",
              numberType3: "Fee Note Numbering",
              numberFormat3: "Fee Note Numbering Format",
              numberType4: "Invoice Payment Numbering",
              numberFormat4: "Invoice Payment Numbering Format",
              radioLabel1: "Auto Numbering",
              radioLabel2: "Manual Numbering"
            },
            notification: {
              Update: " Information Successfully Update"
            }
          },
          numberingFormats: {
            title: "Numbering Formats",
            desc: "Configure Client, Matter and Fee Note Numbering Formats",
            formLabels: {
              name: "Numbering Formats",
              numberType1: "Client Numbering",
              numberFormat1: "Client Numbering Format",
              numberType2: "Matter Numbering",
              numberFormat2: "Matter Numbering Format",
              numberType3: "Fee Note Numbering",
              numberFormat3: "Fee Note Numbering Format",
              numberType4: "Invoice Payment Numbering",
              numberFormat4: "Invoice Payment Numbering Format",
              radioLabel1: "Auto Numbering",
              radioLabel2: "Manual Numbering"
            },
            notification: {
              Update: " Information Successfully Update"
            }
          },
          customFields: {
            title: "Custom Fields",
            desc: "Manage Manage custom fields for client and matter",
            pageLabels: {
              title: "Custom Fields",
              desc: "Manage custom fields for client and matter",
              buttonText: "Add"
            },
            formLabels: {
              name1: "Add Custom Fields",
              name2: "Update Custom Fields",
              formInput1: "Field Name",
              formInput2: "Field Type",
              formInput3: "Drop Down List Options",
              formInput4: "Mandatory Field?",
              formInput5: "Field Target"
            },
            notification: {
              Update: "Custom field saved Successfully",
              Delete: "Custom field Successfully deleted"
            }
          },
          signatures: {
            title: "Signatures",
            desc: "Manage Signatures",
            formLabels: {
              name: "Add Signatures",
              sigName: "Signature Name",
              sigUpload: "Upload Signature"
            },
            notification: {
              Upload: "Signature Uploaded Successfully"
            }
          },
          activityType: {
            title: "Activty Type",
            desc: "Manage Activity Type",
            pageLabels: { title: "Activity Type", desc: "Manage Activity Type", buttonText: "Add" },
            formLabels: {
              name: "Activity Form",
              formInput1: "Activity Group",
              formInput2: "Activity Name",
              formInput3: "Activity Narrative"
            }
          }
        }
      },

      timeSettings: {
        title: "Time Settings",
        desc: "Manage Time Configuration",
        modules: {
          timeFormat: {
            title: "Time Format",
            desc: "Time Display Format Settings",
            formLabels: {
              name: "Time Format Settings",
              radioName: "Time Format",
              radioLabel1: "Fractional e.g 1.5 hours",
              radioLabel2: "Hours and Minutes e.g 1hr 3mins"
            }
          },
          timeSummaryReport: {
            title: "Time Format",
            desc: "Time Display Format Settings",
            formLabels: {
              name: "Time Format Settings",
              radioName: "Time Format",
              radioLabel1: "Fractional e.g 1.5 hours",
              radioLabel2: "Hours and Minutes e.g 1hr 3mins"
            }
          }
        }
      },

      financeSettings: {
        title: "Finance Settings",
        desc: "Manage Finance Settings.",
        modules: {
          accountPeriods: {
            title: "Account Period",
            desc: "Manage Opened/Closed periods",
            pageLabels: { title: "Account Period", desc: "Manage Opened/Closed periods", buttonText: "Add" },
            formLabels: {
              name: "Add Account Period",
              formInput1: "Year",
              formInput2: "Month"
            },
            notification: {
              Update: "Account Period saved Successfully"
            }
          },
          chartOfAccounts: {
            title: "Chart Of Accounts",
            desc: "Manage Industry Settings.",
            formLabels: { name: "Industry Form", formInput: "Industry Name" }
          },
          accReportSettings: {
            title: "Report Settings",
            desc: "Manage Industry Settings.",
            formLabels: { name: "Industry Form", formInput: "Industry Name" }
          }
        }
      },
      crm: {
        modules: {
          contacts: {
            pageLabels: { title: "Contacts" },
            formLabels: {
              name1: "Add Contact",
              name2: "Update Contact",
              formInput1: "First Name",
              formInput2: "Other Name",
              formInput3: "Surname",
              formInput4: "Email Address",
              formInput5: "Telephone",
              formInput6: "Job Title",
              formInput7: "Department",
              formInput8: "Birthday",
              formInput9: "Contact Address",
              formInput10: "Contact Category",
              formInput11: "City",
              formInput12: "State/Province",
              formInput13: "ZipCode",
              formInput14: "Country"
            },
            notification: {
              Update: "Contact saved Successfully",
              Delete: "Contact Successfully deleted "
            }
          },
          companies: {
            companiesPage: {
              breadCrumb1: "Home",
              breadCrumb2: "CRM",
              breadCrumb3: "Companies",
              breadCrumb4: "View",
              title: "Companies",
              dropdown1: "New Company",
              dropdown2: "New Contact",
              dropdown3: "New Lead"
            },
            companyView: {
              button1: "Switch",
              button2: "Edit",
              button3: "Go Back",
              memoHeader: "Interaction Log/Memo",
              memoTitle: "Past Activities",
              memoAdd: "+ Add Log",
              memoDelete: "Delete Note",
              memoImgAlt: "no data",
              memoText: "No Interactions Found.",
              memoButton: "Create New Interaction",
              details: {
                detail1: "Leads",
                detail2: "Contacts",
                detail3: "Won Leads",
                detail4: "Company Detail",
                detail5: "Industry Type: ",
                detail6: "Contact Address:",
                detail7: "State:",
                detail8: "Country:",
                detail9: "Zip Code:",
                detail10: "Annual Revenue:",
                detail11: "No of Employees:",
                detail12: "Website:",
                detail13: "Facebook:",
                detail14: "Twitter:",
                detail15: " LinkedIn:"
              }
            },
            companyForm: {
              title1: "Add Company",
              title2: "Update Company",
              label1: "Company Name",
              label2: "Industry Type",
              label3: "Address",
              label4: "Email",
              label5: "Phone Number",
              label6: "Number of Employees",
              label7: "Country",
              label8: "State / Province",
              label9: "Zip Code",
              label10: "Company Annual Revenue",
              label11: "Company Website",
              label12: "Facebook Link",
              label13: "Twitter Link",
              label14: "LinkedIn Link"
            }
          }
        }
      },
      matters: {
        modules: {
          tasks: {
            tasksPage: {
              breadCrumb1: "Home",
              breadCrumb2: "Matter",
              breadCrumb3: "Tasks",
              title: "Tasks",
              dropdown1: "New Acceptance",
              dropdown2: "New Client",
              dropdown3: "New Matter",
              dropdown4: "New Court Update",
              dropdown5: "Parties/External Resource",
              dropdown6: "New Progress Note",
              dropdown7: "New Task",
              dropdown8: "New Bill",
              dropdown9: "New Time Entry",
              dropdown10: "New Milestone",
              dropdown11: "New Company",
              tab1: "My Tasks",
              tab2: "Tasks Reviewed By Me"
            },
            tasksForm: {
              title1: "Add Task",
              title2: "Update Task",
              label1: "Matter",
              label2: "Title",
              label3: "Task Owners",
              label4: "Task Appraiser",
              label5: "Task Description",
              label6: "Start Date",
              label7: "End Date",
              label8: "Duration",
              label9: "Task Type",
              label10: "Priority",
              label11: "Status"
            }
          },

          progressNotes: {
            progressNotesPage:{
              breadCrumb1 : "Home",
              breadCrumb2 : "Matter",
              breadCrumb3 : "Progress Notes",
              title : "Progress Notes",
              dropdown1 : "New Progress Note",
              dropdown2 : "New Acceptance",
              dropdown3 : "New Client",
              dropdown4 : "New Matter",
              dropdown5 : "New Court Update",
              dropdown6 : "Parties/External Resource",
              dropdown7 : "New Task",
              dropdown8 : "New Time Entry",
              dropdown9 : "New Milestone",
              dropdown10 : "import client",
              dropdown11 : "import Matter",
              dropdown12 : "import Court Update",
              dropdown13 : "import Progress Note",
            },
            progressNotesForm : {
              name1 : "Add Progress Note",
              name2 : "Update Progress Note",
              label1 : "Matter",
              label2 : "Progress Note Detail",
            },
            notification: {
              "Update": "Progress Note saved Successfully",
              "Delete": "Progress Note Successfully deleted "
            }
          },
        }
      },

      time:{
        modules: {
          trackedActivities: {
            activitiesPage : {
              breadCrumb1: "Time & Billing",
              breadCrumb2: "Tracked Activities",
              title: "Tracked Activities",
              dropdown1: "New Time Entry",
              formField : "Show assigned time entries",
              button1 : "Fetch Time Entries",
              button2 : "selected entries",
              button3 : "Assign Timesheet",
              paragraph : "No user entries found for selected date"
            },
            activitiesForm: {
              title: "Assign Timesheet",
              button1: "Duplicate Entry",
              button2: "Remove Entry",
              button3: "Save",
              label1: "Client/Matter",
              label2: "Charge Type",
              label3: "Task Category",
              label4: "Task Activity",
              label5: "Activity Narrative",
              label6: "Time Owner",
              label7: "Date",
              label8: "Hours",
              label9: "Minutes",
              label10: "Activity",
              label11: "Status"
            }
          },
          timesheets: {
            timesheetsPage:{
              breadCrumb1 : "Home",
              breadCrumb2 : "Time",
              breadCrumb3 : "Timesheets",
              title : "Timesheets",
              dropdown1 : "New Client",
              dropdown2 : "New Acceptance",
              dropdown3 : "New Client",
              dropdown4 : "New Matter",
              dropdown5 : "New Court Update",
              dropdown6 : "New Task",
              dropdown7 : "New Time Entry",
            },
            timesheetsForm : {
              name1 : "Update Timesheet",
              name2 : "Add Timesheet",
              label1 : "Matter/Client",
              label2 : "Task Activities",
              label3 : "Date",
              label4 : "Bill Type",
              label5 : "Hours",
              label6 : "Time Owner",
              label7 : "Activity Narrative",
              label8: "Activity",
              label9: "Start Date",
              label10: "End Date",
            },
            notification: {
              "Fetched": "Timesheet Data Fetched",
              "Update": "Timesheet saved Successfully",
              "Delete": "Time entry deleted "
            }
          },

        }
      },
      Dashboard:{
        modules:{
          time:{
            timeDashboard:{
              title : "Time Dashboard",
              dropdown1 : "Add User",
              dropdown2 : "Add Order",
              dropdown3 : "Add Page",
              label1 : "Current Month",
              label2 : "Current Year",
              label3  : "Overall",
              label6  : "Collection Rate",
              label4  : "UtilizationRate",
              label5 : "Realization Rate",
              tag1 : "Billable",              
              tag2  : "Unbillable",
              
            },
          }
        }
      },
    }
  }
};
export default en;
