
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { Inject, Prop, Watch } from "vue-property-decorator";

interface TableHeaderModel {
  name: string;
  prop: string;
  formatter: typeof Function;
}

@Options({
  emits: ["onDelete"]
})
export default class NCDataTable extends Vue {
  @Prop() headers!: TableHeaderModel[];
  @Prop() Data!: any[];
  @Prop() tableId!: string;
  @Inject() handleDelete!: any;
  @Inject() reloadStore!: any;
  @Prop() primaryKey = "Id";
  @Prop() hasDelete = true;

  @Watch("Data")
  handleHeader(): void {
    console.log({
      update: "Table Updated"
    });
    this.initTable();
  }

  created(): void {
    this.initTable();
  }

  initTable(): void {
    this.$jQuery(`#${this.tableId}`)
      .DataTable()
      .destroy();
    setTimeout(() => {
      this.$jQuery(`#${this.tableId}`).DataTable();
    }, 1000);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getTdValue(header: TableHeaderModel, item: any): any {
    const { prop, formatter } = header;
    if (formatter) {
      return formatter(item[prop]);
    }

    return item[prop];
  }

  isSlot(field: Record<string, any>): boolean {
    return field.prop in this.$slots;
  }

  remove(id: any): void {
    console.log("remove event", this.handleDelete);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const main: any = this;
    main
      .$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          this.isLoading = true;
          try {
            const response = await this.handleDelete.remove(id);
            console.log({ response });
            main.$swal("Deleted!", "Item has been deleted", "success");
            await this.reloadStore();
            this.isLoading = false;
            this.toast("success", response.Message);
          } catch (error) {
            this.isLoading = false;
            this.handleError(error);
          }
        }
      });
  }

  // triggerRemove(id: string): void {
  //   this.$emit('onDelete', id);
  // }
}
