import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nk-block tab-pane pt-1",
  id: "matterCourtUpdates"
}
const _hoisted_2 = { class: "nk-block-head nk-block-head-sm" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pfizer_table = _resolveComponent("pfizer-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_pfizer_table, {
        fields: _ctx.fields,
        "table-data": _ctx.Data,
        options: _ctx.options,
        "datatable-options": _ctx.datatableOptions,
        primaryKey: "Id",
        tableId: "matters-court-updates-table",
        serverSide: true,
        serverSideDataName: 'data',
        requestMethod: "POST",
        url: `/api/CaseManager/Matters/CourtUpdates/${_ctx.Matter.MatterId}`
      }, {
        ClientFeedback: _withCtx((prop) => [
          _createElementVNode("p", {
            innerHTML: prop.ClientFeedback
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["fields", "table-data", "options", "datatable-options", "url"])
    ])
  ]))
}