
import { mixins, Options} from 'vue-class-component'
import DataTableMixin from '@/shared/mixins/datatable.mixin';
import { TimeDashboardService } from './timeDashboard.service'
import {dashboardService} from '@/modules/dashboard/matterDashboard/dashboardService'
import { ChartTypes } from "@/constants";



@Options({
    components: {},
})


export default class timeDashboard extends mixins(DataTableMixin){
    monthlySummary: any[] = [];
    yearlySummary: any[] = [];
    allTimeSummary: any[] = [];
    currentMonth: any[] = [];
    rateSummaries: any[] = [];
    TotalTimeEntries = "";
    isLoading = true;

    chartTypes = ChartTypes;

    allTimeStatGraph: any[] = [];
    yearlyStatGraph: any[] = [];
    monthlyStatGraph: any[] = [];

    async created(): Promise<void> {
       await this.getMonthDashboard();
       await this.getYearDashboard();
       await this.getTotalDashboard();
       await this.getCurrentMonthsDashboard();
       await this.getRateSummaries();
        this.plotGraph();
        this.loadJS();
    }


    async getMonthDashboard() : Promise<void> {
    try {
      this.isLoading = true;
      const response = await TimeDashboardService.getMonthlyBillSummary();
      this.monthlySummary = response.Data;
      console.log(this.monthlySummary, "monthlySummary");
      
      this.isLoading = false;
    //   this.toast("success", "Data Fetched");
    } catch (error) {
    this.handleError(error);
    }
  }

   async getYearDashboard() : Promise<void> {
    try {
      this.isLoading = true;
      const response = await TimeDashboardService.getYearlySummary();
      this.yearlySummary = response.Data;
      this.isLoading = false;
    //   this.toast("success", "Data Fetched");
    } catch (error) {
      this.handleError(error);
    }
  }

   async getTotalDashboard() : Promise<void> {
    try {
      this.isLoading = true;
      const response = await TimeDashboardService.getAllTimeSummary();
      this.allTimeSummary = response.Data;
      this.isLoading = false;
    //   this.toast("success", "Data Fetched");
    } catch (error) {
      this.handleError(error);
    }
  }

   async getCurrentMonthsDashboard() : Promise<void> {
    try {
      this.isLoading = true;
      const response = await TimeDashboardService.getMonthlySummary();
      this.currentMonth = response.Data;
      console.log(this.currentMonth[0].TotalTimeEntries, "current month");
      
      this.isLoading = false;
    //   this.toast("success", "Data Fetched");
    } catch (error) {
      this.handleError(error);
    }
  }

  async getRateSummaries() : Promise<void> {
      try {
      this.isLoading = true;
      const response = await dashboardService.GetMatterDashboard();
      if (response.Data){
          const Summaries = response.Data;
          this.rateSummaries =Summaries
        console.log(this.rateSummaries, "rate");
      }
      
      this.isLoading = false;
    //   this.toast("success", "Data Fetched");
    } catch (error) {
      this.handleError(error);
    }
  }

  handleTableReady() {
  this.isLoading = false;
  console.log("handleTableReady --",  this.isLoading, this.pageIsReady);
  }
 
//   beforeMount() {
//    this.isLoading = true;
//   }  

    plotGraph(): void {
        console.log(this.monthlySummary, "Summary for the month");
        console.log(this.yearlySummary, "Yearly Summary", this.allTimeSummary, "all time summary");
        
    this.allTimeStatGraph = [
      {
        value: this.allTimeSummary[0].TotalTimeEntries.toFixed(2),
        category: "Billable",
      },
      {
        value: this.allTimeSummary[1].TotalTimeEntries.toFixed(2),
        category: "Non-billable",
      },
    ];
   this.yearlyStatGraph = [
      {
        value: this.yearlySummary[0].TotalTimeEntries.toFixed(2),
        category: "Billable",
      },
      {
        value: this.yearlySummary[1].TotalTimeEntries.toFixed(2),
        category: "Non-billable",
      },
    ];
    this.monthlyStatGraph = [
      {
        value: this.monthlySummary[0].TotalTimeEntries.toFixed(2),
        category: "Billable",
      },
      {
        value: this.monthlySummary[1].TotalTimeEntries.toFixed(2),
        category: "Non-billable",
      },
    ];
  }
}
