
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { mixins, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { financialConfigurationService } from "./financeConfiguration.service";
@Options({
  components: {},
})
export default class FinancialConfiguration extends mixins(ModalFormMixin) {
  formEvent = FormEvents.FINANCE_CONFIGURATION;
  modalId = "financial-configuration-form";
  service = financialConfigurationService;
  reloadStore = settingsStore.findFinancialConfiguration;
  reloadStore2 = settingsStore.getGlobalSettings;
  @Prop() modalAction!: string;
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop() iconClass!: string;
  options = {
    VATPayableAccountOptions: [
      {
        text: "Accural",
        value: "0",
      },
      {
        text: "Cash",
        value: "1",
      },
    ],
    FiscalMonthOptions: [
      {
        text: "January",
        value: "1",
      },
      {
        text: "February",
        value: "2",
      },
      {
        text: "March",
        value: "3",
      },
      {
        text: "April",
        value: "4",
      },
      {
        text: "May",
        value: "5",
      },
      {
        text: "June",
        value: "6",
      },
      {
        text: "July",
        value: "7",
      },
      {
        text: "August",
        value: "8",
      },
      {
        text: "September",
        value: "9",
      },
      {
        text: "October",
        value: "10",
      },
      {
        text: "November",
        value: "11",
      },
      {
        text: "December",
        value: "12",
      },
    ],
    FiscalDayOptions: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
  };
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    settingsStore.findFinancialConfiguration();
    console.log("somethig...");
    // settingsStore.findFinancialConfiguration();
    console.log("Global settings  " + this.payload.whtaccount);
  }

  async save(payload: unknown[]): Promise<void> {
    console.log("payload", payload);
    try {
      this.isLoading = true;
      const response = await this.service.create(payload);
      // await this.service.findAll();
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      this.isLoading = false;
      this.reloadStore2();
      this.modal(this.modalId, false);
    } catch (error) {
      console.log("error ", error);
      this.handleError(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get financialConfiguration() {
    return settingsStore.financialConfiguration;
  }
}
