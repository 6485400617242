
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    type: String,
    link: Object,
    modalEvent: String,
    handleClick: Function
  }
})
export default class SettingItem extends Vue {}
