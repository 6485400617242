
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import IndustryForm from "./industry-form.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { IndustryModel } from "./industry.model";
import { industryService } from "./industry.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: {
    IndustryForm
  }
})
export default class IndustrySetting extends mixins(DataTableMixin) {
  formEvent = FormEvents.INDUSTRY_FORM;
  isLoading = true;

  created(): void {
    // settingsStore.findAllIndustries();
    this.loadJS();
  }

  handleTableReady() : void {
    this.isLoading = false;
  }

  get industryList(): IndustryModel[] {
    return settingsStore.industries;
  }

  deleteData(prop: IndustryModel, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            // await this.validate();
            // this.globalLoader(true);
            const response = await industryService.remove(prop.IndustryId.toString());
            // this.toastSuccess(response.Message);
            // this.globalLoader(false);
            self.isLoading = false;
            self.toast("success", response.Message);
            settingsStore.findAllIndustries();
          } catch (error) {
            self.handleError(error);
          }
        }
      });
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  fields = [
    {
      title: "Name",
      name: "IndustryName"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.INDUSTRY_FORM, prop);
      }
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: true,
      action: async (prop: IndustryModel, self: any): Promise<void> => {
        this.deleteData(prop, self);
      }
    }
  ];
}
