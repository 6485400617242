
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { invoiceService, InvoiceService } from "../invoice/invoice.service";

// import "vue-select/dist/vue-select.css";
// import { settingsStore } from '../../settings.store';

@Options({})
export default class BrandLogoSettingForm extends mixins(ModalFormMixin) {
  modalId = "brand-logo-setting-form";
  formEvent = FormEvents.BRAND_LOGO_FORM;
  service = InvoiceService;
  previewImage: any = null;

  selectImage(): void {
    let fileInput: any = this.$refs.fileInput;
    fileInput.click();
  }
  pickFile(data: any): void {
    console.log("file", data);
    this.previewImage = data.file;
  }

  async updateLogo() {
    this.isLoading = true;
    try {
      var fdataobj = new FormData();
      fdataobj.append("uploadfile", this.previewImage);
      await invoiceService.uploadLogo(fdataobj);
      this.isLoading = false;
      this.toast("success", "Logo upload successful");
    } catch (error) {
      this.handleError(error);
    }
  }
}
