import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

export class BillGroupService extends BaseService {
  resource = "BillGroup";

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetAllBillGroups");
  }

  findOne(id: string): Promise<AppResponse> {
    return this.get(`GetBillGroup/${id}`);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Rates/Update`, payload);
  }

  removeUserFromGroup(Id: number): Promise<AppResponse> {
    return this.get(`User/Remove/${Id}`);
  }

  addMember(payload: { EmployeeId: number; Id: number }): Promise<AppResponse> {
    return this.post("User/Add", payload);
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }
}

export const billGroupService = new BillGroupService();
