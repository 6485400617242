/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosInstance, CancelToken } from "axios";
import { authStore } from "../modules/auth/auth.store";
import { Helpers } from "../shared/helpers";
import { AppResponse } from "../shared/interfaces/app-response";

export class ApiConfigService {
  public resource = "";
  public hasRootApi = true;

  get resourceUrl() {
    if (this.hasRootApi) return `/api/${this.resource}`;
    return this.resource;
  }

  public request(): AxiosInstance {
    return axios.create({
      // baseURL: process.env.VUE_APP_API_URL,
      baseURL: authStore.tenantConfiguration.AppUrl,
      headers: this.setHeaderAuthorization()
    });
  }

  public loginRequest(): AxiosInstance {
    // console.log('DDDDDDDDDDDDDDDDDDDD', authStore.tenantConfiguration.AppUrl);
    
    return axios.create({
      baseURL: authStore.tenantConfiguration.AppUrl
      // baseURL: process.env.VUE_APP_API_URL,
    });
  }

  private setHeaderAuthorization(): Record<string, string> {
    return {
      Authorization: `Bearer ${authStore.auth.token}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    };
  }

  async post(url?: string, data?: any, isApi = true): Promise<AppResponse> {
    const response = await httpService.request().post(`${isApi ? this.resourceUrl : this.resource}/${url}`, data);
    return Helpers.handleResponse(response);
  }

  async put(url: string, data?: Record<string, unknown> | unknown[], isApi = true): Promise<AppResponse> {
    const response = await httpService.request().put(`${isApi ? this.resourceUrl : this.resource}/${url}`, data);
    return Helpers.handleResponse(response);
  }

  async get(url?: string, data?: any, isApi = true): Promise<AppResponse> {
    const response = await httpService.request().get(`${isApi ? this.resourceUrl : this.resource}/${url}`, data);
    return Helpers.handleResponse(response);
  }

  async getSelect2(url: string, data: CancelToken): Promise<AppResponse> {
    const response = await httpService.request().get(`/api/${url}`, {cancelToken : data});
    return Helpers.handleResponse(response);
  }

  async delete(url: string, isApi = true): Promise<AppResponse> {
    const response = await httpService.request().delete(`${isApi ? this.resourceUrl : this.resource}/${url}`);
    return Helpers.handleResponse(response);
  }

}

export const httpService = new ApiConfigService();
