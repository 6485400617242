
import { mixins, Options } from "vue-class-component";
import { MatterModel } from "./matter";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
// import { mattersService } from "./matters.service";
import { FormEvents } from "../../../constants";

import { mattersStore } from "./matters.store";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import MergeMatterForm from "./components/MergeMatterForm.vue";
import AssignWorkflowForm from "./components/AssignWorkflowForm.vue";
import CloseMatterForm from "./components/CloseMatterForm.vue";
import { Helpers } from "../../../shared/helpers";
import NCApp from "@/app/index";

@Options({
  components: {
    // MatterForm
    MergeMatterForm,
    AssignWorkflowForm,
    CloseMatterForm,
  },
})
export default class Matters extends mixins(DataTableMixin) {
  // Matters: MatterModel[] = [];
  get Matters(): MatterModel[] {
    return mattersStore.Matters;
  }

  app = NCApp;

  isLoading = true;

  formEvent = FormEvents.MATTER_FORM;

  DropdownMenu = [
    {
      id: "new matter",
      name: "New Matter",
      formEventName: this.formEvent,
      hasPermission: this.app.hasPermission("0x5C"),
    },
  ];

  fields = [
    {
      title: "SNO",
      name: "SNO",
    },
    {
      title: "Matter Code",
      name: "MatterCode",
    },
    {
      title: "Matter Title",
      name: "MatterTitle",
    },
    {
      title: "Client Name",
      name: "ClientName",
    },
    {
      title: "Matter Type",
      name: "MatterTypeDescription",
    },
    {
      title: "Contact Person",
      name: "PrimaryContactName",
    },
    {
      title: "Matter Manager",
      name: "MatterManagerDetails",
    },
    {
      title: "Status",
      name: "MatterStatusDescription",
    },
    {
      title: "Date Created",
      name: "FormattedDateCreated",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: false,
      action: (prop: MatterModel): void => {
        // console.log("Edditignin", prop);
        // GlobalEvent.emit(FormEvents.PRACTICE_GROUP_AND_AREA_FORM, prop);
        // this.$router.push({ name: "MatterOverview", params: { MatterId: prop.MatterId } });
        window.location.href = `/matters/matter-overview/${prop.MatterId}`;
      },
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: MatterModel): void => {
        if (this.app.hasPermission("0x5D")) GlobalEvent.emit(FormEvents.MATTER_FORM, prop);
      },
    },
    {
      name: "Merge",
      iconClass: "icon ni ni-minimize-alt",
      showIcon: false,
      action: (prop: MatterModel): void => {
        // console.log("Edditignin", prop);
        GlobalEvent.emit(FormEvents.MERGE_MATTER_FORM, prop);
      },
    },
    {
      name: "Assign Workflow",
      iconClass: "icon ni ni-link-group",
      showIcon: false,
      action: (prop: MatterModel): void => {
        // console.log("Edditignin", prop);
        GlobalEvent.emit(FormEvents.ASSIGN_WORKFLOW_FORM, prop);
      },
    },
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash",
    //   showIcon: false,
    //   action: (prop: MatterModel): void => {
    //     if (this.app.hasPermission("0x5F")) console.log("Edditignin", prop);
    //     // GlobalEvent.emit(FormEvents.PRACTICE_GROUP_AND_AREA_FORM, prop);
    //   },
    // },
    {
      name: "Close Matter",
      iconClass: "icon ni ni-cross",
      showIcon: false,
      action: (prop: MatterModel): void => {
        // console.log("Edditignin", prop);
        GlobalEvent.emit(FormEvents.CLOSE_MATTER_FORM, { ...prop, Items: [] });
      },
    },
  ];

  created(): void {
    // mattersStore.findAll();
    // this.init();
    // setTimeout(() => {
    //   this.Matters = [
    //     {
    //       MatterCode: "cwstu3hwiuf"
    //     },
    //     ...this.Matters
    //   ];
    // }, 5000);

    this.loadJS();
  }

  // async init(): Promise<void> {
  //   try {
  //     const response = await mattersService.findAll();
  //     this.Matters = response.Data;
  //   } catch (error) {
  //     this.handleError(error);
  //   }
  // }
}
