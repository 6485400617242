import { BaseService } from "@/shared/services/base-service";
import { AppResponse } from "@/shared/interfaces/app-response";

export class TasksService extends BaseService {
  resource = "Matters/MatterTasks";

  remove(id: string): Promise<AppResponse> {
    return this.get(`DeleteCompany?CompanyId=${id}`);
  }

  updateStatus(urlExt : string, payload: any): Promise<AppResponse> {
    return this.post(urlExt, payload);
  }
}

export class TasksService2 extends BaseService {
  resource = "GlobalTasks";

}

const taskService = new TasksService();
export const taskService2 = new TasksService2();
export default taskService;