
import { Options, Vue } from 'vue-class-component';
import { FormEvents } from '../../../../constants';
import tmeForm from '@/modules/settings/time/tme/tmeSettingsForm.vue';
import { settingsStore } from "../../settings.store";
import { TMEData } from './tme.model';
@Options({
    props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { tmeForm }
})
export default class tmeSetting extends Vue {
 formEvent = FormEvents.TME_FORM;
 primaryKey = "Id";
 tmeData: TMEData = {
   timeSummaryUsersSelect: [],
  exemptedUsersSelect: [],
 };

      get TME(): any[] {
    return settingsStore.tme;
  }

  mounted() { 
    console.log("on mounted", this.TME, "on mounted");
       const resOptions = this.TME.map((res: any) => {
      return res.SettingValue.split('|');
   })
     this.tmeData.timeSummaryUsersSelect = resOptions[0].map(Number);
     this.tmeData.exemptedUsersSelect = resOptions[1].map(Number);
     console.log(this.tmeData, "AAAAAAAAAAAAA");
     
  }

  showForm2(aa: any){
          const resOptions = this.TME.map((res: any) => {
      return res.SettingValue.split('|');
   })
     this.tmeData.timeSummaryUsersSelect = resOptions[0].map(Number);
     this.tmeData.exemptedUsersSelect = resOptions[1].map(Number);
     console.log(this.tmeData, "DDDDDAAddd", aa)
     this.showForm(this.tmeData)
  }
 
}
