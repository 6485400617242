import { AppResponse } from "../../shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";

export class SettingsService extends BaseService {
  resource = "General/Settings";
  hasRootApi = false;

  getActivities(): Promise<AppResponse> {
    return this.get("AllActivities");
  }
}

export const settingsService = new SettingsService();
