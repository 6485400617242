
import { Options, Vue } from "vue-class-component";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import { tenantsService } from "../../tenants/tenants.service";
import PersonnelSettingsForm from "./PersonnelSettingForm.vue";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import { FormEvents } from "../../../constants";

@Options({
  components: { SettingItem, PersonnelSettingsForm },
})
export default class PersonnelSetting extends Vue {
  personnelSettings: any[] = [];

  created() {
    this.getConfig();
  }

  async getConfig(): Promise<void> {
    try {
      const response = await tenantsService.getConfigurations();
      this.personnelSettings = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }

  handlePersonnelSettingsClick(): void {
    console.log("checking");
    GlobalEvent.emit(FormEvents.PERSONNEL_SETTINGS_FORM, {});
    // this.modal("personnel-settings-form", true);
  }
}
