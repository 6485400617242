  const fr = {
    fr: {
      message: {
        login: { loginDesc: 'Sign in to ypur account'},
        globalSettings: { title:"Paramètres Globaux",desc:"Gérer les Paramètres des Applications", modules: {
          invoiceSetting: { title: "Facture Réglage" , desc: "Révise Facture Paramètres", formLabels: { "name": "Name"}},
          industrySetting: { title: "Lorem DDDDDD" , desc: "Lorem Dilor casua", formLabels: { "name": "Name"}},
          labelSetting: { title: "étiquette de projet" , desc: "par ex. Projet, affaire, procès", formLabels: { name: "Name"}},
      }
      },
      financeSettings: { title:"Finance Settings",desc:"Manage Finance Module Settings", modules: {
        reportSetting: { title: "Report Settings" , desc: "Format Report", formLabels: { name: "Name"}},
        }
      }
    }
  }
}
  export default fr;