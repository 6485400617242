
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import SearchableSelect from "../../components/SearchableSelect.vue";
import { clientsService } from "./clients.service";
import { GlobalEvent } from "../../shared/plugins/tiny-emitter";

// import "vue-select/dist/vue-select.css";
// import { settingsStore } from '../../settings.store';

@Options({
  components: { SearchableSelect }
})
export default class MergeClientForm extends mixins(ModalFormMixin) {
  modalId = "merge-client-form";
  formEvent = FormEvents.MERGE_CLIENT_FORM;
  resource = "Clients/GetSelect2Clients";
  emitName = "mergeClientAction";
  data: any = {};

  created(): void {
    GlobalEvent.on(this.emitName, (value: Record<string, unknown>) => {
      // console.log("emmited", value.Id);
      this.data.TargetClient = value.Id;
    });
  }

  merge() {
    this.$swal
      .fire({
        title: "Are you sure you want to merge client?",
        text: "The Source Client will be deleted and all its records will be migrated to the Destination Client.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            this.isLoading = true;
            this.data.SourceClients = [this.payload.Id];
            const response = await clientsService.mergeClients(this.data);
            this.isLoading = false;
            this.toast("success", response.Message);
          } catch (error) {
            this.handleError(error);
          }
        }
      });
  }
}
