import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "error-msg text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ error: _ctx.getData(_ctx.validation, _ctx.name).$errors.length })
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData(_ctx.validation, _ctx.name).$errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "input-errors",
        key: error.$uid
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(error.$message), 1)
      ]))
    }), 128))
  ], 2))
}