import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class contactService extends BaseService {
  resource = "Contacts"; // insert Api resource here

  findAll(): Promise<AppResponse> {
    return this.post("GetContacts");
  }

  list(): Promise<AppResponse> {
    return this.get("GetContact");
  }

  findOne(id: string): Promise<AppResponse> {
    return this.get(`GetContact?Contactid=${id}`);
  }

  getRelatedData(id: string): Promise<AppResponse> {
    return this.get(`RelatedData/${id}`);
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  removeContact(Id: string): Promise<AppResponse> {
    return this.get(`DeleteContact?ContactId=${Id}`);
  }
}

export const ContactService = new contactService();

export class contactService2 extends BaseService {
  resource = "ContactsMemo";

  getMemo(id: string): Promise<AppResponse> {
    return this.get(`GetMemos/${id}`);
  }

  addMemo(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  deleteMemo(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }
}

export const ContactService2 = new contactService2();
