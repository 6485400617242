<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative inline-block text-left">
  <div>
    <button 
      type="button" 
      class="inline-flex items-center justify-center w-10 h-10 rounded-md border border-blue-800 shadow-sm text-lg font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" 
      :class="classes"
      aria-expanded="true" 
      aria-haspopup="true"
      @click="toggleOpen()"
    >
      <!-- <span class="text-2xl">+</span> -->
      <em class="icon ni ni-plus"></em>
    </button>
  </div>

  <div 
    class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" 
    :class="!isOpen ? 'hidden' : ''" 
    role="menu" 
    aria-orientation="vertical" 
    aria-labelledby="menu-button" 
    tabindex="-1"
  >
    <div class="py-1" role="none">
       <a 
        v-for="option in optionsData" 
        :key="option.id" 
        href="javascript:void(0);" 
        class="block px-4 py-2 text-sm" 
        :class="setActiveClasses(option.name)"
        :style="option.hasPermission ? '' : 'display: none;'"
        role="menuitem" 
        tabindex="-1" 
        :id="option.id"
        @click="setSelected(option.name); toggleOpen(); openMenuForm(option.formEventName)"
      >
          {{ option.name }}
      </a>
    </div>
  </div>
</div>

</template>

<script >
  import { Options, Vue } from 'vue-class-component';
  import {GlobalEvent} from "@/shared/plugins/tiny-emitter" 

  @Options({
    components: {},
    props: {
      defaultOption: String,
      options: Array,
      classes: String,
      onclick: Function,
      style: String,
      // formEventName: String,
   },
  })
   
  export default class NCDropdownMenu extends Vue{
    selected = "";
    isOpen = false;

    optionsData = []

    created() {
      // this.isOpen = true
      this.selected = this.defaultOption
      this.optionsData = this.options
      // console.log(this.options, 'ghg');
    }

    setSelected(selected) {
      this.selected = selected
    }

     openMenuForm(formEventName) {
       console.log("I've been clicked", formEventName);
      GlobalEvent.emit(formEventName);
    } 

    toggleOpen () {
      this.isOpen = !this.isOpen;
    }

    setActiveClasses (params) {
      return params === this.selected ? "bg-gray-100 text-gray-900" : "text-gray-700"
    }
  }
</script>

<style lang="scss" scoped>

</style>