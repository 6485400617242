import { BaseService } from "../../../../shared/services/base-service";
import { BankPostingGroupModel } from "./BankPostingGroup.model";

class BankPostingGroupService extends BaseService {
  resource = "Account";

  findAll() {
    return this.get("GetBankPostingGroups");
  }

  create(payload: { ChartOfAccountId: number; GroupName: string }) {
    return this.post("AddBankPostingGroup", payload);
  }

  update(payload: BankPostingGroupModel) {
    return this.post("UpdateBankPostingGroup", payload);
  }
}

export const bankPostingGroupService = new BankPostingGroupService();
