
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterTeamTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields = [
    {
      title: "SNO",
      name: "SNO"
    },
    { title: "Team member name", name: "EmployeeName" },
    { title: "Email", name: "EmailAddress" }
  ];

  async created(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await mattersService.matterOverviewTeams(this.Matter.MatterId);
      this.Data = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
