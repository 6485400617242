import { httpService } from "../../config/api.config";
import { AppResponse } from "../../shared/interfaces/app-response";
import { authStore } from "./auth.store";
import { UserLoginDto } from "./dto/login.dto";
import { VueEasyJwt } from "vue-easy-jwt";
import { Helpers } from "../../shared/helpers";

export default class AuthService {
  public jwt = new VueEasyJwt();

  static async login(userLoginDto: UserLoginDto): Promise<AppResponse> {
    const response = await httpService.loginRequest().post("api/SignIn", userLoginDto);
    return Helpers.handleResponse(response);
  }

  // verifyToken() {}

  // forgotPassword() {}

  public async checkLogin(): Promise<boolean> {
    const token = authStore.auth.token;
    if (!token) return false;
    const exp = this.jwt.isExpired(token);
    if (exp) return false;
    return true;
  }
}
