
import { mixins, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { mattersService } from "../../matters.service";
import { MatterModel } from "../matter";
import { mattersStore } from "../matters.store";

@Options({})
export default class MergeMatterForm extends mixins(ModalFormMixin) {
  modalId = "merge-matter";
  formEvent = FormEvents.MERGE_MATTER_FORM;
  operationResponse = "";
  // @Prop() SourceMatter!: MatterModel;

  get Matters(): MatterModel[] {
    return mattersStore.Matters;
  }

  async savedata(payload: any) {
    this.$swal
      .fire({
        title: "Are you sure you want to Merge Matter?",
        text: "The Source Matter will be deleted and all its records will be migrated to the Destination Matter.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Migrate it!"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          this.save(payload);
        }
      });
  }

  async save(payload: any): Promise<void> {
    const { TargetMatter, MatterId } = payload;
    try {
      this.isLoading = true;
      const response = await mattersService.merge({ SourceMatters: [MatterId], TargetMatter });
      this.isLoading = false;
      this.operationResponse = response.Data;
      // this.
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }

    // super.save();
  }
}
