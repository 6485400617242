
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { accountService } from "../bank-accounts/account.service";
import { PostingAccountModel } from "./BankPostingGroup.model";
import { bankPostingGroupService } from "./BankPostingGroupService.service";

@Options({
  emits: ["refresh"],
})
export default class BankPostingGroupForm extends mixins(ModalFormMixin) {
  modalId = "bank-posting-group-form";
  formEvent = FormEvents.BANK_POSTING_GROUP_FORM;
  postingAccounts: PostingAccountModel[] = [];
  service = bankPostingGroupService;
  primaryKey = "BankPostingGroupId";

  payload = {
    ChartOfAccountId: 0,
    GroupName: "",
  };

  handleModalOpen(): void {
    this.getPostingAccounts();
  }

  async saveData(payload: any) {
    await this.save(payload);
    this.$emit("refresh");
  }

  async getPostingAccounts(): Promise<void> {
    try {
      const response = await accountService.getPostingAccounts();
      this.postingAccounts = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
