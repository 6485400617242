
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { CustomFieldsService } from "./custom-fields.service";
import { CustomFields } from "./customFields.model";
import { settingsStore } from "../../settings.store";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";


@Options({
  components: { Multiselect }
})
export default class CustomFieldsSettingsForm extends mixins(ModalFormMixin) {
  modalId = "custom-fields-setting-form";
  formEvent = FormEvents.CUSTOM_FIELDS_FORM;
  service = CustomFieldsService;
  reloadStore = settingsStore.getCustomFields;
  fieldType = "";
  target = "";
  mandatory = "";
  optionsList : string[] = [];

  get customFields(): CustomFields[] {
    return settingsStore.customFields;
  }

  created(): void {
    // settingsStore.getCustomFields();
    this.loadJS();
  }

  generateFieldType(): string[] {
    return [
      "Checkbox",
      "Client",
      "Currency",
      "Date",
      "Date Time",
      "Dropdown List",
      "Matter",
      "Number",
      "Text Box",
      "Textarea"
    ];
  }

  generateOptionsList(options:string, enterKeyPressed ? :boolean): void {
    if(options.includes(",") || enterKeyPressed){
      // options.includes(",") ? options=options.slice(0, -1) : null
      options.replace("," ," ")
      let optionValues = options.trim().split(",")
      if(optionValues.length > 1) {
        this.optionsList= [...this.optionsList, ...optionValues]
      }else{
        this.optionsList.push(options.slice(0,))
      }
      this.payload.CustomFieldValueOptions =""
    }
    // if(options[options.length - 1] ===" ") {
    //   let optionValues = options.trim().split(",")
    //   this.optionsList = optionValues
    // }
    //  if(options.length===0){
    //    this.optionsList = []
    //  }
  }

  clearOptionsList(){
    this.optionsList= []
  }

  generateTarget(): string[] {
    return ["Matter", "Client", "Client Acceptance"];
  }

  async saveData(payload: any) : Promise<void>{
    // console.log({ field: this.fieldType, target: this.target });
    payload.FieldType = this.generateFieldType()[this.generateFieldType().indexOf(this.fieldType)];
    payload.EntityType = this.generateTarget().indexOf(this.target) + 1;
    payload.IsMandatory = this.mandatory === "Yes" ? true : false;

    try {
      this.isLoading = true;
      const response = await CustomFieldsService.create(payload);
      this.reloadStore();
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", this.$t('message.matterSettings.modules.customFields.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateData(payload: any): Promise<void>{
    // console.log({ field: this.fieldType, target: this.target });
    payload.FieldType = this.generateFieldType()[this.generateFieldType().indexOf(this.fieldType)];
    payload.EntityType = this.generateTarget().indexOf(this.target) + 1;
    payload.IsMandatory = this.mandatory === "Yes" ? true : false;
    try {
      this.isLoading = true;
      const response = await CustomFieldsService.update(payload);
      this.reloadStore();
      this.clearOptionsList();
      this.isLoading = false;
      this.modal(this.modalId, false);
     this.toast("success", this.$t('message.matterSettings.modules.customFields.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }
}
