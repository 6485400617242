
import { Options, Vue } from "vue-class-component";
import { settingsStore } from "../../settings/settings.store";
import { crmReportService } from "../report.service";
import { IndustryModel } from "../../settings/crm/industry/industry.model";
import { leadService } from "../../leads/leads.service";
import { PageTitles } from "../../../constants";

@Options({})
export default class LeadInfoSummary extends Vue {
  breadcrumbLinks = ["Home", "Lead By Industry"];
  report = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "BusinessUnitName", title: "Business Unit" },
    // { name: "LeadStageName", title: "Lead Stage" },
    { name: "DealValue", title: "Value" },
    { name: "LeadStageName", title: "Status" },
    { name: "DateCreatedFormatted", title: "Date Created" },
  ];
  payload = {
    Tags: [] as any,
  };
  tags = [];
  created(): void {
    document.title = PageTitles.CRMLeadbyTags;
    this.init();
  }

  get industries(): IndustryModel[] {
    return settingsStore.industries;
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await leadService.getLeadTags();
      this.tags = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.Tags.length == 0) payload.Tags = null;
      const response = await crmReportService.CRMLeadTagsReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
