
import { Options, Vue } from "vue-class-component";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Prop } from "vue-property-decorator";
// import { Bar } from "vue-chartjs";
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

am4core.useTheme(am4themes_animated);

@Options({})
export default class NCBarChart extends Vue {
  @Prop() data: any = [];
  @Prop() categoryName: any = [];
  @Prop({ default: 400 }) height = 400;
  //   @Prop({ default: "bar-chart" }) chartId = "bar-chart";
  //   chartData = {
  //     labels: [],
  //     datasets: [],
  //   };
  mounted() {
    this.createChart();
  }
  createChart(): void {
    // this.chartData.labels = this.categoryName;
    // this.chartData.datasets = this.data;
    let chart = am4core.create(this.$refs.barchartdiv as any, am4charts.XYChart);

    chart.data = this.data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.name = this.categoryName[0] || "Series 1";

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "category";
    series2.name = this.categoryName[1] || "Series 2";

    // var series3 = chart.series.push(new am4charts.ColumnSeries());
    // series3.dataFields.valueY = "value3";
    // series3.dataFields.categoryX = "category";
    // series3.name = "Series #3";

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
  }
}
