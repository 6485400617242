import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class ActivityService extends BaseService {
  resource = 'General/Settings';

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("AllActivities", { params: pagination }, false);
  }
}

export class ActivityService2 extends BaseService {
  resource = 'ActivityType';

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`AddActivityType`, payload);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Remove/${id}`);
  }
}

export const activityService = new ActivityService();
export const activityService2 = new ActivityService2();
