import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class ClientPortalService extends BaseService {
  resource = "Tenants";

  findAll(): Promise<AppResponse> {
    return this.get("GetClientPortalSetting");
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post("UpdateClientPortalSetting", payload);
  }
}

export const clientPortalService = new ClientPortalService();
