
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { crmPipelineService } from "../pipeline.service";

@Options({})
export default class LostLeadForm extends mixins(ModalFormMixin) {
  modalId = "lost-lead-form";
  formEvent = FormEvents.LOST_LEAD_FORM;

  Reasons = [];

  async created(): Promise<void> {
    try {
      const response = await crmPipelineService.leadLossReasons();
      this.Reasons = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
  async save(payload: Record<string, unknown>): Promise<void> {
    try {
      this.isLoading = true;
      const response = await crmPipelineService.updateLeadLostReason(payload);
      this.isLoading = false;
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }
}
