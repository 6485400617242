
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { invoiceService } from "./invoice.service";

// import "vue-select/dist/vue-select.css";
import { settingsStore } from '../../settings.store';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
import { INV } from "./invoice.model";

@Options({
  components: {Multiselect}
})
export default class InvoiceSettingsForm extends mixins(ModalFormMixin) {
  modalId = "invoice-setting-form";
  formEvent = FormEvents.INVOICE_FORM;
  service = invoiceService;
  approvingType = [
                {value: 0, name: 'Any'},
                {value: 1, name: 'All'}
                ]
  dataObj: any = [];
  //  primaryKey = "Id";
//   get isUpdate(): boolean {
//     if (this.payload.IndustryId) return true;
//     return false;
//   }
 get invConfig(): any[] {
    return settingsStore.inv;
  }
 get users(): any[] {
    return settingsStore.users;
  }

  async savedata(payload: any) {
        this.dataObj = [];
         for (const key in payload) {
         this.invConfig.map((inv: INV) => {
             if( key == inv.SettingName) {
                    if (inv.SettingName == 'InvoiceApprovers') {
                        const invApr = payload[key].join('|')
                        const itemObj = {
                          Id: inv.Id,
                            SettingValue: invApr
                        }
                        this.dataObj.push(itemObj)
                } else {
                      const itemObj = {
                       Id: inv.Id,
                        SettingValue: payload[key]
                     }
                     this.dataObj.push(itemObj)
                }
             }    
          })     
      }
    
    try {
      this.isLoading = true;
      var response = await invoiceService.updateConfig(this.dataObj);
      settingsStore.getINV()
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false)
    } catch (error) {
      this.handleError(error);
    }
  }
}
