import { Helpers } from "../../shared/helpers";
import { AppResponse } from "../../shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";
import { CreateMatterDto } from "./dto/create-matter.dto";

class MattersService extends BaseService {
  resource = "Matters";

  async create(createMatterDto: CreateMatterDto): Promise<AppResponse> {
    const response = await this.request().post("api/CaseManager/Matters/AddMatter", createMatterDto);
    return Helpers.handleResponse(response);
  }

  getMatterAssignments() {
    return this.get("GetMatterAssignments");
  }

  merge(payload: { SourceMatters: number[]; TargetMatter: number }) {
    return this.post("MergeMatters", payload);
  }

  assignMatters(Employees: number[], Matters: number[]) {
    return this.post("MatterAssignment", { Employees, Matters });
  }

  unAssignMatters(Employees: number[], Matters: number[]) {
    return this.post("MatterAssignment", { AssignmentType: 1, Employees, Matters });
  }

  async findAll() {
    const response = await this.request().get("api/CaseManager/Matters/GetMatters");
    return Helpers.handleResponse(response);
  }

  async matterSourceTypes() {
    const response = await this.request().get("api/PLOV/MatterSourceTypes");
    return Helpers.handleResponse(response);
  }

  async paymentTypes() {
    const response = await this.request().get("api/PLOV/PaymentTypes");
    return Helpers.handleResponse(response);
  }

  async matterOverviewDashboard(MatterId: number) {
    const response = await this.request().get(`api/MatterReports/MatterDashboard/0/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewTeams(MatterId: number) {
    const response = await this.request().get(`api/CaseManager/Matters/TeamMembers/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewCourtUpdates(MatterId: number) {
    const response = await this.request().post(`api/CaseManager/Matters/CourtUpdates/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewParties(MatterId: number) {
    const response = await this.request().get(`api/MatterParties/GetAll/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewProgressNotes(MatterId: number) {
    const response = await this.request().get(`api/Matter/ProgressNote/GetProgressNotes/${MatterId}`);
    // return Helpers.handleResponse(response);
    return response.data;
  }

  async matterOverviewTasks(MatterId: number) {
    return this.get(`MatterTask/GetAll/${MatterId}`);
  }

  async matterOverviewBills(MatterId: number) {
    const response = await this.request().post(`api/MatterBills/ClientMatterBillsByMatter/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewMilestones(MatterId: number) {
    const response = await this.request().get(`api/Matter/Milestone/GetByMatter/${MatterId}`);
    // return Helpers.handleResponse(response);
    return response.data;
  }

  async matterOverviewTimeSheet(MatterId: number) {
    const response = await this.request().post(`api/TimeSheetReports/TimeSheetReport/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async matterOverviewExpense(MatterId: number) {
    const response = await this.request().post(`api/CaseManager/Matters/EmployeeExpenses/${MatterId}`);
    return Helpers.handleResponse(response);
  }

  async workflows() {
    const response = await this.request().get(`api/TaskWorkFlowTemplates/GetAll?type=2`);
    return Helpers.handleResponse(response);
  }

  async workflowById(Id: number) {
    const response = await this.request().get(`api/TaskWorkFlowTemplates/Get/${Id}`);
    return Helpers.handleResponse(response);
  }

  async createWorkflowCalendar(payload: any) {
    const response = await this.request().post(`api/TaskWorkFlowTemplates/CreateWorkflowCalendar`, payload);
    return Helpers.handleResponse(response);
  }

  async matterChecklist(PracticeGroupId: number) {
    const response = await this.request().post(`api/PracticeGroups/MatterChecklist/${PracticeGroupId}`);
    return Helpers.handleResponse(response);
  }

  close(Id: number, payload: any) {
    return this.post(`CloseMatter/${Id}`);
  }
}

export const mattersService = new MattersService();
