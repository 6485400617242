
import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import InvoiceBankForm from "./invoice_bank_form.vue";
import { InvoiceBankModel } from "./invoice_bank.model";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { invoiceService } from "../invoice/invoice.service";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";

@Options({
  components: { InvoiceBankForm }
})
export default class InvoiceBanks extends mixins(DataTableMixin) {
  reloadStore = settingsStore.findAllInvoiceBanks;
  formEvent = FormEvents.INVOICE_BANK_FORM;

  get bankAccounts(): InvoiceBankModel[] {
    return settingsStore.invoiceBanks;
  }

  created(): void {
    this.init();
  }

  deleteInvoiceBank(bank: InvoiceBankModel, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: { value: boolean }) => {
        console.log(result, 'CONFIRM', result.value);
        
        if (result.value) {
          try {
            self.isLoading = true;
            const response = await invoiceService.DeleteBankAccount(bank.Id);
            self.isLoading = false;
            this.reloadStore();
            self.toast("success", response.Message);
          } catch (error) {
            self.handleError(error);
          }
        }
      });
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.findAllInvoiceBanks();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  fields = [
    {
      title: "Account Number",
      name: "AccountNumber"
    },
    {
      title: "Account Name",
      name: "AccountName"
    },
    {
      title: "Bank",
      name: "BankName"
    },

    { name: "actions", title: "Actions", showTitle: true }
  ];

  tableActions = [
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.INVOICE_BANK_FORM, prop);
      }
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        this.deleteInvoiceBank(prop, self);
      }
    }
  ];
}
