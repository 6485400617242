/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { GlobalEvent } from "../plugins/tiny-emitter";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ModalFormMixin extends Vue {
  @Prop() show!: boolean;

  payload: any = {
    Id: "",
    Name: "",
    Description: "",
  };
  modalId = "";
  service: any;
  store: any;
  reloadStore: any;
  formEvent = "";
  primaryKey = "Id";

  get isUpdate(): boolean {
    console.log({ primaryKey: this.payload[this.primaryKey] });
    if (this.payload[this.primaryKey]) return true;
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleModalOpen() {}

  mounted(): void {
    // console.log("mounted form with id", this.formEvent);
    GlobalEvent.on(this.formEvent, (data: any) => {
      this.payload = {};
      this.payload = Object.assign({}, data);
      console.log(`Receiving form event ${this.formEvent}}`, this.payload);
      this.modal(this.modalId, true);
      this.handleModalOpen();
      // const element: any = $(`#${this.modalId}`);
      // element.modal("show");
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    // const main: any = this;

    // $(document).ready(function () {
    //   $(`${main.modalId}`).on("shown", function () {
    //     alert("I want this to appear after the modal has opened!");
    //   });

    //   $(`${main.modalId}`).on("shown.bs.modal", function (e) {
    //     alert("I want this to appear after the modal has opened!");
    //   });
    // });
  }

  async save(payload: any): Promise<void> {
    try {
      // await this.validate();
      this.isLoading = true;
      const response = this.isUpdate ? await this.service.update(payload) : await this.service.create(payload);
      this.payload = { ...this.payload, ...response.data };
      this.toast("success", response.Message);
      // this.globalLoader(false);
      this.isLoading = false;
      this.modal(this.modalId, false);
      if (this.reloadStore) this.reloadStore();
    } catch (error) {
      this.handleError(error);
    }
  }
}
