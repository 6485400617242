import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class CrmSettingsService extends BaseService {
  resource = "Tenants";

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }

  update(payload: unknown): Promise<AppResponse> {
    return this.post("UpdateSettings", payload);
  }
}

export const crmSettingsService = new CrmSettingsService();
