
import { Vue, Options } from "vue-class-component";
import { GlobalEvent } from "./shared/plugins/tiny-emitter";

@Options({})
export default class App extends Vue {
  loadPage = false;
  created(): void {
    // this.loadJS();
    // this.loadDependencyJS(["https://unpkg.com/multiple-select@1.5.2/dist/multiple-select.min.js"]);
  }

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const main = this;
    GlobalEvent.on("isPageReady", () => {
      main.loadPage = true;
    });
  }
}
