
import {mixins} from 'vue-class-component';
import ModalFormMixin from '@/shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import { currencyService } from './currency.service';
import { settingsStore } from '../../settings.store';

export default class CurrencySettingsForm extends mixins(ModalFormMixin) {
  reloadStore = settingsStore.getAllCurrencies;
  modalId = 'currency-setting-form'
  formEvent = FormEvents.CURRENCY_FORM;
  service = currencyService;
  primaryKey = "Id"
  selectedCurrency = 0;

  handleModalOpen() : void {
    settingsStore.getAllCurrencies();
    settingsStore.getTransactionCurrencies();
  }

  get allCurrencies() : unknown[] {
    const transactCurrencies = settingsStore.transactionCurrencies;
    const currencies = [...settingsStore.allCurrencies];
    for (let i = 0; i < transactCurrencies.length; i++) {
        const trCurrency : any = transactCurrencies[i];
        for (let x = 0; x < currencies.length; x++) {
            const currency : any = currencies[x];
            if(currency.CurrencyCode === trCurrency.CurrencyCode) {
                currencies.splice(x, 1);
                break;
            }
        }   
    }
    return currencies;
  }

    get transactionCurrencies() : unknown[] {
        const currencies = [...settingsStore.transactionCurrencies];
        currencies.splice(0, 1);
        return currencies;
    }

    async registerValue(e : any, Id : string, isOfficial : boolean) : Promise<void> {
        try {
            const ExchangeRate = e.target.value;
            const payload = isOfficial ? {
                ExchangeRateOfficial : ExchangeRate,
                Id
            } : 
            {
                ExchangeRate,
                Id
            }
            const response = await currencyService.update(payload);
            this.toast("success", response.Message)
            // settingsStore.getTransactionCurrencies();
        } catch(error) {
            this.handleError(error);
        }
    }

    async addCurrency() : Promise<void> {
        try {
            const response = await currencyService.create({
                CurrencyCode : this.selectedCurrency
            });
            this.toast("success", response.Message);
            settingsStore.getTransactionCurrencies();
        } catch(error) {
            this.handleError(error);
        }
    }

    async deleteCurrency(id : string) : Promise<void> {
        this.$swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        })
        .then(async (result: { value: boolean }) => {
            if (result.value) {
                try {
                    const response = await currencyService.remove(id);
                    this.toast("success", response.Message);
                    settingsStore.getTransactionCurrencies();
                } catch(error) {
                    this.handleError(error);
                }
            }
        });
    }
}
