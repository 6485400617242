
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterPartiesTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  async created(): Promise<void> {
    try {
      const response = await mattersService.matterOverviewParties(this.Matter.MatterId);
      this.Data = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
