<template>
  <div class="simple-table">
    <table :id="tableId" class="nk-tb-list nk-tb-ulist display datatable-init-export" :class="otherClass">
      <thead>
        <tr class="nk-tb-item nk-tb-head">
          <th class="nk-tb-col nk-tb-col-check">
            <div class="custom-control custom-control-sm custom-checkbox notext">
              <input
                @click="handleSelectAll"
                type="checkbox"
                :value="true"
                v-model="isAllSelected"
                class="custom-control-input"
                :id="`${tableId}_isAllSelected`"
              />
              <label class="custom-control-label" :for="`${tableId}_isAllSelected`"></label>
            </div>
          </th>
          <th :data-priority="1" class="nk-tb-col">
            <span class="sub-text">
              SNO
            </span>
          </th>
          <th
            v-for="(field, key) in fields"
            :key="key"
            class="nk-tb-col"
            :class="{ 'exclude-export': isActionColumn(field) }"
            :data-priority="field.name === 'actions' ? 1 : field.priority"
          >
            <span class="sub-text" v-if="field.showTitle !== false">
              {{ field.title || field.name }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- <td class="nk-tb-col nk-tb-col-check"></td> -->
        <tr v-for="(row, index) in tableData" :key="index" class="nk-tb-item">
          <td class="nk-tb-col nk-tb-col-check">
            <div class="custom-control custom-control-sm custom-checkbox notext">
              <input
                @change="handleSingleCheck(fetchField(row, primaryKey))"
                type="checkbox"
                :value="fetchField(row, primaryKey)"
                :id="distintId ? `${distinctId}_${fetchField(row, primaryKey)}` : fetchField(row, primaryKey)"
                v-model="checkedItems"
                class="custom-control-input"
              />
              <label class="custom-control-label" :for="fetchField(row, primaryKey)"></label>
            </div>
          </td>
          <td class="nk-tb-col">
            <span class="tb-lead o2"> {{ index + 1 }}</span>
          </td>
          <td
            v-for="(field, key) in fields"
            :key="index + key"
            class="nk-tb-col"
            :class="{ 'text-center': field.center ?? false }"
          >
            <template v-if="isSlot(field)">
              <slot :name="field.name" v-bind="row" />
            </template>

            <template v-else>
              <span :class="`tb-lead o2 ${field.className}`">
                {{ getRowData(row, field.name) }}
              </span>
            </template>
          </td>
          <!-- <td>
            <a v-if="hasDelete" class="dropdown-item" @click.prevent="remove(row[primaryKey])"
              ><i class="ri-delete-bin-5-line mr-2"></i>Delete</a
            >
          </td> -->
        </tr>
        <slot name="custom-row" />
      </tbody>
    </table>
  </div>
</template>

<script>
import datatableMixin from "./datatable";
export default {
  data() {
    return {
      table: null,
      checkedItems: []
    };
  },
  computed: {
    // isAllSelected() {
    //   console.log(`${this.tableId} ${this.checkedItems.length} ${this.tableData.length}`);
    //   return this.checkedItems.length == this.tableData.length;
    // }
    // selectAll: {
    //   get() {
    //     return this.tableData ? this.checkedItems.length == this.tableData.length : false;
    //   },
    //   set(value) {
    //     const selected = [];
    //     this.tableData.forEach(x => {
    //       selected.push(this.fetchField(x, this.primaryKey));
    //     });
    //     this.checkedItems = selected;
    //   }
    // }
  },
  methods: {
    selectAll() {
      // console.log({ tableData: this.tableData, isAllSelected: this.isAllSelected });
      if (this.isAllSelected) {
        this.checkedItems = [];
        this.$emit("selectedItems", this.checkedItems);
        return;
      }
      const selected = [];
      this.tableData.forEach(x => {
        selected.push(this.fetchField(x, this.primaryKey));
      });

      // console.log({ selected });

      this.checkedItems = selected;
      this.$emit("selectedItems", this.checkedItems);
    },
    getRowData(object, key) {
      const keys = key.split(".");
      let prop = object;
      keys.forEach(key => {
        prop = prop[key];
      });

      return prop;
    },
    isActionColumn(field) {
      return field.name.toLowerCase() === "actions" || field.showTitle === false;
    },
    isSlot(field) {
      return field.name in this.$slots;
    },

    handleSelectAll() {
      this.selectAll();
      this.$emit("onSelectAll");
    },

    handleSingleCheck(value) {
      // if (this.checkedItems.includes(value) > -1) {
      //   this.checkedItems = this.checkedItems.remove(value);
      // } else {
      //   this.checkedItems = this.checkedItems.add(value);
      // }
      // console.log("emitting event", "onCheckRow", value);
      this.$emit("selectedItems", this.checkedItems);
      this.$emit("onCheckRow", value);
    }
  },

  // async mounted() {
  //   // const table = this.$refs.table;
  //   const tableId = this.tableId;
  //   this.table = await this.init(tableId, {
  //     hidePagination: this.hidePagination
  //   });
  //   // console.log("table init", this.table);
  // },
  // mixins: [datatableMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    hasDelete: {
      type: Boolean,
      default: true
    },
    handleDelete: {
      type: Function
    },
    primaryKey: {
      type: String,
      default: "Id"
    },
    tableId: {
      type: String,
      default: "table"
    },
    distintId: {
      type: String
    },
    otherClass: {
      type: String
    }
  },
  watch: {
    tableData: {
      handler() {
        // console.log("data changed", this.table);
        // console.log("data changed", this.tableData);
        if (!this.table) return;
        // console.log("table destroyed", this.tableData);
        this.table.destroy();
        this.$nextTick(async () => {
          const tableId = this.tableId;
          this.table = await this.init(tableId, {
            hidePagination: this.hidePagination
          });
        });
      }
    },
    checkedItems: {
      handler() {
        // console.log(JSON.stringify(this.checkedItems));
        // if (typeof this.checkedItems === "object") {
        //   this.checkedItems = Object.values(this.checkedItems);
        //   console.log(this.checkedItems);
        // }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.simple-table ::v-deep .export-btns {
  position: fixed;
  top: -200%;
}
.simple-table .tb-tnx-head {
  text-transform: none;
  letter-spacing: 0.5px;
}
</style>
