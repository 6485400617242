import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class IndustryService extends BaseService {
  resource = 'PLOV/Industries';

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }
}

export const industryService = new IndustryService();
