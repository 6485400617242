<template>
  <nav style="margin-top: -20px; margin-bottom: 10px">
    <ul class="breadcrumb breadcrumb-arrow">
      <template :key="index" v-for="(link, index) in links_">
        <li class="breadcrumb-item" :class="{ active: index === links.length - 1 }">
          <router-link :to="{ name: link.routeName }" exact v-if="index < links.length - 1">
            {{ link.name }}
          </router-link>
          <span v-else>{{ link.name }}</span>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    links_() {
      return this.links.map(link => {
        const obj = {};
        if (link === Object(link)) {
          // link is an object
          const { name, routeName } = link;
          obj.name = name;
          obj.routeName = routeName || name;
        } else {
          obj.name = link;
          obj.routeName = link;
        }

        return obj;
      });
    }
  },
  props: {
    links: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
