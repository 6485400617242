
import { Options, Vue } from "vue-class-component";
import { PipelineModel } from "../../crm/pipeline/pipeline.model";
import { settingsStore } from "../../settings/settings.store";
import { leadReportService } from "../report.service";
import { IndustryModel } from "../../settings/crm/industry/industry.model";
import { PageTitles } from "../../../constants";

@Options({})
export default class LeadInfoSummary extends Vue {
  breadcrumbLinks = ["Home", "Lead By Industry"];
  report = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "IndustryName", title: "Industry" },
    { name: "LeadOwnerFullName", title: "Lead Owner" },
    { name: "DealValue", title: "Value" },
    { name: "LeadStageName", title: "Status" },
    { name: "DateCreatedFormatted", title: "Date Created" },
  ];
  payload = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    Value: "",
    Industry: [] as any,
  };
  created() {
    document.title = PageTitles.CRMLeadbyIndustry;
    this.init();
  }

  get industries(): IndustryModel[] {
    return settingsStore.industries;
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.findAllIndustries();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.Industry == null) payload.Industry = 0;
      if (this.payload.Keyword == null) payload.Keyword = "";
      const response = await leadReportService.LeadIndustrySummaryReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
