import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_time_keeper = _resolveComponent("time-keeper")!
  const _component_tme_setting = _resolveComponent("tme-setting")!
  const _component_tlc_setting = _resolveComponent("tlc-setting")!
  const _component_time_format_setting = _resolveComponent("time-format-setting")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: _ctx.$t('message.timeSettings.title'),
    description: _ctx.$t('message.timeSettings.desc')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_setting_item, {
        title: "Bills Cover Letter Template",
        description: "Format the Bills/Invoice Cover Letter Template",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Bill Cover Letter Templates' }
      }),
      _createVNode(_component_time_keeper, {
        title: "Time Keeper",
        description: "Setup Responsible Time Keepers.",
        iconClass: "ni-view-list-sq",
        modalAction: true
      }),
      _createVNode(_component_setting_item, {
        title: "Activity Type",
        description: "Manage Activity Type.",
        iconClass: "ni-activity-round",
        type: 'newPage',
        link: { name: 'Activity Settings' }
      }),
      _createVNode(_component_tme_setting, {
        title: "Time Summary Email Users",
        description: "Manage Time Entry Weekly Summary Email Users.",
        iconClass: "ni-view-list-sq",
        modalAction: true
      }),
      _createVNode(_component_tlc_setting, {
        title: "Timer Screen Lock Configuration",
        description: "Set up Timer screen lock configuration",
        iconClass: "ni-lock-alt",
        modalAction: true
      }),
      _createVNode(_component_setting_item, {
        title: _ctx.$t('message.matterSettings.modules.signatures.title'),
        description: _ctx.$t('message.matterSettings.modules.signatures.desc'),
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Signatures' }
      }, null, 8, ["title", "description"]),
      _createVNode(_component_time_format_setting, {
        title: _ctx.$t('message.timeSettings.modules.timeFormat.title'),
        description: _ctx.$t('message.timeSettings.modules.timeFormat.desc'),
        iconClass: "ni-clock",
        modalAction: true
      }, null, 8, ["title", "description"]),
      _createVNode(_component_setting_item, {
        title: "Bill Group",
        description: "Manage Bill Group Rate",
        iconClass: "ni-minimize",
        type: 'newPage',
        link: { name: 'Bill Groups' }
      })
    ]),
    _: 1
  }, 8, ["title", "description"]))
}