
import { mixins, Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ModalFormMixin from "../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings/settings.store";
import billService from "../../time/bill/bill.service";
import { MatterModel } from "../matter/matter";
import { mattersStore } from "../matter/matters.store";
import Matters from "../matter/Matters.vue";
import { BillDetailsDto } from "./dto/bill-details.dto";
import { BillDetail, MatterBillItem } from "./models/bill.model";
import dayjs from "dayjs";
import BillsModal from "./BillModal.vue";
import { AddBillItemDto } from "./dto/add-bill-item.dto";
import { add } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { authStore } from "../../auth/auth.store";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import { FormEvents } from "../../../constants";
import AddBillItemForm from "./components/add-bill-item-form.vue";
import { CreateBillDto } from "./dto/create-bill.dto";
import * as converter from "number-to-words";

interface ProcessBillDto {
  TimesheetItems: MatterBillItem[];
  ExpenseItems: MatterBillItem[];
  UserAddedItems: MatterBillItem[];
  UserAddedVAT: number;
  HasUserAddedVAT: boolean;
  HasTimesheetVAT: boolean;
  TimesheetVAT: number;
  ApprovingOfficers: number[];
  Notes: string;
  BillDescription: string;
  PONumber: number;
  DiscountAmount: number;
}

@Options({
  components: {
    BillsModal,
    AddBillItemForm,
  },
})
export default class BillForm extends mixins(ModalFormMixin) {
  modalId = "bill-form";
  converter = converter;
  billDetail: BillDetail = {
    PreviousBills: [],
    MatterMembers: [],
    MatterBillItems: [],
    VAT: {
      VATPercentage: 0,
      Description: "",
    },
  };

  addBillItemDto: Partial<AddBillItemDto> = {};

  selectedBillItems: number[] = [];

  selectedExpenses: number[] = [];

  selectedUserItems: number[] = [];

  hasLoaded = false;

  paymentTypes = [
    {
      Name: "Lump sum",
      Id: 1,
    },
    {
      Name: "Lump sum payable in installments upon certain milestones",
      Id: 2,
    },
    {
      Name: "Lump sum with hurly rate to be triggered when agreed durations/scope is exceeded",
      Id: 3,
    },
    {
      Name: "Hourly rate",
      Id: 4,
    },
    {
      Name: "Hourly rate payable in installments upon certain milestones",
      Id: 5,
    },
    {
      Name: "Hourly rate with a fee cap",
      Id: 6,
    },
    {
      Name: "Retainer",
      Id: 7,
    },
    {
      Name: "Pro Bono",
      Id: 8,
    },
    {
      Name: "No Charge",
      Id: 9,
    },
  ];

  isHourly = false;

  createBillDto: ProcessBillDto = {
    TimesheetItems: [],
    ExpenseItems: [],
    UserAddedItems: [],
    UserAddedVAT: 0,
    HasUserAddedVAT: false,
    HasTimesheetVAT: false,
    TimesheetVAT: 0,
    ApprovingOfficers: [],
    Notes: "",
    BillDescription: "",
    PONumber: 0,
    DiscountAmount: 0,
  };

  @Watch("TimesheetSubTotal")
  @Watch("UserAddedItemsSubTotal")
  handleTimesheetSubTotalChange() {
    this.createBillDto.UserAddedVAT = (this.billDetail.VAT.VATPercentage / 100) * this.UserAddedItemsSubTotal;
    this.createBillDto.TimesheetVAT = (this.billDetail.VAT.VATPercentage / 100) * this.TimesheetSubTotal;
  }

  @Watch("")
  get Matters(): MatterModel[] {
    return mattersStore.Matters;
  }

  get transactionCurrencies() {
    return settingsStore.transactionCurrencies;
  }

  get ExpenseTotal() {
    const expenses = this.selectedExpenses.map((x) => this.createBillDto.ExpenseItems.find((y) => y.Id == x)) as MatterBillItem[];
    if (expenses.length <= 0) return 0;
    return expenses.map((x) => x.Amount).reduce((prev, next) => prev + next);
  }

  get TimesheetSubTotal() {
    const timesheet = this.selectedBillItems.map((x) => this.createBillDto.TimesheetItems.find((y) => y.Id == x)) as MatterBillItem[];
    if (timesheet.length <= 0) return 0;
    return timesheet.map((x) => x.Amount).reduce((prev, next) => prev + next);
  }

  get TimesheetTotal(): number {
    return this.createBillDto.HasTimesheetVAT ? this.TimesheetSubTotal + this.createBillDto.TimesheetVAT : this.TimesheetSubTotal;
  }

  get UserAddedItemsSubTotal() {
    const items = this.selectedUserItems.map((x) => this.createBillDto.UserAddedItems.find((y) => y.Id == x)) as MatterBillItem[];
    console.log({ items });
    if (items.length <= 0) return 0;
    return items.map((x) => x.Amount).reduce((prev, next) => prev + next);
  }

  get UserAddedItemsTotal(): number {
    return this.createBillDto.HasUserAddedVAT ? this.UserAddedItemsSubTotal + this.createBillDto.UserAddedVAT : this.UserAddedItemsSubTotal;
  }

  handleModalOpen() {
    mattersStore.findAll();
  }

  payload: any = {
    PaymentType: 1,
    BillRateType: 1,
    StartDate: dayjs().toDate(),
    EndDate: dayjs().toDate(),
    MatterMembers: [],
  };

  handleTargetMatterChange(event: any): void {
    const MatterId = event.value;
    const Matter = this.Matters.find((x) => x.MatterId == MatterId);
    if (!Matter) return;
    this.hasLoaded = false;
    this.payload.PaymentType = Number(Matter.PaymentTypeId);
    this.payload.Rate = Matter.BillRateType;
    this.payload.TransactionCurrencyId = Number(Matter.MatterCurrency);
    this.payload.MatterId = Number(MatterId);
    this.createBillDto = {
      TimesheetItems: [],
      ExpenseItems: [],
      UserAddedItems: [],
      UserAddedVAT: 0,
      HasUserAddedVAT: false,
      HasTimesheetVAT: false,
      TimesheetVAT: 0,
      ApprovingOfficers: [],
      Notes: "",
      BillDescription: "",
      PONumber: 0,
      DiscountAmount: 0,
    };
    this.billDetail = {
      PreviousBills: [],
      MatterMembers: [],
      MatterBillItems: [],
      VAT: {
        VATPercentage: 0,
        Description: "",
      },
    };
    console.log({ payload: this.payload });
  }

  async handleBillDetails(payload: BillDetailsDto): Promise<void> {
    try {
      const Matter = this.Matters.find((x) => x.MatterId == payload.MatterId);
      this.selectedBillItems = [];
      this.selectedExpenses = [];
      this.selectedExpenses = [];
      const { PaymentType: PaymentTypeId } = payload;
      const PaymentType = this.paymentTypes.find((x) => x.Id == PaymentTypeId);
      PaymentType?.Name.toLowerCase().split(" ").includes("hourly") ? (this.isHourly = true) : (this.isHourly = false);
      const response = await billService.details(payload);
      this.billDetail = response.Data;
      this.createBillDto.TimesheetItems = this.billDetail.MatterBillItems.filter((x) => x.SourceTypeDescription == "TimeSheet");
      this.createBillDto.ExpenseItems = this.billDetail.MatterBillItems.filter((x) => x.SourceTypeDescription == "Expense");
      this.createBillDto.UserAddedVAT = (this.billDetail.VAT.VATPercentage / 100) * this.UserAddedItemsSubTotal;
      this.createBillDto.TimesheetVAT = (this.billDetail.VAT.VATPercentage / 100) * this.TimesheetSubTotal;
      this.addBillItemDto.Amount = Matter?.MatterAmount;
      this.hasLoaded = true;
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleAddBillItem(addBillItemDto: AddBillItemDto, type = "expense"): Promise<void> {
    try {
      if (!this.billDetail) return;
      if (type == "expense") {
        addBillItemDto.BillSourceType = 2;
      } else {
        addBillItemDto.BillSourceType = 3;
      }
      addBillItemDto.MatterBillId = this.billDetail.MatterBillId as number;
      addBillItemDto.TransactionCurrencyId = this.payload.TransactionCurrencyId;
      addBillItemDto.TransactionDate = dayjs().format("YYYY-MM-DD");
      addBillItemDto.TransactionOwner = authStore.auth.employee?.EmployeeId as number;
      addBillItemDto.Rate = 1;

      const response = await billService.addItem(addBillItemDto);
      this.toast("success", response.Message);
      this.addBillItemDto = {};
      this.createBillDto.UserAddedItems.push(response.Data);
      this.selectedUserItems.push(response.Data.Id);
    } catch (error) {
      this.handleError(error);
    }
  }

  showExpenseForm() {
    GlobalEvent.emit(FormEvents.ADD_BILL_ITEM_FORM, {
      MatterBillId: this.billDetail.MatterBillId as number,
      TransactionCurrencyId: this.payload.TransactionCurrencyId,
      TransactionDate: dayjs().toDate().toString(),
      TransactionOwner: authStore.auth.employee?.EmployeeId as number,
      BillSourceType: 2,
      Rate: 1,
    });
  }

  handleUpdateExpenses(expense: any) {
    this.createBillDto.ExpenseItems.push(expense);
    this.selectedExpenses.push(expense.Id);
  }

  async handleCreateBill(createBillDto: ProcessBillDto) {
    const Matter: any = this.Matters.find((x) => x.MatterId == this.payload.MatterId);
    const payload: CreateBillDto = {
      MatterId: Matter!.MatterId,
      PaymentTypeId: this.payload.PaymentType,
      BillCurrency: this.payload.TransactionCurrencyId,
      Notes: createBillDto.Notes,
      TotalExpenseAmount: this.ExpenseTotal,
      ApprovingOfficers: createBillDto.ApprovingOfficers,
      Vatamount: createBillDto.UserAddedVAT + createBillDto.TimesheetVAT,
      BillRate: 1,
      PONumber: createBillDto.PONumber,
      BaseAmount: Matter.MatterAmount,
      BillDescription: createBillDto.BillDescription,
      LineItems: [
        ...this.selectedExpenses.map((x) => {
          return {
            MatterBillDetailId: x,
          };
        }),
        ...this.selectedUserItems.map((x) => {
          return {
            MatterBillDetailId: x,
          };
        }),
        ...this.selectedBillItems.map((x) => {
          return {
            MatterBillDetailId: x,
          };
        }),
      ],
      DiscountAmount: createBillDto.DiscountAmount,
      ApplyVAT: this.isHourly ? createBillDto.HasTimesheetVAT : createBillDto.HasUserAddedVAT,
      isPrePayment: 0,
      Id: this.billDetail.MatterBillId,
    };

    try {
      this.isLoading = true;
      const response = await billService.create(payload);
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", response.Data);
    } catch (error) {
      this.isLoading = false;
    }
  }
}
