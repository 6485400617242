import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class TimeFormatService extends BaseService {
  resource = 'Tenants';

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetSettings", { params: pagination });
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`UpdateSettings`, payload);
  }
}

export const timeFormatService = new TimeFormatService();