
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { cmaService } from "./cma.service";
import { settingsStore } from "../../settings.store";
import { Watch } from "vue-property-decorator";

@Options({})
export default class cmaForm extends mixins(ModalFormMixin) {
  modalId = "cma-setting-form";
  formEvent = FormEvents.CMA_FORM;
  service = cmaService;
  dataObj: any = [];
  enableConflict = this.payload.EnableConflictCheck == 1 ? true : false;
  get CMA() {
    return settingsStore.cma;
  }

  //   get enableConflict(){
  //       return this.payload.EnableConflictCheck == 1? true: false
  //   }

  updateConflict(aa: any) {
    console.log("@change", aa);
  }
  @Watch("enableConflict")
  public watchEnableConflict(newValue: number): void {
    console.log(newValue, "New conflicy config");
  }
  async savedata(payload: any) {
    console.log(payload, "PAYLOAD");
    this.dataObj = [];
    for (const key in payload) {
      this.CMA.map((cma: any) => {
        if (key == cma.SettingName) {
          if (cma.SettingName == "EnableConflictCheck") {
            const itemObj = {
              Id: cma.Id,
              SettingValue: payload[key] == true ? "1" : "0",
            };
            this.dataObj.push(itemObj);
          } else {
            const itemObj = {
              Id: cma.Id,
              SettingValue: payload[key],
            };
            this.dataObj.push(itemObj);
          }
        }
      });
    }

    try {
      this.isLoading = true;
      var response = await cmaService.updateConfig(this.dataObj);
      this.toast("success", response.Message);
      settingsStore.getCMA();
      this.isLoading = false;
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
