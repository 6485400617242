
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { ClientModel } from "../../../clients/client.model";
import { clientsService } from "../../../clients/clients.service";
import { Contacts } from "../../../crm/contact/contact.model";
import { crmStore } from "../../../crm/crm.store";
import { UserModel } from "../../../settings/employee-users/models/user.model";
import { practiceAreaService } from "../../../settings/matters/practice-areas/practice-areas.service";
import { PracticeArea } from "../../../settings/matters/practice-groups/practice-group.model";
import { settingsStore } from "../../../settings/settings.store";
import { MatterModel } from "../matter";
import { mattersService } from "../../matters.service";
import { mattersStore } from "../matters.store";
import { CreateMatterDto } from "../../dto/create-matter.dto";

@Options({})
export default class MatterForm extends mixins(ModalFormMixin) {
  modalId = "matter-form";
  formEvent = FormEvents.MATTER_FORM;
  primaryKey = "MatterId";

  payload: Partial<CreateMatterDto> = {};
  Clients: ClientModel[] = [];
  PracticeAreas: PracticeArea[] = [];
  MatterSourceTypes: any[] = [];
  PaymentTypes: any[] = [];

  get Contacts(): Contacts[] {
    return crmStore.contacts;
  }

  get Matters(): MatterModel[] {
    return mattersStore.Matters;
  }

  get Users(): UserModel[] {
    return settingsStore.users;
  }

  get TransactionCurrencies(): unknown[] {
    return settingsStore.transactionCurrencies;
  }

  async handleModalOpen(): Promise<void> {
    try {
      crmStore.getContacts();
      settingsStore.AllUsers();
      const [clientRequest, pARequest, mSTRequest, pTRequest] = await Promise.all([
        clientsService.findAll(),
        practiceAreaService.list(),
        mattersService.matterSourceTypes(),
        mattersService.paymentTypes(),
      ]);
      this.Clients = clientRequest.Data;
      this.PracticeAreas = pARequest.Data;
      this.MatterSourceTypes = mSTRequest.Data;
      this.PaymentTypes = pTRequest.Data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async save(payload: Partial<CreateMatterDto>): Promise<void> {
    try {
      this.isLoading = true;
      const response = await mattersService.create(payload as CreateMatterDto);
      this.isLoading = false;
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }
}
