import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class TMEService extends BaseService {
  resource = 'Tenants';

  getAll(): Promise<AppResponse> {
    return this.get(`GetConfigurations/TME`);
  }
  updateConfig(data: any): Promise<AppResponse> {
    return this.post(`UpdateConfigurations`, data);
  }
}

export const tmeService = new TMEService();
