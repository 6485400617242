
import { mixins, Options } from 'vue-class-component';
import ModalFormMixin from '@/shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import { tmeService } from './tme.service';
import { settingsStore } from '../../settings.store';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";

@Options({
  components: {Multiselect}
})
export default class tmeForm extends mixins(ModalFormMixin) {
  modalId = 'tme-setting-form';
  formEvent = FormEvents.TME_FORM;
  service = tmeService;
  value!: any | [];
  primaryKey = "Id"
 
//   get isUpdate(): boolean {
//     if (this.payload.IndustryId) return true;
//     return false;
//   }
  get users(): any[] {
    return settingsStore.users;
  }

    get TME(): any[] {
    return settingsStore.tme;
  }

  // created() {
  //   console.log("on mounted", this.TME, "on mounted");
  //      const resOptions = this.TME.map((res: any) => {
  //     return res.SettingValue.split('|');
  //  })
  // //      const resOptions2 = this.TME[1].SettingValue.map((res: any) => {
  // //    const resSplit = res.SettingValue.split('|');
  //    console.log( resOptions[0].map(Number), 'resOPtions',resOptions[1].map(Number) );
  //    this.payload.timeSummaryUsersSelect = resOptions[0].map(Number);
  //    this.payload.exemptedUsersSelect = resOptions[1].map(Number);
  // //  })
  // }
  async savedata(payload: any) {
    console.log(payload, "PAYLOAD");
    
    try {
      const data = [ 
        { Id: "271",
        SettingValue: payload.timeSummaryUsersSelect.join("|") }, 
        { Id: "272",
       SettingValue: payload.exemptedUsersSelect.join("|") },
        ];
       
     
      this.isLoading = true;
      var response = await tmeService.updateConfig(data);
      console.log('TME VALUES', data);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false)
      settingsStore.getTME();
    } catch (error) {
      this.handleError(error);
    }
  }
}
