
import { mixins, Options, Vue } from "vue-class-component";
import { BillCoverLetterTemplateModel } from "./BillCoverLetterTemplate.model";
import { billCoverLetterTemplateService } from "./bill-cover-templates.service";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { FormEvents } from "../../../../constants";
import BillCoverLetterTemplateForm from "./BillCoverLetterTemplateForm.vue";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";

@Options({
  components: {
    BillCoverLetterTemplateForm,
  },
})
export default class BillCoverLetterTemplates extends mixins(DataTableMixin) {
  billCoverLetterTemplates: BillCoverLetterTemplateModel[] = [];
  formEvent = FormEvents.BILL_COVER_LETTER_TEMPLATE_FORM;

  fields = [
    {
      title: "Template Name",
      name: "TemplateName",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: BillCoverLetterTemplateModel): void => {
        GlobalEvent.emit(FormEvents.BILL_COVER_LETTER_TEMPLATE_FORM, prop);
      },
    },
  ];

  created(): void {
    this.getBillCoverLetterTemplates();
  }

  async getBillCoverLetterTemplates(): Promise<void> {
    try {
      const response = await billCoverLetterTemplateService.findAll();
      this.billCoverLetterTemplates = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
