
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, Vue, mixins} from "vue-class-component";
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { Contacts } from  "./contact.model";
import CompanyForm from "../companies/companyForm.vue";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { ContactService } from "./contact.service";
import ContactForm from "../contact/contactForm.vue";
import newLeadsForm from "../leads/new-leads-form.vue";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: {
    ContactForm,
    CompanyForm,
    newLeadsForm,
  }
})

export default class ContactDetails extends mixins(DataTableMixin)  {
  formEvent = FormEvents.CONTACT_FORM;
  app = NCApp;
  Contacts = [];
  componentKey = 0;

  optionsData = [
      {
        name: 'New Lead',
        id: 'menu-item-0',
        formEventName: FormEvents.ADD_LEADS,
        hasPermission: this.app.hasPermission('0x1C'),
      },
      {
        name: 'New Contact',
        id: 'menu-item-2',
        formEventName: FormEvents.CONTACT_FORM,
        hasPermission:this.app.hasPermission('0x28'),
      },
      {
        name: 'New Company',
        id: 'menu-item-3',
        formEventName: FormEvents.COMPANIES_FORM,
        hasPermission: this.app.hasPermission('0x24'),
      },
    ]
 

  get contacts(): Contacts[] {
  
    return [];
  }

  created(): void {
    this.loadJS();
  }

  renderTable() {
    this.componentKey += 1
    }

  handleTableReady() {
    this.isLoading = false;
    console.log("handleTableReady --",  this.isLoading, this.pageIsReady);
  }
 
 beforeMount() {
   this.isLoading = true;
 }

  openContactForm(payload? : any) : void {
     if(payload && payload.Id) {  
      GlobalEvent.emit(FormEvents.CONTACT_FORM, payload);
    } else {
      GlobalEvent.emit(FormEvents.CONTACT_FORM);
    }
  }

  deleteContact(id: string, self: any) {
    const localeObj2 = self.$t('message.crm.modules.contacts.notification.Delete')
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        if(result.isConfirmed === true){
          ContactService.removeContact(id).then(async response=>{
            if (response.Success === true) {
              try {
                self.isLoading = true;
                await this.renderTable();
                
                self.toast("success", localeObj2);
                self.isLoading = false;
              }
              catch (error) {
                self.handleError(error);
              }
            }else {
              self.isLoading = false;
              self.toast("error", response.Message);
            }
          })
        }else{
          // console.log("delete was cancelled");
        }
      });

  }


  fields = [
    {
      title: "SNO",
      name: "SNO"
    },

    {
      title: "Full Name",
      name: "Fullname"
    },

    {
      title: "Telephone",
      name: "Telephone"
    },

    {
      title: "Email Address",
      name: "EmailAddress"
    },

    {
      title: "Date Created",
      name: "FormattedDateCreated"
    },

    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "View Details",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        try {
          self.$router.push({name: "ContactView", params: {id: prop.Id}});
        } 
        catch (error) {
          self.handleError(error);
        }
      }
    },
    
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: any): void => {
        // console.log("Eddit", prop);
        // console.log(this.formEvent);
        const payload = {
          Birthday:prop.Birthday,
          City: prop.City,
          ContactAddress: prop.ContactAddress,
          Country: prop.Country,
          Department: prop.Department,
          EmailAddress: prop.EmailAddress,
          FirstName: prop.FirstName,
          Id: prop.Id,
          JobTitle: prop.JobTitle,
          OtherName: prop.OtherName,
          // PeopleCategoryName: prop.PeopleCategoryName,
          PeopleCategoryId: prop.PeopleCategoryId,
          StateOrProvince: prop.StateOrProvince,
          Surname: prop.Surname,
          Telephone:prop.Telephone,
          ZipCode:prop.ZipCode,
        }
        this.openContactForm(payload);
      }
    },

    {
       type: 'divider'
    },

    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self:any) => {
        this.deleteContact(prop.Id, self);
      }
    }
  ];
}
