import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cma_setting = _resolveComponent("cma-setting")!
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: "Matter Settings",
    description: "Manage Matter Settings."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cma_setting, {
        title: "Client / Matter Acceptance Settings",
        description: "Client/Matter Acceptance processes",
        iconClass: "ni-folder-check",
        modalAction: true
      }),
      _createVNode(_component_setting_item, {
        title: "Practice Group and Area",
        description: "Manage Practice Group, Practice Area and Team",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Practice Groups' }
      }),
      _createVNode(_component_setting_item, {
        title: _ctx.$t('message.matterSettings.modules.customFields.title'),
        description: _ctx.$t('message.matterSettings.modules.customFields.desc'),
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Custom Fields' }
      }, null, 8, ["title", "description"]),
      _createVNode(_component_setting_item, {
        title: "Multiple Matter Assignment",
        description: "Manage bulk matter assignment to Personnel",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'MMAPage' }
      }),
      _createVNode(_component_setting_item, {
        title: "Client/Matter Workflow",
        description: "Manage Client/Matter Workflow - Task Grouping",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Work Flows' }
      })
    ]),
    _: 1
  }))
}