
import { Options, Vue } from "vue-class-component";
import { settingsStore } from "@/modules/settings/settings.store";
import InvoiceSetting from "@/modules/settings/global/invoice/invoiceSetting.vue";
import CurrencySetting from "@/modules/settings/global/currency/currencySetting.vue";
import ClientPortalSetting from "@/modules/settings/global/clientportal/clientPortal.vue";
import BrandLogoSetting from "@/modules/settings/global/brand-logo/brandLogoSetting.vue";
import { GlobalSettingsModel } from "./model/globalsettings.model";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import { FormEvents } from "../../../constants";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import Numbering from "@/modules/settings/global/numbering/numbering.vue";

@Options({
  components: {
    Numbering,
    InvoiceSetting,
    ClientPortalSetting,
    BrandLogoSetting,
    SettingItem,
    CurrencySetting,
  },
})
export default class GlobalSettings extends Vue {
  handlePersonnelSettingsClick(): void {
    console.log("checking");
    this.modal("personnel-settings-form", true);
  }

  // mounted(): void {
  //   this.loadJS();
  // }
}
