<template>
  <div class="simple-table">
    <div v-show="updateState" class="float-right spinner-border spinner-border-sm text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <table :id="tableId" class="nk-tb-list nk-tb-ulist" style="width: 100%!important;" :class="otherClass">
      <!-- <div v-show="updateState" class="float-right spinner-border spinner-border-sm text-info" role="status">
                                  <span class="sr-only">Loading...</span> 
                                </div> -->
      <thead>
        <tr class="nk-tb-item nk-tb-head">
          <!-- <th class="nk-tb-col nk-tb-col-check">
            <div class="custom-control custom-control-sm custom-checkbox notext">
              <input
                @click="handleSelectAll"
                type="checkbox"
                :value="true"
                v-model="isAllSelected"
                class="custom-control-input"
                :id="`${tableId}_isAllSelected`"
              />
              <label class="custom-control-label" :for="`${tableId}_isAllSelected`"></label>
            </div>
          </th> -->
          <!-- <th :data-priority="1" class="nk-tb-col"  style="max-width:90px;">
            <span class="sub-text">
              SNO
            </span>
          </th> -->
          <th
            v-for="(field, key) in fields"
            :key="key"
            class="nk-tb-col"
            :class="{ 'exclude-export': isActionColumn(field) }"
            :data-priority="field.name === 'actions' ? 1 : field.priority"
          >
            <span class="sub-text" v-if="field.showTitle !== false">
              <!-- {{ field.title || field.name }} -->
              {{ field.title }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- <td class="nk-tb-col nk-tb-col-check"></td> -->
        <tr v-for="(row, index) in handleData" :key="index" class="nk-tb-item">
          <!-- <td class="nk-tb-col nk-tb-col-check">
            <div class="custom-control custom-control-sm custom-checkbox notext">
              <input
                @change="handleSingleCheck(fetchField(row, primaryKey))"
                type="checkbox"
                :value="fetchField(row, primaryKey)"
                :id="distintId ? `${distinctId}_${fetchField(row, primaryKey)}` : fetchField(row, primaryKey)"
                v-model="checkedItems"
                class="custom-control-input"
              />
              <label class="custom-control-label" :for="fetchField(row, primaryKey)"></label>
            </div>
          </td> -->
          <!-- <td class="nk-tb-col tdSNO">
            <span class="tb-lead o2 pl-1"> {{ index + 1 }}</span>
          </td> -->
          <td
            v-for="(field, key) in fields"
            :key="index + key"
            class="nk-tb-col"
            :class="{ 'text-center': field.center ?? false }"
          >
            <!-- <template @click="rowToRoute ? viewOnCLick(field): console.log('nobeRoute')">  :class="{ 'pr-2': field.name == 'actions' }"-->
            <template v-if="field.name == 'SNO'">
              <span class="tb-lead o2 pl-1">{{ index + 1 }}</span>
            </template>
            <template v-if="isSlot(field)">
              <slot :name="field.name" v-bind="row" />
            </template>
            <template v-else>
              <span :class="`tb-lead o2 ${field.className}`">
                {{ getRowData(row, field.name) }}
              </span>
            </template>
            <!-- </template> -->
          </td>
          <!-- <td>
            <a v-if="hasDelete" class="dropdown-item" @click.prevent="remove(row[primaryKey])"
              ><i class="ri-delete-bin-5-line mr-2"></i>Delete</a
            >
          </td> -->
        </tr>
        <slot name="custom-row" />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DataTableMixin from "./datatable2";
export default {
  data() {
    return {
      table: null,
      checkedItems: []
    };
  },
  computed: {
    isAllSelected() {
      // console.log(`${this.tableId} ${this.checkedItems.length} ${this.handleData.length}`);
      return this.checkedItems.length == this.handleData.length;
    },
    handleData() {
      return this.serverSide ? this.internalData : this.tableData;
    }
  },
  methods: {
    selectAll() {
      // console.log({ tableData: this.handleData, isAllSelected: this.isAllSelected });
      if (this.isAllSelected) {
        this.checkedItems = [];
        this.$emit("selectedItems", this.checkedItems);
        return;
      }
      const selected = [];
      this.handleData.forEach(x => {
        selected.push(this.fetchField(x, this.primaryKey));
      });

      // console.log({ selected });

      this.checkedItems = selected;
      this.$emit("selectedItems", this.checkedItems);
    },
    getRowData(object, key) {
      const keys = key.split(".");
      let prop = object;
      keys.forEach(key => {
        prop = prop[key];
      });

      return prop;
    },
    isActionColumn(field) {
      return field.name.toLowerCase() === "actions" || field.showTitle === false;
    },
    isSlot(field) {
      return field.name in this.$slots;
    },
    viewOnClick(field) {
      // console.log(field, "rowToRoute");
    },
    handleSelectAll() {
      this.selectAll();
      this.$emit("onSelectAll");
    },
    handleSingleCheck(value) {
      // console.log("emitting event", "onCheckRow", value);
      this.$emit("selectedItems", this.checkedItems);
      this.$emit("onCheckRow", value);
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    hasDelete: {
      type: Boolean,
      default: true
    },
    handleDelete: {
      type: Function
    },
    primaryKey: {
      type: String,
      default: "Id"
    },
    tableId: {
      type: String,
      default: "table"
    },
    distintId: {
      type: String
    },
    otherClass: {
      type: String
    },
    datatable: {
      type: Boolean,
      default: true
    },
    serverSide: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ""
    },
    requestMethod: {
      type: String,
      default: ""
    },
    rowToRoute: {
      type: Boolean,
      default: false
    }
  },
  mixins: [DataTableMixin]
  // watch: {
  //   tableData: {
  //     handler() {
  //       if (!this.table) return;
  //       this.table.destroy();
  //       this.$nextTick(async () => {
  //         const tableId = this.tableId;
  //         this.table = await this.init(tableId, {
  //           hidePagination: this.hidePagination
  //         });
  //       });
  //     }
  //   }
  //   // checkedItems: {
  //   //   handler() {
  //   //   }
  //   // }
  // }
};
</script>

<style lang="css" scoped>
.simple-table ::v-deep .export-btns {
  position: fixed;
  top: 22%;
}
.simple-table .tb-tnx-head {
  text-transform: none;
  letter-spacing: 0.5px;
}

.tdSNO {
  max-width: 50px;
}
</style>
