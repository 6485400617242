
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterTimesheetTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields: { title: string; name: string }[] = [
    ...this.fields,
    {
      title: "Activity",
      name: "ActivityName"
    },
    { title: "Date", name: "SheetDate" },
    { title: "Time Duration", name: "TimeDuration" },
    { title: "Narrative", name: "Narrative" },
    { title: "Type", name: "ChargeType" }
  ];

  // async created(): Promise<void> {
  //   try {
  //     const response = await mattersService.matterOverviewTimeSheet(this.Matter.MatterId);
  //     this.Data = response.Data;
  //   } catch (error) {
  //     this.handleError(error);
  //   }
  // }
}
