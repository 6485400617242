
import { Options, Vue } from "vue-class-component";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Prop } from "vue-property-decorator";
import { Bar, Line } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, LineElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

am4core.useTheme(am4themes_animated);

@Options({
  components: { Bar, Line },
  extends: { Line },
})
export default class NCAreaChart extends Vue {
  @Prop() data: any = [];
  @Prop() categoryName: any = [];
  @Prop({ default: "chart-id" }) chartId = "chart-id";
  gradient = null;
  gradient2 = "rgba(255, 0,0, 0.5)";
  @Prop() chartData: any;
  chartOptions = {
    responsive: true,
  };

  mounted() {
    // this.createChart();
  }
  //   createChart(): void {
  //     let chart = am4core.create(this.$refs.areachartdiv as any, am4charts.XYChart);

  //     chart.data = this.data;

  //     let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());

  //     categoryAxis.dataFields.category = "category";
  //     categoryAxis.renderer.grid.template.location = 0;
  //     categoryAxis.renderer.minGridDistance = 30;

  //     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  //     // Create series
  //     var series = chart.series.push(new am4charts.ColumnSeries());
  //     series.dataFields.valueY = "value";
  //     series.dataFields.categoryX = "category";
  //     series.name = this.categoryName[0] || "Series 1";

  //     var series2 = chart.series.push(new am4charts.ColumnSeries());
  //     series2.dataFields.valueY = "value2";
  //     series2.dataFields.categoryX = "category";
  //     series2.name = this.categoryName[1] || "Series 2";

  //     // var series3 = chart.series.push(new am4charts.ColumnSeries());
  //     // series3.dataFields.valueY = "value3";
  //     // series3.dataFields.categoryX = "category";
  //     // series3.name = "Series #3";

  //     chart.legend = new am4charts.Legend();
  //     chart.legend.useDefaultMarker = true;
  //   }
}
