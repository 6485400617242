
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../settings.store";
import { Role } from "./models/role.model";
import { employeeService } from "./users.service";
import { Employee } from "../../auth/auth.model";

@Options({})
export default class AddUser extends mixins(ModalFormMixin) {
  modalId = "add_user-form";
  formEvent = FormEvents.CREATE_USER_FORM;
  reloadStore = settingsStore.AllUsers;

  async convert(payload: any): Promise<void> {
    try {
      // await this.validate();
      this.isLoading = true;
      const response = await employeeService.createUserFromEmployee(payload);
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.reloadStore();
    } catch (error) {
      this.handleError(error);
    }
  }

  get employees(): Employee[] {
    return settingsStore.employees.filter(employee => employee.AppUserId == null);
  }

  get roles(): Role[] {
    return settingsStore.roles;
  }
}
