/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import $ from "jquery";
import "jquery/dist/jquery";
import jsZip from "jszip";
// import "datatables.net-bs5/js/dataTables.bootstrap5";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

window.JSZip = jsZip;
window.pdfMake = pdfMake;

export default {
  data: {
    internalData: [],
    updateState: false
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const baseUrl = this.$store.state.auth.tenantConfiguration.AppUrl;
      const token = this.$store.state.auth.auth.token;

      const dataColumns = this.fields;
      // dataColumns.pop();
      const columnData = dataColumns.map(col => {
        return { data: `${col.name}` };
      });
      // console.log({ baseUrl, token }, "init data" , dataColumns,'ddd', columnData);
      if (!this.datatable) return;
      const main = this;
      const topRowClasses = ["row", "justify-between", "g-2"];
      const footerClasses = ["row", "align-items-center"];
      $(document).ready(function() {
        // console.log(`${main.tableId} DATATABLE INIT, SERVER SIDE IS ::: ${main.serverSide}`, $(`${main.tableId}`));
        const options = {
          lengthMenu: [
            [25, 50, 100, 200, 100000],
            [25, 50, 100, 200, "All"]
          ],
          buttons: {
            buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],

            exportOptions: {
              columns: ":visible"
            }
          },
          columns: columnData,
          dom: `<"${topRowClasses.join(
            " "
          )}"<"col-9 col-sm-6 text-left mt-3"f><"dt_length2 col-3 col-sm-6   text-right"Bl>><r><"datatable-wrap my-0"t><"${footerClasses.join(
            " "
          )}"<"col-7 col-sm-12 col-md-9"p><"col-5 col-sm-12 col-md-3 text-left text-md-right"i>>`,
          language: {
            search: "",
            searchPlaceholder: "Type in to Search",
            lengthMenu: `<span class='custom-control-lg '> _MENU_ </span>`,
            info: "_START_ -_END_ of _TOTAL_",
            infoEmpty: "No records found",
            infoFiltered: "( Total _MAX_  )",
            paginate: {
              first: "First",
              last: "Last",
              next: "Next",
              previous: "Prev"
            }
          },
          serverSide: main.serverSide,
          processing: main.serverSide
        };

        if (main.serverSide && main.requestMethod !== "POST") {
          main.updateState = true;
          options.ajax = {
            url: baseUrl + main.url,
            type: "GET",
            headers: {
              Authorization: `Bearer ${token}`
            },
            success: function(data) {
              // console.log({ serverSideData: data.Data });
              main.internalData = data.Data;
              options.processing = false
            }
          };
        } else {
          main.updateState = true;
          options.ajax = {
            url: baseUrl + main.url,
            type: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            },
            success: function(data) {
              // console.log('dddddddddddddddddddddddddddddddddddddddd',{ serverSideData: data.data });
              main.internalData = data.data ? data.data : data.Data;
              main.updateState = false;
            }, error: function(err) {
              // console.log(err, 'FFFFfrom datable');
            }
          };
        }
        // console.log('options here',  options);
       $(`#${main.tableId}`).DataTable(options);
        // setTimeout(() => {
        //   var data = dd
        //     .rows()
        //     .data()
        //     .toArray();
        //   console.log(data, "All Rows");
        // }, 3000);
      });
    }
  },
  watch: {
    tableData: {
      handler() {
        try {
          $(`#${this.tableId}`)
            .DataTable()
            .destroy();
          setTimeout(() => {
            this.init();
          }, 1000);
        } catch (error) {
          // console.log(`${this.tableId} DATATABLE ERROR :::`, error);
        }
      }
    }
  }
};
