import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";
import { InvoiceBankDto } from "./invoice_bank_dto";

export class InvoiceService extends BaseService {
  resource = "Tenants";

  DeleteBankAccount(id: number): Promise<AppResponse> {
    return this.get(`BankAccounts/Delete/${id}`);
  }

  uploadLogo(payload: any): Promise<AppResponse> {
    return this.post("UploadTenantLogo", payload);
  }

  AddBankAccount(payload: InvoiceBankDto): Promise<AppResponse> {
    return this.post("BankAccounts/Add", payload);
  }

  UpdateBankAccount(payload: InvoiceBankDto): Promise<AppResponse> {
    return this.post("BankAccounts/Update", payload);
  }

  GetBankAccounts(): Promise<AppResponse> {
    return this.get("BankAccounts/GetAll");
  }

  getConfig(): Promise<AppResponse> {
    return this.get(`GetConfigurations/INV`);
  }
  updateConfig(data: string): Promise<AppResponse> {
    return this.post(`UpdateConfigurations`, data);
  }
}

export const invoiceService = new InvoiceService();
