import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";
import { BankAccount } from "./bank_account_dto";

export class AccountService extends BaseService {
  resource = "Account";

  create(payload: any): Promise<AppResponse> {
    return this.post("AddBankAccount", payload);
  }

  update(payload: BankAccount): Promise<AppResponse> {
    return this.post("UpdateBankAccount", payload);
  }

  GetBankAccounts(): Promise<AppResponse> {
    return this.get("BankAccounts");
  }

  getPostingGroups(): Promise<AppResponse> {
    return this.get("GetBankPostingGroups");
  }

  getPostingAccounts(): Promise<AppResponse> {
    return this.get("GetPostingAccounts");
  }
}

export const accountService = new AccountService();
