import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class GlobalSettingsService extends BaseService {
    resource = 'Tenants';

    findAll(): Promise<AppResponse> {
        return this.get('GetSettings');
    }
    
}

export const globalSettingService = new GlobalSettingsService();
