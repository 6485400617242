
import { mixins, Options} from "vue-class-component";
import {TimesheetService, TimesheetService2, TimesheetService3} from "@/modules/time/timesheet/timesheet.service"
import TimeSheetsForm from "./timesheetForm.vue"
import TaskAddForm from "../../matters/tasks/task-add-form.vue"
import CourtUpdateForm from "@/modules/matters/courtupdates/new-court-update.vue";
import CmaMatterForm from "@/modules/matters/clientMatterAcc/cmaMatterForm.vue";
import MatterForm from "@/modules/matters/matter/components/MatterForm.vue"
import { FormEvents } from "@/constants";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import NCApp from "@/app/index";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import SearchableSelect from "../../../components/SearchableSelect.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { settingsStore } from "@/modules/settings/settings.store";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";

@Options({
    components: {
      SearchableSelect,
      Multiselect,
      TimeSheetsForm,
      TaskAddForm,
      CourtUpdateForm,
      CmaMatterForm,
      MatterForm,
    }
})
export default class Timesheets extends mixins(DataTableMixin) {
  formEvent = FormEvents.TIME_SHEET_FORM;
  TimeSheets: any = [];
  app = NCApp;
  activities: any= [];
  selectedOption = ''
  // employees = settingsStore.employees;
  payload = {
    ActivityType: "0",
    ChargeType: "",
    ClientId: "0",
    EndDate: "",
    MatterId: "0",
    StartDate: "",
    MatterTitle: "",
  }
  
  optionsData = [
      {
        name: 'New Client',
        id: 'menu-item-0',
        formEventName: FormEvents.MATTER_TASK_ADD_FORM,
        hasPermission: true,
      },
      {
        name: 'Client/Matter Acceptance',
        id: 'menu-item-1',
        formEventName: FormEvents.CMA_MATTER_FORM,
        hasPermission: this.app.hasPermission('0x40'),
      },
      {
        name: 'New Matter',
        id: 'menu-item-2',
        formEventName: FormEvents.MATTER_FORM,
        hasPermission:this.app.hasPermission('0x5C'),
      },
      {
        name: 'New Task',
        id: 'menu-item-3',
        formEventName: FormEvents.MATTER_TASK_ADD_FORM,
        hasPermission: true,
      },
      {
        name: 'New Court Update',
        id: 'menu-item-4',
        formEventName: FormEvents.NEW_COURT_UPDATE,
        hasPermission: this.app.hasPermission('0x3C'),
      },
      {
        name: 'New Time Entry',
        id: 'menu-item-5',
        formEventName: FormEvents.TIME_SHEET_FORM,
        hasPermission: this.app.hasPermission('0x68'),
      },
    ]
  
  resource = "CaseManager/GetSelect2Matters";
  emitName = "timesheetSelected";
  updateState = true;

  customModel = this.payload.ClientId
    ? { MatterName: this.payload.MatterTitle, ClientId: this.payload.ClientId, MatterId: this.payload.MatterId }
    : {};
  customKey = "time-sheets-data-table";
  componentKey = 0;

  created(): void {
      this.loadJS();
  }
  
 async mounted(){
    this.allActivities(settingsStore.activities)
    // this.payload.ChargeType = ""
    // console.log(this.payload, "in mounted block");
    this.filterData(this.payload);

    GlobalEvent.on(this.emitName, (value: Record<string, unknown>) => {
      this.payload.ClientId = value.ClientId as string;
      this.payload.MatterId = value.MatterId as string;
      this.payload.MatterTitle = value.MatterName as string;
      this.customModel = value as any;
      // console.log("The matter Id is: ", this.payload.MatterId,"and the client Id is: ", this.payload.ClientId, value, "custom", this.customModel);
    });
    this.customKey = "time-sheets-data-table";
  }
  
renderTable() {
    this.componentKey += 1
    this.filterData(this.payload)
  console.log("tablereload", this.componentKey);
  
    }

handleTableReady() {
  this.isLoading = false;
  console.log("handleTableReady --",  this.isLoading, this.pageIsReady);
}
 
 beforeMount() {
   this.isLoading = true;
 }
  
generateBillType(): any[] {
    return [
      { label: "Billable", code: "Billable" },
      { label: "Non Billable", code: "Non Billable" }
    ];
  }

  allActivities(arr: any): void {
    const activities = [];
    activities.push({ ActivityName: "All Activities" });

    for (let i = 0; i < arr.length; i++) {
      let obj = { ...arr[i], ActivityName: arr[i].ActivityName.slice(0, 40) };
      activities.push(obj);
    }

    this.activities = activities;
  }

  async filterData(payload: any) : Promise<void> {
    console.log(payload, "payload in filter block", this.payload, "another block");
     if(this.payload.ChargeType== undefined) {
       payload.ChargeType = ""
     }
    
    try {
      this.isLoading = true;
      const response = await TimesheetService.findAll(payload);
      if (response) {
        this.TimeSheets = response;
        console.log("Timesheet saved successfully", response);
      }
      this.isLoading = false;
      // this.modal(this.modalId, false);
      // window.location.reload();
      this.toast("success", this.$t('message.time.modules.timesheets.notification.Fetched'));
    } catch (error) {
      this.handleError(error);
    }
  }

  // getDate(pastTime?: number): string {
  //   const today = new Date();
  //   if (pastTime) {
  //     const priorDate = new Date(new Date().setDate(today.getDate() - 30));
  //     return priorDate.toString();
  //   } else {
  //     return today.toString();
  //   }
  // }

 openTimesheetsForm(prop : Record<string, unknown>) : void {
      GlobalEvent.emit(FormEvents.TIME_SHEET_FORM, prop);
    } 


  deleteTimeSheet(id: string, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        // console.log(result,";;;");
        if(result.isConfirmed == true){
          // console.log("delete is confirmed");
          TimesheetService3.remove(id).then(async response=>{
          if (response.Success === true) {
            try {
              self.isLoading = true;
              self.toast("success", this.$t('message.time.modules.timesheets.notification.Delete'));
              await this.renderTable();
              self.isLoading = false;
            } catch (error) {
              self.handleError(error);
            }
          } else {
            self.isLoading = false;
            self.toast("error", response.Message);
          }
          })
        }else{
          // console.log("delete was cancelled");
        }
      });
  }

  fields = [
    {
      title: "SNO",
      name: "SNO"
    },

    {
      title: "Client",
      name: "ClientName"
    },

    {
      title: "Matter",
      name: "MatterTitle"
    },

    {
      title: "Activity",
      name: "ActivityName"
    },

    {
      title: "Date",
      name: "SheetDate"
    },

    {
      title: "Duration",
      name: "TimeDuration"
    },

    {
      title: "Narrative",
      name: "Narrative"
    },

    {
      title: "Type",
      name: "ChargeType"
    },

    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    // {
    //   name: "View Details",
    //   iconClass: "icon ni ni-eye",
    //   showIcon: true,
    //   action:(prop: any, self: any): void => {

    //       console.log("The view action prop is", prop);
    //   this.openProgressViewForm(prop)
    //   }
    // },

    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: async (prop: any, self: any) => {
        console.log("Edit", prop);
        console.log(this.formEvent);
        const timesheetActivity = this.activities.find((act: any) => act.ActivityName == prop.ActivityName);
        console.log(timesheetActivity, "see me here");
        console.log(prop.ChargeType, "This is the charge type");
        const payload = {
          ActivityTypeId: timesheetActivity.Id,
          ChargeType: prop.ChargeType,
          EmployeeId: prop.EmployeeId,
          MatterId: prop.MatterId,
          Narrative: prop.Narrative,
          SheetDate: prop.SheetDateActual,
          TimeEntry: prop.TimeEntry,
          TimeSheetId: prop.TimeSheetId,
          MatterTitle: prop.MatterTitle
        };
        console.log(payload, "payload before form");
        this.openTimesheetsForm(payload);
      }
    },

    {
      type: "divider"
    },

    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self: any) => {
        this.deleteTimeSheet(prop.TimeSheetId, self);
      }
    }
  ];
}
