import { AppResponse } from "../../../shared/interfaces/app-response";
import { BaseService } from "../../../shared/services/base-service";

export class DashboardService extends BaseService {
  resource = "ClientReports";

  GetMatterDashboard(): Promise<AppResponse> {
    return this.get("AllClientsDashboard/0/0");
  }
}

export const dashboardService = new DashboardService();