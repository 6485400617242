
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { WorkFlow } from "./workflow.model";
import { taskWorkFlowService } from "./workflow.service";
import ClientMatterWorkflow from "./workflow_modal.vue";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
@Options({
  components: { ClientMatterWorkflow }
})
export default class WorkFlows extends mixins(DataTableMixin) {
  reloadStore = settingsStore.findAllWorkFlow;
  formEvent = FormEvents.WORKFLOW_FORM;
  get workFlows(): WorkFlow[] {
    return settingsStore.workflow;
  }
  created(): void {
    this.init();
    this.loadJS();
  }
  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.findAllWorkFlow();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
  async deleteWorkFlow(id: any, self: any): Promise<void> {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            await taskWorkFlowService.remove(id);
            self.isLoading = false;
          } catch (error) {
            console.log(error);
            self.handleError(error);
          }
        }
      });
  }
  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];
  fields = [
    {
      title: "Name",
      name: "WorkflowName"
    },
    {
      title: "Description",
      name: "WorkflowDescription",
      center: false
    },
    {
      title: "Type",
      name: "WorkflowTypeDescription"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];
  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        console.log("Delete", prop);
        await this.deleteWorkFlow(prop.Id, self);
      }
    }
  ];
}
