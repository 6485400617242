
import { Options, Vue } from "vue-class-component";
import {Watch} from 'vue-property-decorator';
import { FormEvents } from "../../../../constants";
import InvoicesSettingsForm from "@/modules/settings/global/invoice/invoicesSettingsForm.vue";
import { invoiceService } from "./invoice.service";
import { INV } from "./invoice.model";
import { settingsStore } from "../../settings.store";
import { watch } from "@vue/runtime-core";
import Login from "@/modules/auth/views/Login.vue";

@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean
  },
  components: { InvoicesSettingsForm }
})
export default class InvoiceSetting extends Vue {
  formEvent = FormEvents.INVOICE_FORM;
 
  invSettings: any | [];
  invSettings4: any | [];
  invApr: any  ;

  get invConfig(): any[] {
    return settingsStore.inv;
  }
  created() {
    this.setFormData();
  }
  async showInvoiceForm(xx: any) {
    // this.setFormData();
    this.showForm(xx);
  }

  setFormData() {
       this.invSettings = this.invConfig.map((inv: INV) => {
     if (inv.SettingName == "InvoiceApprovers" ) {
       inv.SettingName = 'InvoiceApproverse'
       const ddd = inv.SettingValue;
      this.invApr = ddd.split('|').map(Number);
     } else if (inv.SettingName == "InvoiceApproverse") {
        inv.SettingName = 'InvoiceApproverse';
         const ddd = inv.SettingValue;
         this.invApr = ddd.split('|').map(Number);
     }
    return {
        [inv.SettingName]: inv.SettingValue
      }
        
    })
    this.invSettings4 = Object.assign({InvoiceApprovers: this.invApr},  ...this.invSettings );
  }

  @Watch("invConfig")
  public watchConfig(newValue: number): void {
   this.setFormData()
  }
}
