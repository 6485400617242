
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { clientPortalService } from "./clientPortal.service";
import { settingsStore } from "../../settings.store";

@Options({})
export default class ClientPortalForm extends mixins(ModalFormMixin) {
  modalId = "client-portal-form";
  formEvent = FormEvents.CLIENT_PORTAL_FORM;
  service = clientPortalService;
  reloadStore = settingsStore.getClientPortalSettings;
  isClientPortalEnabled: boolean | undefined;

  async save(payload: unknown): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.service.update(payload);
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      this.isLoading = false;
      this.reloadStore();
      this.modal(this.modalId, false);
    } catch (error) {
      console.log("error ", error);
      this.handleError(error);
    }
  }

  data() {
    return {
      isClientPortalEnabled: !this.isClientPortalEnabled,
    };
  }

  toggleChanged() {
    this.isClientPortalEnabled = !this.isClientPortalEnabled;
    console.log(this.isClientPortalEnabled);
  }

  get globalSettings() {
    return settingsStore.globalSettings;
  }

  mounted() {
    console.log(this.globalSettings, "Global Settings from Form");
    this.globalSettings.EnableClientPortal == 1 ? (this.isClientPortalEnabled = true) : (this.isClientPortalEnabled = false);

    console.log(this.isClientPortalEnabled);
  }
}
