
import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { practiceGroupsService } from "../practice-groups/practice-group.service";
import { practiceAreaService } from "../practice-areas/practice-areas.service";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";

@Options({})
export default class PracticeAreaForm extends mixins(ModalFormMixin, DataTableMixin) {
  created(): void {
    this.loadJS();
  }

  formEvent = FormEvents.PRACTICE_AREA_FORM;
  modalId = "practice-area-form";
  reloadStore = settingsStore.findAllPracticeGroups;
  // service = practiceGroupsService;
  primaryKey = "PracticeAreaId";

  payload = {
    PracticeGroupId: "",
    PracticeAreas: [],
  };

  fields = [
    {
      title: "Name",
      name: "PracticeAreaName",
    },
    {
      title: "Code",
      name: "PracticeAreaCode",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any) => {
        try {
          var response = await practiceGroupsService.removePracticeArea(prop.PracticeAreaId);
          await this.fetchPraticeGroup();
          this.reloadStore();
          this.toast("success", response.Message);
        } catch (error) {
          this.handleError(error);
        }
      },
    },
  ];

  async savedata(payload: any) {
    try {
      const data = {
        PracticeAreaCode: payload.PracticeAreaCode,
        PracticeAreaName: payload.PracticeAreaName,
        PracticeGroupId: payload.PracticeGroupId,
      };
      this.isLoading = true;
      var response = await practiceAreaService.create(data);
      await this.fetchPraticeGroup();
      this.reloadStore();
      this.isLoading = false;
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }

  async fetchPraticeGroup() {
    var practicegroupdata = await practiceGroupsService.findOne(this.payload.PracticeGroupId);
    this.payload = practicegroupdata.Data;
  }
}
