import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

class NumberingService extends BaseService {
  resource = 'Tenants';

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetConfigurations/CMG", { params: pagination });
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`UpdateConfigurations`, payload);
  }
}

const numberingService = new NumberingService();
export default numberingService;