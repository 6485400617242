import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-gs" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nc_select3 = _resolveComponent("nc-select3")!
  const _component_nc_input = _resolveComponent("nc-input")!
  const _component_nc_modal3 = _resolveComponent("nc-modal3")!

  return (_openBlock(), _createBlock(_component_nc_modal3, {
    id: _ctx.modalId,
    title: "Multiple Matter Assignment Form",
    customClass: "modal-lg",
    iconClass: "ni-report-profit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_nc_input, { label: "Personnel Information" }, {
            default: _withCtx(() => [
              _createVNode(_component_nc_select3, {
                id: "users",
                multiple: "",
                modelValue: _ctx.payload.SupervisingPartner,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.payload.SupervisingPartner = $event)),
                options: _ctx.users,
                primaryKey: "EmployeeId",
                label: "Surname",
                datatype: "number",
                getLabel: (data) => data.FirstName + ' ' + data.Surname
              }, null, 8, ["modelValue", "options", "getLabel"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_nc_input, { label: "Matter Information" }, {
            default: _withCtx(() => [
              _createVNode(_component_nc_select3, {
                id: "client-list",
                multiple: "",
                modelValue: _ctx.payload.SupervisingPartner,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.payload.SupervisingPartner = $event)),
                options: _ctx.clients,
                primaryKey: "MatterId",
                label: "MatterTitle",
                groupLabel: "ClientName",
                groupChildren: "Matters",
                isGroup: true,
                datatype: "number"
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}