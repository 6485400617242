
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { PracticeGroup } from "./practice-group.model";
import PracticeGroupForm from "./practice-group-form.vue";
import MemberForm from "./member-form.vue";
import PracticeAreaForm from "../practice-areas/practice-area.form.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { practiceGroupsService } from "./practice-group.service";
import DataTableMixin from "../../../../shared/mixins/datatable.mixin";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: {
    PracticeGroupForm,
    MemberForm,
    PracticeAreaForm,
  },
})
export default class PracticeGroups extends mixins(DataTableMixin) {
  reloadStore = settingsStore.findAllPracticeGroups;
  formEvent = FormEvents.PRACTICE_GROUP_AND_AREA_FORM;

  get practiceGroups(): PracticeGroup[] {
    // return [];
    return settingsStore.practiceGroups;
  }

  created(): void {
    settingsStore.findAllPracticeGroups();
    // settingsStore.AllUsers();
    this.loadJS();
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  fields = [
    {
      title: "Name",
      name: "PracticeGroupName",
    },
    {
      title: "Supervising Partners",
      name: "PractisingPartnerName",
      center: false,
    },
    {
      title: "Practice Areas",
      name: "NoOfAreas",
    },
    {
      title: "Members",
      name: "NoOfMembers",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: any): void => {
        console.log("Edditignin", prop);
        GlobalEvent.emit(FormEvents.PRACTICE_GROUP_AND_AREA_FORM, prop);
      },
    },
    {
      name: "Members",
      iconClass: "icon ni ni-user-alt",
      showIcon: true,
      action: async (prop: any): Promise<void> => {
        const practicegroup = await practiceGroupsService.findOne(prop.PracticeGroupId);
        GlobalEvent.emit(FormEvents.MEMBER_FORM, practicegroup.Data);
      },
    },
    {
      name: "Practice Areas",
      iconClass: "icon ni ni-list-index",
      showIcon: true,
      action: async (prop: any): Promise<void> => {
        const practicegroup = await practiceGroupsService.findOne(prop.PracticeGroupId);
        GlobalEvent.emit(FormEvents.PRACTICE_AREA_FORM, practicegroup.Data);
      },
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any) => {
        this.removeTableData(practiceGroupsService.remove, prop.PracticeGroupId);
      },
    },
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any) => {

    //   }
    // }
  ];
}
