import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_finance_journal = _resolveComponent("finance-journal")!
  const _component_FinancialConfiguration = _resolveComponent("FinancialConfiguration")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: _ctx.$t('message.financeSettings.title'),
    description: _ctx.$t('message.financeSettings.desc')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_setting_item, {
        title: _ctx.$t('message.financeSettings.modules.accountPeriods.title'),
        description: _ctx.$t('message.financeSettings.modules.accountPeriods.desc'),
        iconClass: "ni-report-profit ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Account Periods' }
      }, null, 8, ["title", "description"]),
      _createVNode(_component_setting_item, {
        title: "Report Settings",
        description: "Configure Reporting Format",
        iconClass: "ni-reports-alt",
        type: 'newPage',
        link: { name: 'Report Settings' }
      }),
      _createVNode(_component_setting_item, {
        title: "Chart Of Accounts",
        description: "Setup Chart Of Accounts",
        iconClass: "ni-cc-secure",
        type: 'newPage',
        link: { name: 'Chart Of Accounts' }
      }),
      _createVNode(_component_setting_item, {
        title: "Bank Account",
        description: "Manage Bank Accounts",
        iconClass: "ni-list-thumb-alt-fill",
        formEvent: _ctx.bankAccountsFormEvent,
        type: 'newPage',
        link: { name: 'Bank Accounts' }
      }, null, 8, ["formEvent"]),
      _createVNode(_component_setting_item, {
        title: "Bank Posting Group",
        description: "Manage Bank Posting Groups",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Bank Posting Groups' }
      }),
      _createVNode(_component_finance_journal, {
        title: "Post Journals Automatically",
        description: "Enable Posting without approval",
        iconClass: "ni-notify",
        modalAction: false
      }),
      _createVNode(_component_setting_item, {
        title: "Financial Configuration",
        description: "Configure Financial Settings",
        iconClass: "ni-money",
        type: 'modal',
        handleClick: _ctx.handlePersonnelSettingsClick
      }, null, 8, ["handleClick"]),
      _createVNode(_component_setting_item, {
        title: "Setup Account Type",
        description: "Manage Account Types",
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Setup Account Types' }
      }),
      _createVNode(_component_FinancialConfiguration)
    ]),
    _: 1
  }, 8, ["title", "description"]))
}