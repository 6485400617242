import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

class TimesheetCalendarService extends BaseService {
    resource = "MatterReports";

    fetchCalendarActivities(payload : Record<string, unknown | string>) {
      return this.post("PersonalCalendar", payload)
    }
}

export const timesheetCalendarService = new TimesheetCalendarService();
