
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import { fileService, uploadService } from "./files.service";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import AddFileFormLink from "./new-file-link-form.vue";

@Options({
  components: {
    AddFileFormLink,
    VueEditor
  },
  emits: ["reloadFile"]
})
export default class AddFileForm extends mixins(ModalFormMixin) {
  modalId = "leads-add-file-form";
  formEvent = FormEvents.ADD_FILE;
  service = fileService;
  service2 = uploadService;
  previewImage: any = null;

  formData = {
    Description: "",
    FileLinkUrl: "",
    IsShared: 0,
    ModuleId: 0,
    ModuleType: 1,
    TenantFileId: 0
  };

  openLinkForm(): void {
    this.modal(this.modalId, false);
    GlobalEvent.emit(FormEvents.ADD_FILE_LINK, { id: this.payload.id });
  }

  selectImage(): void {
    let fileInput: any = this.$refs.fileInput;
    fileInput.click();
  }
  pickFile(data: any): void {
    console.log("file", data);
    this.previewImage = data.file;
  }

  async uploadLogo() {
    this.isLoading = true;
    this.formData.ModuleId = this.payload.id;
    try {
      var fdataobj = new FormData();
      fdataobj.append("uploadfile", this.previewImage);
      await this.service2.upload(fdataobj).then(response => {
        console.log("File response", response);
        this.formData.TenantFileId = response.Data.Id;
        this.service.add(this.formData).then(response => {
          this.isLoading = false;
          this.toast("success", "File upload successful");
          GlobalEvent.emit("reloadFile");
          this.modal(this.modalId, false);
          this.pickFile(null);
          this.formData = {
            Description: "",
            FileLinkUrl: "",
            IsShared: 0,
            ModuleId: 0,
            ModuleType: 1,
            TenantFileId: 0
          };
        });
      });
    } catch (error) {
      this.handleError(error);
    }
  }
}
