
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mixins, Options } from "vue-class-component";
import { ChartTypes } from "@/constants";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { AuthResponse } from "@/modules/auth/auth.model";
import { authStore } from "@/modules/auth/auth.store";
import { clientReportService } from "@/modules/clients/clients.service";
import $ from "jquery";
// import { DashboardTables } from "./dashboard-tables";

@Options({
  components: {},
})
export default class Landing extends mixins(DataTableMixin) {
  async created(): Promise<void> {
    console.log("created");

    await this.getDashboard();

    this.loadJS();
  }

  get user(): Partial<AuthResponse> {
    return authStore.auth;
  }
  calenderEntries = [];
  chartTypes = ChartTypes;

  async getDashboard(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await clientReportService.getLandingDashboard();
      const summary = response.Data;
      const { MyCalenderEntries } = summary;
      this.calenderEntries = MyCalenderEntries;
    } catch (error) {
      this.handleError(error);
    }
  }

  getImage(): string {
    let day = new Date();
    let hour = day.getHours();
    if (hour >= 0 && hour < 12) {
      return "landing-1";
    } else if (hour >= 12 && hour <= 17) {
      return "landing-2";
    } else {
      return "landing-3";
    }
  }

  toggleDropdown(): void {
    console.log("inhere");
    const a = document.getElementById("dropDo");
    a?.click();
    console.log("in]]outhere", a);
    
    // document.getElementById('dropDo')?.click()

    //   if(d != null) {
    //     d.click
    //     //  d.parentElement?.click()
    //     //  dd.classList.toggle('show')
    //     //  dd.style.display = "block"
    //     //  dd.style.width = "60vw"
    //     //  dd.style.transform = "translate3d(-125px, 33px, 0px)"
    //     //  dd.style. willChange = "transform"
    //    }
  }
}
