
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterTasksTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields: { title: string; name: string }[] = [
    ...this.fields,
    {
      title: "Title",
      name: "Title"
    },
    { title: "Assigned To", name: "TaskOwnerFullName" },
    { title: "Start Date", name: "FormattedStartDate" },
    { title: "End Date", name: "FormattedEndDate" },
    { title: "Priority", name: "PriorityDescription" },
    { title: "Status", name: "StatusDescription" },
    { title: "Description", name: "Description" }
  ];

  async created(): Promise<void> {
    try {
      const response = await mattersService.matterOverviewTasks(this.Matter.MatterId);
      this.Data = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }
}
