
import { Options, Vue } from 'vue-class-component';
import { FormEvents } from '../../../../constants';
import tlcForm from "@/modules/settings/time/timerLockConfig/timerLockConfigForm.vue";
import { settingsStore } from "../../settings.store";
import { TMEData } from './tme.model';
@Options({
    props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { 
    tlcForm 
    }
})
export default class tlcSetting extends Vue {
 formEvent = FormEvents.TLC_FORM;
 primaryKey = "Id";
settingItems = [
  'FrequencyMode', 'TriggerTime', 'MinimumTimeSheetDuration'
]
// settingObj = {
// MinimumTimeSheetDuration: 0;
// }
      get TME(): any[] {
    return settingsStore.tme;
  }
  getIndex (item: any, searchValue: string) {
      return item.findIndex((i: any) => i.SettingName == searchValue)
    }
  // mounted() { 
  //   console.log("on mounted", this.TME, "on mounted");

     
  // }

  // showForm2(aa: any){
  //         const resOptions = this.TME.map((res: any) => {
  //     return res.SettingValue.split('|');
  //  })
  //    this.tmeData.timeSummaryUsersSelect = resOptions[0].map(Number);
  //    this.tmeData.exemptedUsersSelect = resOptions[1].map(Number);
  //    console.log(this.tmeData, "DDDDDAAddd", aa)
  //    this.showForm(this.tmeData)
  // }
 
}
