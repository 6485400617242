import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { secureStorage } from "@/shared/plugins/secure-storage";
import { StoreKeys } from "../constants";

const keys = [StoreKeys.auth, StoreKeys.settings, StoreKeys.users, StoreKeys.crm, StoreKeys.matters, StoreKeys.clients];

const saveInStore = (key: StoreKeys) =>
  createPersistedState({
    key,
    paths: [key],
    storage: {
      getItem: key => secureStorage.get(key),
      setItem: (key, value) => secureStorage.set(key, value),
      removeItem: key => secureStorage.remove(key)
    }
  });

export default createStore({
  plugins: keys.map(key => saveInStore(key)),
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
