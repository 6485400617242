
import { mixins, Options } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import DataTableMixin from "../../../shared/mixins/datatable.mixin";
import { AuthResponse } from "../../auth/auth.model";
import { authStore } from "../../auth/auth.store";
import { clientReportService } from "../../clients/clients.service";
import { DashboardTables } from "../dashboard-tables";

@Options({
  components: {},
})
export default class GeneralDashboard extends mixins(DataTableMixin) {
  async created(): Promise<void> {
    await this.getDashboard();
  }

  get user(): Partial<AuthResponse> {
    return authStore.auth;
  }

  clientStats: any = {};
  matterStats: any = {};
  timeStats: any = {};
  unpaidInvoices = {};
  unbilledMatters = {};
  unbilledTime = {};
  unbilledExpense = {};
  calenderEntries = [];
  recentOpportunities = [];
  recentMatter = [];
  recentActivities = [];

  recentOpportunitiesFields = DashboardTables.recentOpportunities;
  recentMatterFields = DashboardTables.recentMatter;
  activitiesFields = DashboardTables.recentActivities;

  async getDashboard(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await clientReportService.getLandingDashboard();
      const summary = response.Data;
      const {
        ClientStats,
        MatterStats,
        MyCalenderEntries,
        RecentActivities,
        RecentFiveOpportunities,
        RecentMatters,
        TimeStats,
        UnbilledExpense,
        UnbilledMatters,
        UnbilledTime,
        UnpaidInvoices,
      } = summary;
      this.clientStats = ClientStats;
      this.matterStats = MatterStats;
      this.timeStats = TimeStats;
      this.unpaidInvoices = UnpaidInvoices;
      this.unbilledMatters = UnbilledMatters;
      this.unbilledTime = UnbilledTime;
      this.unbilledExpense = UnbilledExpense;
      this.calenderEntries = MyCalenderEntries;
      this.recentOpportunities = RecentFiveOpportunities;
      this.recentMatter = RecentMatters.RecentMatterSummaries;
      this.recentActivities = RecentActivities;
    } catch (error) {
      this.handleError(error);
    }
  }
}
