import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { StoreKeys } from "../../constants";
import { PreserveState } from "../../shared/plugins/secure-storage";
import { crmPipelineService } from "./pipeline/pipeline.service";
import { PipelineModel } from "./pipeline/pipeline.model";
// import { Contacts} from "./Contacts/contact.model";
// import { ContactService } from "./Contacts/contact.service";
import companiesService from "./companies/companies.service";
import { LeadStageName } from "./leads/model/lead-stages.model";
import { leadStagesService } from "./leads/leads.service";
import { Contacts } from "./contact/contact.model";
import { ContactService } from "./contact/contact.service";
import { LostLeadReasons, lostLeadService } from "./leads/leads-detail.service";

@Module({
  store,
  name: StoreKeys.crm,
  dynamic: true,
  namespaced: true,
  preserveState: PreserveState(StoreKeys.crm)
})
class CrmModule extends VuexModule {
  public leadPipelines: PipelineModel[] = [];
  public leadStageArray: LeadStageName[] = [];
  public leadLossReasons: LostLeadReasons[] = [];

  // public contacts: Contacts[] = [];
  public companies: [] = [];

  public contacts: Contacts[] = [];

  @Mutation
  SET_CONTACTS(data: Contacts[]) {
    this.contacts = data;
  }

  @Mutation
  SET_PIPELINE(data: PipelineModel[]) {
    this.leadPipelines = data;
  }
  // @Mutation
  // SET_CONTACT_DATA(data: Contacts[]) {
  //   this.contacts = data
  // }

  @Mutation
  SET_LEADS_STAGES_ARR(data: LeadStageName[]) {
    this.leadStageArray = data;
  }

  @Mutation
  SET_COMPANIES(data: []) {
    this.companies = data;
  }

  @Mutation
  SET_LOSS_LEAD(data: []) {
    this.leadLossReasons = data;
  }

  @Action({ commit: "SET_PIPELINE", rawError: true })
  async getSalesPipeline(): Promise<void> {
    const response = await crmPipelineService.getPipeline();
    return response.Data;
  }

  @Action({ commit: "SET_LEADS_STAGES_ARR", rawError: true })
  async getLeadStageArr(): Promise<void> {
    const response = await leadStagesService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_CONTACTS", rawError: true })
  async getContacts(): Promise<void> {
    const response = await ContactService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_COMPANIES", rawError: true })
  async getCompanies(): Promise<void> {
    const response = await companiesService.findAll();
    return response.Data;
  }

  @Action({ commit: "SET_LOSS_LEAD", rawError: true })
  async getLossLeadReasons(): Promise<void> {
    const response = await lostLeadService.findAll();
    return response.Data;
  }
}

export const crmStore = getModule(CrmModule);
