
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { MatterModel } from "../matter";
import { mattersStore } from "../matters.store";
import MatterDashboardTab from "./tabs/MatterDashboardTab.vue";
import MatterTeamTab from "./tabs/MatterTeamTab.vue";
import MatterCourtUpdatesTab from "@/modules/matters/matter/overview/tabs/MatterCourtUpdatesTab.vue";
import MatterPartiesTab from "@/modules/matters/matter/overview/tabs/MatterPartiesTab.vue";
import MatterProgressNotesTab from "@/modules/matters/matter/overview/tabs/MatterProgressNotesTab.vue";
import MatterTasksTab from "@/modules/matters/matter/overview/tabs/MatterTasksTab.vue";
import MatterBillsTab from "@/modules/matters/matter/overview/tabs/MatterBillsTab.vue";
import MatterTimesheetTab from "@/modules/matters/matter/overview/tabs/MatterTimesheetTab.vue";
import MatterExpenseTab from "@/modules/matters/matter/overview/tabs/MatterExpenseTab.vue";
import MatterMilestonesTab from "@/modules/matters/matter/overview/tabs/MatterMilestonesTab.vue";
import SwitchMatter from "../components/SwitchMatter.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: {
    MatterDashboardTab,
    MatterTeamTab,
    MatterCourtUpdatesTab,
    MatterPartiesTab,
    MatterProgressNotesTab,
    MatterTasksTab,
    MatterBillsTab,
    MatterTimesheetTab,
    MatterExpenseTab,
    MatterMilestonesTab,
    SwitchMatter,
  },
})
export default class MatterOverview extends Vue {
  formEvent = FormEvents.MATTER_FORM;

  // @Provide() Matter

  get Matter(): MatterModel | undefined {
    return mattersStore.Matters.find((x) => x.MatterId.toString() == this.$route.params.MatterId);
  }

  handleSwitchMatter() {
    GlobalEvent.emit(FormEvents.SHOW_SWITCH_MATTER_FORM, {
      MatterTitle: null,
      MatterId: null,
    });
  }
}
