
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { Contacts } from  "./contact.model";
import { GlobalEvent } from '@/shared/plugins/tiny-emitter';
import { ContactService } from "./contact.service";
import {countryService} from "../companies/companies.service";
import { ContactCategoryService } from "@/modules/settings/crm/contact-categories/contact-category.service";
// import { crmStore } from "../crm.store";


// import "vue-select/dist/vue-select.css";

@Options({})
export default class contactForm extends mixins(ModalFormMixin) {
  modalId = "contact-form";
  formEvent = FormEvents.CONTACT_FORM;
  service = ContactService;
  countries = [];
  updateState = true;
  Contacts = [];
  contactCategories = [];
  payload = {
    Birthday:"",
    City: "",
    ContactAddress: "",
    Country: "",
    Department: "",
    EmailAddress: "",
    FirstName: "",
    Id: "",
    JobTitle: "",
    OtherName: "",
    PeopleCategoryName: "",
    PeopleCategoryId: "",
    StateOrProvince: "",
    Surname: "",
    Telephone:"",
    Zipcode:"",
  };

  created(): void {
    // crmStore.getContacts();
    this.setContactCategory();
    this.setCountries();
  }
  
  setCountries() : void {
    countryService.getCountries().then((response) => {
      if(response.Success) {
        this.countries = response.Data;
      }
    })
  }

   setContactCategory() : void {
      ContactCategoryService.findAll().then((response) => {
        if(response.Success) {
          this.contactCategories = response.Data;
          // console.log(this.contactCategories, "heeerrrrreeee");
        }
      })
    }

  get contacts(): Contacts[] {
    return [];
  }

  async saveData(payload: any) : Promise<void> {
    try {
      this.isLoading = true;
      const response = await ContactService.create(payload);
      // console.log("contact saved successfully", response);
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.$emit('reloadTable','1');
      this.toast("success", this.$t('message.crm.modules.contacts.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }

   async fetchContact() : Promise<void> {
    const contactData = await ContactService.findOne(this.payload.Id);
    // console.log(contactData, "fishes");
    
    // this.payload = practicegroupdata.Data;
  }

  async updateData(payload: any) : Promise<void> {
    try {
      this.isLoading = true;
      const response = await ContactService.update(payload);
      this.isLoading= false;
      this.modal(this.modalId, false);
      this.$emit('reloadTable','1');
      // console.log("contact updated successfully", response);
      this.toast("success", this.$t('message.crm.modules.contacts.notification.Update'));
    } catch (error) {
      this.handleError(error);
       GlobalEvent.emit("contact-update");
    }
  }
}
