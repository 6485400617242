
import { mixins, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterCourtUpdatesTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields = [
    { title: "SNO", name: "SNo" },
    { title: "Judge", name: "Judge" },
    { title: "Hearing", name: "HearingDateFmt" },
    { title: "Next Date", name: "AdjournDateFmt" },
    { title: "Comment", name: "Comments" },
    { title: "Client Feedback", name: "ClientFeedback" }
  ];

  // async created(): Promise<void> {
  //   try {
  //     const response = await mattersService.matterOverviewCourtUpdates(this.Matter.MatterId);
  //     this.Data = response.Data;
  //   } catch (error) {
  //     this.handleError(error);
  //   }
  // }
}
