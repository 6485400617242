
  import {mixins, Options} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {GlobalEvent} from "@/shared/plugins/tiny-emitter";
  import {FormEvents} from '@/constants';
  import {ProgressNotesService} from './progressNotes.service'
  import {VueEditor} from "vue3-editor";
  import SearchableSelect from "../../../components/SearchableSelect.vue";

  @Options({
    components: {
      VueEditor,
      SearchableSelect
    },
    
  })
  export default class progressNotesForm extends mixins(ModalFormMixin) {
    modalId = 'show-progress-notes';
    formEvent = FormEvents.PROGRESS_NOTES_FORM;
    service = ProgressNotesService;
    primaryKey = "Id";
    resource = "CaseManager/GetSelect2Matters";
    emitName = "progressNoteSelected";
    updateState = true;

    customModel = this.payload.Id ? {MatterName : this.payload.MatterTitle, MatterId : this.payload.MatterId} : {};

    mounted(): void {
      GlobalEvent.on(this.emitName, (value: Record<string, unknown>) => {
        this.payload.MatterId = value.MatterId;
        this.payload.MatterTitle = value.MatterName;
        this.customModel = value as any;
        console.log("The matter Id is: ", this.payload.MatterId, value, "custom", this.customModel);
        });
    }

    

    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        console.log("The form data is:", this.payload);
        await ProgressNotesService.create(this.payload);
        this.isLoading = false;
        this.toast("success", this.$t('message.matters.modules.progressNotes.notification.Update'));
        GlobalEvent.emit("progress-notes-datatable-key");
        this.payload = {
          MatterId : 0,
          Notes : ""
        };
        this.modal(this.modalId, false);
      } catch (error) {
        this.handleError(error);
     }
    }

    async updateData(): Promise<void> {
      console.log(this.payload, "heeerrrrreeee");
      try {
          this.isLoading = true;
        console.log("The form data is:", this.payload);
        await ProgressNotesService.update(this.payload);
        this.isLoading = false;
        this.toast("success", this.$t('message.matters.modules.progressNotes.notification.Update'));
       GlobalEvent.emit("progress-notes-datatable-key");
        this.modal(this.modalId, false);
      } catch (error) {
        this.handleError(error);
     }
    }
  }
