import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_crm_setting = _resolveComponent("crm-setting")!
  const _component_nc_setting = _resolveComponent("nc-setting")!

  return (_openBlock(), _createBlock(_component_nc_setting, {
    title: _ctx.$t('message.crmSettings.title'),
    description: _ctx.$t('message.crmSettings.desc')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_setting_item, {
        title: _ctx.$t('message.crmSettings.modules.industrySettings.title'),
        description: _ctx.$t('message.crmSettings.modules.industrySettings.desc'),
        iconClass: "ni-home-alt",
        type: 'newPage',
        link: { name: 'Industry Settings' }
      }, null, 8, ["title", "description"]),
      _createVNode(_component_crm_setting, {
        title: "CRM Settings",
        description: "Company Contact, Lead Privacy Configuration",
        iconClass: "ni-folder-check",
        modalAction: true
      }),
      _createVNode(_component_setting_item, {
        title: "Configure Pipeline Stages",
        description: "Manage Pipelines",
        iconClass: "ni-opt-dot",
        type: 'newPage',
        link: { name: 'Pipelines' }
      }),
      _createVNode(_component_setting_item, {
        title: _ctx.$t('message.crmSettings.modules.contactCategory.title'),
        description: _ctx.$t('message.crmSettings.modules.contactCategory.desc'),
        iconClass: "ni-list-thumb-alt-fill",
        type: 'newPage',
        link: { name: 'Contact Categories' }
      }, null, 8, ["title", "description"])
    ]),
    _: 1
  }, 8, ["title", "description"]))
}