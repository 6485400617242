
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import CurrencySettingsForm from "./currencySettingsForm.vue";

@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean
  },
  components: {
    CurrencySettingsForm
  }
})
export default class CurrencySetting extends Vue {
  formEvent = FormEvents.CURRENCY_FORM;

  created(): void {
    this.loadJS();
  }

  openCurrencyForm() : void {
    GlobalEvent.emit(this.formEvent);
  }
}
