
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
// import { PracticeGroup } from "../practice-groups/practice-group.model";
import ActivityForm from './activitySettingsForm.vue';
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { ActivityModel } from "./activitytype.model";
import { activityService2 } from "./activity.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: {
    ActivityForm,
  }
})

export default class CurrencySetting extends mixins(DataTableMixin) {
  reloadStore = settingsStore.getActivityTypes;
  formEvent = FormEvents.ACTIVITY_TYPE_FORM;
  isLoading = true;

  get activityTypes() : ActivityModel[] {
    return settingsStore.activityTypes;
  }

  handleTableReady() : void {
    this.isLoading = false;
  }

  created(): void {
    settingsStore.getActivityTypes()
    .then((data) => {
      const dataList = this.filterDatalist(data)
      settingsStore.setActivityGroups(dataList);
    })
    this.loadJS();
  }

  filterDatalist(activityTypes : ActivityModel[]) : string[] {
    const activityGroupList = activityTypes.map(data => data.ActivityGroup);
    const uniqueList = activityGroupList.filter((char, index) => {
      return activityGroupList.indexOf(char) === index;
    });

    return uniqueList;
  }

  deleteData(prop : ActivityModel, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            // await this.validate();
            // this.globalLoader(true);
            const response = await activityService2.remove(prop.Id.toString());
            // this.toastSuccess(response.Message);
            // this.globalLoader(false);
            self.isLoading = false;
            self.toast("success", response.Message);
            settingsStore.getActivityTypes();
          } catch (error) {
            this.handleError(error);
          }
        }
      });
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  fields = [
    {
      title: "Name",
      name: "ActivityName"
    },
    {
      title: "Group",
      name: "ActivityGroup",
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: true,
      action: async (prop: ActivityModel, self : any): Promise<void> => {
        this.deleteData(prop, self);
      }
    }
  ];
}
