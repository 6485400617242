
  import {mixins} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import { FormEvents } from '@/constants';
  import companiesService, { countryService } from './companies.service';
import { settingsStore } from '@/modules/settings/settings.store';
import { GlobalEvent } from '@/shared/plugins/tiny-emitter';
  interface PayloadData {
    AnnualRevenue : "",
    ClientName : "",
    ContactAddress : "",
    ContactEmail : "",
    ContactPhoneNumber : "",
    Country : "",
    CountryName?: "",
    Id : "",
    Industry : "",
    IndustryDetail? : "",
    NoOfEmployees : "",
    StateOrProvince : "",
    TwitterLink : "",
    LinkedInLink : "",
    FacebookLink : "",
    WebsiteLink : "",
    ZipOrPostalCode : ""
  }
  export default class CompaniesForm extends mixins(ModalFormMixin) {
    modalId = 'companies-form';
    formEvent = FormEvents.COMPANIES_FORM;
    primaryKey = "ClientId";
    industries = settingsStore.industries;
    countries = [];
    service = companiesService;
    payload : PayloadData = {
      AnnualRevenue : "",
      ClientName : "",
      ContactAddress : "",
      ContactEmail : "",
      ContactPhoneNumber : "",
      Country : "",
      Id : "",
      Industry : "",
      NoOfEmployees : "",
      StateOrProvince : "",
      TwitterLink : "",
      LinkedInLink : "",
      FacebookLink : "",
      WebsiteLink : "",
      ZipOrPostalCode : ""
    };

    async handleModalOpen() : Promise<void> {
      this.setCountries();

      await settingsStore.findAllIndustries();
      this.industries = settingsStore.industries;
    }

    setCountries() : void {
      countryService.getCountries().then((response) => {
        if(response.Success) {
          this.countries = response.Data;
        }
      })
    }

    submitForm() : void {
      if(this.payload.Id) {
        this.updateData();
      } else {
        this.saveData();
      }
    }

    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        // await this.validate();
        // this.globalLoader(true);
        const response = await this.service.create(this.payload as unknown as Record<string, unknown>);
        this.isLoading = false;
        GlobalEvent.emit("company-datatable-key");
        this.modal(this.modalId, false);
        this.toast("success", response.Message);
      } catch (error) {
        this.handleError(error);
      }
    }

    async updateData(): Promise<void> {
      try {
        this.isLoading = true;
        // await this.validate();
        // this.globalLoader(true);
        if(this.payload.CountryName) {
          delete this.payload.CountryName;
        } 
        if(this.payload.IndustryDetail) {
          delete this.payload.IndustryDetail;
        }
        const response = await this.service.update(this.payload);
        this.isLoading = false;
        GlobalEvent.emit("company-datatable-key");
        this.modal(this.modalId, false);
        this.toast("success", response.Message);
        GlobalEvent.emit("company-update");
      } catch (error) {
        this.handleError(error);
      }
    }
  }
