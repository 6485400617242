
import { mixins, Options, Vue } from "vue-class-component";
import { httpService } from "../../../config/api.config";
import { Helpers } from "../../../shared/helpers";
import DataTableMixin from "../../../shared/mixins/datatable.mixin";
import { PracticeGroup } from "../../settings/matters/practice-groups/practice-group.model";
import { settingsStore } from "../../settings/settings.store";
import SplineChart from "@/components/NCSplineChart.vue";
import { FinanceDashboardModel } from "./finance-dashboard.model";

@Options({
  components: { SplineChart },
})
export default class FinanceDashboard extends mixins(DataTableMixin) {
  financeDashboard: Partial<FinanceDashboardModel> = {};

  get practiceGroups(): PracticeGroup[] {
    // return [];
    return settingsStore.practiceGroups;
  }

  fields = [
    {
      title: "DATE",
      name: "BillDate",
    },
    {
      title: "INVOICE #",
      name: "FeeNoteNumber",
    },
    {
      title: "INVOICE AMOUNT",
      name: "FullAmount",
    },
    {
      title: "TAX",
      name: "VATAmount",
    },
    {
      title: "AMOUNT PAID",
      name: "PaidAmount",
    },
  ];

  series: any[] = [];
  chartOptions: any = {};

  created() {
    this.handleFetchData();
  }

  async handleFetchData() {
    try {
      const response = await httpService.request().post(`api/RevenueReports/Dashboard/2019/0`);
      this.financeDashboard = Helpers.handleResponse(response).Data;

      const PreviousYear = {
        name: this.financeDashboard.MIGYearAndPrevioue?.PreviousYear[0].Year,
        data: this.financeDashboard.MIGYearAndPrevioue?.PreviousYear.map((x) => x.RevenueAmount),
      };
      const TargetYear = {
        name: this.financeDashboard.MIGYearAndPrevioue?.TargetYear[0].Year,
        data: this.financeDashboard.MIGYearAndPrevioue?.TargetYear.map((x) => x.RevenueAmount),
      };

      this.series = [PreviousYear, TargetYear];

      const chartOptions = {
        xaxis: {
          type: "month",
          categories: this.financeDashboard.MIGYearAndPrevioue?.TargetYear.map((x) => x.MonthLabel),
          lines: {
            show: false,
          },
        },
      };

      this.chartOptions = chartOptions;
    } catch (error) {
      this.handleError(error);
    }
  }

  handlePercentage(value: number) {
    const total = this.financeDashboard.PracticeGroupRevenue?.reduce((psum, x) => psum + x.RevenueAmount, 0);
    console.log({ total });
    return (value / total) * 100;
  }
}
