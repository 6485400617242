
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import CrmSettingsForm from "./crmSettingsForm.vue";
import { Prop } from "vue-property-decorator";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { settingsStore } from "../../settings.store";
@Options({
  components: { CrmSettingsForm },
})
export default class CrmSetting extends Vue {
  formEvent = FormEvents.CRM_FORM;

  @Prop() description!: string;
  @Prop() title!: string;
  @Prop() modalAction!: string;
  @Prop() iconClass!: string;

  async showCRMForm() {
    GlobalEvent.emit(this.formEvent);
  }
}
