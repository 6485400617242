export enum FormEvents {
  INDUSTRY_FORM = "show-industry-form",
  INVOICE_FORM = "show-invoice-form",
  LABEL_FORM = "show-label-form",
  CMA_FORM = "show-cma-form",
  TME_FORM = "show-tme-form",
  TLC_FORM = "show-tlc-form",
  CUSTOM_FIELDS_FORM = "show-custom-fields-form",
  ACCOUNT_PERIOD_FORM = "show-account-period-form",
  CONTACT_CATEGORY_FORM = "show-contact-category-form",
  CONTACT_FORM = "show-contact-form",
  NEW_FORM = "show-new-form",
  SIGNATURE_FORM = "show-signature-form",
  BILL_GROUP = "show-bill-group",
  ADD_BILL_GROUP = "add-bill-group",
  ADD_LEADS = "add-leads",
  ADD_NOTE = "add-note",
  ADD_TASK = "add-task",
  ADD_FILE = "add-file",
  ADD_FILE_LINK = "add-file-link",
  LOST_LEAD = "lost-lead",
  BILL_GROUP_RATE = "show-bill-group-rate",
  CLIENT_PORTAL_FORM = "show-client-portal-form",
  CRM_FORM = "show-crm-form",
  FINANCE_CONFIGURATION = "show-financial-configuration",
  TIME_KEEPER_FORM = "show-time-keeper-form",
  ADD_ROLE_FORM = "show-add-role-form",
  BANK_ACCOUNT_FORM = "show-bank-account-form",
  INVOICE_BANK_FORM = "show-invoice-bank-form",
  WORKFLOW_FORM = "show-workflow-form",
  PIPELINE_FORM = "show-pipeline-form",
  BRAND_LOGO_FORM = "show-brand-logo-form",
  PRACTICE_GROUP_AND_AREA_FORM = "show-practice-group-and-area-form",
  BILL_COVER_LETTER_TEMPLATE_FORM = "show-bill-cover-letter-template-form",
  BANK_POSTING_GROUP_FORM = "show-bank-posting-group-form",
  MEMBER_FORM = "show-member-form",
  BILL_GROUP_MEMBR_FORM = "show-bill_group_member-form",
  CURRENCY_FORM = "show-currency-form",
  TIME_FORMAT_FORM = "time-format-form",
  ACTIVITY_TYPE_FORM = "show-activity-form",
  PRACTICE_AREA_FORM = "show-practice-area-form",
  NUM_FORM = "show-numbering-form",
  REPORT_FORM = "show-REPORT-form",
  MATTER_ASSIGNMENTS = "show-matter-assigments",
  MATTER_ASSIGNMENT_FORM = "show-matter-assigment-form",
  EMPLOYEE_TO_USER_FORM = "employee-to-user-form",
  ADD_EMPLOYEE_FORM = "add-employee-form",
  CREATE_USER_FORM = "create-user-form",
  PERSONNEL_SETTINGS_FORM = "show-personnel-settings-form",
  CHART_OF_ACCOUNTS = "show-coa",
  ADD_SUBACCOUNT_FORM = "show-add-subaccount-form",
  ADD_MEMO_FORM = "add-memo-form",
  COMPANIES_FORM = "companies-form",
  SWITCH_FORM = "show-switch-form",
  SHOW_CONTACT_MEMO = "show-contact-memo",
  CMA_MATTER_FORM = "matter-acceptance-form",
  TASK_VIEW_FORM = "show-task-view",
  PROGRESS_NOTES_FORM = "show-progress-notes",
  NEW_COURT_UPDATE = "show-court-update-form",
  PROGRESS_NOTES_VIEW_FORM = "show-progress-notes-view",
  MATTER_TASK_ADD_FORM = "matter-task-add-form",
  SHOW_CLIENT_FORM = "show-client-form",
  LOST_LEAD_FORM = "show-lost-lead-form",
  MATTER_FORM = "show-matter-form",
  MERGE_MATTER_FORM = "show-merge-matter-form",
  ASSIGN_WORKFLOW_FORM = "show-assign-workflow-form",
  CLOSE_MATTER_FORM = "show-close-matter-form",
  MERGE_CLIENT_FORM = "merge-client-form",
  TIME_SHEET_FORM = "show-time-sheets-form",
  TIMESHEET_FORM = "show-timesheet-form",
  PREVIOUS_BILLS = "previous-bills",
  VIEW_COURT_UPDATE = "view-court-update",
  ADD_BILL_ITEM_FORM = "show-add-bill-item-form",
  SHOW_SWITCH_MATTER_FORM = "show-switch-matter-form",
  TIMELINE_FORM = "show-timeline-form",
}

export enum StoreKeys {
  auth = "auth",
  matters = "matters",
  clients = "clients",
  settings = "settings",
  users = "users",
  crm = "crm",
  dashboard = "dashboard",
}

export enum PageTitles {
  // MattersProgressNotes = "NextCounsel PMS | Progress Notes",
  CRMLeadbySummary = "NextCounsel PMS | Lead By Summary",
  CRMLeadbyIndustry = "NextCounsel PMS | Lead By Industry",
  CRMLeadbyOwner = "NextCounsel PMS | Lead By Owner",
  CRMLeadbyBusiness = "NextCounsel PMS | Lead By Business Unit",
  CRMLeadbySource = "NextCounsel PMS | Lead By Source",
  CRMLeadbyTags = "NextCounsel PMS | Lead By Tags",
  CRMLeadActivity = "NextCounsel PMS | Lead Activity",
  CRMLeadbyStage = "NextCounsel PMS | Lead By Lead Stage",
  CRMLostLeads = "NextCounsel PMS | Lost Leads",
  CRMCompany = "NextCounsel PMS | Companies",
  CRMCompanyView = "NextCounsel PMS | Company View",
  MatterTasks = "NextCounsel PMS | Matter - Tasks",
}

export enum ChartTypes {
  PIECHART = "pie",
  DONUTCHART = "donut",
  SEMIDONUTCHART = "semidonut",
}
