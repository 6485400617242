
import { Options, Vue } from "vue-class-component";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import CrmSetting from "./crm/crmSettings.vue";

@Options({
  components: { SettingItem, CrmSetting }
})
export default class crmSettings extends Vue {
  mounted() {
    this.loadJS();
  }
}
