
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    description: String,
  },
})
export default class Ncsetting extends Vue {}
