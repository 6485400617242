import { authStore } from "./../modules/auth/auth.store";
import { settingsStore } from "../modules/settings/settings.store";

const NCPermissions = {
  hasPermission(code: string): boolean {
    return authStore.auth.Permissions?.find(permission => permission.PermissionCode == code) ? true : false;
  },

  hasGroup(group: string): boolean {
    return authStore.auth.Permissions?.find(permission => permission.PermissionGroup == group) ? true : false;
  }
};

export default NCPermissions;
