<template>
  <select id="testing" class="">
    <!-- <slot></slot> -->
  </select>
</template>

<script>
// const $ = window.jQuery;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */

export default {
  // mounted() {

  // },

  // methods: {},
  computed: {
    isMultiple() {
      return this.$jQuery(this.$el).attr("multiple");
    }
  },
  unmounted: function() {
    this.$jQuery(this.$el).multipleSelect("destroy");
  },
  methods: {
    getConfig() {
      const config = this.config || {};
      return Object.assign(config, {
        data: this.options,
        placeholder: this.placeholder || "Select option",
        allowClear: this.allowClear
      });
    },
    selectedChanged(newValue) {
      // Check if this select-box has a multiple attribute
      // If so, compare their values directly
      if (!this.isMultiple) {
        return this.value != newValue;
      }

      // Else, do array comparison
      const value = this.value || [];
      newValue = newValue || [];
      if (value.length !== newValue.length) return true;

      const same = value.every(x => newValue.includes(x));
      return !same;
    }
  },
  mounted: function() {
    var vm = this;
    this.$jQuery(this.$el)
      // initialize multipleSelect
      .multipleSelect({
        ...this.getConfig(),
        onClick: function(data) {
          const changed = vm.selectedChanged(data.value);
          if (changed) {
            vm.$emit("update:modelValue", data.value);
            vm.$emit("change");
          }
        }
      })
      .val(this.value)
      .trigger("change")
      // handle onChange event
      .on("change", function() {
        // const value = vm.$jQuery(this).val();
        // const changed = vm.selectedChanged(value);
        // if (changed) {
        //   vm.$emit("update:modelValue", value);
        //   vm.$emit("change");
        // }
      });
  },
  props: ["options", "value", "placeholder", "config", "allowClear"],
  watch: {
    value: function(value) {
      // update value
      this.$jQuery(this.$el)
        .val(value)
        .trigger("change");
    },
    options: {
      immediate: true,
      handler(options) {
        // TODO: If there hasn't been any request to enable
        // default selection by `2020-12-08`, remove the
        // `if` condition completely.
        if (
          options &&
          options.length &&
          (this.value === null || typeof this.value === "undefined") &&
          !(this.isMultiple || this.placeholder)
        ) {
          //   this.$emit("input", options[0].id);
        }
        const element = this.$jQuery(this.$el);
        const config = this.config || {};
        // update options
        element.multipleSelect(
          Object.assign(config, {
            data: options,
            placeholder: this.placeholder || "Select option"
          })
        );

        element.val(this.value).trigger("change");
      }
    }
  }
};
</script>
