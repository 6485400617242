
import { authStore } from "@/modules/auth/auth.store";
import { Options, Vue } from "vue-class-component";

// import { AuthResponse} from '../modules/auth/auth.model';
// import { UserModel } from '../modules/authentication/auth.model';
// import { Logout } from '../modules/authentication/auth.api';

@Options({
  components: {
    // PersonnelSettingsForm
  }
})
export default class Topbar extends Vue {
  get user() {
    //   console.log(authStore.auth);
    return authStore.auth;
  }

  handlePersonnelSettingsClick(): void {
    console.log("checking");
    // GlobalEvent.emit(FormEvents.PERSONNEL_SETTINGS_FORM, {});
    this.modal("personnel-settings-form", true);
  }
}
