
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { SignatureService } from "./signature.service";
import { UploadSignatureService, } from "./signature.service";
import { settingsStore } from '../../settings.store';
import DropZone from 'dropzone-vue'
import 'dropzone-vue/dist/dropzone-vue.common.css';

// import "vue-select/dist/vue-select.css";

@Options({
  components:{DropZone}
})
export default class SignatureSettingsForm extends mixins(ModalFormMixin) {
  modalId = "signature-setting-form";
  formEvent = FormEvents.SIGNATURE_FORM;
  service = SignatureService;
  reloadStore = settingsStore.getSignatures;
  primaryKey = "Id";
  file: any;
  fileId = "";

  async uploadFile(event: any) {
    this.file = event?.target?.files[0];
    const signatureFile = new FormData();
    signatureFile.append("uploadfile", this.file);
    const response = await UploadSignatureService.upload(signatureFile);
    this.fileId = response.Data.Id;
    // console.log(response, "signatures");
  }

  

  async saveData(payload: any) {
    try {
      this.payload.fileId = this.fileId;
      this.isLoading = true;
      const response = await SignatureService.create(payload);
      this.reloadStore();
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", this.$t("message.matterSettings.modules.signatures.notification.Upload"));
    } catch (error) {
      this.handleError(error);
    }
  }
}
