
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FormEvents } from "../../../constants";
import ModalFormMixin from "../../../shared/mixins/modal.mixin";
import { BillModel } from "./models/bill.model";

@Options({})
export default class BillsModal extends mixins(ModalFormMixin) {
  modalId = "previous-bills-modal";
  formEvent = FormEvents.PREVIOUS_BILLS;

  @Prop() Bills!: BillModel[];
}
