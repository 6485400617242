
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { cmaService } from "@/modules/settings/matters/cma/cma.service";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Options, Vue } from "vue-class-component";
import CMAService, { cmaCustomField }  from "./cmaMatter.service";
import customFieldsComp from './customFields.vue'
@Options({
  components: {
   customFieldsComp
  }
  
})
export default class CMAReview extends Vue {
  formEvent = FormEvents.CMA_MATTER_FORM;
  cmaData: any;
  customFieldsData: any;

  relatedData: Record<string, unknown> = {};
  updateState = true;
  app = NCApp;
    feedbackCount: any = { awaiting: [], noConflict: [], yesConflict: [] };

  async created(): Promise<void> {
    try {
      const cmaDataId = this.$route.params.id as string;
      const res = await CMAService.findOnePending(cmaDataId);
      this.customFieldsData = (await cmaCustomField.findAll('3')).Data;
      this.cmaData = res.Data;
      this.updateState = false;
     
            this.cmaData.ClientMatterAcceptanceEmployeeFeedback.map((feedback: any) => {
        if (feedback.Status == 0) {
          this.feedbackCount.awaiting.push(feedback);
        }
        if (feedback.Status == 1) {
          this.feedbackCount.noConflict.push(feedback);
        }
        if (feedback.Status == 2) {
          this.feedbackCount.yesConflict.push(feedback);
        }
      });
    } catch (error) {
      console.log("Error occured in created lifecycle", error);
    }
  }
  async approveMatter() {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
      this.isLoading = true;
        GlobalEvent.emit('customFields-cma-submit', this.$route.params.id);
   
          } catch (error) {
            this.handleError(error);
          }
        }
      });
    }

  async disapproveMatter() {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
      this.isLoading = true;
      const res = await CMAService.disapprove(this.$route.params.id as string)
      this.isLoading = false 
      this.toast("success", res.Message);
          } catch (error) {
            // this.handleError(error);
          }
        }
      }); 
  }
       
//   get customFields(): any {
//     const cusRes: any = await cmaCustomField.findAll('3');
//     console.log(cusRes, 'CUS RES');
    
//     return cusRes.Data;
//     //   this.customFieldsData = (await cusRes).Data
//   }


  getInitials(names: string): string {
    let initials = "";
    if (names) {
      const nameList = names?.split(" ");
      nameList.forEach(name => (initials += name.charAt(0)?.toUpperCase()));

      if (initials.length > 2) {
        initials = initials.substring(0, 2);
      }
    }
    return initials;
  }
    async sendReminder() {
        this.$swal
      .fire({
        title: "Send Reminder",
        text: "Are you sure you want to send a Conflict Check Reminder to Pending Feedbacks?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            this.isLoading = true;
            const cmaDataId = this.$route.params.id as string;
            const res = await CMAService.reminder(cmaDataId);
            this.isLoading = false;
            this.toast("success", "Reminders Sent Successfully");
          } catch (error) {
            this.handleError(error);
          }
        }
      });
 
  }
}
