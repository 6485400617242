
import { Options, Vue } from "vue-class-component";
import { FinJournalService } from "./financeJournal.service";
import { settingsStore } from "../../settings.store";
@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
    switchId: String
  }
})
export default class FinanceJournal extends Vue {
  postJournal = this.globalSettings.PostJournalsAutomatically == 1 ? true: false
  get globalSettings() {
    return settingsStore.globalSettings;
  }
  mounted() {
    console.log(this.globalSettings, " this globalSettings");
  }
  async postJournalFlag(aa: any){
    console.log(aa, "chnaged flag");
  //  const res = await FinJournalService.enableJournalPosting({'enableFlag': aa})
  }
}
