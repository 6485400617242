
import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import SearchableSelect from "../../../../components/SearchableSelect.vue";
import { Watch } from "vue-property-decorator";

@Options({
  components: { SearchableSelect },
})
export default class SwitchMatter extends mixins(ModalFormMixin) {
  modalId = "switch-matter-form";
  formEvent = FormEvents.SHOW_SWITCH_MATTER_FORM;
  payload = {
    MatterTitle: null,
    MatterId: null,
  };

  @Watch("payload", { deep: true })
  handleMatterChange(data: Record<string, any>) {
    if (!data.MatterId) return;
    // console.log({ data });
    window.location.href = `/matters/matter-overview/${data.MatterId}`;
    this.modal(this.modalId, false);
  }
}
