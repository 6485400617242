
import { mixins, Options } from 'vue-class-component';
import ModalFormMixin from '@/shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import { tmeService } from './tme.service';
import { settingsStore } from '../../settings.store';
import Multiselect from '@vueform/multiselect';
import Datepicker from "vue3-date-time-picker";
import "@vueform/multiselect/themes/default.css";
import { ref } from 'vue';
@Options({
  components: {Multiselect, Datepicker}
})
export default class tlcForm extends mixins(ModalFormMixin) {
    modalId = 'tlc-settings-form';
    formEvent = FormEvents.TLC_FORM;
    main = this;
    isLoading = false;
    pageLoading = false;
    service = tmeService;
    value!: any | [];
    primaryKey = "Id";
    storePropName = settingsStore.tme;
    settingItems = [
      'FrequencyMode', 'TriggerTime', 'MinimumTimeSheetDuration'
    ]
    payloads: Record<string, unknown>[] = [];
    frequencyMode = [
      {mode : 'None', value: 'None'},
      {mode : 'Daily', value: 'Daily'},
      {mode : 'Weekly', value: 'Weekly'},
      {mode : 'Monthly', value: 'Monthly'},
    ]
        get TME(): any[] { 
        return settingsStore.tme;
      }

  get SettingOb(): Record<string, unknown>[] {
     
      const newSet: Record<string, unknown>[]  = [];
      // console.log('inhere check');
    const item = settingsStore.tme.map((x: any)=> {
       this.settingItems.map((y: any)=> {
         if (x.SettingName == y) {
           newSet.push(x)
           this.payloads = newSet;
           console.log('entering payloadXXXXXXXS',  this.payload, y);
           if(y == 'TriggerTime') {
              this.payload[y] = {hours: Number(x.SettingValue.split(':')[0]), minutes: 0}
            //  this.payload['Time'] = {hours: Number(x.SettingValue.split(':')[0]), minutes: 0}
           }else {
             this.payload[y] = x.SettingValue
           }
         }
       })
    });
    return newSet;
  }
  getIndex (item: any, searchValue: string) {
      return item.findIndex((i: any) => i.SettingName == searchValue)
    }

  async handleModalOpen(): Promise<void> {
      this.isLoading = true;
    try {
      settingsStore.getTME().then((x: any) => {
        this.SettingOb;
        this.isLoading = false});
    } catch (error) {
      this.handleError(error);
    }
  }
  async savedata(payload: any, e: Event) {
    // e.preventDefault()
    const dataTo = Object.keys(payload);
    // console.log(dataTo, 'Payload',payload, 'fullOBJ', this.payloads);
   const data = dataTo.map((n: any ) => {
     if(n == "TriggerTime"){
       this.payloads[this.getIndex(this.payloads, n)].SettingValue = `${payload[n].hours}:00`
     }else{
       this.payloads[this.getIndex(this.payloads, n)].SettingValue = payload[n];
     }
    }) 
    try {
 
     
      this.isLoading = true;
      // console.log(data,'TME VALUES',  this.payloads);
      var response = await tmeService.updateConfig(this.payloads);
      console.log('TME VALUES', response);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false)
      settingsStore.getTME();
    } catch (error) {
      this.handleError(error);
    }
  }

    // async saveConfig(vvv: any, type: string) {
   
  //     this.fieldData.map((comp: any) => {
 
  //   // this.payloadx.push({EntityId: EntityId, CustomFieldId: CustomFieldId, CustomFieldData: CustomFieldData})
 
  // })
    // console.log(this.payloadx, 'xx' );
    // const res = await cmaCustomField.addCustomValues(this.payloadx)
    // this.payloadx = [];
    // this.payloads = {};
     
    //  this.toast("success", res.Message);
    // this.updateState = false;
    // const res2 = type == 'Approve' ? await CMAService.approve(this.$route.params.id as string) : await CMAService.disapprove(this.$route.params.id as string)
    // console.log(res, res2, 'dhdh');
    //  this.toast("success", res2.Message);
  // }
}
