
import { FormEvents } from "@/constants";
import { settingsStore } from "@/modules/settings/settings.store";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import {mixins, Options} from "vue-class-component";
import { activityService, activityService2 } from "./trackedActivities.service";
import {TheMask} from "vue-the-mask";
import { authStore } from "@/modules/auth/auth.store";

@Options({
  components : {
    TheMask
  }
})
export default class TimesheetForm extends mixins(ModalFormMixin) {
  employee : any = authStore.auth.employee; // current signed-in user
  modalId = "timesheet-form";
  formEvent = FormEvents.TIMESHEET_FORM;
  resource = "CaseManager/GetSelect2Matters";
  emitName = "timesheetMatterSelect";
  activityLists : any = [];
  fullActivityList = []
  activityGroups = [];
  timeOwners : any = [];
  assignedActivityList = [];

  //curent client/matter index being used
  currentMatter = 0;

  // mask input v-models
  instantHr = 0;
  instantMn = 0;

  //new entry datalist
  entryPayloadData = [
    {
      MatterId : "",
      chargeType : 1,
      TaskCategory : "",
      TaskActivity : "",
      Narrative : "",
      TimeOwner : this.employee.EmployeeId || "",
      CurrentDate : new Date() || "",
      instantHr : 0,
      instantMn : 0,
    }
  ];

  addEntry () : void {
    console.log(this.entryPayloadData);
    const newData = {
      MatterId : "0",
      chargeType : 1,
      TaskCategory : "",
      TaskActivity : "",
      Narrative : "",
      TimeOwner : this.employee.EmployeeId || "",
      CurrentDate : new Date() || "",
      instantHr : 0,
      instantMn : 0,
    }

    this.entryPayloadData.push(newData);
  }

  removeEntry () : void {
    this.entryPayloadData.pop();
  }

  mounted() : void {
    if (this.payload.isNew && !this.employee) {
      this.employee = authStore.auth.employee;
    }

    console.log("The paload is: ", this.payload);

    GlobalEvent.on(this.emitName, (value : any) => {
      if (this.payload.isNew) {
        this.entryPayloadData[this.currentMatter].MatterId = value.MatterId;
        console.log("The duplicate matter Id: ", this.entryPayloadData[this.currentMatter].MatterId, this.currentMatter);
      } else {
        this.payload.MatterId = value.MatterId;
        console.log("The new matter Id: ", this.payload.MatterId);
      }
    });
  }

  handleModalOpen() : void {
    this.getActivityGroups();
    this.getActivityList();
    this.getTimeOwners();
  }

  // checkMaxHr2() : void {

  //   if(this.instantHr == 0 ) return;

  //   this.instantHr > 17 ? this.instantHr = 17 : this.instantHr;
  //   console.log("Update Hr is:", this.instantHr);
  // }

  // checkMaxMn2() : void {

  //   if(this.instantHr == 0 && this.instantMn == 0 ) return;

  //   this.instantMn > 59 ? this.instantMn = 59 : this.instantMn;
  //   console.log("Update Hr is:", this.instantMn);
  // }

  updateCurrentMatter(index : number) : void {
    console.log("Update current matter is called: ", index);
    this.currentMatter = index;
  }

  getActivityList() : void {
    const list : any = settingsStore.activities || [];
    console.log("ActivityList is:", list);
     
    this.fullActivityList = list;
    console.log("The activity list 2 is: ", this.fullActivityList);
  }

  getActivityGroups () : void {
    const groups : any = settingsStore.activityGroups || [];
    const returnGroups : any = [];

    groups.forEach((data : any, index : number) => {
      if(data) {
        returnGroups.push({value: index, label : data})
      }
    })
    console.log("Groups List is:", groups);

    this.activityGroups = returnGroups;
  }

  async getTimeOwners() : Promise<void> {
    try {
      const currentEmployee = {
        FirstName: this.employee.FirstName,
        Fullname: `${this.employee.FirstName} ${this.employee.Surname}`,
        Id: this.employee.EmployeeId,
        OtherName: this.employee.OtherName,
        Surname: this.employee.Surname
      }
      console.log(`The employee info is: ${this.employee}`)
      const fetchedOwners = await activityService2.getTimeOwners(this.employee.EmployeeId);
      const fetchedTimeOwners = fetchedOwners.Data.EmployeesWithNames;
      
      this.timeOwners = [currentEmployee, ...fetchedTimeOwners]
      console.log("The time owners are: ", this.timeOwners);
    } catch (error) {
      console.log("The error is: ", error);
    }
  }

  updateActivities(index : number) : void {
    const currentCategory = this.payload.isNew ? this.entryPayloadData[index || 0].TaskCategory : this.payload.TaskCategory;
    const updatedActivities : any = [];

    console.log("This fn is called", currentCategory);
    this.getActivityList();

    if(currentCategory && this.fullActivityList.length > 0) {

      this.fullActivityList.forEach((data : any) => {
        if(data.ActivityGroup === currentCategory) {
          updatedActivities.push(data);
        }
      });

      if(this.payload.isNew) {
        this.activityLists[index] = updatedActivities;
        console.log("The activity list is: ", this.activityLists);
      } else {
        this.assignedActivityList = updatedActivities;
        console.log("The activity list is: ", this.activityLists);
      }
    }
  }
  
  updateNarrative() : void {
    const currentActivity = this.payload.TaskActivity;
    const narrative = currentActivity ? currentActivity.DefaultDescription || "" : "";
    this.payload.Narrative = narrative;
  }

  chargeType = [
    {value : 1, label : "Billable"},
    {value : 0, label : "Non Billable"}
  ]

  getFormattedTime(time : number) : string {
    let timeValue = "";
    
    if(time < 60) {
      timeValue = `${time}s`
    } else if (time < 3600) {
      let n = time / 60;
      n = Math.abs(n); // Change to positive
      let minutes = (Math.floor(n));
      let seconds =((n - Math.floor(n)) * 60).toFixed(0);

      timeValue = `${minutes}mins ${seconds}s`
    } else if(time < (3600 * 60)) {
      let n = time / (60 * 60);
      n = Math.abs(n); // Change to positive
      let hours = (Math.floor(n));
      let minutes = ((n - Math.floor(n)) * 60).toFixed(0);

      timeValue = `${hours}hrs ${minutes}mins`
    }
    return timeValue;
  }

  updatePayloadDate(newDate : Date, index : number) : void {
    if(index >= 0) {
      this.entryPayloadData[index].CurrentDate = newDate
      console.log("Updated date is: ","new entry", this.entryPayloadData[index].CurrentDate);
      
    } else {
      this.payload.Date = newDate;
      console.log("Updated date is: ","assigned entry", this.payload.Date);
    }
  }

  updateHour(event : Record<string, any>, index : number) : void {
    const value = event.target.value;
    console.log("The value is: ", value);

    if(value < 0) {
      this.entryPayloadData[index].instantHr = 0;
    } else if (value > 17) {
      this.entryPayloadData[index].instantHr = 17;
    }
  }

  updateMins(event : Record<string, any>, index : number) : void {
    const value = event.target.value;
    console.log("The value is: ", value);

    if(value < 0) {
      this.entryPayloadData[index].instantMn = 0;
    } else if (value > 59) {
      this.entryPayloadData[index].instantMn = 59;
    }
  }

  async submitForm() : Promise<void> {
    console.log("The payload is: ", this.payload);
    this.isLoading = true;
    if(this.payload.isNew) {
      const entryPayloads : any[] = [];
      console.log("The payload data is: ", this.entryPayloadData);

      this.entryPayloadData.forEach((data) => {
        const {MatterId, CurrentDate, Narrative, TaskActivity, TaskCategory, TimeOwner, chargeType, instantHr, instantMn} = data;

        const newEntryPayload = {
          ManualChargeType: chargeType === 0 ? "Non Billable" : "Billable",
          ManualTimeEntryDate : CurrentDate || "",
          ManualTimeEntryHour : ((instantHr * 60) + instantMn) || "",
          ManualTimeEntryProject : MatterId || "",
          ManualTimeEntryTask : TaskActivity || "",
          ManualTimeEntryTaskCategory : TaskCategory || "",
          ManualTimeEntryTaskNarative : Narrative || "",
          ManualTimeEntryTaskUserId : TimeOwner || "",
          ManualTimeEntryProjectOLD: "",
          ManualTimeEntryClientOLD: ""
        }

        entryPayloads.push(newEntryPayload);
      })

      try {
        console.log("The new entry paloads: ", entryPayloads);
        
        const newTimeEntry = await (await activityService.makeTimeEntries(entryPayloads)).Data;
        console.log("New entry response is: ", newTimeEntry);
        this.toast("success", "New entry added successfully.");
        this.isLoading = false;
        this.entryPayloadData = [];
        this.addEntry();
        this.modal(this.modalId, false);
      } catch (error) {
        console.log("Add new entry error", error);
        this.toast("error", "Adding new entry failed");
        this.isLoading = false;
      }
    } else {
      const {MatterId, Narrative, TaskActivity, chargeType} = this.payload;

      const newEntryPayload = {
        TimeSheetDate : new Date() || "",
        TimeentryString : JSON.stringify(this.payload.checkedList) || "",
        chargetype: chargeType === 0 ? "Non Billable" : "Billable",
        matterId : MatterId || "",
        activitytypeid : TaskActivity || "",
        narrative : Narrative || "",
        newactivity : ""
      }

      try {
        const newTimeEntry = await (await activityService.makeTimeEntry(newEntryPayload)).Data;
        console.log("Assigned entry response is: ", newTimeEntry);
        this.toast("success", " Entry assigned successfully");
        this.isLoading = false;
        GlobalEvent.emit("trackedChecklist", this.payload.checkedList);
        this.modal(this.modalId, false);
        this.payload = {}
      } catch (error) {
        console.log("Assign entry error", error);
        this.toast("error", "Assigning entries failed");
        this.isLoading = false;
      }
    }
  }
}
