import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "nk-content-inner" }
const _hoisted_3 = { class: "nk-content-body" }
const _hoisted_4 = { class: "nk-block" }
const _hoisted_5 = { class: "card card-bordered" }
const _hoisted_6 = { class: "card-aside-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sidenav = _resolveComponent("sidenav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_view),
              _createVNode(_component_sidenav)
            ])
          ])
        ])
      ])
    ])
  ]))
}