
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import $ from "jquery";
// import "multiple-select/dist/multiple-select.min.css";
import "../assets/css/mutiple-select.min.css";
import "multiple-select/dist/multiple-select.js";

@Options({
  emits: ["change"]
})
export default class NCSelect3 extends Vue {
  @Prop() id!: string;
  @Prop() options!: any;
  @Prop() config!: Record<string, any>;
  @Prop() placeholder!: string;
  @Prop() showClear!: boolean;
  @Prop() primaryKey!: string;
  @Prop() label!: string;
  @Prop() groupLabel!: string;
  @Prop() getLabel!: (prop: any) => string;
  @Prop() datatype = "number";
  @Prop() isGroup = false;
  @Prop() groupChildren!: string;

  selectField: any;

  get isMultiple(): boolean {
    return this.selectField.attr("multiple") ? true : false;
  }

  @Watch("options")
  handleOptionsChange() {
    // if (!this.selectField) return;
    // console.log("options changed", this.options.length);
    this.selectField.multipleSelect("destroy");
    this.init();
  }

  mounted(): void {
    const vm = this;

    // $(document).ready(function() {
    vm.init();
    
    // });
    // console.log({ select3: $ });
  }
  init(): void {
    const vm = this;
    this.selectField = $(`#${this.id}`)
    this.selectField.multipleSelect({
      ...vm.getConfig(),
      filter: true,
      multiple: vm.isMultiple,
      onClick: function(data: any) {   
        vm.updatevalue(data);
      },
      onOpen: function(e: any) {   
      const x:any = $(`#${vm.id}`)[0].parentElement;
      const z:number = x.getBoundingClientRect().y + x.clientHeight;
      const z2: number = x.getBoundingClientRect().y - 253;
      const l: number =  Math.round(x.getBoundingClientRect().left);
      const bottomDist = window.innerHeight - x.getBoundingClientRect().bottom
        x.getElementsByClassName("ms-drop bottom")[0].style.position = "fixed";
        x.getElementsByClassName("ms-drop bottom")[0].style.left = `${l}px`
        x.getElementsByClassName("ms-drop bottom")[0].style.width = `${x.clientWidth.toFixed(0)}px`
        bottomDist >= 250  ? x.getElementsByClassName("ms-drop bottom")[0].style.top = `${z.toFixed(2)}px`: x.getElementsByClassName("ms-drop bottom")[0].style.top = `${z2.toFixed(2)}px`;
        
        

        console.log(  'Open',window.innerHeight, x.getBoundingClientRect(),  'SELDRr', ( window.innerHeight - x.getBoundingClientRect().bottom), '---', window);
      },
      onCheckAll: function() {
        vm.updatevalue({});
      },
      onUncheckAll: function() {
        vm.updatevalue({});
      },
      onAfterCreate: function() {
        vm.handleInitialValue();
      }
    })
    var selectField = document.getElementById(`${this.id}`);
  
    var observer = new MutationObserver(function() {
      vm.handleInitialValue();
    });
    
    observer.observe(selectField as Node, {
      attributes: true,
      attributeFilter: ["modelvalue"]
    });
   
  }

  updatevalue(data: any): void {
    let value;
    if (this.isMultiple) {
      value = this.selectField.multipleSelect("getSelects", "value");
    } else {
      value = data.value;
    }
    this.$emit("update:modelValue", value);
    this.$emit("selected", value);
    this.$emit("change", data);
  }

  handleInitialValue(): void {
    let initialValue = this.selectField.attr("modelvalue");

    if (!initialValue) {
      this.selectField.multipleSelect("setSelects", []);
      return;
    }

    initialValue = initialValue.split(",");

    let result;

    switch (this.datatype) {
      case "number":
        result = initialValue.map((x: string) => JSON.parse(x));
        break;
      case "boolean":
        result = initialValue.map((x: string) => JSON.parse(x.toLowerCase()));
        break;
      case "string":
        result = initialValue.map((x: string) => {
          return typeof x != 'string'? JSON.parse(x) : x
        });
        break;
      default:
        result = initialValue;
        break;
    }

    this.selectField.multipleSelect("setSelects", result);
  }

  unmounted(): void {
    this.selectField.multipleSelect("destroy");
  }

  parsedata(object: Record<string, any>, primaryKey: string): any {
    const keys = primaryKey.split(".");
    let prop = object;
    keys.forEach(primaryKey => {
      prop = prop[primaryKey];
    });

    return prop;
  }

  formatOptions(options: any[]) {
    return options.map((x: any) => {
      let text;
      const value = this.parsedata(x, this.primaryKey);
      if (typeof this.getLabel === "function") {
        // if (this.getLabel) {
        text = this.getLabel(x);
      } else {
        text = this.parsedata(x, this.label);
      }
      return {
        text,
        value
      };
    });
  }

  getConfig(): Record<string, any> {
    const config = this.config || {};
    let options = this.options;
    let groupOptions = [];

    if (this.isGroup) {
      // groupOptions.push({
      //   type: 'optgroup',
      //     label: ,
      // })

      for (const option of options) {
        groupOptions.push({
          type: "optgroup",
          label: option[this.groupLabel],
          children: this.formatOptions(option[this.groupChildren])
        });
      }

      // options.forEach((x: any) => {
      //   console.log(x);

      // });
    } else {
      if (this.primaryKey && this.label) {
        options = this.formatOptions(options);
      }
    }

    return Object.assign(config, {
      data: this.isGroup ? groupOptions : options,
      placeholder: this.placeholder || "Select option",
      showClear: this.showClear,
      singleRadio: this.isMultiple ? false : true,
      multipleWidth: null
    });
  }
}
