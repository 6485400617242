
import { mixins, Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DataTableMixin from "../../../../../shared/mixins/datatable.mixin";
import { mattersService } from "../../../matters.service";
import { MatterModel } from "../../matter";

@Options({})
export default class MatterMilestonesTab extends mixins(DataTableMixin) {
  @Prop() Matter!: MatterModel;
  Data: any[] = [];

  fields: { title: string; name: string }[] = [
    ...this.fields,
    {
      title: "Description",
      name: "Description"
    },
    { title: "Amount", name: "Amount" },
    { title: "Due Date", name: "CompletionDateFormatted" },
    { title: "Status", name: "StatusDescription" }
  ];

  async created(): Promise<void> {
    try {
      const response = await mattersService.matterOverviewMilestones(this.Matter.MatterId);
      this.Data = response;
    } catch (error) {
      this.handleError(error);
    }
  }
}
