
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
// import { invoiceService } from "./invoice.service";

// import "vue-select/dist/vue-select.css";
import { settingsStore } from '../../settings.store';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
import { AccountType, BaseAccountType } from "./chart-account.model";
import { CoaService } from "./chart-account.service";

@Options({
  components: {Multiselect},
  computed: {
    accTypeData() {
      return this.accType.filter((type: AccountType) => {
      return  type.BaseAccountTypeId == this.payload.BaseAccountTypeId
      });
    }
  }
})
export default class InvoiceSettingsForm extends mixins(ModalFormMixin) {
  modalId = "chart-accounts-form";
  formEvent = FormEvents.CHART_OF_ACCOUNTS;
//   service = invoiceService;
    primaryKey = 'ChartOfAccountId';
//   get isUpdate(): boolean {
//     if (this.payload.IndustryId) return true;
//     return false;
//   }
Indentations = 0;
AccountType = 0;
accountTypesData = [
                    {valued: 0, name: 'Heading'},
                    {valued: 1, name: 'Begin Total'},
                    {valued: 2, name: 'Posting'},
                    {valued: 3, name: 'End Total'},
                    {valued: 4, name: 'Total'}          
                  ]
 get baseAccType(): any[] {
    return settingsStore.baseAccountTypes;
  }
 get accType(): any[] {
    return settingsStore.accountTypes;
  }

    async savedata(payload: any) {
    console.log(payload, "PAYLOAD");
    const subCategory = this.accType.find((acc: AccountType) => {
     return acc.AccountTypeId === this.payload.AccountTypeId
    })
    const baseAcc = this.baseAccType.find((acc: BaseAccountType) => {
      return acc.BaseAccountTypeId === this.payload.BaseAccountTypeId
    })
    
    try {
      const data = {
        AccountName: payload.AccountName,
        AccountSubCategory: subCategory.TypeName,
        AccountType: payload.AccountType,
        AccountTypeId: payload.AccountTypeId,
        BaseAccountName: baseAcc.TypeName,
        BaseAccountTypeId: payload.BaseAccountTypeId,
        Blocked: payload.Blocked? true : false,
        ChartNumber: payload.ChartNumber,
        DirectPosting: payload.DirectPosting? true : false,
        Indentation: payload.Indentation,
        Totalling: payload.Totalling,
  } 
  
       
     console.log(data, "reformed Payload");
     
      this.isLoading = true;
      const response = this.isUpdate ? await CoaService.updateGLAccount(payload)
       :await CoaService.addGLAccount(data);
      settingsStore.getChartOfAccounts()
        //  this.$emit('submitForm', data);
      this.toast("success", response.Message);
      this.isLoading = false;
      this.modal(this.modalId, false)
    } catch (error) {
      this.handleError(error);
    }

        
      
     
   
   
  }

  created() {
      this.loadJS();
  }
}
