/* eslint-disable @typescript-eslint/no-explicit-any */
// globals.ts

import SecureLS from 'secure-ls';

export const storageKey = 'nextcounsel'; // the key used by localStorage

export const secureStorage = new SecureLS({
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_KEY,
});

const initialStoreContent = secureStorage.get(storageKey); // or localStorage.get(storageKey)
export const initialUnencryptedStorage = initialStoreContent
  ? JSON.parse(initialStoreContent)
  : {}; // parse the localStorage value to an object when it's defined, else set it as an empty object

export function PreserveState(storageKey: string): boolean {
  const initialStoreContent = secureStorage.get(storageKey);
  if (!initialStoreContent) return false;

  if (initialStoreContent) {
    const response: any = {};
    response[storageKey] = JSON.parse(initialStoreContent)[storageKey];
    return Boolean(JSON.parse(initialStoreContent));
  }

  return false;
}
