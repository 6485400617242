
import { Options, Vue } from "vue-class-component";
import tmeSetting from "@/modules/settings/time/tme/timeSummaryUsers.vue";
import tmeSettings from "./tme/timeSummaryUsers.vue";
import tlcSetting from "@/modules/settings/time/timerLockConfig/timerLockConfig.vue" 
import timeKeeper from "@/modules/settings/time/time-keeper/timeKeeper.vue";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import TimeFormatSetting from "./timeFormat/timeFormatSetting.vue";
import { settingsStore } from "../settings.store";

@Options({
  components: {
    tmeSetting,
    TimeFormatSetting,
    timeKeeper,
    SettingItem,
    tlcSetting
  }
})
export default class TimeSettings extends Vue {

 
  mounted() : void {
     settingsStore.getTME();
}
  handlePersonnelSettingsClick(): void {
    console.log("checking");
    // GlobalEvent.emit(FormEvents.PERSONNEL_SETTINGS_FORM, {});
    this.modal("tlc-settings-form", true);
  }

}
