
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { billGroupService } from "./billgroup.service";

@Options({})
export default class AddBillGroupForm extends mixins(ModalFormMixin) {
  formEvent = FormEvents.ADD_BILL_GROUP;
  modalId = "add-bill-group-form";
  service = billGroupService;
  reloadStore = settingsStore.getAllBillGroups;
}
