
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, mixins } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { ContactCategory } from "./contactCategory.model";
import contactCategorySettingsForm from "./contactCategorySettingsForm.vue";
import contactCategorySettingsUpdateForm from "./contactCategorySettingsForm.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { ContactCategoryService } from "./contact-category.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: {
    contactCategorySettingsForm,
    contactCategorySettingsUpdateForm
    }
})
export default class ContactCategoryDetails extends mixins(DataTableMixin) {
  reloadStore = settingsStore.getContactCategories;
  formEvent = FormEvents.CONTACT_CATEGORY_FORM;
  ContactCategory = [];

  get contactCategory(): ContactCategory[] {
    return settingsStore.contactCategory;
  }

  created(): void {
    settingsStore.getContactCategories();

    this.loadJS();
  }
  

  deleteContactCategory(id: string, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        if(result.isConfirmed === true){
          ContactCategoryService.removeCategory(id).then(response=>{
            if (response.Success === true) {
              try {
                self.isLoading = true;
                this.reloadStore();
                self.isLoading = false;
                self.toast("success", self.$t('message.crmSettings.modules.contactCategory.notification.Delete'));
              } catch (error) {
                self.handleError(error);
              }
            }else if (response.Success === false){
              self.isLoading = false;
              self.toast("Error", response.Message);
              }
          });
        }else{
          // console.log("delete was cancelled");
        }
      });
  }

  fields = [
    {
      title: "Name",
      name: "CategoryName"
    },

    { name: "actions", title: "", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: true,
      action: (prop: any): void => {
        // console.log("Edditignin", prop);
        // console.log(this.formEvent);
        GlobalEvent.emit(FormEvents.CONTACT_CATEGORY_FORM, prop);
      }
    },
    
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: true,
      action: (prop: any, self:any) => {
        this.deleteContactCategory(prop.Id, self);
      }
    }
  ];
}
