
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../constants";
import clientsStore from "./client.store";
import { ClientModel } from "./models";
import ClientModal from "./client.modal.vue";
import { ClientTable } from "./client-tables";
import DataTableMixin from "../../shared/mixins/datatable.mixin";
import MergeClientForm from "./merge-client.modal.vue";
import AssignWorkflowForm from "../matters/matter/components/AssignWorkflowForm.vue";

@Options({
  components: { ClientModal, MergeClientForm, AssignWorkflowForm },
})
export default class Clients extends mixins(DataTableMixin) {
  breadcrumbLinks = [{ routeName: "Home", name: "Dashboard" }, { name: "Matter", routeName: "Home" }, "Clients"];
  reloadStore = clientsStore.getClients;
  formEvent = FormEvents.SHOW_CLIENT_FORM;

  fields = ClientTable.fields;
  tableActions = ClientTable.actions;
  async created(): Promise<void> {
    this.loadJS();
    await clientsStore.getClients();
    // console.log("Clients store", clientsStore);
  }

  get clients(): ClientModel[] {
    // console.log("Clients store", clientsStore);
    return clientsStore.clients;
  }
}
