

import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

class coaService extends BaseService {
  resource = "Account";

  getCoa(): Promise<AppResponse> {
    return this.get("GetCOA");
  }
  getBaseAccountTypes(): Promise<AppResponse> {
    return this.get("GetBaseAccountTypes");
  }
  getAccountTypes(): Promise<AppResponse> {
    return this.get("GetAccountType");
  }

  updateGLAccount(reportData: any): Promise<AppResponse> {
    return this.post("UpdateGLAccount",   reportData );
  }

  addGLAccount(payload: any): Promise<AppResponse> {
    return this.post("AddGLAccount", payload);
  }

 
}

export const CoaService = new coaService();