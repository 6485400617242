import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.buttonLoading === false && _ctx.$emit('click'))),
    disabled: _ctx.buttonLoading,
    type: "button",
    class: _normalizeClass(_ctx.classes)
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.buttonLoading)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          active: true,
          loader: "spinner",
          color: "black",
          "is-full-page": false,
          height: 30
        }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}