
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import CustomFieldsSettingsForm from "@/modules/settings/matters/custom-fields/customFieldsSettingsForm.vue";

@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { CustomFieldsSettingsForm },
})
export default class CustomFieldsSetting extends Vue {
  formEvent = FormEvents.CUSTOM_FIELDS_FORM;
}
