import { AppResponse } from '@/shared/interfaces/app-response';
import { BaseService } from '@/shared/services/base-service';

export class progressNotesService extends BaseService {
  resource = 'Matter/ProgressNote'; // insert Api resource here

  findAll(): Promise<AppResponse> {
    return this.post('ProgressNotes');
  }

  // findOne(id: string): Promise<AppResponse> {
  //   return this.get(`GetContact?Contactid=${id}`);
  // }

  // getRelatedData(id: string): Promise<AppResponse> {
  //   return this.get(`RelatedData/${id}`);
  // }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }

  remove(Id: string): Promise<AppResponse> {
    return this.get(`Delete/${Id}`);
  }

}

export const ProgressNotesService = new progressNotesService();

export class progressNotesService2 extends BaseService {
  resource = 'Clients'

  findAllClients(): Promise<AppResponse> {
    return this.post('GetClients');
  }

}
export const ProgressNotesService2 = new progressNotesService2();

