/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
import { Helpers } from "../helpers";
import { GlobalEvent } from "../plugins/tiny-emitter";
import { ToastOptions, ToastTitles } from "../plugins/toast";
import dayjs from "dayjs";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import NCApp from "@/app/index";

import { useSwal } from "../../useSwal";
import { uuid } from "uuidv4";

import * as redraw from "redraw-dom";

import useVuelidate from "@vuelidate/core";
// import { AppResponse } from "../interfaces/app-response";

const GlobalMixin = defineComponent({
  data() {
    return {
      v$: useVuelidate(),
      isLoading: false,
      pageIsReady: false,
      formEvent: "",
      storePropName: null,
      onModalOpenFunc: null,
      isPageReady: false,
      $swal: useSwal(),
      app: NCApp,
      DropdownMenu: [],
    };
  },

  provide() {
    return {
      validation: this.v$,
    };
  },

  methods: {
    getError(fieldName: string): string {
      return this.errors.first(fieldName);
    },

    async validate(): Promise<void> {
      const isValid: boolean = await this.v$.$validate();
      // console.log(isValid, this.v$);
      if (!isValid) {
        Helpers.error("Complete Required Fields");
      }
    },

    handleTableReady() {
      this.isLoading = false;
    },

    toast(type: string, message: string, options?: ToastOptions) {
      this.$notify({
        title: ToastTitles[type],
        text: message,
        type: type,
        ...options,
      });
    },

    handleError(error: any) {
      this.isLoading = false;
      if (!error.response) {
        console.log("error", error);
        return this.toast("error", error.message || error.Message);
      }
      const response = error.response.data;
      console.log("error", response);
      this.toast("error", response.Message || response.message);
    },

    // onModalOpen(storePropName: any, onOpen: any) {
    //   storePropName == null ? console.log('check = fetchData'):console.log('checkOnOpen = Data exist');
    //   console.log('modal is now opened ----', storePropName, onOpen);
    //   onOpen != null ? onOpen() : console.log('no func found');
    // },

    modal(modalId: string, show = true) {
      const element: any = $(`#${modalId}`);
      // if (this.onModalOpened && typeof this.onModalOpened === 'function'){
      //   this.onModalOpened()
      // }

      // show ?  this.onModalOpen(this.storePropName, this.onModalOpenFunc) : console.log('modal is now closing');
      element.modal(show ? "show" : "hide");
      // main.$(`#${modalId}`).modal(show ? "show" : "hide");
      // this.$jQuery(`#${modalId}`).modal(show ? "show" : "hide");
    },

    showForm(item: any) {
      console.log("sending event", this.formEvent, item);
      GlobalEvent.emit(this.formEvent, item);
    },

    fetchField(object: Record<string, any>, fieldName: string): any {
      const keys = fieldName.split(".");
      let prop = object;
      keys.forEach((fieldName) => {
        prop = prop[fieldName];
      });

      return prop;
    },
    // Format data
    formatDate(date: string, format = "DD MMM YYYY") {
      return dayjs(date).format(format);
    },

    async loadJS(source?: string) {
      const app: any = this;
      this.isPageReady = false;
      $(document).ready(function () {
        const main = document.createElement("script");
        // const bundle = document.createElement('script');
        // bundle.setAttribute('src', '/assets/js/bundle.js?ver=2.7.0');
        // main.setAttribute('src', '/assets/js/scripts.js?ver=2.7.0');
        main.setAttribute("src", "/assets/js/main.js");
        main.setAttribute("id", "main-js");
        try {
          // $('#bundle-js').remove();
          setTimeout(() => {
            window.jQuery("script[id=main-js]").remove();
            const el = document.querySelector("#app");
            redraw(el);
            // document.body.appendChild(bundle);
            document.body.appendChild(main);
            app.isPageReady = true;
          }, 1000);
        } catch (error) {
          console.log({ loadJsError: error });
          // document.body.appendChild(bundle);
          document.body.appendChild(main);
          app.isPageReady = true;
        }
      });
    },

    async loadDependencyJS(srcs: string[]) {
      let index = 0;
      for (const item of srcs) {
        const dependency = document.createElement("script");
        dependency.setAttribute("src", item);
        dependency.setAttribute("id", `${index}_dependency`);
        $(`${index}_dependency`).remove();
        // this.$jQuery(`${index}_dependency`).remove();
        setTimeout(() => {
          document.body.appendChild(dependency);
        }, 1100);
        index += 1;
      }
    },

    removeTableData(func: any, id: number) {
      console.log("remove event", id);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const main: any = this;
      main
        .$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result: { value: boolean }) => {
          if (result.value) {
            this.isLoading = true;
            try {
              const response = await func(id);
              console.log({ response });
              main.$swal("Deleted!", "Item has been deleted", "success");
              // await this.reloadStore();
              this.isLoading = false;
              this.toast("success", response.Message);
            } catch (error) {
              this.isLoading = false;
              this.handleError(error);
            }
          }
        });
    },

    getInitials(name: string) {
      const data: any[] = name.split(" ");
      let initials: string;

      if (data.length > 1) {
        initials = data.shift().charAt(0) + data.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }

      return initials.toUpperCase();
    },
    getGreeting(): string {
      const day = new Date();
      const hour = day.getHours();
      if (hour >= 0 && hour < 12) {
        return "Good Morning";
      } else if (hour >= 12 && hour <= 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },

    moneyFormat(number: number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    customId() {
      return uuid();
    },
  },

  validations() {
    return {};
  },
});

export default GlobalMixin;
