
import { FormEvents } from "@/constants";
import { Vue, Options } from "vue-class-component";
import {activityService} from "./trackedActivities.service";
import TimesheetForm from "./timesheetForm.vue";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Tippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css'

@Options({
  components: {
    TimesheetForm,
    Tippy
  }
})
export default class TrackedActivities extends Vue {
  formEvent = FormEvents.TIMESHEET_FORM;

  userEntries : any = [];
  checkedList : any = [];
  filterDate = new Date();
  showAssigned = false;
  timeFilter = 1;
  isLoading = false;
  entryDate = "" || new Date();

  timeFilters = [
    {value : 1, label : "Above a second"},
    {value : 20, label : "Above 30 second"},
    {value : 300, label : "Hide less than 5 minutes"},
    {value : 600, label : "Hide less than 10 minutes"},
  ]

  created() : void {
    this.loadJS();
    GlobalEvent.on("trackedChecklist", (checkList : any) => {
      console.log("This emit was called", checkList, this.userEntries);
      this.checkedList = [];
      this.getFilteredEntries();
      // for (let i = 0; i < this.userEntries.length; i++) {
      //   const entry = this.userEntries[i].EntryDetails;
      //   for (let x = 0; x < entry.length; x++) {
      //     const item = entry[x];
      //     for (let a = 0; a < checkedList.length; a++) {
      //       const checkedItem = checkedList[a];
      //       if(checkedItem.TimeEntryId === item.TimeEntryId) {
      //         console.log("The matched detail is: ", checkedItem);
      //         this.userEntries[i]?.EntryDetails.splice(x, 1);
      //         break;
      //       }
      //     }
      //   }
      // }
    })
  }
  
  async getTimeEntries(data : any) : Promise<void> {
    this.isLoading = true;
    try {
      const entries = await activityService.getTrackedActivities(data);
      this.userEntries = entries.Data;
      this.entryDate = this.filterDate;
      console.log("The user entries are: ", this.userEntries);
    } catch(err) {
      console.log("Tracked activities error: ", err);
    }
    this.isLoading = false;
  }

  assignTimeEntry(newTask : never) : void {
    this.openForm(false, newTask);
  }

  async deleteTimeEntry(task : any, innerIndex : number, outerIndex : number) : Promise<void> {
    try {
      await activityService.deleteEntry(task.TimeEntryId);
      console.log("Outer n inner indices are:", innerIndex, outerIndex);
      
      this.userEntries[outerIndex].EntryDetails.splice(innerIndex, 1);
      console.log("Deleted Data: ", this.userEntries[outerIndex].EntryDetails[innerIndex]);
      
    } catch (error) {
      console.log("Couldn't delete entry: ", error);
      this.toast("error", "Error occured - could not delete time entry");
    }

  }

  openForm(isNew : boolean, singleEntry? : any) : void {
    console.log("The checked list is: ", this.checkedList, isNew);
    if(isNew) {
      GlobalEvent.emit(this.formEvent, {isNew});
    } else if(singleEntry) {
      let timeTotal = singleEntry.TimeDifference || 0;
        
      if(timeTotal > 60) {
        GlobalEvent.emit(this.formEvent, {isNew, timeTotal, checkedList : [singleEntry]});
      } else {
        this.toast("error", "Total time is less than 1 minute");
      }
    } else if(this.checkedList.length > 0) {
      let timeTotal = 0;
      this.checkedList.forEach((data : any) => timeTotal += data.TimeDifference)
      if(timeTotal > 60) {
        GlobalEvent.emit(this.formEvent, {isNew, timeTotal, checkedList : this.checkedList});
      } else {
        this.toast("error", "Total time is less than 1 minute");
      }
    }
  }

  getFormattedTime(time : number) : string {
    let timeValue = "";
    if(time < 60) {
      timeValue = `${time}s`
    } else if (time < 3600) {
      let n = time / 60;
      n = Math.abs(n); // Change to positive
      let minutes = (Math.floor(n));
      let seconds = ((n - Math.floor(n)) * 60).toFixed(0);

      timeValue = `${minutes}mins ${seconds}s`
    } else if(time < (3600 * 60)) {
      let n = time / (60 * 60);
      n = Math.abs(n); // Change to positive
      let hours = (Math.floor(n));
      let minutes = ((n - Math.floor(n)) * 60).toFixed(0);

      timeValue = `${hours}hrs ${minutes}mins`
    }
    return timeValue;
  }
  
  getDateIntervals() : any {
    if(this.filterDate) {
      const date = new Date(this.filterDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      let startDate = new Date(`${year}-${month}-${day}`).getTime();
      let endDate = new Date(`${year}-${month}-${day + 1}`).getTime() - 1000;
      console.log("The date is: ", date, year, month, day, startDate, endDate);
      return {startDate, endDate}
    }
  }

  updateFilterDate(newDate : any) : void {
    this.filterDate = newDate;
  }

  getFilteredEntries() : void {
    const {startDate, endDate} = this.getDateIntervals();
    const payload = {
      startdate : startDate,
      enddate: endDate,
      categorytype: "process",
      showassigned: this.showAssigned,
      mintimesize: this.timeFilter,
    }

    console.log("The entry filters are: ", payload);
    this.getTimeEntries(payload);
  }

  updateGroupCheck(event : any) : void {
    const isChecked = event.target.checked;
    const checkValue = event.target._value;
    console.log("The returned value is: ", event, isChecked, checkValue);

    if(isChecked) {
      checkValue.EntryDetails?.forEach((element : any) => {
        if(element.TimeSheetId === null) this.checkedList.push(element);
      });
      console.log("Current checklist is: ", this.checkedList.length);
    } else {
      for (let i = this.checkedList.length - 1; i >= 0; --i) {
        const elem = this.checkedList[i];
        if(elem.ApplicationName === checkValue.EntryName) {
          this.checkedList.splice(i, 1)
        }
      }
    }
  }
}
