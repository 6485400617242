
  import {mixins} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {FormEvents} from '@/constants';

  export default class TimelineForm extends mixins(ModalFormMixin) {
    modalId = 'show-timeline-form';
    formEvent = FormEvents.TIMELINE_FORM;

    getFormattedTime(time : number) : string {
      let timeValue = "";
      if(time < 60) {
        timeValue = `${time}s`
      } else if (time < 3600) {
        let n = time / 60;
        n = Math.abs(n); // Change to positive
        let minutes = (Math.floor(n));
        let seconds = ((n - Math.floor(n)) * 60).toFixed(0);

        timeValue = `${minutes}mins ${seconds}s`
      } else if(time < (3600 * 60)) {
        let n = time / (60 * 60);
        n = Math.abs(n); // Change to positive
        let hours = (Math.floor(n));
        let minutes = ((n - Math.floor(n)) * 60).toFixed(0);

        timeValue = `${hours}hrs ${minutes}mins`
      }
      return timeValue;
    }
  }
