
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { roleService } from "./role.service";
import { settingsStore } from "../settings.store";
// import { settingsStore } from '../../settings.store';

@Options({})
export default class AddRole extends mixins(ModalFormMixin) {
  modalId = "add-role-form";
  formEvent = FormEvents.ADD_ROLE_FORM;
  service = roleService;
  reloadStore = settingsStore.findAllRoles;
}
