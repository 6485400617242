<template>
  <div class="report-table">
    <div class="nk-block">
      <div class="card card-bordered card-stretch">
        <div class="card-inner-group">
          <div class="card-inner position-relative card-tools-toggle">
            <div class="card-title-group">
              <div class="card-tools">
                <div class="form-inline flex-nowrap gx-3">
                  <span class="preview-title-lg overline-title">REPORT</span>
                </div>
              </div>
              <div class="card-tools mr-n1">
                <export-button :table="$refs.tableContainer" :id="id" :export-options="['csv', 'excel', 'pdf']" />
              </div>
            </div>
          </div>
          <div class="card-inner p-0" ref="tableContainer">
            <table class="nk-tb-list nk-tb-ulist no-footer" data-ordering="false" ref="table" v-if="showTable" :id="id">
              <thead>
                <tr class="nk-tb-item nk-tb-head font-weight-bold bg-azure-dim">
                  <th
                    v-for="(field, key) in fields_"
                    :key="key"
                    class="nk-tb-col"
                    :class="{ 'exclude-export': field.hide === true }"
                    :data-priority="1"
                  >
                    <span class="sub-text" v-show="field.hide !== true">
                      {{ field.title || field.name }}
                    </span>
                  </th>
                  <th class="nk-tb-col nk-tb-col-tools text-right exclude-export">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="btn btn-xs btn-outline-light btn-icon dropdown-toggle text-danger bg-white"
                        data-toggle="dropdown"
                        data-offset="0,5"
                        aria-expanded="false"
                        ><em class="icon ni ni-plus"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-md dropdown-menu-right" style="">
                        <ul class="link-tidy sm no-bdr">
                          <li v-for="(field, index) in fields_" :key="index">
                            <div class="custom-control custom-control-sm custom-checkbox" @click="toggleField(index)">
                              <input type="checkbox" class="custom-control-input" :checked="field.hide !== true" />
                              <label class="custom-control-label" for="m1">{{ field.title || field.name }}</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index" class="nk-tb-item">
                  <td v-for="(field, key) in fields_" :key="index + key" class="nk-tb-col tb-col-lg">
                    <span v-show="field.hide !== true">
                      <template v-if="isSlot(field)">
                        <slot :name="field.name" v-bind="row" />
                      </template>
                      <template v-else>
                        <span :class="field.className || ''">
                          {{ getRowData(row, field.name) }}
                        </span>
                      </template>
                    </span>
                  </td>
                  <td class="nk-tb-col nk-tb-col-tools"></td>
                </tr>
              </tbody>
            </table>
            <div v-if="tableData.length < 1" class="no-data-img">
              <img style="width : 400px; height : auto" src="../assets/nodata.svg" alt="no data" />
              <span style="margin-top : 10px; font-weight : 600; font-size : 17px">No Record found.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datatableMixin from "./datatable2";
export default {
  computed: {
    options() {
      const hidePagination = this.hidePagination;
      return {
        hidePagination,
        isReport: true,
        order: []
      };
    }
  },
  data() {
    return {
      fields_: [],
      showTable: true,
      tableKey: 0,
      table: null,
      tableRef: null
    };
  },
  methods: {
    getRowData(object, key) {
      const keys = key.split(".");
      let prop = object;
      keys.forEach(key => {
        prop = prop[key];
      });

      return prop;
    },
    isSlot(field) {
      return field.name in this.$slots;
    },
    toggleField(index) {
      const field = this.fields_[index];
      field.hide = !field.hide;
      this.showTable = false;
      this.showTable = true;
    },
    filterFields() {
      return this.fields_.filter(field => field.hide === false);
    }
  },

  mounted() {
    const table = this.$refs.table;
    this.table = this.init(table, this.options);
    // this.tableRef = table;
  },
  mixins: [datatableMixin],
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      }
    },
    fields: {
      type: Array,
      required: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler(fields) {
        this.fields_ = fields;
      }
    }
  }
};
</script>

<style scoped>
.report-table ::v-deep .export-btns {
  position: fixed;
  top: -200%;
}
.report-table ::v-deep .hide {
  width: 0;
}
.report-table .tb-tnx-head {
  text-transform: none;
  letter-spacing: 0.5px;
}
.hide {
  display: hidden;
}
</style>
