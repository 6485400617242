
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { settingsStore } from "@/modules/settings/settings.store";
import { Options, Vue } from "vue-class-component";
import { Provide } from "vue-property-decorator";
import { formActionsService, leadDetailService } from "./form-actions.service";
import { leadsService } from "./leads.service";
import { leadsResourceService } from "./leads-detail.service";
import { fileService } from "./files.service";
import newLeadsForm from "./new-leads-form.vue";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import AddNoteForm from "./new-note-form.vue";
import AddTaskForm from "./new-task-form.vue";
import AddFileForm from "./new-file-form.vue";
import LostLeadForm from "./lost-lead-form.vue";
import { crmStore } from "../crm.store";
@Options({
  components: {
    newLeadsForm,
    AddNoteForm,
    AddTaskForm,
    AddFileForm,
    LostLeadForm,
    CircleProgress,
  },
})
export default class LeadsDetailPage extends Vue {
  @Provide() handleDelete = leadsService;
  formEvent = FormEvents.ADD_LEADS;
  leadNotes: unknown[] = [];
  service = leadsService;
  leadsData: any;
  globalTasks: any;
  notes: any;
  files: any;
  updateState = true;
  updateNote = false;
  hasNote = true;
  hasTask = true;
  percent = 0;
  imgLink = "";
  app = NCApp;

  payload = {
    leadStageAt: null,
  };

  updatePayload = {
    Id: 0,
    LeadStageId: 0,
  };

  findFilesPayload = {
    ModuleId: 0,
    ModuleType: 1,
  };

  initiateClientMatterAcceptance() {
    if (this.leadsData.LeadStage.StageOrder > 198) {
      console.log("NOt initiated here");
    }
    console.log("Initiated here");
  }

  openLeadsForm(): void {
    GlobalEvent.emit(this.formEvent);
  }

  openUpdateLeadsForm(): void {
    GlobalEvent.emit(this.formEvent, this.leadsData);
  }

  openNoteForm(): void {
    GlobalEvent.emit(FormEvents.ADD_NOTE, { id: this.leadsData.Id });
  }

  openTaskForm(): void {
    GlobalEvent.emit(FormEvents.ADD_TASK, { id: this.leadsData.Id });
  }

  openFileForm(): void {
    GlobalEvent.emit(FormEvents.ADD_FILE, { id: this.leadsData.Id });
  }

  openLossLeadForm(): void {
    GlobalEvent.emit(FormEvents.LOST_LEAD, { id: this.leadsData.Id });
  }

  setStatusToWon(id: string) {
    this.$swal
      .fire({
        title: "Approve Opportunity?",
        text: "Approve Opportunity and set as Won?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Set as Won",
      })
      .then(async (result: { value: boolean }) => {
        formActionsService.setLeadStatusToWon(id).then((response) => {
          if (response.Data.Success == true) {
            this.responseAlert("Success", response.Data.Message, "success");
          }

          if (response.Data.Success == false) {
            this.responseAlert("Error", response.Data.Message, "error");
          }
        });
        return;
      });
  }

  deleteNote(id: string) {
    this.$swal
      .fire({
        title: "Delete Note?",
        text: "Are you sure you want to delete this note?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      })
      .then(async (result: { value: boolean }) => {
        const response = leadsResourceService.deleteNote(id);
        console.log("response is =>", response);
        this.isLoading = false;
        this.toast("success", "Memo deleted Successfully");
        await this.delay(1000);
        this.getLeadNotes();
        return;
      });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  responseAlert(title: string, message: string, icon: string) {
    this.$swal.fire({
      title: title,
      text: message,
      icon: icon,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    });
  }

  async getLeadNotes(): Promise<void> {
    try {
      this.updateNote = true;
      const response = await formActionsService.findOne(this.$route.params.id as string);
      const newNote = response.Data.CRMNotes;
      this.leadsData.CRMNotes = newNote;
      this.updateNote = false;
    } catch (error) {
      this.handleError(error);
      this.updateNote = false;
    }
  }
  async updateStage(leadstage: any) {
    this.updatePayload = {
      Id: this.leadsData.Id,
      LeadStageId: leadstage.Id,
    };
    await formActionsService.updateLeadStatus(this.updatePayload);
    this.updateState = true;
    const response = await formActionsService.findOne(this.$route.params.id as string);
    this.leadsData = response.Data;
    const res = await leadDetailService.findOne(this.$route.params.id as string);
    this.globalTasks = res.Data;
    this.updateState = false;
  }

  async created() {
    const response = await formActionsService.findOne(this.$route.params.id as string);
    this.leadsData = response.Data;
    const res = await leadDetailService.findOne(this.$route.params.id as string);
    this.findFilesPayload.ModuleId = this.leadsData.Id;
    const fileRes = await fileService.findAllFiles(this.findFilesPayload);
    this.files = fileRes.Data;
    this.imgLink = this.files.FileLinkUrl;
    this.globalTasks = res.Data;
    this.updateState = false;

    console.log("=======>", this.leadsData);
  }

  mounted(): void {
    GlobalEvent.on("switchLeads", async (emitData: string) => {
      this.updateState = true;
      const response = await formActionsService.findOne(emitData);
      this.leadsData = response.Data;
      const res = await leadDetailService.findOne(emitData);
      this.globalTasks = res.Data;
      this.updateState = false;
    });

    GlobalEvent.on("detailFormEdit", async (_: unknown) => {
      this.updateState = true;
      const response = await formActionsService.findOne(this.$route.params.id as string);
      this.leadsData = response.Data;
      const res = await leadDetailService.findOne(this.$route.params.id as string);
      this.globalTasks = res.Data;
      this.updateState = false;
    });

    GlobalEvent.on("reloadNote", async (_: unknown) => {
      this.updateNote = true;
      const response = await formActionsService.findOne(this.$route.params.id as string);
      const newNote = response.Data.CRMNotes;
      this.leadsData.CRMNotes = newNote;
      this.updateNote = false;
    });

    GlobalEvent.on("reloadLeadsDetail", async (_: string) => {
      this.updateState = true;
      const response = await formActionsService.findOne(this.$route.params.id as string);
      this.leadsData = response.Data;
      const res = await leadDetailService.findOne(this.$route.params.id as string);
      this.globalTasks = res.Data;
      this.updateState = false;
    });

    GlobalEvent.on("reloadTask", async (_: string) => {
      this.updateState = true;
      const response = await formActionsService.findOne(this.$route.params.id as string);
      this.leadsData = response.Data;
      const res = await leadDetailService.findOne(this.$route.params.id as string);
      this.globalTasks = res.Data;
      this.updateState = false;
    });

    GlobalEvent.on("reloadFile", async (_: string) => {
      this.updateState = true;
      this.findFilesPayload.ModuleId = this.leadsData.Id;
      const fileRes = await fileService.findAllFiles(this.findFilesPayload);
      this.files = fileRes.Data;
      this.imgLink = this.files.FileLinkUrl;
      this.updateState = false;
    });

    GlobalEvent.on("reloadFileLink", async (_: string) => {
      this.updateState = true;
      this.findFilesPayload.ModuleId = this.leadsData.Id;
      const fileRes = await fileService.findAllFiles(this.findFilesPayload);
      this.files = fileRes.Data;
      this.imgLink = this.files.FileLinkUrl;
      this.updateState = false;
    });
  }

  get leadStages() {
    return settingsStore.leadStages;
  }

  get leadStageArray() {
    return crmStore.leadStageArray.filter((lead: any) => {
      return lead.StageOrder <= 198;
    });
  }
}
