import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-gs" }
const _hoisted_2 = { class: "slot__option__desc" }
const _hoisted_3 = { class: "slot__option__title" }
const _hoisted_4 = { class: "slot__option__small" }
const _hoisted_5 = { class: "slot__option__small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searchable_select = _resolveComponent("searchable-select")!
  const _component_nc_modal3 = _resolveComponent("nc-modal3")!

  return (_openBlock(), _createBlock(_component_nc_modal3, {
    id: _ctx.modalId,
    title: "Switch Matter",
    customClass: "modal-xl",
    iconClass: "ni-report-profit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_searchable_select, {
          style: {"width":"100%"},
          customVmodel: { MatterName: _ctx.payload.MatterTitle, MatterId: _ctx.payload.MatterId },
          title: "Matter/Client",
          label: 'MatterName',
          trackBy: 'MatterId',
          resource: "CaseManager/GetSelect2Matters",
          emitName: "matterSelected",
          onMatterSelected: _ctx.handleMatterChange
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(option.MatterName), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(option.MatterCode), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(option.ClientName), 1)
            ])
          ]),
          _: 1
        }, 8, ["customVmodel", "onMatterSelected"])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}