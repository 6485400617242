import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

export class TaskWorkFlowService extends BaseService {
  resource = "TaskWorkFlowTemplates";

  getTaskWorkflow(): Promise<AppResponse> {
    return this.get(`GetAll`);
  }
}

export const taskWorkFlowService = new TaskWorkFlowService();
