<template>
  <!-- main @s -->
  <!-- wrap @s -->
  <div class="nk-wrap nk-wrap-nosidebar">
    <!-- content @s -->
    <div class="nk-content">
      <div class="nk-block nk-block-middle wide-md mx-auto">
        <div class="nk-block-content nk-error-ld text-center">
          <img class="nk-error-gfx" src="/images/gfx/error-403.svg" alt="" />
          <div class="wide-xs mx-auto">
            <h3 class="nk-error-title">Oops! Access Denied!</h3>
            <p class="nk-error-text">
              We are very sorry for inconvenience. It looks like you do not have permission to visit this page.
            </p>
            <router-link to="/" class="btn btn-lg btn-primary mt-2">Back To Home</router-link>
          </div>
        </div>
      </div>
      <!-- .nk-block -->
    </div>
    <!-- wrap @e -->
  </div>
  <!-- content @e -->
  <!-- main @e -->
</template>

<style scoped>
.nk-wrap-nosidebar .nk-content {
  padding: 0 !important;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}
.nk-wrap {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}
</style>
