
import { Vue, Options } from "vue-class-component";
import {activityService} from "../trackedActivities/trackedActivities.service";
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { FormEvents } from "@/constants";
import TimelineForm from "./timelineForm.vue";

@Options({
  components: {
    FullCalendar,
    TimelineForm
  },
})
export default class TimeLineOverView extends Vue {
  userEntries : any = [];
  checkedList : any = [];
  filterDate = new Date();
  showAssigned = false;
  timeFilter = 1;
  isLoading = false;
  entryDate = "" || new Date();
  customKeys = "ck-1";

  calendarOptions : any = {
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: 'timeGridDay',
    initialDate: this.filterDate,
    allDaySlot: false,
    eventClick: this.handleDateClick,
    events: [],
  }

  updateCalendarOptions(events : Record<string, unknown>[], date : string | Date,) : void {
    if(events && events.length) {
      this.calendarOptions = {...this.calendarOptions, events}

      console.log("This event runs", this.calendarOptions);
    }

    if(date) {
      this.calendarOptions = {...this.calendarOptions, initialDate : date}

      console.log("This date runs", this.calendarOptions);
    }
    this.customKeys = this.customKeys === 'ck-2' ? 'ck-1' : 'ck-2';
  }

  timeFilters = [
    {value : 1, label : "Above a second"},
    {value : 20, label : "Above 30 second"},
    {value : 300, label : "Hide less than 5 minutes"},
    {value : 600, label : "Hide less than 10 minutes"},
  ]

  handleDateClick(data : any) : void {
    console.log("The data passed is: ", data);
    GlobalEvent.emit(FormEvents.TIMELINE_FORM, data.event?._def?.extendedProps?.data || {})
  }

  async getTimeEntries(data : any) : Promise<void> {
    this.isLoading = true;
    try {
      const entries = await activityService.getTrackedActivities(data);
      console.log("The fetched entries are: ", entries.Data);
      if(entries.Data.length) {
        const newArray : any[] = [];

        for (let i = 0; i < entries.Data.length; i++) {
          const element = entries.Data[i];

          let details : any[] = element.EntryDetails;

          details.forEach(data => {
            const description = data.Description.length < 81 ? data.Desciption : (data.Description.slice(0, 81) + "...");
            const title = `${data.ApplicationName} - ${description}`;
            const date = new Date(data.StartTimeFromEpochTime).toISOString();
            newArray.push({title, date, data});
          })
        }

        console.log("The user entries are: ", newArray);
        this.updateCalendarOptions(newArray, this.filterDate);
      }
    } catch(err) {
      console.log("Tracked activities error: ", err);
    }
    this.isLoading = false;
  }

  getDateIntervals() : any {
    if(this.filterDate) {
      const date = new Date(this.filterDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      let startDate = new Date(`${year}-${month}-${day}`).getTime();
      let endDate = new Date(`${year}-${month}-${day + 1}`).getTime() - 1000;
      console.log("The date is: ", date, year, month, day, startDate, endDate);
      return {startDate, endDate}
    }
  }

  updateFilterDate(newDate : any) : void {
    this.filterDate = newDate;
  }

  getFilteredEntries() : void {
    const {startDate, endDate} = this.getDateIntervals();
    const payload = {
      startdate : startDate,
      enddate: endDate,
      categorytype: "day",
      showassigned: this.showAssigned,
      mintimesize: this.timeFilter,
    }

    console.log("The entry filters are: ", payload);
    this.getTimeEntries(payload);
  }
}
