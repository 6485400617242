import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { StoreKeys } from '../../constants';
import { PreserveState } from '../../shared/plugins/secure-storage';
import { AuthResponse, Tenantconfiguration } from './auth.model';
import { UserLoginDto } from './dto/login.dto';
import AuthService from './auth.service';
import { TenantConfiguration } from '../../app/app.model';

@Module({
  store,
  name: StoreKeys.auth,
  dynamic: true,
  namespaced: true,
  preserveState: PreserveState(StoreKeys.auth),
})
class AuthModule extends VuexModule {
  public auth: Partial<AuthResponse> = {};
  public tenantConfiguration: Partial<TenantConfiguration> = {};


 
  @Mutation
  LOGGED_IN(data: AuthResponse) {
    this.auth = data;
  }

  @Mutation
  LOGOUT() {
    this.auth = {};
  }

  @Mutation
  SET_CONFIGURATION(data: TenantConfiguration) {
    this.tenantConfiguration = data;
  }

  @Action({ commit: 'LOGGED_IN', rawError: true })
  async login(userLoginDto: UserLoginDto): Promise<AuthResponse> {
    const response = await AuthService.login(userLoginDto);
    return response.Data;
  }
}

export const authStore = getModule(AuthModule);
