import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chart",
  class: "bg-lighter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_apex_charts = _resolveComponent("vue-apex-charts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_apex_charts, {
      type: "area",
      height: "500",
      options: _ctx.options,
      series: _ctx.series
    }, null, 8, ["options", "series"])
  ]))
}