
  import {Options, Vue} from 'vue-class-component';
  import { FormEvents } from '../../../../constants';
  import { settingsStore } from '../../settings.store';
  import FormatSettingsForm from './timeFormatSettingsForm.vue';

  @Options({
    props: {
      title : String,
      description : String,
      iconClass : String,
      modalAction : Boolean
    },
    components: {
      FormatSettingsForm
    }
  })

export default class TimeFormatSetting extends Vue {
  formEvent = FormEvents.TIME_FORMAT_FORM;

  created(): void {
    settingsStore.getTimeFormat();
  }

}
