import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c2e073ca")
const _hoisted_1 = { class: "card-inner card-inner-lg" }
const _hoisted_2 = { class: "nk-block" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_tools = _resolveComponent("table-tools")!
  const _component_data_table = _resolveComponent("data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_data_table, {
        fields: _ctx.fields,
        "table-data": _ctx.practiceGroups,
        tableId: "leads-tablex",
        primaryKey: "Id",
        serverSide: true,
        requestMethod: "POST",
        contentType: "application/x-www-form-urlencoded",
        url: "/api/Leads/GetLeads"
      }, {
        actions: _withCtx((prop) => [
          _createVNode(_component_table_tools, {
            data: prop,
            "hide-tool-tip": false,
            actions: _ctx.tableActions
          }, null, 8, ["data", "actions"])
        ]),
        _: 1
      }, 8, ["fields", "table-data"])
    ])
  ]))
}