import { BaseService } from "../../../../shared/services/base-service";

class PracticeAreaService extends BaseService {
  resource = "Matter/PracticeArea";

  list() {
    return this.get("GetAll", null, false);
  }

  create(payload: any) {
    return this.post("Add", payload, false);
  }
}

export const practiceAreaService = new PracticeAreaService();
