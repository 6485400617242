
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import { crmStore } from "../crm.store";
import { formActionsService } from "./form-actions.service";

@Options({
  components: {
    VueEditor
  },
  emits: ["reloadLeadsDetail"]
})
export default class LostLeadForm extends mixins(ModalFormMixin) {
  modalId = "lost-lead-form";
  formEvent = FormEvents.LOST_LEAD;
  service = formActionsService;

  formData = {
    LeadId: "",
    Reason: "",
    Comment: ""
  };

  get getLossLeadReasons(): unknown {
    return crmStore.leadLossReasons;
  }

  async markLeadsAsLost(): Promise<void> {
    try {
      this.isLoading = true;
      this.formData.LeadId = this.payload.id;
      console.log("The form data is: ", this.formData, this.payload);
      const response = await this.service.setLeadStatusToLost(this.formData);
      this.isLoading = false;
      this.toast("success", "Lead Updated to Lost");
      this.formData = {
        LeadId: "",
        Reason: "",
        Comment: ""
      };
      GlobalEvent.emit("reloadLeadsDetail");
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
