
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { mixins, Options } from "vue-class-component";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import SearchableSelect from "../../../components/SearchableSelect.vue";
import { courtUpdateMatterService } from "./services/courtupdatesinput.service";
// import { matterStore } from "../matter.store";

@Options({
  components: { VueEditor, SearchableSelect },
  emits: ["courtUpdateForm"],
})
export default class newCourtUpdateForm extends mixins(ModalFormMixin) {
  app = NCApp;
  formEvent = FormEvents.NEW_COURT_UPDATE;
  modalId = "new-court-update-form";
  primaryKey = "Id";
  resource = "CaseManager/GetSelect2Matters";
  // matterList = [];
  emitName = "matterSelected";

  customModel = this.payload.Id ? { MatterName: this.payload.MatterTitle, MatterId: this.payload.MatterId } : {};

  mounted(): void {
    GlobalEvent.on(this.emitName, (value: Record<string, unknown>) => {
      this.payload.MatterId = value.MatterId;
      // this.payload.ModuleEntityName = value.MatterName;
      this.customModel = value as any;
      this.getCourtMatterUpdateDetails();
      console.log("The module Id is: ", this.payload.MatterId, value);
    });
  }

  async getCourtMatterUpdateDetails(): Promise<void> {
    try {
      const response = await courtUpdateMatterService.findOne(this.payload.MatterId);
      this.payload.SuitNumber = response.Data.SuitNumber;
      this.payload.Court = response.Data.Court;
      this.payload.Judge = response.Data.Judge;
      this.payload.HearingDate = response.Data.HearingDateFmt;
      this.payload.Comments = response.Data.Comments;
      this.payload.Type = response.Data.HearingType;
      this.payload.Status = response.Data.Status;
      console.log("MATTER RES", response);
    } catch (error) {
      console.log(error);
    }
  }

  Status = [
    {
      label: "Open",
      value: 0,
    },
    {
      label: "Adjourn",
      value: 1,
    },
    {
      label: "Closed",
      value: 2,
    },
  ];

  Type = [
    {
      label: "Fixed Date",
      value: 1,
    },
    {
      label: "No Fixed Date",
      value: 2,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // get matterCourtUpdate() {
  //   return matterStore.matterCourtUpdate;
  // }

  submitForm(): void {
    if (this.isUpdate) {
      this.update();
    } else {
      this.save();
    }
  }

  async update(): Promise<void> {
    try {
      this.isLoading = true;
      // this.payload.MatterCourtUpdateAttendee = [
      //   {
      //     EmployeeId: "49",
      //     EmployeeName: "Oluwadare Anu",
      //     Id: "",
      //     MatterCourtUpdateId: "",
      //     TimeEntry: "120"
      //   }
      // ];
      // this.payload.MatterCourtUpdateParty = {};
      console.log("payload data ", this.payload);
      var response = await courtUpdateMatterService.update(this.payload);
      this.isLoading = false;
      this.toast("success", response.Message);
      GlobalEvent.emit("courtUpdateForm");
      this.modal(this.modalId, false);
    } catch (error) {
      console.log("error", error);
      this.handleError(error);
    }
  }

  async save(): Promise<void> {
    try {
      this.isLoading = true;
      // this.payload.MatterCourtUpdateAttendee = [
      //   {
      //     EmployeeId: "49",
      //     EmployeeName: "Oluwadare Anu",
      //     Id: "",
      //     MatterCourtUpdateId: "",
      //     TimeEntry: "120"
      //   }
      // ];
      // this.payload.MatterCourtUpdateParty = {};
      console.log("payload data ", this.payload);
      var response = await courtUpdateMatterService.create(this.payload);
      this.isLoading = false;
      this.toast("success", response.Message);
      GlobalEvent.emit("courtUpdateForm");
      this.modal(this.modalId, false);
    } catch (error) {
      console.log("error", error);
      this.handleError(error);
    }
  }
}
