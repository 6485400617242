
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
// import { FormEvents } from "../../../../constants";
import TimeKeeperForm from "@/modules/settings/time/time-keeper/time-keeper-form.vue";
import { authStore } from "../../../auth/auth.store";
@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { TimeKeeperForm },
})
export default class TimeKeeper extends Vue {
  formEvent = FormEvents.TIME_KEEPER_FORM;
  payload: any = {};

  created() {
    this.payload.TimeKeeper = authStore.auth.employee?.EmployeeId;
  }
}
