
import { mixins, Options } from "vue-class-component";
import {PropType} from "vue";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { financeReportService } from "./reportSetting.service";
import { ReportConfig } from "./reportSetting.model";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { settingsStore } from '../../settings.store';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";

@Options({
  components: {Multiselect},
  props: {
    reportTitle: String,
    reportType: Number,
  },
  emits: ["closeForm", "submitForm"]
})
export default class ReportSettingsForm extends mixins(ModalFormMixin) {
  service = financeReportService;
   primaryKey = "AccountReportScheduleInfoId";
  minRange = 0;
  maxRange = 0;
  // reportType = 1;
  reporttypes = -1;
  totallingTypes = -1;
 
 get users(): any[] {
    return settingsStore.users;
  }
  created() {
    this.loadJS();
  }
    mounted() {
      this.payload = Object.assign({}, {});
         GlobalEvent.on("edit-report-config", (data: any) => {
      this.payload = Object.assign({}, data);
      this.totallingTypes = this.payload.TotallingType == null ? -1 : this.payload.TotallingType;
      this.minRange =  this.payload.IncludeRange.split('..')[0];
      this.maxRange =  this.payload.IncludeRange.split('..')[1];
        window.scrollTo(0, 0);
    }); 
  }
 
    async savedata(payload: any, Type: number) {
      const range = `${this.minRange}..${this.maxRange}`;
      this.payload.TotallingType = this.totallingTypes;
      this.payload.IncludeRange = range;
      delete this.payload.key;
      const data = {
        AccountReportScheduleId: Type,
        reporttype: this.reporttypes,
        ...payload
      }

    try {
      this.isLoading = true;
      // console.log(this.isUpdate, "isUpdate", payload.AccountReportScheduleInfoId, "infoId");
      const response = this.isUpdate ? await financeReportService.updateReportConfig(data): await financeReportService.addConfig(data) ;
      this.toast("success", response.Message);
      this.isLoading = false;
      // if (data.AccountReportScheduleId === 1){
      //   console.log('11RELOAD STORE', data.AccountReportScheduleId );
        settingsStore.getIncomeStatement();
        // }
      // if (data.AccountReportScheduleId === 2){
      //    console.log('22RELOAD STORE', data.AccountReportScheduleId);
         this.$emit('submitForm', data);
        settingsStore.getFinPosition()
        //  }
    } catch (error) {
      this.handleError(error);
    }
  

  }

 resetForm() {
     this.payload = {};
     this.minRange = 0;
    this.maxRange = 0;
      this.reporttypes = -1;
  this.totallingTypes = -1;
  }

  closeForm() {
    this.resetForm();
     this.$emit('closeForm', this.payload);
  }
  submitForm() {
     this.$emit('submitForm',);
  }

  
}
