import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class CurrencyService extends BaseService {
  resource = 'TransactionCurrencies';

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetAll", { params: pagination });
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }
}

export class CurrencyService2 extends BaseService {
  resource = 'PLOV/Currencies';

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("", { params: pagination });
  }
}

export const currencyService2 = new CurrencyService2()
export const currencyService = new CurrencyService();
