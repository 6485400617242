
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { AccountPeriodService, GetAccountPeriodService } from "./accountperiod.service";
import { settingsStore } from "../../settings.store";
import { accountPeriod } from "./accountPeriod.model";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

@Options({
  components: { Multiselect }
})
export default class accountperiodSettingsForm extends mixins(ModalFormMixin) {
  modalId = "accountperiod-setting-form";
  formEvent = FormEvents.ACCOUNT_PERIOD_FORM;
  service = AccountPeriodService;
  reloadStore = settingsStore.getAllAccountPeriods;
  year = "";
  month = "";

  get accountPeriods(): accountPeriod[] {
    return settingsStore.accountPeriods;
  }

  created(): void {
    this.loadJS();
  }

  generateYearArray(): number[] {
    const year = new Date().getFullYear();
    let yearPlaceHolder = year;
    let arr = [];

    for (let i = 0; i < 4; i++) {
      arr.unshift((yearPlaceHolder -= 1));
    }

    arr.push(year, year + 1);

    return arr;
  }

  generateMonths(): string[] {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
  }

  async saveData(payload: any) {
    // console.log({ month: this.month, year: this.year });
    payload.AccountMonth = this.generateMonths().indexOf(this.month) + 1;
    payload.AccountYear = this.year;

    try {
      this.isLoading = true;
      const response = await GetAccountPeriodService.create(payload);
      this.reloadStore();
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", this.$t('message.financeSettings.modules.accountPeriods.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }
}
