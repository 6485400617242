import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

class timeDashboardService extends BaseService {
  resource = "TimeEntry";

    getMonthlyBillSummary(): Promise<AppResponse> {
      return this.get(`GetTimeSheetCurrentMonthBillablevNonBillableSummary`);
    }
    
    getYearlySummary(): Promise<AppResponse> {
        return this.get(`GetTimeSheetDashboardYearlySummary`);
    }
    
    getAllTimeSummary(): Promise<AppResponse> {
        return this.get(`GetTimeSheetDashboardAllTimeSummary`);
    }
    
    getMonthlySummary(): Promise<AppResponse> {
      return this.get(`GetTimeSheetDashboardMonthSummary`);
    }
}

export const TimeDashboardService = new timeDashboardService();
