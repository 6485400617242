import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { StoreKeys } from "../../../constants";
import { PreserveState } from "../../../shared/plugins/secure-storage";
import { mattersService } from "./../matters.service";
import { MatterModel } from "./matter";

@Module({
  store,
  name: StoreKeys.matters,
  dynamic: true,
  namespaced: true,
  preserveState: PreserveState(StoreKeys.matters)
})
class MattersModule extends VuexModule {
  public Matters: MatterModel[] = [];

  @Mutation
  SET_MATTERS(data: MatterModel[]) {
    this.Matters = data;
  }

  @Action({ commit: "SET_MATTERS", rawError: true })
  async findAll(): Promise<MatterModel[]> {
    const response = await mattersService.findAll();
    return response.Data;
  }
}

export const mattersStore = getModule(MattersModule);
