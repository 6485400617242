export interface ToastOptions {
  group: string;
  width: number;
  classes: string | string[];
  position: string | string[];
  duration: number;
}

export const ToastTitles: Record<string, string> = {
  success: 'Success',
  error: 'Error',
  warn: 'Warning',
  info: 'Info',
};
