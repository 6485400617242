
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import BrandLogoSettingForm from "@/modules/settings/global/brand-logo/brandLogoSettingForm.vue";
@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean,
  },
  components: { BrandLogoSettingForm },
})
export default class BrandLogoSetting extends Vue {
  formEvent = FormEvents.BRAND_LOGO_FORM;
}
