
import { FormEvents } from "@/constants";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { mixins, Options, Vue } from "vue-class-component";
import print from "vue3-print-nb";
import { courtUpdateMatterService } from "./services/courtupdatesinput.service";

@Options({
  directives: {
    print,
  },
})
export default class CourtUpdateDetailPage extends mixins(ModalFormMixin) {
  // updateState = true;
  courtUpdateData: any;
  // formEvent2 = FormEvents.NEW_COURT_UPDATE;
  formEvent = FormEvents.VIEW_COURT_UPDATE;

  modalId = "show-court-update-detail";

  async created() {
    console.log("Payload from prop", this.payload);
    const response = await courtUpdateMatterService.find(this.payload.Id as string | unknown);
    this.courtUpdateData = response.Data[0];
    console.log("court update data is ", this.courtUpdateData);
    // this.updateState = false;
  }

  updated() {
    this.courtUpdateData = this.payload;
    console.log("Component has updated ", this.payload);
  }

  openNewCourtUpdateForm(): void {
    GlobalEvent.emit(this.formEvent);
  }
}
