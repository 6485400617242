
import { Options, Vue } from "vue-class-component";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Prop, Watch } from "vue-property-decorator";
import { ChartTypes } from "../constants";

am4core.useTheme(am4themes_animated);

@Options({})
export default class NCPieChart extends Vue {
  @Prop() data: any = [];
  @Prop({ default: false }) legends = false;
  @Prop({ default: ChartTypes.PIECHART }) type!: ChartTypes;
  chart: any = null;
  mounted() {
    this.createChart();
  }

  @Watch("data")
  reloadChart() {
    this.createChart();
  }

  createChart() {
    let chart = am4core.create(this.$refs.piechartdiv as any, am4charts.PieChart);

    chart.paddingRight = 0;

    chart.data = this.data;

    // Add and configure Series

    chart.radius = am4core.percent(100);
    chart.innerRadius = this.type == ChartTypes.PIECHART ? am4core.percent(0) : am4core.percent(50);
    chart.startAngle = this.type == ChartTypes.PIECHART || this.type == ChartTypes.DONUTCHART ? 0 : 180;
    chart.endAngle = 360;
    // chart.colors

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";

    series.slices.template.cornerRadius = 5;
    series.slices.template.innerCornerRadius = 3;
    // series.slices.template.draggable = true;
    series.slices.template.inert = true;
    series.alignLabels = false;

    if(this.legends) chart.legend = new am4charts.Legend();

    this.chart = chart;
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
