
import { mixins, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { UserModel } from "../../../settings/employee-users/models/user.model";
import { settingsStore } from "../../../settings/settings.store";
import { mattersService } from "../../matters.service";

@Options({})
export default class AssignWorkflowForm extends mixins(ModalFormMixin) {
  modalId = "assign-workflow-form";
  formEvent = FormEvents.ASSIGN_WORKFLOW_FORM;
  workflows: any[] = [];
  selectedWorkflows: any[] = [];
  @Prop({ default: "Matter" }) module = "Matter";

  get Users(): UserModel[] {
    return settingsStore.users;
  }

  handleModalOpen(): void {
    this.selectedWorkflows = [];
    this.getWorkflows();
  }

  async getWorkflows(): Promise<void> {
    try {
      const response = await mattersService.workflows();
      this.workflows = response.Data;
      this.pageIsReady = true;
    } catch (error) {
      console.log({ workflows: error });
      this.handleError(error);
    }
  }

  async save(data: any): Promise<void> {
    console.log(this.selectedWorkflows);
    const payload = {
      MatterClientId: this.payload.MatterId,
      NewWorkflowName: this.payload.NewWorkflowName,
      StartDate: this.payload.StartDate,
      isDuplicateWorkflow: false,
      Workflows: this.selectedWorkflows.map((x) => {
        return {
          TaskWorkflowTemplateId: x.Id,
          ExecutionType: this.payload.ExecutionType,
          WorkflowTasks: x.WorkFlowTasks.map((y: any) => {
            return {
              WorkflowTaskId: y.Id,
              Priority: y.Priority,
              DueDays: y.DueDays,
              AssignedEmployees: y.EmployeeIds,
            };
          }),
        };
      }),
    };

    console.log(payload);

    try {
      await this.validate();
      const response = await mattersService.createWorkflowCalendar(payload);
      this.toast("success", response.Message);
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleWorkflowChange(data: any) {
    try {
      const { value: Id } = data;
      if (!Id) return;
      const response = await mattersService.workflowById(Id);
      const workflow = response.Data;

      const exist = this.selectedWorkflows.find((x) => x.Id == Id);
      if (!exist) this.selectedWorkflows.push(workflow);
    } catch (error) {
      this.handleError(error);
    }
  }
}
