/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";

export class LeadReportService extends BaseService {
  resource = "LeadReports";

  LeadInfoSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("LeadInfoSummaryReport", payload);
  }

  LeadSourceSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("LeadSourceSummaryReport", payload);
  }

  LeadIndustrySummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLeadIndustrySummaryReport", payload);
  }

  LeadOwnerSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLeadOwnerSummaryReport", payload);
  }

  LeadPracticeGroupSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLeadPracticeGroupSummaryReport", payload);
  }

  LostLeadsSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLostLeadsSummaryReport", payload);
  }

  LeadStageSummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLeadLeadStageSummaryReport", payload);
  }

  LeadsActivitySummaryReport(payload: any): Promise<AppResponse> {
    return this.post("SearchLeadsActivitySummaryReport", payload);
  }
}

export const leadReportService = new LeadReportService();

export class CRMReportService extends BaseService {
  resource = "CRMReports";

  CRMLeadTagsReport(payload: any): Promise<AppResponse> {
    return this.post("CRMLeadTagsReport", payload);
  }
}

export const crmReportService = new CRMReportService();