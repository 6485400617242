import { authStore } from "@/modules/auth/auth.store";
import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";
import axios from "axios";
import { data } from "jquery";
import { Clients } from "../model/clients.model";

//`[MATTER SERVICE]`
export class CourtUpdateMatterService extends BaseService {
  resource = "MatterCourtUpdate";

  create(payload: any): Promise<AppResponse> {
    return this.post("Add", payload);
  }

  find(id: unknown): Promise<AppResponse> {
    return this.get(`Get/${id}`);
  }

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetAll", pagination);
  }

  findOne(id: unknown): Promise<AppResponse> {
    return this.get(`GetLast/${id}`);
  }

  delete(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }
}

export const courtUpdateMatterService = new CourtUpdateMatterService();
