
import { mixins, Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
// import { ReportConfig } from "./reportSetting.model";
import ChartAccountForm from "./chart-account-form.vue";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { CoaService } from "./chart-account.service";
import { chartOfAccount } from "./chart-account.model";
// import { practiceGroupsService } from "./practice-group.service";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: {
   ChartAccountForm
    // memberform,
    
  }
})
export default class ChartOfAccounts extends mixins(DataTableMixin) {
  formEvent = FormEvents.CHART_OF_ACCOUNTS;
  primaryKey = 'ChartOfAccountId';

  get coa(): any[] {
    return settingsStore.coa;
  }
  
  created(): void {
    settingsStore.getChartOfAccounts();
    settingsStore.getBaseAccountTypes();
    settingsStore.getAccountTypes();
    this.loadJS();
  }
//   @Watch("reportType")
//   public watchReportType(newValue: number): void {
//     if(newValue == 2) {
//       this.reportData = this.finPosition;
//       this.reportTitle = "Statement Of Financial Position";
//     }
//     if(newValue == 1) {
//       this.reportData = this.incomeStatement;
//       this.reportTitle = "Income Statement Config";
//     }
  
//   }

//   showAddForm() {
//     this.switchCase = false;
//   }
//   closeForm(val: any) {
//     this.switchCase = true;
//     console.log(val, "ON FORM CLOSE");
    
//   }

  saveDataForm(val: any) {
    console.log(val, "ON submit Dta", val.AccountReportScheduleId.reportType);
    
  }
  mounted() {
    // console.log(this.coa, 'coaaaa');
    
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

  deleteData(prop: any, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            // await this.validate();
            // this.globalLoader(true);.toString()
            // const response = await CoaService.removeReport(prop.AccountReportScheduleInfoId);
            console.log('The coas payload is: ', prop);
            // this.toastSuccess(response.Message);
            // this.globalLoader(false);
            self.isLoading = false;
            // self.toast("success", "Report Config Deleted");
            //     if (prop.AccountReportScheduleId === 1){
            //   console.log('11RELOAD STORE', prop.AccountReportScheduleId);
              
            //   settingsStore.getIncomeStatement() }
            // if (prop.AccountReportScheduleId === 2){
            //   console.log('22RELOAD STORE', prop.AccountReportScheduleId);
            //   settingsStore.getFinPosition() }
          } catch (error) {
            console.log(error);
          }
        }
      });
  }
  fields = [
    {
      title: "Chart Number",
      name: "ChartNumber"
    },
    {
      title: "Name",
      name: "AccountName",
      center: false
    },
    {
      title: "Direct Posting",
      name: "DirectPosting"
    },
    {
      title: "Income or Balance",
      name: "RelatedBookName"
    },
    {
      title: "Account Category",
      name: "BaseAccountName"
    },
    {
      title: "AccountSubCategory",
      name: "AccountSubCategory"
    },
    {
      title: "Account Type",
      name: "AccountTypeName"
    },
    {
      title: "Totaling",
      name: "Totalling"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: chartOfAccount, self: any): void => {
        console.log("Edditignin Report", prop);
        GlobalEvent.emit(FormEvents.CHART_OF_ACCOUNTS, prop);
      }
    },
    // {
    //   name: "Members",
    //   iconClass: "icon ni ni-user-alt",
    //   showIcon: true,
    //   action: async (prop: any): Promise<void> => {
    //     const practicegroup = await practiceGroupsService.findOne(prop.PracticeGroupId);
    //     GlobalEvent.emit(FormEvents.MEMBER_FORM, practicegroup.Data);
    //   }
    // },
    // {
    //   name: "Practice Areas",
    //   iconClass: "icon ni ni-list-index",
    //   showIcon: true,
    //   action: async (prop: any): Promise<void> => {
    //     const practicegroup = await practiceGroupsService.findOne(prop.PracticeGroupId);
    //     GlobalEvent.emit(FormEvents.PRACTICE_AREA_FORM, practicegroup.Data);
    //   }
    // },
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any, self: any) => {
    //     // this.removeTableData(practiceGroupsService.remove, prop.PracticeGroupId);
    //     this.deleteData(prop, self);
    //   }
    // }
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any) => {

    //   }
    // }
  ];
}
