import { FormEvents } from "../../constants";
import { GlobalEvent } from "../../shared/plugins/tiny-emitter";

export const ClientDashboardTable = {
  matterFields: [
    {
      title: "Code",
      name: "MatterCode"
    },
    {
      title: "Title",
      name: "MatterTitle",
      center: false
    },
    {
      title: "Contact Person",
      name: "PrimaryContactName"
    },
    {
      title: "Status",
      name: "MatterStatusDescription"
    },
    {
      title: "Date Created",
      name: "StartDate"
    },
    { name: "actions", title: "Actions", showTitle: true }
  ],
  matterTableActions: [
    {
      name: "View",
      iconClass: "icon ni ni-eye-alt",
      showIcon: false
    }
  ],
  billsFields: [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Matter",
      name: "MatterName"
    },
    {
      title: "Amount",
      name: "BillTotal",
      center: false
    },
    {
      title: "Currency",
      name: "Currency"
    },
    {
      title: "Date",
      name: "FormattedDateCreated"
    },
    {
      title: "Created By",
      name: "CreatedByName"
    },
    {
      title: "Status",
      name: "BillStatusDescription"
    },
    { name: "actions", title: "Actions", showTitle: true }
  ],
  billTableActions: [
    {
      name: "View",
      iconClass: "icon ni ni-eye-alt",
      showIcon: false
    }
  ],
  contactsFields: [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Name",
      name: "ContactName"
    },
    {
      title: "Email",
      name: "EmailAddress",
      center: false
    },
    {
      title: "Telephone",
      name: "Telephone"
    },
    {
      title: "Date",
      name: "DateCreatedFormatted"
    },
    {
      title: "Source",
      name: "ContactType"
    }
  ],
  expenseFields: [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Description",
      name: "SNO",
      center: false
    },
    {
      title: "Name",
      name: "SNO"
    },
    {
      title: "Matter",
      name: "SNO"
    },
    {
      title: "Amount",
      name: "SNO"
    },
    {
      title: "Date",
      name: "SNO"
    }
  ],
  timeFields: [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Matter",
      name: "MatterTitle"
    },
    {
      title: "Activity",
      name: "ActivityTypeDescription",
      center: false
    },
    {
      title: "Date",
      name: "TimeSheetDate"
    },
    {
      title: "Hours",
      name: "TimeEntryInHours"
    },
    {
      title: "Narrative",
      name: "Narrative"
    },
    {
      title: "Type",
      name: "ChargeType"
    },
    { name: "TimeSheetOwner", title: "User" }
  ],
  courtFields: [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Matter",
      name: "MatterTitle"
    },
    {
      title: "Suit #",
      name: "SuitNumber",
      center: false
    },
    {
      title: "Court",
      name: "Court"
    },
    {
      title: "Judge",
      name: "Judge"
    },
    {
      title: "Hearing",
      name: "HearingDateFmt"
    },
    {
      title: "Next Date",
      name: "AdjournDateFmt"
    }
  ]
};

export const ClientTable = {
  fields: [
    // {
    //   title: "SNO",
    //   name: "SNO"
    // },
    {
      title: "Client Name",
      name: "ClientName"
    },
    {
      title: "Code",
      name: "ClientCode"
    },
    {
      title: "Telephone Number",
      name: "TelephoneNo"
    },
    {
      title: "Relationhip Manager",
      name: "RelationshipManagerName"
    },
    {
      title: "Status",
      name: "ClientStatusDescription"
    },
    { name: "actions", title: "Actions", showTitle: true }
  ],

  actions: [
    {
      name: "View",
      iconClass: "icon ni ni-eye-alt",
      showIcon: true,
      action: (prop: any, self: any) => {
        self.$router.push({ name: "Client Dashboard", params: { id: prop.ClientId } });
      }
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false
    },
    {
      name: "Merge",
      iconClass: "icon ni ni-swap",
      showIcon: false,
      action: (prop: any, self: any) => {
        GlobalEvent.emit(FormEvents.MERGE_CLIENT_FORM, prop);
      }
    },
    {
      name: "Assign Workflow",
      iconClass: "icon ni ni-link",
      showIcon: false,
      action: (prop: any, self: any) => {
        GlobalEvent.emit(FormEvents.ASSIGN_WORKFLOW_FORM, prop);
      }
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self: any) => {
        // this.deleteUser(prop, self);
      }
    }
  ]
};
