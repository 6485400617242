import { BaseService } from "@/shared/services/base-service";

class CalendarService extends BaseService {
  resource = "MatterReports";

  fetchCalendarActivities(payload : Record<string, unknown | string>) {
    return this.post("PersonalCalendar", payload)
  }
}

const calendarService = new CalendarService()
export default calendarService;