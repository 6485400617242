
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { ContactCategoryService } from "./contact-category.service";
import { ContactCategory } from "./contactCategory.model";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { settingsStore } from "../../settings.store";

// import "vue-select/dist/vue-select.css";

@Options({})
export default class ContactCategorySettingsForm extends mixins(ModalFormMixin) {
  modalId = "contact-category-setting-form";
  formEvent = FormEvents.CONTACT_CATEGORY_FORM;
  service = ContactCategoryService;
  ContactCategory = [];
  reloadStore = settingsStore.getContactCategories;

  get contactCategory(): ContactCategory[] {
    return settingsStore.contactCategory;
  }

  created(): void {
    // settingsStore.getContactCategories();
    this.loadJS();
  }

  async saveData(payload: any) {
    try {
      // this.payload.projectLabel = ''
      this.isLoading = true;
      const response = await ContactCategoryService.create(payload);
      this.reloadStore();
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", this.$t('message.crmSettings.modules.contactCategory.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateData(payload: any) {
    try {
      const data = {
        CategoryName: payload.CategoryName,
        Id: payload.Id
      };
      this.isLoading = true;
      const response = await ContactCategoryService.update(data);
      this.reloadStore();
      this.isLoading = false;
      this.modal(this.modalId, false);
      this.toast("success", this.$t('message.crmSettings.modules.contactCategory.notification.Update'));
    } catch (error) {
      this.handleError(error);
    }
  }
}
