
import { clientReportService } from "@/modules/clients/clients.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { mixins, Vue } from "vue-class-component";
import { dashboardService } from "./dashboardService";

export default class MatterDashboard extends mixins(DataTableMixin) {
  dashboardData: any = {};
  chartData: any = [];
  recentMatters: any = [];

  async created(): Promise<void> {
    this.loadJS();
    try {
      const response = await dashboardService.GetMatterDashboard();
      if (response.Data) {
        const responseData = response.Data;
        this.dashboardData = responseData;
        console.log("The dashboard data is: ", this.dashboardData);
        response.Data.BillInflowByPracticeGroup.forEach((data: any) => {
          this.chartData.push({
            value: data.Amount,
            category: data.Description,
          });
        });
        console.log("The chart graph is: ", this.chartData);
      }

      const matterResponse = await clientReportService.getLandingDashboard();
      const matterDetails = matterResponse.Data?.RecentMatters.RecentMatterSummaries;
      this.recentMatters = matterDetails;

      console.log("The recent matters are: ", this.recentMatters);
    } catch (error) {
      this.handleError(error);
    }
  }
}
