
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import numberingForm from "./numberingForm.vue";
@Options({
  props: {
    title: String,
    description: String,
    iconClass: String,
    modalAction: Boolean
  },
  components: { numberingForm }
})
export default class Numbering extends Vue {
  formEvent = FormEvents.NUM_FORM;

  created(): void {
    settingsStore.getNumberingFormats();
  }
}
