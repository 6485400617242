
import { Options, prop, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import dayjs from "dayjs";

@Options({ components: { Datepicker } })
export default class NCDateTime extends Vue {
  value: any = new Date();
  @Prop() id!: string;
  @Prop({ default: 0 }) date = 0;
  @Prop({ default: new Date() }) defaultDate!: any;
  @Prop({ default: false }) enableTimePicker = false;
  @Prop({ default: false }) monthPicker = false;
  @Prop({ default: false }) timePicker = false;
  @Prop({ default: "dd MMM, yyyy" }) format = "dd MMM, yyyy";

  // value = new Date();

  created() {
    this.value = dayjs(this.defaultDate)
      .add(this.date, "day")
      .toDate();
    this.enableTimePicker
      ? this.$emit("update:modelValue", dayjs(this.value).format("YYYY-MM-DD : HH:mm"))
      : this.$emit("update:modelValue", dayjs(this.value).format("YYYY-MM-DD"));
  }

  @Watch("defaultDate")
  public watchVmodel(newValue: any, oldValue : any): void {
    if(newValue != oldValue) {
      this.value = newValue;
    }
  }

  get nFormat(): string {
    return this.enableTimePicker ? "dd MMM, yyyy : HH:mm" : "dd MMM, yyyy";
  }

  @Watch("defaultDate")
  handleDefaultDateChange(newValue: any) {
    this.value = dayjs(newValue)
      .add(this.date, "day")
      .toDate();
    this.enableTimePicker
      ? this.$emit("update:modelValue", dayjs(this.value).format("YYYY-MM-DD : HH:mm"))
      : this.$emit("update:modelValue", dayjs(this.value).format("YYYY-MM-DD"));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  setDate(date: any) {
    // console.log(dayjs(date).format("YYYY-MM-DD"));
    this.value = date;
    this.enableTimePicker
      ? this.$emit("update:modelValue", dayjs(date).format("YYYY-MM-DD : HH:mm"))
      : this.$emit("update:modelValue", dayjs(date).format("YYYY-MM-DD"));
  }
}
