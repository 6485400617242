
import { FormEvents } from "@/constants";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import axios, { CancelTokenSource } from "axios";
import { mixins, Options } from "vue-class-component";
import Multiselect from "vue-multiselect";
import taskService, {taskService2} from "./tasks.service";
import {settingsStore} from "../../settings/settings.store"
import { UserModel } from "@/modules/settings/employee-users/models/user.model";
import SearchableSelect from "../../../components/SearchableSelect.vue"
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Watch } from "vue-property-decorator";
import dayjs from "dayjs";

@Options({
  components : {
    Multiselect,
    SearchableSelect
  }
})
export default class MatterTaskAddForm extends mixins(ModalFormMixin) {
  cancelSource: CancelTokenSource | null = null;

  modalId = "matter-task-add-form";
  formEvent = FormEvents.MATTER_TASK_ADD_FORM;
  primaryKey = "Id";
  resource = "CaseManager/GetSelect2Matters"
  matterList = [];
  service = taskService;
  service2 = taskService2;
  emitName = "matterSelected"

  taskDuration = 0;
  customModel = this.payload.Id ? {MatterName : this.payload.ModuleEntityName, MatterId : this.payload.ModuleEntityId} : {};

  created() : void {
    this.loadJS();
  }

  mounted() : void {
    GlobalEvent.on(this.emitName, (value : Record<string, unknown>) => {
      this.payload.ModuleEntityId = value.MatterId;
      this.payload.ModuleEntityName = value.MatterName;
      this.customModel = value as any;
    })
  }

  async handleModalOpen() : Promise<void> {
    await settingsStore.AllUsers();
  }

  @Watch("payload")
  public watchVmodel(newValue: any, oldValue : any): void {
    if(newValue.EndDate != oldValue.EndDate) {
      const date1 = dayjs(this.payload.StartDate || new Date());
      const date2 = dayjs(this.payload.EndDate);

      const duration = date2.diff(date1, "day");
      this.taskDuration = duration;
    }
  }

  updateEndDate(newValue: any): void {
    const date1 = dayjs(this.payload.StartDate || new Date());
    const date2 = dayjs(newValue);

    this.payload.EndDate = date2;

    const duration = date2.diff(date1, "day");
    this.taskDuration = duration;
  }

  updateStartDate(newValue: any): void {
    const date1 = dayjs(newValue);
    const date2 = dayjs(this.payload.EndDate || new Date());

    this.payload.StartDate = date1;

    const duration = date2.diff(date1, "day");
    this.taskDuration = duration;
  }

  updateDuration(event: any): void {
    const newValue = event.target.value;

    const date1 = dayjs(this.payload.StartDate || new Date());
    const endDate = date1.add(newValue, "day");
    
    this.payload.EndDate = endDate;
  }

  get usersList() : UserModel[] {
    return settingsStore.users
  }

  Status = [
    {
      label: "Not Started",
      value: 0
    },
    {
      label: "In Progress",
      value: 1
    },
    {
      label: "On Hold",
      value: 2
    },
    {
      label: "Abandoned",
      value: 3
    },
    {
      label: "Completed",
      value: 4
    }
  ];

  Type = [
    {
      label: "Phone Call",
      value: 1
    },
    {
      label: "Email",
      value: 2
    },
    {
      label: "Meeting",
      value: 3
    },
    {
      label: "Appointment",
      value: 4
    },
    {
      label: "General",
      value: 5
    },
    {
      label: "Document",
      value: 6
    },
    {
      label: "Workflow",
      value: 7
    }
  ];

  Priority = [
    {
      label: "Low",
      value: 1
    },
    {
      label: "Medium",
      value: 2
    },
    {
      label: "High",
      value: 3
    }
  ]

  cancelFetch() : void {
    if (this.cancelSource) {
      this.cancelSource.cancel("Cancelled this ongoing request");
    }
  }

  async fetchOptions(value: string): Promise<void> {
    try {
      if (value.length > 1) {

        this.cancelFetch();
        this.cancelSource = axios.CancelToken.source();

        const response = await this.service.getSelect2(
          `${this.resource}?term=${value}&_type=query&q=${value}`,
          this.cancelSource.token
        );
        this.matterList = (response as unknown) as [];
        this.cancelSource = null;
      }
    } catch (error : any) {
      if (error[0] && error[0].MatterId) {
        this.matterList = error as [];
      }
    }
  }

  submitForm() : void {
    this.payload.Id ? this.updateData() : this.saveData();
  }

  async saveData() : Promise<void> {
    this.isLoading = true;

    try {
      this.payload.ModuleType = 4;
      this.payload.Id = "";
      this.payload.StartDate = this.payload.StartDate && new Date(this.payload.StartDate).toISOString();
      this.payload.EndDate =  this.payload.EndDate && new Date(this.payload.EndDate).toISOString();
      this.payload.DueDate = this.payload.EndDate || "";

      await this.service.create(this.payload);
      this.isLoading = false;
      GlobalEvent.emit("tasks-datatable-key");
      this.modal(this.modalId, false);
      this.toast("success", "Task Added Successfully");
    } catch (error : any) {
      this.isLoading = false;
      this.handleError(error);
    }
  }

  async updateData() : Promise<void> {
    this.isLoading = true;

    this.payload.StartDate = this.payload.StartDate && new Date(this.payload.StartDate).toISOString();
    this.payload.EndDate =  this.payload.EndDate && new Date(this.payload.EndDate).toISOString();
    this.payload.DueDate = this.payload.EndDate || "";

    try {
      await this.service2.update(this.payload);
      this.isLoading = false;
      GlobalEvent.emit("tasks-datatable-key");
      this.modal(this.modalId, false);
      this.toast("success", "Task Updated Successfully");
    } catch (error) {
      this.isLoading = false;
      this.handleError(error);
    }
  }
}
