
import { Options, Vue } from "vue-class-component";
// import "jkanban/dist/jkanban";
import $ from "jquery";
import { PipelineModel } from "./pipeline.model";
import { crmStore } from "../crm.store";
import PipelineHeader from "./components/PipelineHeader.vue";
import PipelineItem from "./components/PipelineItem.vue";
import { LeadModel } from "../../leads/lead.model";
import { leadService } from "../../leads/leads.service";
import LostLeadForm from "./components/LostLeadForm.vue";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import { FormEvents } from "../../../constants";
/* eslint-disable @typescript-eslint/no-this-alias */

@Options({
  components: {
    PipelineHeader,
    PipelineItem,
    LostLeadForm,
  },
})
export default class SalesPipeline extends Vue {
  get pipelineData(): PipelineModel[] {
    return crmStore.leadPipelines;
  }

  kanban: any;

  async mounted(): Promise<void> {
    const main: SalesPipeline = this;
    this.isLoading = true;
    await crmStore.getSalesPipeline();
    this.isLoading = false;
    $(document).ready(async function () {
      console.log("load load");
      main.setupKanban();

      $(".onClickWon").on("click", async function () {
        const LeadId = $(this).attr("data-id");
        main.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, move to won!",
          })
          .then(async (result: { value: boolean }) => {
            if (result.value) {
              try {
                var response = await leadService.updateLeadStatusToWon(LeadId as string);
                main.toast("success", response.Message);
              } catch (error) {
                main.handleError(error);
              }
            }
          });
      });

      $(".onClickLost").on("click", async function () {
        const LeadId = $(this).attr("data-id");

        GlobalEvent.emit(FormEvents.LOST_LEAD_FORM, {
          LeadId,
          Comment: "",
          Reason: 1,
        });

        // main.modal("lost-lead-form", true);

        // main.$swal
        //   .fire({
        //     title: "Are you sure?",
        //     text: "You won't be able to revert this!",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Yes, move to won!"
        //   })
        //   .then(async (result: { value: boolean }) => {
        //     if (result.value) {
        //       try {
        //         var response = await leadService.updateLeadStatusToWon(LeadId as string);
        //         main.toast("success", response.Message);
        //       } catch (error) {
        //         main.handleError(error);
        //       }
        //     }
        //   });
      });
    });
  }

  async setupKanban(): Promise<void> {
    try {
      const boards: any[] = [];
      for (const pipelineStage of this.pipelineData) {
        boards.push({
          id: `${pipelineStage.Id}`,
          title: this.PiplelineHeader(pipelineStage),
          class: "kanban-primary",
          item: pipelineStage.Leads.map((lead: LeadModel) => {
            return {
              title: this.PiplelineItem(lead),
              id: lead.Id,
            };
          }),
        });
      }

      // this.isLoading = false;

      const main: SalesPipeline = this;

      const kanban = new window.jKanban({
        element: "#kanban",
        gutter: "0",
        widthBoard: "320px",
        responsivePercentage: false,

        dropEl: async function (el: any, target: any, source: any, sibling: any) {
          const leadId = el.dataset.eid;
          const sourcePiplelineStageId = source.offsetParent.dataset.id;
          const targetPiplelineStageId = target.offsetParent.dataset.id;
          const board = boards.find((x) => x.id == sourcePiplelineStageId);
          const item = board.item.find((x: any) => x.id == leadId);

          // const sourceBoard = main.kanban.findBoard(sourcePiplelineStageId);
          // const targetBoard = main.kanban.findBoard(targetPiplelineStageId);

          try {
            const response = await leadService.updateLeadStatus(leadId as string, targetPiplelineStageId);
            main.toast("success", response.Message);
          } catch (error) {
            main.kanban.addElement(sourcePiplelineStageId, item);
            const targetElement = $(`[data-id="${targetPiplelineStageId}"] [data-eid="${item.id}"]`).remove();
            main.handleError(error);
          }
        },
        boards,
      });
      this.kanban = kanban;
      this.isLoading = false;
    } catch (error) {
      console.log({ kanbanerror: error });
      throw error;
    }
  }

  PiplelineHeader(piplelineStage: PipelineModel): string {
    let total = 0;
    for (const lead of piplelineStage.Leads) {
      total += lead.DealValue;
    }

    const totalFormatted = this.moneyFormat(total);
    return ` <div data-lead-id=${piplelineStage.Id} class="kanban-title-content">
    <h6 class="title">${piplelineStage.StageName}</h6>
    
    <span class="badge badge-pill badge-outline-light text-dark">${piplelineStage.NumberOfLeads}</span>
    
  </div>

  ${piplelineStage.Leads.length > 0 ? `<p class="float-right">${piplelineStage.Leads[0].CurrencySymbol}${totalFormatted}</p>` : ""}
  
  
  `;
  }

  PiplelineItem(lead: LeadModel): string {
    const main: SalesPipeline = this;
    return `<div class="kanban-item-title">
      <h6 class="title">${lead.DealName}</h6>
      <div class="dropdown">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <div class="user-avatar-group">
                <div class="user-avatar xs bg-primary"><span>${this.getInitials(lead.DealName)}</span></div>
            </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
            <ul class="link-list-opt no-bdr p-3 g-2">
                <li>
                    <div class="user-card">
                        <div class="user-avatar sm bg-primary">
                            <span>${this.getInitials(lead.DealName)}</span>
                        </div>
                        <div class="user-name">
                            <span class="tb-lead">${lead.DealName}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    </div>
    <div class="kanban-item-text mb-2">
      <p>${lead.CurrencySymbol}${lead.DealValueFormatted}</p>
    </div>
    <div class="kanban-item-text">
      <p>${lead.CompanyName}</p>
    </div>

    <ul class="kanban-item-tags">
        <li><span class="badge badge-success onClickWon" data-id="${lead.Id}">Won</span></li>
        <li><span class="badge badge-danger onClickLost" data-id="${lead.Id}">Lost</span></li>
    </ul>
    
    <div class="kanban-item-meta">
      <ul class="kanban-item-meta-list">
        <li class="">${lead.ContactFullName}</li>
       
      </ul>
      <ul class="kanban-item-meta-list">
        <li>${lead.FormattedDateCreated}</li>
      </ul>
    </div>`;
  }
}
