
import { Options, Vue } from "vue-class-component";
import VueApexCharts from "vue3-apexcharts";
import { Prop } from "vue-property-decorator";
// ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

// am4core.useTheme(am4themes_animated);

@Options({
  components: { VueApexCharts },
  //   extends: { Line },
})
export default class SplineChart extends Vue {
  @Prop() data: any = [];
  @Prop() categoryName: any = [];
  @Prop({ default: "chart-id" }) chartId = "chart-id";
  @Prop() series: any[] = [];
  @Prop({ default: {} }) chartOptions!: Record<string, any>;

  // series: any = [
  //   {
  //     name: "series1",
  //     data: [31, 40, 28, 51, 42, 109, 100],
  //   },
  //   {
  //     name: "series2",
  //     data: [11, 32, 45, 32, 34, 52, 41],
  //   },
  // ];
  get options() {
    return {
      ...this.defaultOptions,
      ...this.chartOptions,
    };
  }
  defaultOptions: any = {
    chart: {
      height: 500,
      type: "area",
      background: "#FFFFFF",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    fill: {
      colors: ["#E6E6FA", "#E6E6FA"],
    },
    colors: ["#bac2ff", "#E6E6FA"],
    stroke: {
      curve: "smooth",
      lineCap: "square",
    },
    theme: {
      palette: "palette2",
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
      lines: {
        show: false,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
        // format: 'dd/MM/yy HH:mm'
      },
    },
  };
}
