
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import { UserModel } from "../../employee-users/models/user.model";
import { Activity } from "../../settings.model";
import { taskWorkFlowService } from "./workflow.service";
import { v4 as uuidv4 } from "uuid";
@Options({})
export default class ClientMatterWorkflow extends mixins(ModalFormMixin) {
  modalId = "workflow-form";
  formEvent = FormEvents.WORKFLOW_FORM;
  WorkFlowTasks = [{}];
  TaskWOrkFlowActivity: any[] = [{ id: "Start", name: "Workflow Start Date" }];
  tasks = 1;
  service = taskWorkFlowService;
  date = new Date();
  reloadStore = settingsStore.findAllWorkFlow;

  get users(): UserModel[] {
    return settingsStore.users;
  }

  get activities(): Activity[] {
    return settingsStore.activities;
  }

  addActivity(activity: any, index: number): void {
    console.log("addActivity", activity);
    if (index > -1) {
      this.TaskWOrkFlowActivity.splice(index + 1, 1);
    }
    this.TaskWOrkFlowActivity.splice(index + 1, 0, { id: uuidv4(), name: activity });
    this.TaskWOrkFlowActivity = Array.from(new Set(this.TaskWOrkFlowActivity.map((a) => a.id))).map((id) => {
      return this.TaskWOrkFlowActivity.find((a) => a.id === id);
    });
  }
  addTask(): void {
    this.WorkFlowTasks.push({});
    this.tasks++;
  }
  async save(payload: any): Promise<void> {
    try {
      // await this.validate();
      this.isLoading = true;
      // this.WorkFlowTasks.forEach((task: any) => {
      //   task.WorkFlowTaskGUID = uuidv4();
      // });
      this.payload.WorkFlowTasks = this.WorkFlowTasks;
      const response = this.isUpdate ? await this.service.update(payload) : await this.service.create(payload);
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      // this.globalLoader(false);
      this.isLoading = false;
      this.reloadStore();
    } catch (error) {
      this.handleError(error);
    }
  }
}
