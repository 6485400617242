
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    buttonLoading: Boolean,
    classes: String
  },
  emits: ["click"]
})
export default class ButtonComponent extends Vue {}
