import { BaseService } from "@/shared/services/base-service";
import { AppResponse } from "@/shared/interfaces/app-response";

export class CMAMatterService extends BaseService {
  resource = 'ClientMatterAcceptanceProcess'; 
  findOne(id: string): Promise<AppResponse> {
        return this.get(`ClientMatterAcceptanceReport/${id}`);
      }
  findOnePending(id: string): Promise<AppResponse> {
        return this.get(`Get/${id}`);
      }
  reminder(id: string): Promise<AppResponse> {
    return this.get(`RespondentsReminder/${id}`);
  }
  approve(id: string): Promise<AppResponse> {
    return this.get(`ApproveOnBoardCMP/${id}`);
  }
  disapprove(id: string): Promise<AppResponse> {
    return this.get(`DisapproveCMP/${id}`);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }

  addCMA(newData : Record<string, unknown>) : Promise<AppResponse> {
    return this.post(`Add`, newData);
  }

//   deleteMemo(id : string) : Promise<AppResponse> {
//     return this.get(`ClientMemos/Delete/${id}`);
//   }
}

export class CMACustomFields extends BaseService {
  resource = 'CustomFields';
  findAll(id: string): Promise<AppResponse> {
    return this.get(`GetAllCustomFieldsByEntityType/3`);
  }
  addCustomValues(data: any): Promise<AppResponse> {
    return this.post(`CustomFieldValues/Add`, data);
  }
}
export const cmaCustomField = new CMACustomFields();
// export const countryService = new CompaniesService3();
const CMAService = new CMAMatterService();
// export const filterData = new DataFilter();
export default CMAService;
