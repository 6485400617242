import { AppResponse } from "../../../../shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

export class PipelineService extends BaseService {
  resource = "LeadStages";

  getPipelines(): Promise<AppResponse> {
    return this.get("GetLeadStages");
  }

  removePipeline(id: number): Promise<AppResponse> {
    return this.post("Remove", { id });
  }
}

export const pipelineService = new PipelineService();
