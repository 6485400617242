import { BaseService } from "../../../shared/services/base-service";

class TrackedActivity extends BaseService {
  resource = "Time/Entries";

  getTrackedActivities(params : Record<string, unknown | string>) {
    console.log("The params are: ", params);
    return this.get("UserEntriesByCategory", {params});
  }

  deleteEntry(id : string) {
    console.log("Time entry id: ", id);
    return this.get(`DeleteTimeEntry/${id}`)
  }

  makeTimeEntries(payload : any) {
    return this.post("MakeTImeSheetEntries", payload);
  }

  makeTimeEntry(payload : any) {
    return this.post("MakeTImeSheetEntry", payload);
  }
}

class TrackedActivity2 extends BaseService {
  resource = "Employees";

  getTimeOwners(userId : number | string) {
    return this.get(`TimeKeeperEmployees/${userId}`)
  }
}

export const activityService = new TrackedActivity();
export const activityService2 = new TrackedActivity2();