import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class contactCategoryService extends BaseService {
  resource = 'PLOV/PeopleCategories'; // insert Api resource here

  findAll(): Promise<AppResponse> {
    return this.get('');
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  update(payload: any): Promise<AppResponse> {
    return this.post(`Update`, payload);
  }


  removeCategory(Id: string): Promise<AppResponse> {
    return this.get(`Delete/${Id}`);
  }
}

export const ContactCategoryService = new contactCategoryService();
