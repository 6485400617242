
import { Options, Vue, mixins } from "vue-class-component";
import { crmDashboardService } from "././../../dashboard/crm/service/crmDashboard.service";
import { CrmDashboardModel } from "././../../dashboard/crm/model/crmDashboard.model";
import NCApp from "@/app/index";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { clientReportService } from "@/modules/clients/clients.service";
import { DashboardTables } from "../dashboard-tables";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import SplineChart from "@/components/NCSplineChart.vue";

@Options({
  components: {
    CircleProgress,
    SplineChart,
  },
})
export default class CrmDashboard extends mixins(DataTableMixin) {
  app = NCApp;
  service = crmDashboardService;
  result: CrmDashboardModel | Record<string, unknown> = {};
  PracticeGroupOpportunity: any = {};
  PracticeGroupOpportunityDetail: any = {};
  practiceGroupOpportunityGraph: any[] = [];

  PipelineConversion: any = {};
  AllOpportunity: any = {};
  WonOpportunity: any = {};
  OtherOpportunity: any = {};
  LostOpportunity: any = {};
  mainStages = this.AllOpportunity[0];
  AllStages = this.AllOpportunity[0];
  PracticeGroupOpportunitiees = this.PracticeGroupOpportunity[0];
  PracticeGroupOpportunityDetails = this.PracticeGroupOpportunityDetail[0];
  LostOpportunityReasons: any = {};
  TopFiveOpportunities = this.AllOpportunity[0];
  WinLossOpportunities = this.AllOpportunity[0];

  recentMatter = [];
  recentActivities = [];
  recentOpportunitesFields = [];

  recentOpportunitiesFields = DashboardTables.recentOpportunities;
  recentMatterFields = DashboardTables.recentMatter;
  activitiesFields = DashboardTables.recentActivities;

  async created(): Promise<void> {
    await this.getCRMDashboardData();
    await this.getMatterAndTimeData();
    this.plotGraph();
  }

  async getCRMDashboardData(): Promise<void> {
    try {
      this.isLoading = true;
      this.result = (await this.service.findAll("2022")).Data;
      const {
        PipelineConversion,
        AllOpportunity,
        WonOpportunity,
        OtherOpportunity,
        LostOpportunity,
        MainStages,
        AllStages,
        PracticeGroupOpportunities,
        PracticeGroupOpportunityDetails,
        LostOpportunityReasons,
        TopFiveOpportunities,
        WinLossOpportunities,
      } = this.result;

      this.PipelineConversion = PipelineConversion;
      this.AllOpportunity = AllOpportunity;
      this.WonOpportunity = WonOpportunity;
      this.OtherOpportunity = OtherOpportunity;
      this.LostOpportunity = LostOpportunity;
      this.mainStages = MainStages;
      this.AllStages = AllStages;
      this.PracticeGroupOpportunitiees = PracticeGroupOpportunities;
      this.PracticeGroupOpportunityDetails = PracticeGroupOpportunityDetails;
      this.LostOpportunityReasons = LostOpportunityReasons;
      this.TopFiveOpportunities = TopFiveOpportunities;
      this.WinLossOpportunities = WinLossOpportunities;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMatterAndTimeData(): Promise<void> {
    try {
      this.isLoading = true;
      const matterResponse = await clientReportService.getLandingDashboard();
      const matterSummary = matterResponse.Data;
      const { RecentActivities, RecentMatters } = matterSummary;

      this.recentMatter = RecentMatters.RecentMatterSummaries;
      this.recentActivities = RecentActivities;

      console.log("recent matter", this.recentMatter);
      console.log("recent activity", this.recentActivities);
    } catch (error) {
      this.handleError(error);
    }
  }

  plotGraph(): void {
    for (var e in this.PracticeGroupOpportunityDetails) {
      this.practiceGroupOpportunityGraph.push({
        category: this.PracticeGroupOpportunityDetails[e].DealName,
        value: this.PracticeGroupOpportunityDetails[e].DealValue,
      });
    }
    console.log("chart data is ", this.practiceGroupOpportunityGraph);
  }
}
