<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main ">
      <!-- wrap @s -->
      <div class="nk-wrap nk-wrap-nosidebar">
        <!-- content @s -->
        <div class="nk-content ">
          <div class="nk-block nk-block-middle wide-md mx-auto">
            <div class="nk-block-content nk-error-ld text-center">
              <img class="nk-error-gfx" src="/images/gfx/error-404.svg" alt="" />
              <div class="wide-xs mx-auto">
                <h3 class="nk-error-title">Hi, Tenant Not found </h3>
                <p class="nk-error-text">
                  We are very sorry for inconvenience. It looks like you’re try to access a page that either has been
                  deleted or never existed.
                </p>
                <a href="/" class="btn btn-lg btn-primary mt-2">Back To Home</a>
              </div>
            </div>
          </div>
          <!-- .nk-block -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- content @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<style scoped>
.nk-wrap-nosidebar .nk-content {
  padding: 0 !important;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}
.nk-wrap {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}
</style>