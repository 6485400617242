
import { Vue, mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import AcceptanceForm from "./cmaMatterForm.vue";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import CMAService from "./cmaMatter.service";

@Options({
  components: {
    VueEditor,
    AcceptanceForm,
  },
})
export default class CMA extends mixins(DataTableMixin) {
  formEvent = FormEvents.CMA_MATTER_FORM;
  componentKey = 0;
  isLoading = true;

  get cmaData() {
    return [];
  }
  renderTable() {
    this.componentKey += 1;
  }
  openCMAForm() {
    // console.log('open sesame', this.formEvent);
    GlobalEvent.emit(this.formEvent);
  }
  deleteCMA(id: string, self: any) {
    // console.log("the id is", id);
    self.$swal
      .fire({
        title: "Are you sure you want to delete this Client/Matter Acceptance?",
        text: "This lead will be removed along with all associated data.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async () => {
        CMAService.remove(id).then(async (response) => {
          if (response.Success === true) {
            try {
              self.isLoading = false;
              self.toast("success", response.Message);
              await this.renderTable();
            } catch (error) {
              self.isLoading = false;
              self.toast("error", response.Message);
              self.handleError(error);
            }
          } else {
            self.isLoading = false;
            self.toast("error", response.Message);
          }
        });
        return;
      });
  }

  fields = [
    {
      title: "SNO",
      name: "SNO",
    },
    {
      title: "Client",
      name: "ClientName",
    },
    {
      title: "Matter",
      name: "MatterTitle",
    },
    {
      title: "Industry",
      name: "IndustryName",
    },
    {
      title: "Agreed Fee",
      name: "AgreedFee",
    },
    {
      title: "Payment Terms",
      name: "PaymentTerms",
    },
    {
      title: "Time Frame",
      name: "TimeFrame",
    },
    {
      title: "Owner",
      name: "TransactionOwnerName",
    },
    {
      title: "Date",
      name: "FormattedDateCreated",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        self.$router.push({ name: "CMAView", params: { id: prop.Id } });
      },
    },
  ];

  tableActionsPending = [
    {
      name: "Review",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        self.$router.push({ name: "CMAReview", params: { id: prop.Id } });
      },
    },
    {
      type: "divider",
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any, self: any): Promise<void> => {
        // this.deleteData(prop, self);
      },
    },
  ];

  handleTableReady() {
    this.isLoading = false;
    
  }
 
}
