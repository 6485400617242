import { Options, Vue } from "vue-class-component";
import { authStore } from "../../modules/auth/auth.store";

@Options({})
export default class DataTableMixin extends Vue {
  fields: { title: string; name: string }[] = [
    {
      title: "SNO",
      name: "SNO"
    }
  ];

  get baseUrl(): string {
    return authStore.tenantConfiguration.AppUrl as string;
  }

  get token(): string {
    return authStore.auth.token as string;
  }

  get options(): Record<string, any> {
    return {
      ...this.optionstemp,
      auth: {
        baseUrl: this.baseUrl,
        token: this.token
      }
    };
  }

  topRowClasses = ["row", "justify-between", "g-2"];
  footerClasses = ["row", "align-items-center"];
  optionstemp = {
    css: {
      tableDiv: "simple-table",
      table: "nk-tb-list nk-tb-ulist display",
      theadTr: "nk-tb-item nk-tb-head",
      theadTh: "nk-tb-col",
      theadTitle: "sub-text",
      tbodyTr: "nk-tb-item",
      tbodyTd: "nk-tb-col",
      tbodyTdText: "tb-lead o2"
    }
  };

  datatableOptions = {
    lengthMenu: [
      [25, 50, 100, 200, 100000],
      [25, 50, 100, 200, "All"]
    ],
    // lengthMenu: `<span class='d-none d-sm-inline-block'>Show</span><div class='form-control-select'> _MENU_ </div>`,
    buttons: {
      buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],

      exportOptions: {
        columns: ":visible"
      }
    },

    // columns: columnData,
    dom: `<"${this.topRowClasses.join(
      " "
    )}"<"col-9 col-sm-6 text-left mb-2"f><"dt_length2 col-3 col-sm-6 mb-2 text-right"Bl>><r><"datatable-wrap my-0"t><"${this.footerClasses.join(
      " "
    )}"<"col-7 col-sm-12 col-md-9 mt-3"p><"col-5 col-sm-12 col-md-3 text-left text-md-right"i>>`,
    // dom: `<"${this.topRowClasses.join(
    //   " "
    // )}"<"col-7 col-sm-6 text-left"f><<B<"datatable-filter mt-2  mb-0"l>>><"col-5 col-sm-6 text-right">><"datatable-wrap my-3"t><"${this.footerClasses.join(
    //   " "
    // )}"<"col-7 col-sm-12 col-md-9"p><"col-5 col-sm-12 col-md-3 text-left text-md-right"i>>`,
    language: {
      search: "",
      searchPlaceholder: "Type in to Search",
      lengthMenu: `<span class='custom-control-lg '> _MENU_ </span>`,
      info: "_START_ -_END_ of _TOTAL_",
      infoEmpty: "No records found",
      infoFiltered: "( Total _MAX_  )",
      paginate: {
        first: "First",
        last: "Last",
        next: "Next",
        previous: "Prev"
      }
    }
    // serverSide: main.serverSide,
    // processing: main.serverSide
  };

  // processData() {}
}
