import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nk-block tab-pane pt-1",
  id: "matterBills"
}
const _hoisted_2 = { class: "nk-block-head nk-block-head-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pfizer_table = _resolveComponent("pfizer-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_pfizer_table, {
        fields: _ctx.fields,
        "table-data": _ctx.Data,
        options: _ctx.options,
        "datatable-options": _ctx.datatableOptions,
        primaryKey: "Id",
        tableId: "matters-bills-table"
      }, null, 8, ["fields", "table-data", "options", "datatable-options"])
    ])
  ]))
}