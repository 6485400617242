
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../constants";
import ModalFormMixin from "../../../shared/mixins/modal.mixin";
import { tenantsService } from "@/modules/tenants/tenants.service";

@Options({})
export default class PersonnelSetting extends mixins(ModalFormMixin) {
  formEvent = FormEvents.PERSONNEL_SETTINGS_FORM;
  modalId = "personnel-settings-form";
  defaultOptions = [
    {
      text: "Yes",
      value: "Yes",
    },
    {
      text: "No",
      value: "No",
    },
  ];

  payload = {
    test: "Yes",
  };

  handleSettingName(settingName: string) {
    return settingName.split(/(?=[A-Z])/).join(" ");
  }

  options = {
    AccountModule: this.defaultOptions,
    AccountType: ["Accrual", "Cash"],
    LeaveSubmission: this.defaultOptions,
    DashboardType: [
      {
        text: "Both",
        value: "Both",
      },
      {
        text: "Objective",
        value: "Objective",
      },
      {
        text: "Subjective",
        value: "Subjective",
      },
      {
        text: "Task Subjective",
        value: "TaskSub",
      },
    ],
    UseHRLogin: this.defaultOptions,
    AppraisalType: [
      {
        text: "Both",
        value: "Both",
      },
      {
        text: "Objective",
        value: "Objective",
      },
      {
        text: "Subjective",
        value: "Subjective",
      },
      {
        text: "Task Subjective",
        value: "TaskSub",
      },
    ],
    // appraisalTypes: this.dashboardTypes
    UseGoalApp: [
      {
        text: "Goal Only",
        value: "GoalOnly",
      },
      {
        text: "Goal Task",
        value: "GoalTask",
      },
      {
        text: "Task Only",
        value: "TaskOnly",
      },
    ],
    BypassFortyEightHours: this.defaultOptions,
    BypassReplacer: this.defaultOptions,
    BypassFundVerification: this.defaultOptions,
    LeavePartnerApproval: this.defaultOptions,
    PettyCashAccount: this.defaultOptions,
    CashRequestToPMwithLimit: this.defaultOptions,
    DefaultClientExpenseLimit: "",
    UseExemption: this.defaultOptions,
  };

  handleModalOpen(): void {
    this.getConfig();
  }

  // mounted(): void {
  //   super.mounted();
  //   this.getConfig();
  // }

  async getConfig(): Promise<void> {
    try {
      const response = await tenantsService.getConfigurations();
      this.payload = response.Data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async save(payload: any): Promise<void> {
    try {
      this.isLoading = true;
      const response = await tenantsService.updateConfiguration(payload);
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
