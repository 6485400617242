
import { Options, Vue } from "vue-class-component";
import { leadReportService } from "../report.service";
import { leadService } from "../../leads/leads.service";
import { PageTitles } from "../../../constants";

@Options({})
export default class LeadBySource extends Vue {
  breadcrumbLinks = ["Home", "Lead By Source"];
  report = [];
  sources = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "LeadSource", title: "Lead Source" },
    { name: "LeadOwnerFullName", title: "Lead Owner" },
    { name: "DealValue", title: "Value" },
    { name: "LeadStageName", title: "Status" },
    { name: "DateCreatedFormatted", title: "Date Created" }
  ];
  payload = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    Value: "",
    LeadSources: [] as any
  };
  created() {
    document.title = PageTitles.CRMLeadbySource;
    this.init();
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await leadService.GetLeadSources();
      this.sources = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.LeadSources.length == 0) payload.LeadSources = null;
      if (this.payload.Keyword == null) payload.Keyword = "";
      const response = await leadReportService.LeadSourceSummaryReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
