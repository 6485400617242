
import { Options, Vue } from "vue-class-component";
import { settingsStore } from "../settings.store";
import Sidenav from "./sidenav.vue";
@Options({
  components: { Sidenav }
})
export default class Settings extends Vue {
  mounted() {
    settingsStore.findAllPostingGroups();
    settingsStore.getActivities();
  }

  created() {
    settingsStore.getTME();
    settingsStore.getINV();
    settingsStore.getChartOfAccounts();
    settingsStore.getFinPosition();
    settingsStore.getIncomeStatement();
    settingsStore.getBaseAccountTypes();
    settingsStore.getAccountTypes();
    this.loadJS();
  }
  // created() {
  //   this.loadJS();
  // }
}
