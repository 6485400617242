import { AppResponse } from "../../shared/interfaces/app-response";
import { BaseService } from "../../shared/services/base-service";

class FinanceService extends BaseService {
  resource = "Account";

  getBaseAccountTypes(): Promise<AppResponse> {
    return this.get("GetBaseAccountTypes");
  }

  getAccountTypes(): Promise<AppResponse> {
    return this.get("GetAccountType");
  }

  moveAccountUp(AccountTypeId: number) {
    return this.post("MoveAccountUp", { AccountTypeId });
  }

  moveAccountDown(AccountTypeId: number) {
    return this.post("MoveAccountDown", { AccountTypeId });
  }

  addAccountType(payload: { BaseAccountTypeId: number; ParentAccountTypeId: number; TypeName: string }) {
    return this.post("AddAccountType", payload);
  }
}

export const financeService = new FinanceService();
