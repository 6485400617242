
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  // inject: {
  //   v$: "foo",
  // },
})
export default class ValidateField extends Vue {
  @Prop() name!: string;
  @Prop() validation!: any;

  // @Inject("validation") validation: any;

  created() {
    // console.log("ValidateField", this.validation);
  }

  getData(object: Record<string, any>, key: string) {
    const keys = key.split(".");
    let prop = object;
    keys.forEach((key) => {
      prop = prop[key];
    });

    return prop;
  }
}
