/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from "axios";
import { AppResponse } from "../interfaces/app-response";

export class Helpers {
  static handleResponse(response: AxiosResponse): AppResponse {
    const output: AppResponse = response.data;

    if (output.Success) {
      return output;
    } else if (output.Success == false) {
      throw output;
    }

    return output;
  }

  static error(message: string): void {
    throw new Error(message);
  }

  // static generateId() {
  //   return base64.encode(uuid());
  // }

  // static handleDelete(func: () => Promise<void>, id: number) {

  //     console.log("remove event", id, this.handleDelete);
  //     // eslint-disable-next-line @typescript-eslint/no-this-alias
  //     const main = this;
  //     main
  //       .$swal({
  //         title: "Are you sure?",
  //         text: "You won't be able to revert this!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes, delete it!"
  //       })
  //       .then(async result => {
  //         if (result.value) {
  //           this.isLoading = true;
  //           try {
  //             const response = await this.handleDelete.remove(id);
  //             console.log({ response });
  //             main.$swal("Deleted!", "Item has been deleted", "success");
  //             await this.reloadStore();
  //             this.isLoading = false;
  //             this.toast("success", response.Message);
  //           } catch (error) {
  //             this.isLoading = false;
  //             this.handleError(error);
  //           }
  //         }
  //       });

  // }

  // static emit(id: string, data: any): void {
  //   emitter.emit(id, data);
  // }
}
