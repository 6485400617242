
import { mixins, Options } from "vue-class-component";
import { Provide } from "vue-property-decorator";
import newCourtUpdateForm from "./new-court-update.vue";
import CourtUpdateDetailPage from "./courtupdates-detail2.vue";
import { courtUpdatesService } from "../courtupdates/services/courtupdates.service";
import { courtUpdateMatterService } from "../courtupdates/services/courtupdatesinput.service";
import { FormEvents } from "@/constants";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
@Options({
  components: { newCourtUpdateForm, CourtUpdateDetailPage },
})
export default class CourtUpdatesPage extends mixins(DataTableMixin) {
  @Provide() handleDelete = courtUpdatesService;
  formEvent = FormEvents.NEW_COURT_UPDATE;
  formEvent2 = FormEvents.VIEW_COURT_UPDATE;
  service = courtUpdatesService;
  courtUpdates: any = [];
  isLoading = true;

  mounted() {
    GlobalEvent.on("courtUpdateForm", async (event: unknown) => {
      await courtUpdatesService.getCourtUpdatesData().then((res) => {
        this.courtUpdates = res.data;
      });
    });
  }
  handleTableReady() {
    this.isLoading = false;
  }

  openNewCourtUpdateForm(payload?: Record<string, unknown>): void {
    // GlobalEvent.emit(this.formEvent);
    if (payload && payload.Id) {
      console.log("its edith");

      GlobalEvent.emit(this.formEvent, payload);
    } else {
      GlobalEvent.emit(this.formEvent);
    }
  }

  openViewCourtUpdateForm(payload?: Record<string, unknown>): void {
    GlobalEvent.emit(this.formEvent2, payload);
  }

  fields = [
    {
      title: "SNO",
      name: "SNO",
    },
    {
      title: "Matter Title",
      name: "Matter.MatterTitle",
    },

    {
      title: "Suit Number",
      name: "SuitNumber",
    },

    {
      title: "Court",
      name: "Court",
    },

    {
      title: "Hearing Date",
      name: "HearingDateFmtSecond",
    },
    {
      title: "Next Date",
      name: "AdjournDateFmtSecond",
    },

    {
      title: "Status",
      name: "StatusDescription",
    },

    { name: "actions", title: "Actions", showTitle: false },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  deleteMatterFromCourtUpdate(id: string, self: any) {
    console.log("the id is", id);
    self.$swal
      .fire({
        title: "Are you sure you want to Delete the Court Update?",
        text: "All the detail of this court update will be removed",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result: Record<string, unknown>) => {
        console.log("result object from court update delete", result);
        courtUpdateMatterService.delete(id).then(async (response) => {
          if (response.Success === true) {
            try {
              self.isLoading = false;
              self.toast("success", response.Message);
              await this.service.getCourtUpdatesData();
            } catch (error) {
              self.isLoading = false;
              self.toast("error", response.Message);
              self.handleError(error);
            }
          } else {
            self.isLoading = false;
            self.toast("error", response.Message);
          }
        });
        return;
      });
  }

  tableActions = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: Record<string, unknown>, self: any): Promise<void> => {
        try {
          // self.$router.push({ name: "CourtUpdatesDetailPage", params: { id: prop.Id } });
          console.log("ID passed to detail page", prop);
          this.openViewCourtUpdateForm(prop);
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: async (prop: Record<string, unknown>): Promise<void> => {
        try {
          this.openNewCourtUpdateForm(prop);
        } catch (error) {
          console.log(error);
        }
      },
    },
    { type: "divider" },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any, self: any) => {
        this.deleteMatterFromCourtUpdate(prop.Id, self);
      },
    },
  ];
}
