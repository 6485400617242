
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "@/modules/settings/settings.store";
import { Provide } from "vue-property-decorator";
import memberform from "./member-form.vue";
import BillGroupForm from "./billgrouprate.modal.vue";
import AddBillGroupForm from "./addbillgroup.vue";
import { billGroupService } from "./billgroup.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

import { BillGroup } from "./model/billgroup.model";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";

@Options({
  components: { memberform, BillGroupForm, AddBillGroupForm },
})
export default class BillGroupsDetails extends mixins(DataTableMixin) {
  formEvent = FormEvents.ADD_BILL_GROUP;
  @Provide() handleDelete = billGroupService;
  service = billGroupService;
  reloadStore = settingsStore.getAllBillGroups;
  currentBillGroup!: BillGroup;

  get billGroups(): BillGroup[] {
    return settingsStore.billGroups;
  }

  fields = [
    {
      title: "Group",
      name: "BillGroupName",
    },

    { name: "actions", title: "Actions", showTitle: false },
  ];

  tableActions = [
    {
      name: "Members",
      iconClass: "icon ni ni-user-alt",
      showIcon: true,
      action: async (prop: any): Promise<void> => {
        const billGroup = await billGroupService.findOne(prop.Id);
        GlobalEvent.emit(FormEvents.MEMBER_FORM, billGroup.Data);
      },
    },
    {
      name: "Rates",
      iconClass: "icon ni ni-sign-usd",
      showIcon: true,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.BILL_GROUP_RATE, prop);
      },
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any) => {
        console.log("delete Id", prop.Id);
        this.removeTableData(billGroupService.remove, prop.Id);
      },
    },
  ];

  onClick(data: BillGroup) {
    this.currentBillGroup = data;
    console.log({ data });
    this.modal("rates-view", true);
  }
}
