
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "@/constants/index";
import companiesService from "./companies.service";
import { companiesService2 } from "./companies.service";
import NCApp from "@/app/index";
import { crmStore } from "../crm.store";
import CompanyForm from "./companyForm.vue";
import ContactForm from "../contact/contactForm.vue";
import LeadForm from "../leads/new-leads-form.vue";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: {
    CompanyForm,
    ContactForm,
    LeadForm
  }
})
export default class CompaniesPage extends mixins(DataTableMixin) {
  formEvent = FormEvents.COMPANIES_FORM;
  app = NCApp;
  customKey = "company-data-table";
  isLoading = true;

  optionsData = [
    {
      name: "New Company",
      id: 'menu-item-0',
      formEventName: this.formEvent,
      hasPermission: this.app.hasPermission('0x24'),
    },
    {
      name: "New Contact",
      id: 'menu-item-1',
      formEventName: FormEvents.CONTACT_FORM,
      hasPermission: this.app.hasPermission('0x28'),
    },
    {
      name: "New Lead",
      id: 'menu-item-2',
      formEventName: FormEvents.ADD_LEADS,
      hasPermission:this.app.hasPermission('0x1C'),
    },
  ]
  
  created(): void {
    crmStore.companies;

    GlobalEvent.on("company-datatable-key", (_ : unknown) => {
      this.customKey = this.customKey == "company-data-table" ? "company-data-table1" : "company-data-table";
    })
  }

  get companies() {
    return [];
  }
  
   handleTableReady() {
    this.isLoading = false;
  }
 

  openCompanyForm(): void {
    GlobalEvent.emit(this.formEvent);
  }

  openContactForm(): void {
    GlobalEvent.emit(FormEvents.CONTACT_FORM);
  }

  openLeadForm(): void {
    GlobalEvent.emit(FormEvents.ADD_LEADS);
  }

  deleteData(prop: any, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            self.isLoading = true;
            const response = await companiesService.remove(prop.Id);
            self.isLoading = false;
            self.toast("success", response.Message);
            GlobalEvent.emit("company-datatable-key");
          } catch (error : any) {
            self.toast("error", error.Message);
          }
        }
      });
  }

  fields = [
    {
      title: "SNO",
      name: "SNo"
    },
    {
      title: "Company Name",
      name: "ClientName"
    },
    {
      title: "Contact Telephone",
      name: "ContactPhoneNumber"
    },
    {
      title: "Contact Email",
      name: "ContactEmail"
    },
    {
      title: "Date Created",
      name: "FormattedDateCreated"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        self.$router.push({ name: "CompanyView", params: { id: prop.Id } });
      }
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: async (prop: any, self: any): Promise<void> => {
        try {
          const companyData = await companiesService.getCompany(prop.Id);
          GlobalEvent.emit(this.formEvent, companyData.Data);
        } catch (error) {
          //this.handleError(error);
        }
      }
    },
    {
      type: "divider"
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any, self: any): Promise<void> => {
        this.deleteData(prop, self);
      }
    }
  ];
}
