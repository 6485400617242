
  import {mixins, Options} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {GlobalEvent} from "@/shared/plugins/tiny-emitter";
  import { FormEvents } from '@/constants';
  import {leadsResourceService} from './leads-detail.service';
  import {VueEditor} from "vue3-editor";

  @Options({
    components: {
      VueEditor
    },
    emits: ["reloadNote"],

  })
  export default class AddNoteForm extends mixins(ModalFormMixin) {
    modalId = 'add-note-form';
    formEvent = FormEvents.ADD_NOTE;
    service = leadsResourceService;
   
    formData = {
      ModuleId: 0,
      ModuleType: 1,    
      NoteContent: ""
    };

    async saveData(): Promise<void> {
      try {
        this.isLoading = true;
        this.formData.ModuleId = this.payload.id;
        console.log("The form data is: ", this.formData, this.payload);
        const response = await this.service.addNotes(this.formData);
        this.isLoading = false;
        this.toast("success", "Note added successfully");
        this.formData = {
          ModuleId: 0,
          ModuleType: 1,
          NoteContent: ""
        };
        GlobalEvent.emit("reloadNote");
      this.modal(this.modalId, false);

      } catch (error) {
        this.handleError(error);
      }
    }
  }
