
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { financeService } from "../../../finances/finances.service";

@Options({})
export default class AddSubAccount extends mixins(ModalFormMixin) {
  modalId = "add-sub-account-form";
  formEvent = FormEvents.ADD_SUBACCOUNT_FORM;

  async save(payload: any): Promise<void> {
    try {
      this.isLoading = true;
      const response = await financeService.addAccountType(payload);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
