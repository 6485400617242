import { BaseService } from "@/shared/services/base-service";
import { AppResponse } from "@/shared/interfaces/app-response";
import { BillDetailsDto } from "../../matters/bills/dto/bill-details.dto";
import { AddBillItemDto } from "../../matters/bills/dto/add-bill-item.dto";

export class BillService extends BaseService {
  resource = "MatterBills"; //MyMatterBills
  findOne(id: string): Promise<AppResponse> {
    return this.get(`MyMatterBills`);
  }
  findOnePending(id: string): Promise<AppResponse> {
    return this.get(`Get/${id}`);
  }
  reminder(id: string): Promise<AppResponse> {
    return this.get(`RespondentsReminder/${id}`);
  }
  approve(id: string): Promise<AppResponse> {
    return this.get(`ApproveOnBoardCMP/${id}`);
  }
  disapprove(id: string): Promise<AppResponse> {
    return this.get(`DisapproveCMP/${id}`);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Delete/${id}`);
  }

  addCMA(newData: Record<string, unknown>): Promise<AppResponse> {
    return this.post(`Add`, newData);
  }

  details(payload: BillDetailsDto) {
    return this.post("BillDetails", payload);
  }

  addItem(payload: AddBillItemDto) {
    return this.post("MatterBillDetails/Add", payload);
  }

  //   deleteMemo(id : string) : Promise<AppResponse> {
  //     return this.get(`ClientMemos/Delete/${id}`);
  //   }
}

// export class CMACustomFields extends BaseService {
//   resource = 'CustomFields/';
//   findAll(id: string): Promise<AppResponse> {
//     return this.get(`GetAllCustomFieldsByEntityType/3`);
//   }
//   addCustomValues(data: any): Promise<AppResponse> {
//     return this.post(`CustomFieldValues/Add`, data);
//   }
// }

const billService = new BillService();
// export const filterData = new DataFilter();
export default billService;
