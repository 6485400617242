
import { mixins, Options } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import { settingsStore } from "../../settings.store";
import { practiceGroupsService } from "./practice-group.service";

@Options({})
export default class PracticeGroupandAreaForm extends mixins(ModalFormMixin) {
  // created() : void {
  //   this.loadJS();
  // }

  // created() {
  //   // settingsStore.AllUsers();
  // }

  formEvent = FormEvents.PRACTICE_GROUP_AND_AREA_FORM;
  modalId = "practice-group-and-area-form";
  service = practiceGroupsService;
  primaryKey = "PracticeGroupId";
  reloadStore = settingsStore.findAllPracticeGroups;

  // payload = {
  //   supervising: [284, 88, 49, 20, 24, 143]
  // };

  get users() {
    return settingsStore.users;
  }

  savedata(payload: any) {
    console.log({ payload });
  }
}
