import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "areachartdiv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Line, {
      "chart-options": _ctx.chartOptions,
      "chart-data": _ctx.chartData,
      "chart-id": _ctx.chartId,
      "dataset-id-key": "label",
      width: 100,
      height: 100
    }, null, 8, ["chart-options", "chart-data", "chart-id"])
  ], 512))
}