
import { mixins, Options } from 'vue-class-component';
import ModalFormMixin from '../../../../shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import { activityService2 } from './activity.service';
import { settingsStore } from '../../settings.store';

@Options({})
export default class ActivityForm extends mixins(ModalFormMixin) {
  reloadStore = settingsStore.getActivityTypes;
  modalId = 'show-activity-form';
  formEvent = FormEvents.ACTIVITY_TYPE_FORM;
  service = activityService2;
  primaryKey = "Id"
  formData = {
    ActivityName : "",
    ActivityGroup : "",
    DefaultDescription : ""
  }
  get activityGroups() : string[] {
    const groupList = settingsStore.activityGroups;
    return groupList;
  }

  async addData(): Promise<void> {
    try {
      this.isLoading = true;
      // await this.validate();
      // this.globalLoader(true);
      const response = await this.service.create(this.formData);
      // this.toastSuccess(response.Message);
      // this.globalLoader(false);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.reloadStore();
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
