
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, mixins } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { InvoiceSignatures } from "./signature.model";
import signatureSettingsForm from "./signatureSettingsForm.vue";
import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";
import { SignatureService } from "./signature.service";
import DataTableMixin from "@/shared/mixins/datatable.mixin";

@Options({
  components: { signatureSettingsForm }
})

export default class signatureDetails extends mixins(DataTableMixin) {
  reloadStore = settingsStore.getSignatures;
  formEvent = FormEvents.SIGNATURE_FORM;

  get signatures(): InvoiceSignatures[] {
    return settingsStore.signatures;
  }

  created(): void {
    this.loadJS();
  }

  deleteSignature(id: string, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: any) => {
        if(result.isConfirmed === true){
          SignatureService.remove(id).then(response => {
            if (response.Message === "Successful") {
              try {
                self.isLoading = true;
                this.reloadStore();
                self.isLoading = false;
                self.toast("success", "Signature deleted");
              } catch (error) {
                self.handleError(error);
              }
            }else if (response.Success === false){
                self.isLoading = false;
                self.toast("Error", response.Message);
                }
          });
        }else{
          // console.log("delete was cancelled");
        }
      });
  }

  fields = [
    {
      title: "Name",
      name: "SignatureName"
    },

    {
      title: "Signature",
      name: "FileName"
    },
    { name: "actions", title: "", showTitle: false }
  ];

  tableActions = [
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any, self: any) => {
        this.deleteSignature(prop.Id, self);
      }
    }
  ];
}
