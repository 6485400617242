import { AppResponse } from '../../../../shared/interfaces/app-response';
import { BaseService } from '../../../../shared/services/base-service';

export class signatureService extends BaseService {
  resource = 'InvoiceSignatures'; // insert Api resource here

 findAll(): Promise<AppResponse> {
    return this.get(`GetAll`);
  }

  findOne(id: string): Promise<AppResponse> {
    return this.get(`Get/${id}`);
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`Add`, payload);
  }

  remove(id: string): Promise<AppResponse> {
    return this.get(`Remove/${id}`);
  }
}

export const SignatureService = new signatureService();


export class uploadSignatureService extends BaseService {
  resource = 'Tenants'; // insert Api resource here

  upload(payload: FormData): Promise<AppResponse> {
    return this.post(`UploadGeneralFile`, payload);
  }

  update(payload: {projectLabel: string}): Promise<AppResponse> {
    return this.post(`UpdateProjectLabel`, payload);
  }

}

export const UploadSignatureService = new uploadSignatureService();

