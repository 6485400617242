import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "../../../../shared/services/base-service";

export class FinancialConfigurationService extends BaseService {
  resource = "Account";

  findAll(pagination?: any): Promise<AppResponse> {
    return this.get("GetPostingAccounts");
  }

  create(payload: Record<string, unknown> | unknown[]): Promise<AppResponse> {
    return this.post(`UpdatePayableAndVAT`, payload);
  }
}

export const financialConfigurationService = new FinancialConfigurationService();
