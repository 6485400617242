
import { Options, Vue } from "vue-class-component";
import SettingItem from "@/modules/settings/components/settings-item.vue";
import FinancialConfiguration from "./configuration/financialConfiguration.vue";
import FinanceJournal from "./financeJournal/financeJournal.vue";

import { FormEvents } from "../../../constants";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { settingsStore } from "../settings.store";

@Options({
  components: { SettingItem, FinancialConfiguration, FinanceJournal },
})
export default class FinancesSettings extends Vue {
  bankAccountsFormEvent = FormEvents.BANK_ACCOUNT_FORM;
  handlePersonnelSettingsClick(): void {
    console.log("checking");
    var payload = {
      IncomeRecognitionMethod: this.globalSettings.IncomeRecognitionMethod,
      whtaccount: this.globalSettings.WHTReceivableAccount,
      vataccount: this.globalSettings.VATPayableAccount,
      clientvataccount: this.globalSettings.ClientsVATAccount,
      vendorsaccount: this.globalSettings.VendorsPayableAccount,
      firstDayOfFiscalYear: this.globalSettings.FirstDayOfFiscalYear,
      firstMonthOfFiscalYear: this.globalSettings.FirstMonthOfFiscalYear,
    };
    GlobalEvent.emit(FormEvents.FINANCE_CONFIGURATION, payload);
  }
  mounted(): void {
    // this.loadJS();
    settingsStore.getGlobalSettings();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get globalSettings() {
    return settingsStore.globalSettings;
  }
}
