
import { Options, Vue } from "vue-class-component";
import { PipelineModel } from "../../crm/pipeline/pipeline.model";
import { settingsStore } from "../../settings/settings.store";
import { leadReportService } from "../report.service";
import { IndustryModel } from "../../settings/crm/industry/industry.model";
import { practiceGroupsService } from "../../settings/matters/practice-groups/practice-group.service";
import { PageTitles } from "../../../constants";

@Options({})
export default class LeadInfoSummary extends Vue {
  breadcrumbLinks = ["Home", "Lead By Industry"];
  report = [];
  fields = [
    { name: "LeadName", title: "Deal Name" },
    { name: "BusinessUnitName", title: "Business Unit" },
    { name: "LeadOwnerFullName", title: "Lead Owner" },
    { name: "DealValue", title: "Value" },
    { name: "LeadStageName", title: "Status" },
    { name: "DateCreatedFormatted", title: "Date Created" }
  ];
  payload = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    Value: "",
    BusinessUnit: [] as any
  };
  units = [];
  created() {
    document.title = PageTitles.CRMLeadbyBusiness;
    this.init();
  }

  get industries(): IndustryModel[] {
    return settingsStore.industries;
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await practiceGroupsService.findAllasBusinessUnits();
      this.units = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async generateReport(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = Object.assign({}, this.payload);
      if (this.payload.BusinessUnit.length == 0) payload.BusinessUnit = null;
      if (this.payload.Keyword == null) payload.Keyword = "";
      const response = await leadReportService.LeadPracticeGroupSummaryReport(payload);
      this.report = response.Data;
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
