import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

export class LeadsResourceService extends BaseService {
  resource = "CRM";

  addNotes(payload: any): Promise<AppResponse> {
    return this.post("Notes/Add", payload);
  }

  findNotes(payload: any): Promise<AppResponse> {
    return this.post("Notes/GetNotes", payload);
  }

  findFIles(payload: any): Promise<AppResponse> {
    return this.post("Files/GetAll", payload);
  }
  deleteNote(id: string): Promise<AppResponse> {
    return this.get(`Notes/Remove/${id}`);
  }
  getMemo(id : string) : Promise<AppResponse> {
    return this.get(`Notes/Get/${id}`);
  }
}

export const leadsResourceService = new LeadsResourceService();

export class LostLeadService extends BaseService {
  resource = "PLOV";

  findAll(): Promise<AppResponse> {
    return this.get("LeadLossReasons");
  }
}

export const lostLeadService = new LostLeadService();

export interface LostLeadReasons {
  Id: number;
  Reason: string;
}
