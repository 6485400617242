
import NCApp from "@/app/index";
import { FormEvents } from "@/constants";
import { settingsStore } from "@/modules/settings/settings.store";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { mixins, Options } from "vue-class-component";
import { formActionsService } from "./form-actions.service";
import CompanyForm from "@/modules/crm/companies/companyForm.vue";
import contactForm from "@/modules/crm/contact/contactForm.vue";

@Options({
  components: {
    CompanyForm,
    contactForm,
  },
  emits: ["detailFormEdit", "newLeadAdded"],
})
export default class newLeadsForm extends mixins(ModalFormMixin) {
  formEvent = FormEvents.ADD_LEADS;
  formEvent2 = FormEvents.COMPANIES_FORM;
  formEvent3 = FormEvents.CONTACT_FORM;

  app = NCApp;

  modalId = "new-leads-form";

  openCompanyForm(): void {
    console.log("open company form click");
    GlobalEvent.emit(this.formEvent2);
  }

  openContactForm(): void {
    console.log("open contact form click");
    GlobalEvent.emit(this.formEvent3);
  }

  mounted() {
    GlobalEvent.on("companySuccess", (_: unknown) => {
      settingsStore.getLeadsCompanies();
    });
    console.log("transaction currencies ", this.transactionCurrencies);
    console.log("industries", this.industries);
    console.log("lead stages", this.leadStages);
    console.log("busienss units", this.businessUnits);
    console.log("companyData", this.companyData);
  }

  get contactData() {
    return settingsStore.leadsContacts;
  }

  get companyData() {
    return settingsStore.leadsCompanies;
  }

  async refresh(): Promise<void> {
    this.payload = {};
  }

  get businessUnits() {
    return settingsStore.businessUnits;
  }

  get transactionCurrencies() {
    return settingsStore.transactionCurrencies;
  }

  get industries() {
    return settingsStore.industries;
  }

  get leadStages() {
    return settingsStore.leadStages;
  }

  get users() {
    return settingsStore.users;
  }

  submitForm(): void {
    if (this.payload.Id) {
      this.update();
    } else {
      this.save();
    }
  }

  async save() {
    try {
      console.log("payload data ", this.payload);
      this.isLoading = true;
      var response = await formActionsService.create(this.payload);
      this.isLoading = false;
      this.toast("success", response.Message);
      GlobalEvent.emit("leads-datatable-key");
      this.modal(this.modalId, false);
    } catch (error) {
      console.log("error ", error);
      this.handleError(error);
    }
  }

  async update() {
    try {
      console.log("payload data ", this.payload);
      this.isLoading = true;
      var response = await formActionsService.update(this.payload);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
      GlobalEvent.emit("leads-datatable-key");
      // GlobalEvent.on("formEvent");
    } catch (error) {
      console.log("error", error);
      this.handleError(error);
    }
  }
}
