import { AppResponse } from "@/shared/interfaces/app-response";
import { BaseService } from "@/shared/services/base-service";

class CrmDashboardService extends BaseService {
    resource = 'CRMReports';

    findAll(year: string, pagination?: any): Promise<AppResponse> {
        return this.get(`CRMPipelineDashboard/${year}`, pagination);
    }
}

export const crmDashboardService = new CrmDashboardService();