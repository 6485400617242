
import { FormEvents } from "@/constants";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { mixins, Options } from "vue-class-component"
import taskService from "./tasks.service";
import StarRating from "vue-star-rating";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";

@Options({
  components : {
    StarRating
  }
})

export default class TaskViewForm extends mixins(ModalFormMixin) {
  modalId = "show-task-view";
  formEvent = FormEvents.TASK_VIEW_FORM;
  service = taskService;

  statusList = [
    {
      label: "Not Started",
      value: 0
    },
    {
      label: "In Progress",
      value: 1
    },
    {
      label: "On Hold",
      value: 2
    },
    {
      label: "Abandoned",
      value: 3
    },
    {
      label: "Completed",
      value: 4
    }
  ];

   async changeStatus(newStatus : Record<string, unknown>) : Promise<void> {
    this.payload.Status = newStatus.value;
    this.payload.StatusDescription = newStatus.label;
    
    try {
      await this.service.updateStatus('UpdateTaskStatus', {Id : this.payload.Id, Status : this.payload.Status});
      this.modal(this.modalId, false);
      GlobalEvent.emit("tasks-datatable-key");
      this.toast("success", "Task Status updated successfully");
    } catch (error) {
      console.log("An error occured while updating status", error);
      this.handleError(error);
    }
  }

  hideModal() : void {
    this.modal(this.modalId, false);
  }

}
