
import { mixins, Options } from "vue-class-component";
import Multiselect from "vue-multiselect";

import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { BaseService } from "@/shared/services/base-service";
import axios, { CancelTokenSource } from "axios";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    Multiselect
  }
})
export default class SwitchForm extends mixins(ModalFormMixin) {
  private cancelSource: CancelTokenSource | null = null;

  @Prop() title!: string;
  @Prop() label!: string;
  @Prop() trackBy!: string;
  @Prop() resource!: string;
  @Prop() routeName!: string;
  @Prop() emitName!: string;
  @Prop() placeholder!: string;
  @Prop() customSearch!: unknown;
  @Prop() customSelect!: unknown;

  modalId = "show-switch-form";
  formEvent = FormEvents.SWITCH_FORM;
  primaryKey = "Id";
  //resource = "Companies/GetSelect2Companies"
  service = new BaseService();
  formLoading = false;

  dataList: Record<string, unknown>[] = [];
  selectedCompany = {};

  async fetchOptions(value: string): Promise<void> {
    try {
      if (value.length > 1) {
        this.formLoading = true;

        this.cancelFetch();
        this.cancelSource = axios.CancelToken.source();

        const response = await this.service.getSelect2(
          `${this.resource}?term=${value}&_type=query&q=${value}`,
          this.cancelSource.token
        );
        // console.log("The search value and response value are: ", value, response);
        this.dataList = (response as unknown) as [];
        this.cancelSource = null;
        this.formLoading = false;
      }
    } catch (error : any) {
      // console.log("An error occured while fetching options", error);
      if (error[0] && error[0][this.trackBy]) {
        this.dataList = error as [];
        this.formLoading = false;
      }
    }
  }

  cancelFetch(): void {
    if (this.cancelSource) {
      this.cancelSource.cancel("Cancelled this ongoing request");
    }
  }

  async fetchSelected(selected: Record<string, unknown>): Promise<void> {
    try {
      // console.log("The jquery is", this.modalId, window.jQuery);
      // window.jQuery(`#${this.modalId}`).modal( "hide");
      this.$router.push({ name: this.routeName, params: { id: selected[this.trackBy] as string } });
      // console.log("A new fn is called: ", selected);
      const fetchId = selected[this.trackBy] as string;
      window.jQuery("#show-switch-form").modal("hide");
      GlobalEvent.emit(this.emitName, fetchId);

      this.selectedCompany = {};
    } catch (error) {
      this.handleError(error);
    }
    // console.log("A new fn is called: ", selected);
  }
}
