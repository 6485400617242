
import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../../constants";
import { settingsStore } from "../../settings.store";
import { PipelineModel } from "./pipeline.model";
import draggable from "vuedraggable";
import { pipelineService } from "./pipeline.service";
import PipelineForm from "./pipeline_modal.vue";

@Options({
  components: { draggable, PipelineForm }
})
export default class Pipelines extends Vue {
  formEvent = FormEvents.PIPELINE_FORM;
  drag = false;

  get dragOptions(): any {
    return {
      animation: 0,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  created(): void {
    this.init();
    this.loadJS();
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await settingsStore.getPipelines();
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  get pipelines(): PipelineModel[] {
    return settingsStore.pipelines;
  }

  checkMove(evt: any) {
    if (
      evt.draggedContext.element.StageName.toLowerCase() == "new" ||
      evt.draggedContext.element.StageName.toLowerCase() == "won" ||
      evt.draggedContext.element.StageName.toLowerCase() == "lost"
    ) {
      return false;
    }
    return true;
  }

  async updatePipelines(): Promise<void> {
    this.isLoading = true;
    try {
      await pipelineService.update(
        this.pipelines.map(pipeline => {
          return {
            Id: pipeline.Id
          };
        })
      );
      // this.pipelines = settingsStore.pipelines;
      this.isLoading = false;
      settingsStore.getPipelines();
    } catch (error) {
      this.handleError(error);
    }
  }

  async deleteWorkFlow(id: number): Promise<void> {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(async (result: { value: boolean }) => {
        if (result.value) {
          try {
            this.isLoading = true;
            await pipelineService.removePipeline(id);
            this.init();
            this.isLoading = false;
          } catch (error) {
            console.log(error);
            this.handleError(error);
          }
        }
      });
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];
}
