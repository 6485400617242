
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { settingsStore } from "../../settings.store";
import { UserModel } from "../../employee-users/models/user.model";
import { AuthResponse, Employee } from "../../../auth/auth.model";
import { employeeService } from "../../employee-users/users.service";
import { authStore } from "../../../auth/auth.store";
// import { settingsStore } from '../../settings.store';

@Options({})
export default class tmeForm extends mixins(ModalFormMixin) {
  modalId = "time-keeper-form";
  formEvent = FormEvents.TIME_KEEPER_FORM;

  created() {
    this.payload.TimeKeeper = authStore.auth.employee?.EmployeeId;
    this.getTimeKeeper();
  }

  get user(): Partial<AuthResponse> {
    return authStore.auth;
  }

  get users(): UserModel[] {
    return settingsStore.users;
  }

  get employees(): Employee[] {
    return settingsStore.employees;
  }

  async getTimeKeeper(): Promise<void> {
    try {
      // await this.validate();
      this.isLoading = true;
      const response = await employeeService.GetTimeKeeperEmployees(this.payload.TimeKeeper);
      this.payload.Employees = response.Data.Employees;
      // this.toast("success", response.Message);
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateTimeKeeper(payload: any): Promise<void> {
    try {
      // await this.validate();
      this.isLoading = true;
      const response = await employeeService.SaveTimeKeeperEmployees(payload);
      this.payload = { ...this.payload, ...response.Data };
      this.toast("success", response.Message);
      this.modal(this.modalId, false);
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }
}
