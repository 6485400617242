
import { mixins, Options, Vue } from "vue-class-component";
import { clientReportService, clientsService } from "./clients.service";
import { ClientModel } from "./models";
import { ClientDashboardTable } from "./client-tables";
import DataTableMixin from "../../shared/mixins/datatable.mixin";

@Options({})
export default class ClientDashboard extends mixins(DataTableMixin) {
  breadcrumbLinks = [{ routeName: "Home", name: "Dashboard" }, { name: "Matters", routeName: "Home" }, "Clients", "View"];

  bills = [];
  contacts = [];
  time = [];
  expenses = [];
  court = [];
  chartData: any[] = [];
  client = {};
  dashboard = {};

  clientId = "";

  matterFields = ClientDashboardTable.matterFields;
  billFields = ClientDashboardTable.billsFields;
  contactFields = ClientDashboardTable.contactsFields;
  expenseFields = ClientDashboardTable.expenseFields;
  timeFields = ClientDashboardTable.timeFields;
  courtFields = ClientDashboardTable.courtFields;

  matterTableActions = ClientDashboardTable.matterTableActions;
  billTableActions = ClientDashboardTable.billTableActions;

  created(): void {
    this.loadJS();
    this.clientId = this.$route.params.id as string;
    this.getClient();
    this.getDashboard();
  }

  async getClient(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await clientsService.findOne(this.clientId);
      this.client = response as any;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getDashboard(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await clientReportService.getDashboard(this.clientId);
      this.dashboard = response.Data;
      response.Data.BillInflowByPracticeGroup.forEach((data: any) => {
        console.log('data', data);
        this.chartData.push({
          value: data.Amount,
          category: data.Description,
        });
      });
    } catch (error) {
      this.handleError(error);
    }
  }
}
