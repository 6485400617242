
import { Options, Vue } from "vue-class-component";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
Multiselect;
@Options({
  components: { Multiselect },
  props: {
    id: String,
    trackBy: String,
    label: String,
    valueProp: String,
    placeholder: String,
    options: Array,
  },
})
export default class NCSelect extends Vue {
  value!: any | [];
  // handleTagRemove(s: any, a: any) {
  //     console.log(s,a, 'dddddd', this.value)
  // }
  handleSelect() {
    console.log("THE VALUE", this.value);
    this.$emit("selected", this.value);
  }
}
