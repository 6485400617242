
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    id: String,
    title: String,
    customClass: String,
    iconClass: String,
  },
})
export default class NCModal2 extends Vue {}
