
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "@/shared/mixins/modal.mixin";
import { FormEvents } from "@/constants";
import { VueEditor } from "vue3-editor";
import { fileService } from "./files.service";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";

@Options({
  components: {
    VueEditor
  },
  emits: ["reloadFileLink"]
})
export default class AddFileFormLink extends mixins(ModalFormMixin) {
  modalId = "leads-add-file-form-link";
  formEvent = FormEvents.ADD_FILE_LINK;
  service = fileService;

  formData = {
    Description: "",
    FileLinkUrl: "",
    IsShared: 0,
    ModuleId: 0,
    ModuleType: 1,
    TenantFileId: 0
  };

  async uploadLogo() {
    this.isLoading = true;
    this.formData.ModuleId = this.payload.id;
    console.log("========>", this.formData);
    try {
      await this.service.add(this.formData).then(response => {
        this.isLoading = false;
        this.toast("success", "File upload successful");
        GlobalEvent.emit("reloadFileLink");
        this.modal(this.modalId, false);
        this.formData = {
          Description: "",
          FileLinkUrl: "",
          IsShared: 0,
          ModuleId: 0,
          ModuleType: 1,
          TenantFileId: 0
        };
      });
    } catch (error) {
      this.handleError(error);
    }
  }
}
