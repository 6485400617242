
  import {mixins, Options} from 'vue-class-component';
  import ModalFormMixin from '@/shared/mixins/modal.mixin';
  import {FormEvents} from '@/constants';
  import {ProgressNotesService} from './progressNotes.service';

  @Options({
    components: {
    },
  })
  
  export default class progressNotesViewForm extends mixins(ModalFormMixin) {
    modalId = 'show-progress-notes_view';
    formEvent = FormEvents.PROGRESS_NOTES_VIEW_FORM;
    service = ProgressNotesService;
    
    // hideModal() : void {
    //     this.modal(this.modalId, false);
    // }

  }
