
import { mixins, Options } from "vue-class-component";
import ModalFormMixin from "../../../../shared/mixins/modal.mixin";
import Editor from "@tinymce/tinymce-vue";
import { FormEvents } from "../../../../constants";
import { billCoverLetterTemplateService } from "./bill-cover-templates.service";

@Options({
  components: {
    editor: Editor,
  },
  emits: ["refresh"],
})
export default class BillCoverLetterTemplateForm extends mixins(ModalFormMixin) {
  modalId = "bill-cover-letter-template-form";
  formEvent = FormEvents.BILL_COVER_LETTER_TEMPLATE_FORM;
  service = billCoverLetterTemplateService;

  specialChars = [
    { text: "invoice logo", value: "[Invoice_Logo]" },
    { text: "client name", value: "[Client_Name]" },
    { text: "client address", value: "[Client_Address]" },
    { text: "invoice date", value: "[Invoice_Date]" },
    { text: "matter name", value: "[Matter_Name]" },
    { text: "matter manager", value: "[Matter_Manager]" },
    { text: "matter description", value: "[Matter_Description]" },
    { text: "fee note no", value: "[Fee_Note_No]" },
    { text: "vat no", value: "[VAT_No]" },
    { text: "contact person", value: "[Contact_Person]" },
    { text: "currency", value: "[Currency]" },
    { text: "professional fees", value: "[Professional_Fees]" },
    { text: "vat percentage", value: "[VAT_Percentage]" },
    { text: "vat amount", value: "[VAT_Amount]" },
    { text: "expense amount", value: "[Expense_Amount]" },
    { text: "account name", value: "[Account_Name]" },
    { text: "account number", value: "[Account_Number]" },
    { text: "bank name", value: "[Bank_Name]" },
    { text: "bank address", value: "[Bank_Address]" },
    { text: "sort code", value: "[Sort_Code]" },
    { text: "swift code", value: "[Swift_Code]" },
    { text: "other account info", value: "[Other_Account_Info]" },
    { text: "invoice signature", value: "[Invoice_Signature]" },
    { text: "signature name", value: "[Signature_Name]" },
    { text: "invoice fee total", value: "[Invoice_Fee_Total]" },
    { text: "invoice expense total", value: "[Invoice_Expense_Total]" },
    { text: "invoice total", value: "[Invoice_Total]" },
    { text: "invoice amount word", value: "[Invoice_Amount_Word]" },
    { text: "invoice address", value: "[Invoice_Address]" },
    { text: "notes", value: "[Notes]" },
  ];

  async savedata(payload: any) {
    await this.save(payload);
    this.$emit("refresh");
  }
}
