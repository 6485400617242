
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { settingsStore } from "../settings.store";
import { PermissionModel } from "./models/permissions.model";
import { Role } from "./models/role.model";
import { roleService } from "./role.service";
import AddRole from "./add_role_modal.vue";
import { FormEvents } from "../../../constants";

@Options({
  components: { AddRole }
})
export default class RolesMgmt extends Vue {
  formEvent = FormEvents.ADD_ROLE_FORM;
  payload: { RoleId: number; PermissionsId: number[] } = {
    RoleId: 12,
    PermissionsId: []
  };

  roleId = 12;

  permissionGroups: string[] = [];

  getPermissionGroups(): void {
    this.permissionGroups = [...new Set(this.permissions.map(item => item.PermissionGroup))];
  }

  getPermissionSubGroups(group: string): string[] {
    const permissions = this.getGroupPermissions(group);
    return [...new Set(permissions.map(item => item.SubGroup))];
  }

  getGroupPermissions(group: string): PermissionModel[] {
    return this.permissions.filter(permission => permission.PermissionGroup == group);
  }

  getSubGroupPermissions(subGroup: string, permissions: PermissionModel[]): PermissionModel[] {
    return permissions.filter(permission => permission.SubGroup == subGroup);
  }

  created(): void {
    this.getPermissionGroups();
    this.getRolePermissions();
  }

  @Watch("roleId")
  public watchRoleId(newValue: number): void {
    this.payload.RoleId = newValue;
    this.getRolePermissions();
  }

  @Watch("permissions")
  public watchPermissions(newValue: PermissionModel[]): void {
    this.getPermissionGroups();
  }

  get permissions(): PermissionModel[] {
    return settingsStore.permissions;
  }

  get roles(): Role[] {
    return settingsStore.roles;
  }

  async getRolePermissions(): Promise<void> {
    const permissions: PermissionModel[] = (await roleService.getRolePermissions(this.payload.RoleId)).Data;
    this.payload.PermissionsId = permissions.map((permission: PermissionModel) => permission.Id);
  }

  async updatePermissions(id: number): Promise<void> {
    try {
      this.isLoading = true;
      const check = this.payload.PermissionsId.includes(id);
      const response = !check
        ? await roleService.removePermissionToRole({ RoleId: this.payload.RoleId, PermissionId: id })
        : await roleService.addPermissionToRole({ RoleId: this.payload.RoleId, PermissionId: id });
      await this.getRolePermissions();
      this.toast("success", response.Message);
      this.isLoading = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  convertToSentenceCase(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  mapNameFromGroup(group: string): string {
    switch (group) {
      case "GENERAL":
        group = "GLOBAL PERMISSIONS";
        break;

      case "CRM":
        group = "CRM";
        break;

      case "CASEMANAGER":
        group = "MATTER PERMISSIONS";
        break;

      case "TIME":
        group = "TIME PERMISSIONS";
        break;

      case "DOCUMENTS":
        group = "DOCUMENTS PERMISSIONS";
        break;

      case "ACCOUNTS":
        group = "FINANCE PERMISSIONS";
        break;

      default:
        group = "OTHERS";
        break;
    }
    return group;
  }
}
