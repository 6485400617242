
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { mixins, Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../constants";
import { settingsStore } from "../settings.store";
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";
import { Employee } from "../../auth/auth.model";
import ConvertToUser from "./convert_to_user_form.vue";
import AddEmployee from "./add_employee_form.vue";
import DataTableMixin from "../../../shared/mixins/datatable.mixin";
// import { GlobalEvent } from "../../../../shared/plugins/tiny-emitter";

@Options({
  components: { ConvertToUser, AddEmployee }
})
export default class EmployeesMgmt extends mixins(DataTableMixin) {
  reloadStore = settingsStore.AllEmployees;
  formEvent = FormEvents.ADD_EMPLOYEE_FORM;

  get employees(): Employee[] {
    return settingsStore.employees;
  }

  created(): void {
    // settingsStore.AllEmployees();
  }

  fields = [
    {
      title: "Number",
      name: "EmployeeNumber"
    },
    {
      title: "First Name",
      name: "FirstName"
    },
    {
      title: "Surname",
      name: "Surname",
      center: false
    },
    {
      title: "Other Name",
      name: "OtherName"
    },
    {
      title: "Telephone",
      name: "PhoneNumber"
    },
    {
      title: "Email Address",
      name: "EmailAddress"
    },
    {
      title: "Date Created",
      name: "DateCreated"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];

  tableActions = [
    {
      name: "Edit Employee",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: any): void => {
        GlobalEvent.emit(FormEvents.ADD_EMPLOYEE_FORM, prop);
      }
    },
    {
      name: "Manage User Account",
      iconClass: "icon ni ni-unlock",
      showIcon: false,
      action: (prop: any): void => {
        console.log("Edditignin", prop);
        GlobalEvent.emit(FormEvents.EMPLOYEE_TO_USER_FORM, prop);
      }
    }
  ];
}
