
import { FormEvents } from "@/constants";
import { settingsStore } from "@/modules/settings/settings.store";
import { Options, Vue, mixins } from "vue-class-component";
import { Provide } from "vue-property-decorator";
import { leadsService } from "./leads.service";
import newLeadsForm from "./new-leads-form.vue";
import CompanyForm from "@/modules/crm/companies/companyForm.vue";
import contactForm from "@/modules/crm/contact/contactForm.vue";
import { formActionsService } from "./form-actions.service";
import { GlobalEvent } from "@/shared/plugins/tiny-emitter";
import DataTableMixin from "@/shared/mixins/datatable.mixin";
import { crmStore } from "../crm.store";
@Options({
  components: { newLeadsForm, CompanyForm, contactForm },
})
export default class LeadsPage extends mixins(DataTableMixin) {
  @Provide() handleDelete = leadsService;
  formEvent = FormEvents.ADD_LEADS;
  formEvent2 = FormEvents.COMPANIES_FORM;
  formEvent3 = FormEvents.CONTACT_FORM;
  service = leadsService;
  leads: any = [];
  isLoading = true;
  customKey = "leads-data-table";
  get AllLeads() {
    return [];
  }
  handleTableReady() {
    this.isLoading = false;
  }
  openLeadsForm(): void {
    GlobalEvent.emit(this.formEvent);
  }
  openCompanyForm(): void {
    console.log("open company form click");
    GlobalEvent.emit(this.formEvent2);
  }
  openContactForm(): void {
    console.log("open contact form click");
    this.fetchLeadsData();
    GlobalEvent.emit(this.formEvent3);
  }

  mounted() {
    GlobalEvent.on("leads-datatable-key", (_: unknown) => {
      this.customKey = this.customKey == "leads-data-table" ? "leads-data-table1" : "leads-data-table";
    });
  }

  created() {
    this.loadJS();
  }

  fetchLeadsData() {
    this.isLoading = true;
    leadsService.getLeadsData().then((res) => {
      console.log("leads respponse leads", res.data);
      this.isLoading = false;
      this.leads = res.data;
    });
  }
  fields = [
    {
      title: "SNO",
      name: "SNO",
    },
    {
      title: "Deal Name",
      name: "DealName",
    },
    {
      title: "Contact Name",
      name: "ContactFullName",
    },
    {
      title: "Company Name",
      name: "CompanyName",
    },
    {
      title: "Lead Owner",
      name: "LeadOwnerFullName",
    },
    {
      title: "Status",
      name: "LeadStageName",
    },
    {
      title: "Date Created",
      name: "FormattedDateCreated",
    },
    { name: "actions", title: "Actions", showTitle: false },
  ];

  deleteLead(id: string, self: any) {
    self.$swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result: { value: boolean }) => {
        const response = await formActionsService.remove(id);
        console.log("deleteLeadResponse", response.Message);
        if (response.Success === true) {
          // try {
          //   self.isLoading = false;
          //   self.toast("success", response.Message);
          //   this.fetchLeadsData();
          // } catch (error) {
          //   self.isLoading = false;
          //   self.toast("error", response.Message);
          //   self.handleError(error);
          // }
          self.isLoading = false;
          self.toast("success", response.Message);
          GlobalEvent.emit("leads-datatable-key");
        } else {
          self.isLoading = false;
          self.toast("error", response.Message);
        }
        return;
      });
  }

  tableActions = [
    {
      name: "View",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any, self: any): Promise<void> => {
        try {
          console.log("entering router view");
          self.$router.push({ name: "Leads Detail Page", params: { id: prop.Id } });
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      name: "Edit",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: async (prop: unknown): Promise<void> => {
        GlobalEvent.emit(this.formEvent, prop);
        GlobalEvent.emit("editLead");
      },
    },
    { type: "divider" },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: async (prop: any, self: any) => {
        this.deleteLead(prop.Id, self);
      },
    },
    { type: "divider" },
    {
      name: "Won",
      iconClass: "icon ni ni-plus-circle-fill",
      showIcon: false,
      action: async (prop: any): Promise<void> => {
        return;
      },
    },
    {
      name: "Lost",
      iconClass: "icon ni ni-minus-circle-fill",
      showIcon: false,
      action: async (prop: any): Promise<void> => {
        return;
      },
    },
  ];
}
