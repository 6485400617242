
import { mixins, Options } from 'vue-class-component';
import ModalFormMixin from '../../../../shared/mixins/modal.mixin';
import { FormEvents } from '@/constants';
import { industryService } from './industry.service';
import { settingsStore } from '../../settings.store';

@Options({})
export default class IndustryForm extends mixins(ModalFormMixin) {
  reloadStore = settingsStore.findAllIndustries;
  modalId = 'industry-form';
  formEvent = FormEvents.INDUSTRY_FORM;
  service = industryService;
  primaryKey = "IndustryId"

  saveData() : void {
    console.log("Save Data function is called");
    this.isUpdate ? this.updateData() : this.addData();
  }

  async updateData(): Promise<void> {
    try {
      console.log("Save industry data is called");
      this.isLoading = true;
      // await this.validate();
      // this.globalLoader(true);
      const response = await this.service.update(this.payload);
      console.log('The industry form payload is: ', response);
      // this.toastSuccess(response.Message);
      // this.globalLoader(false);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.reloadStore();
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }

  async addData(): Promise<void> {
    try {
      this.isLoading = true;
      // await this.validate();
      // this.globalLoader(true);
      const response = await this.service.create({IndustryName : this.payload.IndustryName});
      console.log('The industry form payload is: ', response);
      // this.toastSuccess(response.Message);
      // this.globalLoader(false);
      this.isLoading = false;
      this.toast("success", response.Message);
      this.reloadStore();
      this.modal(this.modalId, false);
    } catch (error) {
      this.handleError(error);
    }
  }
}
