
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, Vue } from "vue-class-component";
import { FormEvents } from "../../../constants";
import { settingsStore } from "../../settings/settings.store";
import { leadsService } from "./leads.service";
// import PracticeGroupForm from "./practice-group-form.vue";
// import MemberForm from "./member-form.vue";
// import PracticeAreaForm from "../practice-areas/practice-area.form.vue";
// import tableVue from '@/components/VDatatable.vue'
import { GlobalEvent } from "../../../shared/plugins/tiny-emitter";

@Options({
  components: {
  // tableVue
  }
})
export default class PracticeGroups extends Vue {
  reloadStore = settingsStore.findAllPracticeGroups;
  formEvent = FormEvents.PRACTICE_GROUP_AND_AREA_FORM;

  get practiceGroups() {
    return [];
    // return settingsStore.practiceGroups;
  }

  created(): void {
    // settingsStore.findAllPracticeGroups();
    // settingsStore.AllUsers();
    // this.loadJS();
    // leadsService.getData()
  }

  // headers = [
  //   {
  //     name: "Name",
  //     prop: "PracticeGroupName"
  //   }
  // ];

 fields = [
    {
      title: "SNO",
      name: "SNO"
    },
    {
      title: "Deal Name",
      name: "DealName"
    },
    {
      title: "Contact Person",
      name: "ContactFullName",
      center: false
    },
    {
      title: "CompanyName",
      name: "CompanyName"
    },
    {
      title: "Lead Owner",
      name: "LeadOwnerFullName"
    },
    {
      title: "Status",
      name: "LeadStageName"
    },
    {
      title: "Date Created",
      name: "FormattedDateCreated"
    },
    { name: "actions", title: "Actions", showTitle: false }
  ];


// fields = [

//     {

//        title: 'S/NO',
//       name: "ClientIds"

//     },

//     {

//       title: "Company Name",

//       name: "ClientName",
//       className:"testinhClass"

//     },

//     {

//       title: "Contact Telephone",

//       name: "TelephoneNo",

//     },

//     {

//       title: "Contact Email",

//       name: "EmailAddress",

//     },

//     {

//       title: "Date Created",

//       name: "FormattedDateCreated",

//     },

//     { name: "actions", title: "Actions", showTitle: false,  }

//   ];


  tableActions = [
    {
      name: "View Lead",
      iconClass: "icon ni ni-eye",
      showIcon: true,
      action: async (prop: any): Promise<void> => {
        // const practicegroup = await practiceGroupsService.findOne(prop.PracticeGroupId);
        // GlobalEvent.emit(FormEvents.MEMBER_FORM, practicegroup.Data);
        console.log("VIEW PAGE");
        
      }
    },
    {
      name: "Edit Details",
      iconClass: "icon ni ni-edit",
      showIcon: false,
      action: (prop: any): void => {
        console.log("Edditignin", prop);
        // GlobalEvent.emit(FormEvents.PRACTICE_GROUP_AND_AREA_FORM, prop);
      }
    },
    {
       type: 'divider'
    },
    {
      name: "Delete",
      iconClass: "icon ni ni-trash text-danger",
      showIcon: false,
      action: (prop: any) => {
        // this.removeTableData(practiceGroupsService.remove, prop.PracticeGroupId);
      }
    }
    // {
    //   name: "Delete",
    //   iconClass: "icon ni ni-trash text-danger",
    //   showIcon: false,
    //   action: (prop: any) => {

    //   }
    // }
  ];
}
