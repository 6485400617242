
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    id: String,
    title: String,
    customClass: String,
  },
})
export default class NCModal extends Vue {}
